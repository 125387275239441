import React from 'react'
import { BadgeStyled } from '../../styles/admin/Table.styled'

function Badge(props) {
    return (
        <>
            <BadgeStyled {...props}>
                {props.children}
            </BadgeStyled>
        </>
    )
}

export default Badge