import styled, { css } from "styled-components";

export const RoleWrapper = styled.div`
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    position: relative;
    gap: 40px;
    padding-bottom: 2rem;
    background: #fff;
    border-radius: 8px;
    border: 1px solid #edeff5;
    margin-top: 64px;
`;

export const RoleNav = styled.div`
    width: 20%;
`;

export const RoleMain = styled.div`
    flex: 1;
`;

export const CategoryTitle = styled.h4`
    font-size: 1.3rem;
    margin-top: 20px;
    margin-left: 30px;
    margin-bottom: 20px;
    color: #000;
`;

export const NavMenu = styled.div`
   & ul {
        margin: 0;
        padding: 0;
        list-style: none;
    }

    & ul button {
        display: block;
        border: none;
        font-size: 0.95rem;
        color: var(--color-text-primary);
        padding: 20px 30px;
        border-bottom: 1px solid #edf1f2;
        font-weight: normal;
        text-decoration: none;
        background: none;
        
        width: 100%;
        text-align: left;
        cursor: pointer;
    
    }

    & ul button:hover {
        background-color: #fcfcfc;
    }

    & li:last-child button {
        border-bottom: none;
    }

    & li.active button {
        background-color: rgba(223, 38, 33, .2);
    }

    & li.role-nav-btn button {
        color: #ccc;
        font-weight: bold;
    }
    & li.role-nav-btn button:hover {
        color: ${({ theme: { colors } }) => colors.primaryColor} !important;
    }
`;

export const RoleTitleHeader = styled.div`
    padding-top: 20px;
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    & h4 {
        font-size: 1.5rem;
    }

    & p {
        font-weight: 400;
        margin-top: 10px;
    }

    & a {
        display: inline-block;
        color: var(--color-text-primary);
        color: var(--color-text-primary);
        font-size: 0.9rem;
        margin-right: 10px;
    }

    & a:hover {
        color: var(--color-primary);
    }
`;

export const RoleTitleSubHeader = styled.div`
    padding: 15px 30px;
    border-bottom: 1px solid #edf1f2;
    border-top: 1px solid #edf1f2;
    font-size: 0.95rem;
    color: #a4a59a;
    font-weight: 300;

    & span i {
        margin-right: 10px;
    }
`;

export const RoleDetails = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    gap: 40px;
`;

export const RolePermissionsHeader = styled.div`
   padding: 15px 20px;
   
   & h5 {
        font-size: 1rem;
        font-weight: 500;
    }
`;

export const RolePermissionsWrapper = styled.div`
    width: 50%;
    border-radius: 10px;
    margin: 30px;
    overflow-x: hidden;

    ${props => props.negative && css`
        border: 1px solid #FFE7E9;

        ${RolePermissionsHeader} {
            background-color: #FFE7E9;

            & h5 {
                color: #F36775;
            } 
        }
    `}

    ${(props) => props.positive && css`
        border: 1px solid #1ecb782e;

        & ${RolePermissionsHeader} {
            background-color: #1ecb782e;

            & h5 {
                color: #1ECB78;
            } 
        }
    `};

    & span i {
        margin-right: 10px;
    }
`;



export const RolePermissionsList = styled.div`
   & ul {
        margin: 0;
        padding: 0;
        list-style: none;
    }

    & ul li {
        display: block;
        font-size: 0.95rem;
        color: $primary-text-color;
        padding: 20px 30px;
        border-bottom: 1px solid #edf1f2;
        font-weight: normal;
    }

    & li:last-child {
        border-bottom: none;
    }
`;




