import {createSlice, createAsyncThunk} from '@reduxjs/toolkit'
import { Routes } from '../../core/routes/apiRoutes'
import caregiverService from './caregiverService'

const initialState = {
    data: null,
    isError: false,
    isSuccess: false,
    isLoading: false,
    message: '',
    errors: null,
}

//Get - Requires Admin Authentication
export const getCaregivers = createAsyncThunk(Routes.getCaregiverUrl, async ( url, thunkAPI) => {
    try{
        return await caregiverService.getCaregivers(url);
    }catch (error) {
        var errorData = {message: (error.response && error.response.data && error.response.data.message) || error.message || error.toString()}
        if(error.response.status === 422){
            errorData['errors'] = error.response.data.errors;
            
        }
        return thunkAPI.rejectWithValue(errorData);
    }
})

export const getApprovedCaregivers = createAsyncThunk(Routes.getCaregiverUrl + "/approved", async ( url, thunkAPI) => {
    try{
        return await caregiverService.getCaregivers(url, 'approved');
    }catch (error) {
        var errorData = {message: (error.response && error.response.data && error.response.data.message) || error.message || error.toString()}
        if(error.response.status === 422){
            errorData['errors'] = error.response.data.errors;
            
        }
        return thunkAPI.rejectWithValue(errorData);
    }
})

//delete - Requires Admin Authentication
export const deleteCaregiver = createAsyncThunk(Routes.getCaregiverUrl+"/delete", async ( email, thunkAPI) => {
    try{
        return await caregiverService.deleteCaregiver(email);
    }catch (error) {
        var errorData = {message: (error.response && error.response.data && error.response.data.message) || error.message || error.toString()}
        if(error.response.status === 422){
            errorData['errors'] = error.response.data.errors;
            
        }
        return thunkAPI.rejectWithValue(errorData);
    }
})


//accept - Requires Admin Authentication
export const acceptCaregiverApplication = createAsyncThunk(Routes.getCaregiverUrl+"/accept", async ( email, thunkAPI) => {
    try{
        return await caregiverService.acceptCaregiverApplication(email);
    }catch (error) {
        var errorData = {message: (error.response && error.response.data && error.response.data.message) || error.message || error.toString()}
        if(error.response.status === 422){
            errorData['errors'] = error.response.data.errors;
            
        }
        return thunkAPI.rejectWithValue(errorData);
    }
})

//deny - Requires Admin Authentication
export const denyCaregiverApplication = createAsyncThunk(Routes.getCaregiverUrl+"/deny", async ( email, thunkAPI) => {
    try{
        return await caregiverService.denyCaregiverApplication(email);
    }catch (error) {
        var errorData = {message: (error.response && error.response.data && error.response.data.message) || error.message || error.toString()}
        if(error.response.status === 422){
            errorData['errors'] = error.response.data.errors;
            
        }
        return thunkAPI.rejectWithValue(errorData);
    }
})

export const assignServiceUser = createAsyncThunk(Routes.getCaregiverUrl+"/assign", async (data, thunkAPI) => {
    try{
        
        const {caregiver,service_user, startDate, startTime, endTime} = data;
        return await caregiverService.assignServiceUser(caregiver, service_user, startDate, startTime, endTime);
    }catch (error) {
        var errorData = {message: (error.response && error.response.data && error.response.data.message) || error.message || error.toString()}
        if(error.response.status === 422){
            errorData['errors'] = error.response.data.errors;
            
        }
        return thunkAPI.rejectWithValue(errorData);
    }
})

export const unassignServiceUser = createAsyncThunk(Routes.getCaregiverUrl+"/unassign", async (email, thunkAPI) => {
    try{
        
        return await caregiverService.unassignServiceUser(email);
    }catch (error) {
        var errorData = {message: (error.response && error.response.data && error.response.data.message) || error.message || error.toString()}
        if(error.response.status === 422){
            errorData['errors'] = error.response.data.errors;
            
        }
        return thunkAPI.rejectWithValue(errorData);
    }
})

export const caregiverSlice = createSlice({
    name: 'caregiver',
    initialState,
    reducers: {
        reset: (state) => {
            state.isLoading = false;
            state.isSuccess = false;
            state.isError = false;
            state.message = '';
            state.errors = null;
        }
    }, 
    extraReducers: (builder) => {
        function sharedRejectedReducer(state, action){
            state.isLoading = false
            state.isError = true
            state.message = action.payload.message
            state.errors = action.payload.errors
        }
        function sharedPendingReducer(state, action){
            state.isLoading = true
        }

        builder
       
        .addCase(getCaregivers.pending,sharedPendingReducer)
        .addCase(getCaregivers.rejected, sharedRejectedReducer)
        .addCase(getApprovedCaregivers.pending,sharedPendingReducer)
        .addCase(getApprovedCaregivers.rejected, sharedRejectedReducer)
        .addCase(deleteCaregiver.pending,sharedPendingReducer)
        .addCase(deleteCaregiver.rejected, sharedRejectedReducer)
        .addCase(acceptCaregiverApplication.pending,sharedPendingReducer)
        .addCase(acceptCaregiverApplication.rejected, sharedRejectedReducer)
        .addCase(denyCaregiverApplication.pending,sharedPendingReducer)
        .addCase(denyCaregiverApplication.rejected, sharedRejectedReducer)
        .addCase(assignServiceUser.pending,sharedPendingReducer)
        .addCase(assignServiceUser.rejected, sharedRejectedReducer)
        .addCase(unassignServiceUser.pending,sharedPendingReducer)
        .addCase(unassignServiceUser.rejected, sharedRejectedReducer)

         //Get
         .addCase(getCaregivers.fulfilled, (state,action) => {
            state.isLoading = false
            state.isSuccess = true
            state.isError = false
            state.data = action.payload
            state.errors = null

        })

        //Get
        .addCase(getApprovedCaregivers.fulfilled, (state,action) => {
            state.isLoading = false
            state.isSuccess = true
            state.isError = false
            state.data = action.payload
            state.errors = null

        })

         //Delete
         .addCase(deleteCaregiver.fulfilled, (state,action) => {
            state.isLoading = false
            state.isSuccess = true
            state.message = action.payload.message
            state.isError = false
            state.errors = null
        })

        //Accept
        .addCase(acceptCaregiverApplication.fulfilled, (state,action) => {
            state.isLoading = false
            state.isSuccess = true
            state.message = action.payload.message
            state.isError = false
            state.errors = null
        })

        //Deny
        .addCase(denyCaregiverApplication.fulfilled, (state,action) => {
            state.isLoading = false
            state.isSuccess = true
            state.message = action.payload.message
            state.isError = false
            state.errors = null
        })

        //Assign
        .addCase(assignServiceUser.fulfilled, (state,action) => {
            state.isLoading = false
            state.isSuccess = true
            state.message = action.payload.message
            state.isError = false
            state.errors = null
        })

        //Unassign
        .addCase(unassignServiceUser.fulfilled, (state,action) => {
            state.isLoading = false
            state.isSuccess = true
            state.message = action.payload.message
            state.isError = false
            state.errors = null
        })

       
    }
})

export const {reset} = caregiverSlice.actions
export default caregiverSlice.reducer