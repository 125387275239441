import {createSlice, createAsyncThunk} from '@reduxjs/toolkit'
import { Routes } from '../../core/routes/apiRoutes'
import subscriptionPlanService from './subscriptionPlanService'

const initialState = {
    data: null,
    isError: false,
    isSuccess: false,
    isLoading: false,
    message: '',
    errors: null,
}

export const publicGetSubscriptionPlans = createAsyncThunk(Routes.getSubscriptionPlansUrl+"/public", async ( url, thunkAPI) => {
    try{
        return await subscriptionPlanService.getPublicSubscriptionPlans(url);
    }catch (error) {
        var errorData = {message: (error.response && error.response.data && error.response.data.message) || error.message || error.toString()}
        if(error.response.status === 422){
            errorData['errors'] = error.response.data.errors;
            
        }
        return thunkAPI.rejectWithValue(errorData);
    }
})

export const getSubscriptionPlans = createAsyncThunk(Routes.getSubscriptionPlansUrl, async ( url, thunkAPI) => {
    try{
        return await subscriptionPlanService.getSubscriptionPlans(url);
    }catch (error) {
        var errorData = {message: (error.response && error.response.data && error.response.data.message) || error.message || error.toString()}
        if(error.response.status === 422){
            errorData['errors'] = error.response.data.errors;
            
        }
        return thunkAPI.rejectWithValue(errorData);
    }
})

export const createSubscriptionPlan = createAsyncThunk(Routes.createSubscriptionPlanUrl, async ( formData, thunkAPI) => {
    try{
        return await subscriptionPlanService.createSubscriptionPlan(formData);
    }catch (error) {
        var errorData = {message: (error.response && error.response.data && error.response.data.message) || error.message || error.toString()}
        if(error.response.status === 422){
            errorData['errors'] = error.response.data.errors;
            
        }
        return thunkAPI.rejectWithValue(errorData);
    }
})

//delete admin
export const showSubscriptionPlan = createAsyncThunk(Routes.showSubscriptionPlanUrl, async ( slug, thunkAPI) => {
    try{
        return await subscriptionPlanService.showSubscriptionPlan(slug);
    }catch (error) {
        var errorData = {message: (error.response && error.response.data && error.response.data.message) || error.message || error.toString()}
        if(error.response.status === 422){
            errorData['errors'] = error.response.data.errors;
            
        }
        return thunkAPI.rejectWithValue(errorData);
    }
})

//delete admin
export const deleteSubscriptionPlan = createAsyncThunk(Routes.getSubscriptionPlansUrl+"/delete", async ( slug, thunkAPI) => {
    try{
        return await subscriptionPlanService.deleteSubscriptionPlan(slug);
    }catch (error) {
        var errorData = {message: (error.response && error.response.data && error.response.data.message) || error.message || error.toString()}
        if(error.response.status === 422){
            errorData['errors'] = error.response.data.errors;
            
        }
        return thunkAPI.rejectWithValue(errorData);
    }
})

//Update
export const updateSubscriptionPlan = createAsyncThunk(Routes.updateSubscriptionPlanUrl, async (data, thunkAPI) => {
    try{
        return await subscriptionPlanService.updateSubscriptionPlan(data);
    }catch (error) {
        var errorData = {message: (error.response && error.response.data && error.response.data.message) || error.message || error.toString()}
        if(error.response.status === 422){
            errorData['errors'] = error.response.data.errors;
            
        }
        return thunkAPI.rejectWithValue(errorData);
    }
})



export const subscriptionPlanSlice = createSlice({
    name: 'subscriptionPlan',
    initialState,
    reducers: {
        reset: (state) => {
            state.isLoading = false;
            state.isSuccess = false;
            state.isError = false;
            state.message = '';
            state.errors = null;
            state.data = null;
        }
    }, 
    extraReducers: (builder) => {
        function sharedRejectedReducer(state, action){
            state.isLoading = false
            state.isError = true
            state.message = action.payload.message
            state.errors = action.payload.errors
        }
        function sharedPendingReducer(state, action){
            state.isLoading = true
        }

        builder
       
        .addCase(getSubscriptionPlans.pending,sharedPendingReducer)
        .addCase(getSubscriptionPlans.rejected, sharedRejectedReducer)
        .addCase(createSubscriptionPlan.pending,sharedPendingReducer)
        .addCase(createSubscriptionPlan.rejected, sharedRejectedReducer)
        .addCase(deleteSubscriptionPlan.pending,sharedPendingReducer)
        .addCase(deleteSubscriptionPlan.rejected, sharedRejectedReducer)
        .addCase(updateSubscriptionPlan.pending,sharedPendingReducer)
        .addCase(updateSubscriptionPlan.rejected, sharedRejectedReducer)
        .addCase(showSubscriptionPlan.pending,sharedPendingReducer)
        .addCase(showSubscriptionPlan.rejected, sharedRejectedReducer)
        .addCase(publicGetSubscriptionPlans.pending,sharedPendingReducer)
        .addCase(publicGetSubscriptionPlans.rejected, sharedRejectedReducer)

         //Get Public
        .addCase(publicGetSubscriptionPlans.fulfilled, (state,action) => {
            state.isLoading = false
            state.isSuccess = true
            state.isError = false
            state.data = action.payload
            state.errors = null

        })
         //Get
        .addCase(getSubscriptionPlans.fulfilled, (state,action) => {
            state.isLoading = false
            state.isSuccess = true
            state.isError = false
            state.data = action.payload
            state.errors = null

        })

        .addCase(showSubscriptionPlan.fulfilled, (state,action) => {
            state.isLoading = false
            state.isSuccess = true
            state.isError = false
            state.data = action.payload
            state.errors = null

        })

         //Create
        .addCase(createSubscriptionPlan.fulfilled, (state,action) => {
            state.isLoading = false
            state.isSuccess = true
            state.isError = false
            state.data = action.payload
            state.message = "Created Plan Successfully"
            state.errors = null

        })

         //Delete
         .addCase(deleteSubscriptionPlan.fulfilled, (state,action) => {
            state.isLoading = false
            state.isSuccess = true
            state.message = action.payload.message
            state.isError = false
            state.errors = null
        })

        //change Status
        .addCase(updateSubscriptionPlan.fulfilled, (state,action) => {
            state.isLoading = false
            state.isSuccess = true
            state.message = action.payload.message
            state.isError = false
            state.errors = null
        })

        
    }
})

export const {reset} = subscriptionPlanSlice.actions
export default subscriptionPlanSlice.reducer