import React, {useState, useEffect } from "react";
import { useSelector, useDispatch} from "react-redux";
import { AdminModal, Badge, ChangeMeetingStatusPanel, CustomDataTable, PopupMenu, RequestPanel } from "../../../components";
import { DashboardRow } from "../../../styles/admin/CardBox.styled";
import { getMeetingRequestsBadge } from "../../../misc/helpers";
import { LoadingBar } from "../../../components/loading_bar/LoadingBar";
import { getDemoRequests, deleteDemoRequest, changeStatus, reset } from "../../../features/request_demo/demoRequestSlice";
import { Routes } from "../../../core/routes/apiRoutes";
import useResource from "../../../core/hooks/useResource";
import moment from "moment";

const perPage = 30;
let modalContent;
function DemoRequests() {
	const dispatch = useDispatch();
	const [open, setOpen] = useState(false);

	const handleOpen = (id) => {
		const modalData = tableData.filter((item) => item.id === id);
		if (modalData) {
			const data = modalData[0];
			modalContent =  <RequestPanel data={data} />;
			setOpen(true);
		}
	};
	
	const onChangeStatus = (id) => {
		const modalData = tableData.filter((item) => item.id === id);
		if (modalData) {
			const data = modalData[0];
			modalContent =  <ChangeMeetingStatusPanel data={data} changeStatus={changeRequestStatus} />;
			setOpen(true);
		}
	};

	const changeRequestStatus = (id, formData) => {
		setOpen(false);
        ref.current.continuousStart();
        setNeedsReload(true);
		dispatch(changeStatus({id: id, data: formData}));
	}

	const handleClose = () => setOpen(false);

	const prepareTableData = (response, page) => {
		var rows = [];
		const tempData = response.data;

		

		for (let i = 0; i < tempData.length; i++) {
			var item = {
				sn: i + 1 + page,
				id: tempData[i].id,
				name: tempData[i].name,
				email: tempData[i].email,
				phoneNumber: tempData[i].phone_number,
				organization: tempData[i].organization,
				jobTitle: tempData[i].job_title,
				status: tempData[i].status,
				dateCreated: moment.unix(tempData[i].created_at).fromNow(),
			};

			item['details'] = {
				"Name": item.name,
				"Email": item.email,
				"Phone Number": item.phoneNumber,
				"Organization": item.organization,
				"Job Title": item.jobTitle,
				"Date Created": item.dateCreated,
			};

			rows.push(item);
		}

		return rows;
	};

	const { onPrevPage, onNextPage, page, tableData, ref, isLoading, onDelete, onSearch, setNeedsReload} =
		useResource({
			selector: useSelector((state) => state.demoRequest),
			perPage: perPage,
			getReducer: getDemoRequests,
			resetReducer: reset,
			deleteReducer: deleteDemoRequest,
			renderTableData: prepareTableData,
			routes: {
				getRoute: Routes.getDemoRequestsUrl,
			},
		});

	const actions = [
		{ title: "View Details", onClick: handleOpen },
		{ title: "Change Status", onClick: onChangeStatus },
		{ title: "Delete", onClick: onDelete },
	];

	const columns = [
		{
			field: "sn",
			headerName: "S/N",
			minWidth: 10,
			flex: 1,
		},
		{
			field: "name",
			headerName: "Name",
			minWidth: 100,
			flex: 2,
		},

		{
			field: "email",
			headerName: "Email Address",
			minWidth: 100,
			flex: 2,
		},
		{
			field: "phoneNumber",
			headerName: "Phone Number",
			minWidth: 100,
			flex: 2,
		},
		{
			field: "jobTitle",
			headerName: "Job Title",
			minWidth: 100,
			flex: 1,
		},
		{
			field: "organization",
			headerName: "Organization",
			minWidth: 100,
			flex: 1,
		},
		
		{
			field: "dateCreated",
			headerName: "Date Created",
			minWidth: 100,
			flex: 1,
		},
		{
			field: "status",
			headerName: "Status",
			minWidth: 100,
			flex: 2,
			sortable: false,
			renderCell: (params) => {
				const badge = getMeetingRequestsBadge(params.row.status);
				return <Badge {...badge.badgeType}>{badge.badgeText}</Badge>;
			},
			disableClickEventBubbling: true,
		},
		{
			field: "actions",
			headerName: "",
			minWidth: 100,
			flex: 1,
			sortable: false,
			renderCell: (params) => {
				return <PopupMenu id={params.row.id} options={actions} />;
			},
			disableClickEventBubbling: true,
		},
	];

	useEffect(() => {
		document.title = "Demo Requests - Greymate Care Admin";
	}, []);

	return (
		<>
			<LoadingBar ref={ref} shadow={true} />
			<h1>Demo Requests</h1>

			<DashboardRow>
				<CustomDataTable
					disableSelectionOnClick
					pageSize={perPage}
					pagination="false"
					rowsPerPageOptions={[]}
					loading={isLoading}
					columns={columns}
					rows={tableData}
					hideFooter={true}
					prev={onPrevPage}
					next={onNextPage}
					currentPage={page}
					onSearchChange={onSearch}
				/>
			</DashboardRow>
			<AdminModal open={open} onClose={handleClose}>
				{modalContent}
            </AdminModal>
		</>
	);
}

export default DemoRequests;
