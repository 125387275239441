import GlobalStyles from "../../styles/GlobalStyles";
import AppTheme from "../../styles/AppTheme";
import React, {useEffect, useState, useRef} from "react";
import { useSelector, useDispatch } from 'react-redux'
import {useNavigate} from 'react-router-dom'
import {toast} from 'react-toastify'
import { Outlet } from "react-router-dom";
import { AdminBody, AdminContentArea, AdminHeader, AdminHeaderLinks, LoggedInUser, LogoutBtn, MenuBtn } from "../../styles/admin/Admin.styled";
import {AdminPanelStyled } from "../../styles/admin/AdminSidebar.styled";
import { RiLoginBoxLine, RiMenu3Line, RiUser3Fill } from "react-icons/ri";
import { AdminSidebar } from "../../components";
import { AppRoutes } from "../../core/routes/routes";
import {logout, reset} from '../../features/auth/authSlice'
import { LoadingBar } from "../../components/loading_bar/LoadingBar";



function Admin() {
  const [isCollapseSidebar, setIsCollapseSidebar] = useState(false);

  const user = JSON.parse(localStorage.getItem('user'));
  const ref = useRef(null)

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const {isError, isSuccess, isLoading, message} = useSelector((state) => state.auth)
  useEffect(()=>{
    if(isError){
        toast.error(message)
        ref.current.complete()
    }

    if(isSuccess) {
        ref.current.complete()
        navigate(AppRoutes.LoginURL)
    }

    dispatch(reset())
     // eslint-disable-next-line
}, [isError, isSuccess, message])

  const toggleIsCollapseNav = () => {
    setIsCollapseSidebar(!isCollapseSidebar);
  };

  const handleLogout = (e) => {
      if(!isLoading){
        ref.current.continuousStart();
        dispatch(logout())
      }
      
  }

  return (
    <AppTheme>
        <GlobalStyles />
        <LoadingBar color="#f11946" ref={ref} shadow={true} />
        <AdminPanelStyled>
          <AdminSidebar isCollapseSidebar={isCollapseSidebar} toggleIsCollapseNav={toggleIsCollapseNav}/>
          <AdminBody>
            <AdminHeader className="main__header">
              <MenuBtn onClick={toggleIsCollapseNav}>
                <RiMenu3Line size={14} />                       
              </MenuBtn>
              <AdminHeaderLinks>
                <LoggedInUser>
                  <RiUser3Fill size={14}/>
                  <span>Hello, {user != null ? user['last_name'] +" "+user['first_name'] : ''}</span>
                </LoggedInUser>

                <LogoutBtn onClick={handleLogout}>
                  <RiLoginBoxLine size={14}/>
                  <span>Logout</span>
                </LogoutBtn>
                
              </AdminHeaderLinks>
            </AdminHeader>
            <AdminContentArea>
              <Outlet />
            </AdminContentArea>
            {/* <div className="main__footer">
              <h2>hello</h2>
            </div> */}
          </AdminBody>
        </AdminPanelStyled>
      
    </AppTheme>
  );
}

export default Admin;
