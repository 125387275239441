import React, { useEffect, useState } from 'react'
import { AdminModal, AssignedDetailsPanel, AssignPanel, Badge, CustomDataTable, DetailsPanel, PopupMenu } from '../../../components';
import { DashboardRow } from '../../../styles/admin/CardBox.styled';
import {getAssignCaregiversBadge, getCaregiversJobStatusBadge } from '../../../misc/helpers';
import { LoadingBar } from '../../../components/loading_bar/LoadingBar';
import useResource from '../../../core/hooks/useResource';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment/moment';
import { assignServiceUser, deleteCaregiver, getApprovedCaregivers, reset, unassignServiceUser } from '../../../features/caregiver/caregiverSlice';
import { Routes } from '../../../core/routes/apiRoutes';
import Can from '../../../components/auth_gate/Can';
import { ASSIGN_CAREGIVERS, CREATE_AND_MANAGE_CAREGIVERS } from '../../../misc/constants';

let modalContent;

function AssignCaregivers() {
    const dispatch = useDispatch();
    const perPage = 30;
    const [open, setOpen] = useState(false);

   


	const handleClose = () => setOpen(false);
   

	const prepareTableData = (response, page) => {
		var rows = [];
		const tempData = response.data;

		for (let i = 0; i < tempData.length; i++) {
			const itemData = tempData[i];
			const itemProfile = tempData[i]?.profile;
			const itemBankDetails = tempData[i]?.bank_details;
			const itemPreference = tempData[i]?.preference;
            const itemServiceUser = tempData[i]?.service_user;
			var item = {
				id: i + 1 + page,
				sn: i + 1 + page,
                serviceUserId: itemServiceUser?.id,
                avatar: itemData.avatar,
				name: itemData.last_name + " " + itemData.first_name,
				email: itemData.email,
				phone: itemData.phone_number,
				gender: itemData.gender,
                dateSubmitted: moment.unix(itemData.joined_at).fromNow(),
                dateApproved: moment.unix(itemData.date_approved).fromNow(),
				status: itemData.status,
				jobStatus: itemData.job_status,
			};

			item["profile"] = {
                name: item.name,
                email: item.email,
                avatar: item.avatar,
                profile: {
                    'Full Name': item.name,
                    'Phone Number': item.phone,
                    'Gender': item.gender,
                    'Date of Birth': moment(itemProfile.date_of_birth).format('l'),
                    'Address': itemProfile.address,
                    'Bio': itemProfile.bio,
                    "Guarantors Name": itemProfile.guarantors_name,
                    "Guarantors Phone Number": itemProfile.guarantors_phone_number,
                    "Guarantors Address": itemProfile.guarantors_address,
                },
                payment: {
                    'Account Name': itemBankDetails.account_name,
                    'Account Number': itemBankDetails.account_number,
                    'Bank': itemBankDetails.bank_name,
                },
                skills: {
                    'Certifications': itemProfile.certification,
                    'Years of Experience': itemProfile.experience,
                    'Skills': itemProfile.services?.join(', '),
                    'Health conditions': itemProfile.conditions?.join(', '),
                    'Work Schedule':itemPreference.availability?.join(', '),
                    'Living Arrangements':itemPreference.living_arrangements?.join(', '),
                    'Contract Type':itemPreference.contract_types?.join(', '),
                },
                documents: [
                    {
                        'name': 'Photo ID',
                        'type': 'jpg',
                        'link': itemProfile.photo_id,
                    },
                    {
                        'name': 'Certificate',
                        'type': 'jpg',
                        'link': itemProfile.certificate,
                    },
                    {
                        'name': 'Proof of Address',
                        'type': 'jpg',
                        'link': itemProfile.proof_of_address,
                    },
                ],
			};

			rows.push(item);
		}

		return rows;
	};

	const {
		onPrevPage,
		onNextPage,
		page,
		tableData,
		ref,
		isLoading,
		onDelete,
		onSearch,
        setNeedsReload,
	} = useResource({
		selector: useSelector((state) => state.caregiver),
		perPage: perPage,
		getReducer: getApprovedCaregivers,
		resetReducer: reset,
		deleteReducer: deleteCaregiver,
		renderTableData: prepareTableData,
		routes: {
			getRoute: Routes.getCaregiverUrl,
            searchRoute: Routes.getCaregiverUrl + "?status=approved&"
		},
	});

    const onAssign = (caregiver, service_user, startDate, startTime, endTime) => {
        setOpen(false);
        const data = {
            caregiver,
            service_user,
            startDate,
            startTime,
            endTime,
        };
        ref.current.continuousStart();
        setNeedsReload(true);
        dispatch(assignServiceUser(data));
    }

    const handleUnassign = (email) => {
        ref.current.continuousStart();
        setNeedsReload(true);
        dispatch(unassignServiceUser(email));
    }

	const handleViewDetails = (id) => {
		const caregiver = tableData.filter((item) => item.email === id);
		if (caregiver) {
            const data = caregiver[0];
			modalContent = <DetailsPanel data={data.profile} onEdit={handleClose} hideActions={true} />
            
			setOpen(true);
		}
	};

    const handleAssign = (id) => {
		const caregiver = tableData.filter((item) => item.email === id);
		if (caregiver) {
            const data = caregiver[0];
			modalContent =  <AssignPanel data={data} onAssign={(id, startDate, startTime, endTime)=>onAssign(data.email, id, startDate, startTime, endTime)}/>;
			setOpen(true);
		}
	};


    const handleViewWorkDetails = (id) => {
		const caregiver = tableData.filter((item) => item.email === id);
		if (caregiver) {
            const data = caregiver[0];
			modalContent = <AssignedDetailsPanel data={data}/>;
			setOpen(true);
		}
	};

    const getActions = () => {
        let actions = [];

        if(Can(CREATE_AND_MANAGE_CAREGIVERS)) {
             actions.push({ title: "View Details", onClick: handleViewDetails });
             actions.push({ title: "Delete", onClick: onDelete });
        }

        if(Can(ASSIGN_CAREGIVERS)){
            actions.push({ title: "Assign Service User", onClick: handleAssign });
        }

        return actions;
    };

    const getAssignActions = () => {
        let actions = [];

        if(Can(CREATE_AND_MANAGE_CAREGIVERS)) {
             actions.push({ title: "View Details", onClick: handleViewDetails });
             actions.push({ title: "View Work Details", onClick: handleViewWorkDetails },);
             actions.push({ title: "Delete", onClick: onDelete });
        }

        if(Can(ASSIGN_CAREGIVERS)){
            actions.push({ title: "Change Assigned", onClick: handleAssign });
            actions.push({ title: "Unassign", onClick: handleUnassign },);
        }

        return actions;
    };

    useEffect(() => {
        document.title = "Assign Caregivers - Greymate Care Admin";
    }, []);


    const columns = [
        {
            field: "sn",
            headerName: "S/N",
            minWidth: 40,
            
        },
        {
            field: 'name',
            headerName: 'Full Name',
            minWidth: 100,
            flex: 1
        },
        {
            field: 'email',
            headerName: 'Email Address',
            minWidth: 100,
            flex: 2
        },
        {
            field: 'gender',
            headerName: 'Gender',
            minWidth: 100,
            flex: 1
        },
        {
            field: 'phone',
            headerName: 'Phone Number',
            minWidth: 100,
            flex: 1
        },
        {
            field: 'dateApproved',
            headerName: 'Date Approved',
            minWidth: 100,
            flex: 1
        },
        {
            field: 'status',
            headerName: 'Status',
            minWidth: 100,
            flex: 1,
            renderCell: (params) => {
                const badge = getAssignCaregiversBadge(params.row.status);
                return (<Badge {...badge.badgeType}>{badge.badgeText}</Badge>);
            },
            disableClickEventBubbling: true,
        },
        {
            field: 'job_status',
            headerName: 'Job Status',
            minWidth: 100,
            flex: 1,
            renderCell: (params) => {
                const status = params.row.jobStatus;
                if(status == null){
                    return;
                }
                const badge = getCaregiversJobStatusBadge(params.row.jobStatus);
                return (<Badge {...badge.badgeType}>{badge.badgeText}</Badge>);
            },
            disableClickEventBubbling: true,
        },
		{
			field: "actions",
			headerName: "",
			minWidth: 100,
			flex: 1,
			sortable: false,
			renderCell: (params) => {
                const status = params.row.status;
				return <PopupMenu id={params.row.email} options={status ==='unassigned' ? getActions() : getAssignActions()} />;
			},
			disableClickEventBubbling: true,
		},
       
    ];


    return (
        <>
            <LoadingBar ref={ref} shadow={true} />
            <h1>Assign Caregivers</h1>

            <DashboardRow>
				<CustomDataTable
					disableSelectionOnClick
					pageSize={perPage}
					pagination="false"
					rowsPerPageOptions={[]}
					loading={isLoading}
					columns={columns}
					rows={tableData}
					hideFooter={true}
					prev={onPrevPage}
					next={onNextPage}
					currentPage={page}
					onSearchChange={onSearch}
				/>
			</DashboardRow>
            <AdminModal open={open} onClose={handleClose}>
                {modalContent}

            </AdminModal>
        </>

    )
}

export default AssignCaregivers