import {createSlice, createAsyncThunk} from '@reduxjs/toolkit'
import { Routes } from '../../core/routes/apiRoutes'
import authService from './authService'

const user = JSON.parse(localStorage.getItem('user'))

const initialState = {
    user: user ? user : null,
    isError: false,
    isSuccess: false,
    isLoading: false,
    message: '',
    errors: null,
}

//Login User
export const login = createAsyncThunk(Routes.loginUrl, async (loginData, thunkAPI) => {
    try{
        return await authService.login(loginData);
    }catch (error) {
        
        var errorData = {message: (error.response && error.response.data && error.response.data.message) || error.message || error.toString()}
        if(error.response.status === 422){
            errorData['errors'] = error.response.data.errors;
            
        }
        return thunkAPI.rejectWithValue(errorData);
    }
})

//Forgot Password
export const forgotPassword = createAsyncThunk(Routes.forgotPasswordUrl, async (formData, thunkAPI) => {
    try{
        return await authService.forgotPassword(formData);
    }catch (error) {
        
        var errorData = {message: (error.response && error.response.data && error.response.data.message) || error.message || error.toString()}
        if(error.response.status === 422){
            errorData['errors'] = error.response.data.errors;
            
        }
        return thunkAPI.rejectWithValue(errorData);
    }
})

export const resetPassword = createAsyncThunk(Routes.resetPasswordUrl, async (formData, thunkAPI) => {
    try{
        
        return await authService.resetPassword(formData.token, formData);
    }catch (error) {
        
        var errorData = {message: (error.response && error.response.data && error.response.data.message) || error.message || error.toString()}
        if(error.response.status === 422){
            errorData['errors'] = error.response.data.errors;
            
        }
        return thunkAPI.rejectWithValue(errorData);
    }
})

export const resetSponsorPassword = createAsyncThunk(Routes.sponsorResetPasswordUrl, async (formData, thunkAPI) => {
    try{
        
        return await authService.sponsorResetPassword(formData.token, formData);
    }catch (error) {
        
        var errorData = {message: (error.response && error.response.data && error.response.data.message) || error.message || error.toString()}
        if(error.response.status === 422){
            errorData['errors'] = error.response.data.errors;
            
        }
        return thunkAPI.rejectWithValue(errorData);
    }
})

export const resetCaregiverPassword = createAsyncThunk(Routes.caregiverResetPasswordUrl, async (formData, thunkAPI) => {
    try{
        
        return await authService.caregiverResetPassword(formData.token, formData);
    }catch (error) {
        
        var errorData = {message: (error.response && error.response.data && error.response.data.message) || error.message || error.toString()}
        if(error.response.status === 422){
            errorData['errors'] = error.response.data.errors;
            
        }
        return thunkAPI.rejectWithValue(errorData);
    }
})

export const logout = createAsyncThunk(Routes.logoutUrl, async (thunkAPI) => {
    try{
        return await authService.logout();
    }catch (error) {
        var errorData = {message: (error.response && error.response.data && error.response.data.message) || error.message || error.toString()}
        if(error.response.status === 403){
            return await authService.logoutLocal()
        }
        return thunkAPI.rejectWithValue(errorData);
    }
})

export const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        reset: (state) => {
            state.isLoading = false;
            state.isSuccess = false;
            state.isError = false;
            state.message = '';
            state.errors = null;
        }
    }, 
    extraReducers: (builder) => {
        builder
        //Login
        .addCase(login.pending, (state) => {
            state.isLoading = true
        })
        .addCase(login.fulfilled, (state,action) => {
            state.isLoading = false
            state.isSuccess = true
            state.isError = false
            state.user = action.payload
            state.errors = null

        })
        .addCase(login.rejected, (state, action) => {
            state.isLoading = false
            state.isError = true
            state.message = action.payload.message
            state.errors = action.payload.errors
        })

        //LOGOUT
        .addCase(logout.pending, (state) => {
            state.isLoading = true
        })

        .addCase(logout.fulfilled, (state,action) => {
            state.isLoading = false
            state.isSuccess = true
            state.isError = false
            state.user = null
            state.errors = null
        })

        .addCase(logout.rejected, (state, action) => {
            state.isLoading = false
            state.isError = true
            state.message = action.payload.message
            state.errors = null
        })

        //Forgot Password
        .addCase(forgotPassword.pending, (state) => {
            state.isLoading = true
        })

        .addCase(forgotPassword.fulfilled, (state,action) => {
            state.isLoading = false
            state.isSuccess = true
            state.isError = false
            state.user = null
            state.message = action.payload.message
            state.errors = null
        })

        .addCase(forgotPassword.rejected, (state, action) => {
            state.isLoading = false
            state.isError = true
            state.message = action.payload.message
            state.errors = null
        })

        //Reset Password
        .addCase(resetPassword.pending, (state) => {
            state.isLoading = true
        })
        
        .addCase(resetPassword.fulfilled, (state,action) => {
            state.isLoading = false
            state.isSuccess = true
            state.isError = false
            state.user = null
            state.message = action.payload.message
            state.errors = null
        })

        .addCase(resetPassword.rejected, (state, action) => {
            state.isLoading = false
            state.isError = true
            state.message = action.payload.message
            state.errors = null
        })

         //Reset Sponsor Password
         .addCase(resetSponsorPassword.pending, (state) => {
            state.isLoading = true
        })
        
        .addCase(resetSponsorPassword.fulfilled, (state,action) => {
            state.isLoading = false
            state.isSuccess = true
            state.isError = false
            state.user = null
            state.message = action.payload.message
            state.errors = null
        })

        .addCase(resetSponsorPassword.rejected, (state, action) => {
            state.isLoading = false
            state.isError = true
            state.message = action.payload.message
            state.errors = null
        })

         //Reset Caregiver Password
         .addCase(resetCaregiverPassword.pending, (state) => {
            state.isLoading = true
        })
        
        .addCase(resetCaregiverPassword.fulfilled, (state,action) => {
            state.isLoading = false
            state.isSuccess = true
            state.isError = false
            state.user = null
            state.message = action.payload.message
            state.errors = null
        })

        .addCase(resetCaregiverPassword.rejected, (state, action) => {
            state.isLoading = false
            state.isError = true
            state.message = action.payload.message
            state.errors = null
        })
    }
})

export const {reset} = authSlice.actions
export default authSlice.reducer