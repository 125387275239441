import styled from "styled-components";

export const PanelForm = styled.div`
    display: flex;
    flex-direction: column;
    gap: 1rem;
`;

export const PanelRow = styled.div`
    padding: 1rem 0;

    & label {
        display: block;
        font-weight: 500;
        font-size: 12px;
        line-height: 20px;
        color: var(--color-text-primary);
        margin-bottom: .4rem;
    }

    & input[type="text"], & input[type='password'], & input[type='email'], & select {
        display: block;
        width: 100%;
        background: #F7F9FC;
        border: 1px solid #EDF2F7;
        border-radius: 4px;
        padding: 1rem;
        color: #3C5366;
        outline: none;
    }
`;