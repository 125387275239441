import React, { StrictMode } from "react";
import ReactDOM from "react-dom/client";
import reportWebVitals from "./reportWebVitals";
import { store } from './app/store'
import { Provider } from 'react-redux'
// import * as Sentry from "@sentry/react";
// import { BrowserTracing } from "@sentry/tracing";
import App from "./App";
// Sentry.init({
// 	dsn: "https://b0d0cf78bd1f43f2b9b0d60f45d18cf5@o1392754.ingest.sentry.io/6714291",
// 	integrations: [new BrowserTracing()],
  
// 	// Set tracesSampleRate to 1.0 to capture 100%
// 	// of transactions for performance monitoring.
// 	// We recommend adjusting this value in production
// 	tracesSampleRate: .30,
//   });
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
	<StrictMode>
		<Provider store={store}>
			<App />
		</Provider>
	</StrictMode>
	
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
