import React from "react";
import { useForm } from "react-hook-form";
import {
	FormDropdownField,
} from "../../../components/custom_form_component/CustomFormComponent";
import {
	DetailsBody,
	DetailsFooter,
	DetailsHeader,
	DetailsHeaderDesc,
	DetailsPanelStyled,
} from "../../../styles/admin/DetailsPanel";
import { PanelForm, PanelRow } from "../../../styles/admin/Forms.styled";
import { FormPanelButton } from "../../../styles/FormPanel.Styled";


function ChangeTeamMemberRole({role, roles, onSubmit}) {
	const {
		handleSubmit,
		control,
		formState: { errors },
	} = useForm({
		defaultValues: {
			role: '',
		},
	});

	return (
		<>
				<DetailsPanelStyled as="form" onSubmit={handleSubmit(onSubmit)}>
					<DetailsHeader>
						<DetailsHeaderDesc>
							<h3>Change Role</h3>
						</DetailsHeaderDesc>
					</DetailsHeader>
					<DetailsBody padding="2rem">
						<PanelForm>
							<PanelRow>
							<FormDropdownField
								name="role"
								label="Role"
								options={roles}
								control={control}
								placeholder="Choose role"
								rules={{
									required: {
										value: "required",
										message: "Please select role",
									},
								}}
								errors={errors}
							/>
							</PanelRow>
						</PanelForm>
					</DetailsBody>
					<DetailsFooter>
						<div></div>
						<div>
							<FormPanelButton type="submit" primary>
								Change Account Role
							</FormPanelButton>
						</div>
					</DetailsFooter>
				</DetailsPanelStyled>
		</>
	);
}

export default ChangeTeamMemberRole;
