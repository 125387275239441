import React from 'react'
import { SponsorFormProvider } from '../../../core/context/SponsorFormContext'
import SetupSponsorProfile from './SetupSponsorProfile'

function GetStarted() {
  return (
    <SponsorFormProvider>
        <SetupSponsorProfile />
    </SponsorFormProvider>
  );
}

export default GetStarted