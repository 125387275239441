import {createSlice, createAsyncThunk} from '@reduxjs/toolkit'
import { Routes } from '../../core/routes/apiRoutes'
import sponsorService from './sponsorService'

const sponsor = JSON.parse(localStorage.getItem('sponsor'))

const initialState = {
    sponsor: sponsor ? sponsor : null,
    data: null,
    isError: false,
    isSuccess: false,
    isLoading: false,
    message: '',
    errors: null,
}

//Get - Requires Admin Authentication
export const getSponsors = createAsyncThunk(Routes.getSponsorsUrl, async ( url, thunkAPI) => {
    try{
        return await sponsorService.getSponsors(url);
    }catch (error) {
        var errorData = {message: (error.response && error.response.data && error.response.data.message) || error.message || error.toString()}
        if(error.response.status === 422){
            errorData['errors'] = error.response.data.errors;
            
        }
        return thunkAPI.rejectWithValue(errorData);
    }
})

//delete - Requires Admin Authentication
export const deleteSponsor = createAsyncThunk(Routes.getSponsorsUrl+"/delete", async ( email, thunkAPI) => {
    try{
        return await sponsorService.deleteSponsor(email);
    }catch (error) {
        var errorData = {message: (error.response && error.response.data && error.response.data.message) || error.message || error.toString()}
        if(error.response.status === 422){
            errorData['errors'] = error.response.data.errors;
            
        }
        return thunkAPI.rejectWithValue(errorData);
    }
})

//Register Sponsor
export const register = createAsyncThunk(Routes.sponsorRegisterUrl, async (formData, thunkAPI) => {
    try{
        return await sponsorService.register(formData);
    }catch (error) {
        var errorData = {message: (error.response && error.response.data && error.response.data.message) || error.message || error.toString()}
        if(error.response.status === 422){
            errorData['errors'] = error.response.data.errors;
            
        }
        return thunkAPI.rejectWithValue(errorData);
    }
})

export const logout = createAsyncThunk(Routes.logoutUrl, async (thunkAPI) => {
    try{
        return await sponsorService.logout();
    }catch (error) {
        var errorData = {message: (error.response && error.response.data && error.response.data.message) || error.message || error.toString()}
        if(error.response.status === 403){
            return await sponsorService.logoutLocal()
        }
        return thunkAPI.rejectWithValue(errorData);
    }
})

export const sponsorSlice = createSlice({
    name: 'sponsor',
    initialState,
    reducers: {
        reset: (state) => {
            state.isLoading = false;
            state.isSuccess = false;
            state.isError = false;
            state.message = '';
            state.errors = null;
        }
    }, 
    extraReducers: (builder) => {
        function sharedRejectedReducer(state, action){
            state.isLoading = false
            state.isError = true
            state.message = action.payload.message
            state.errors = action.payload.errors
        }
        function sharedPendingReducer(state, action){
            state.isLoading = true
        }

        builder
       
        .addCase(getSponsors.pending,sharedPendingReducer)
        .addCase(getSponsors.rejected, sharedRejectedReducer)
        .addCase(deleteSponsor.pending,sharedPendingReducer)
        .addCase(deleteSponsor.rejected, sharedRejectedReducer)
        .addCase(register.pending,sharedPendingReducer)
        .addCase(register.rejected, sharedRejectedReducer)
        .addCase(logout.pending,sharedPendingReducer)
        .addCase(logout.rejected, (state, action) => {
            state.isLoading = false
            state.isError = true
            state.message = action.payload.message
            state.errors = null
        })

         //Get
         .addCase(getSponsors.fulfilled, (state,action) => {
            state.isLoading = false
            state.isSuccess = true
            state.isError = false
            state.data = action.payload
            state.errors = null

        })

         //Delete
         .addCase(deleteSponsor.fulfilled, (state,action) => {
            state.isLoading = false
            state.isSuccess = true
            state.message = action.payload.message
            state.isError = false
            state.errors = null
        })

        //Register
        .addCase(register.fulfilled, (state,action) => {
            state.isLoading = false
            state.isSuccess = true
            state.isError = false
            state.sponsor = action.payload
            state.errors = null

        })

        //LOGOUT
        .addCase(logout.fulfilled, (state,action) => {
            state.isLoading = false
            state.isSuccess = true
            state.isError = false
            state.sponsor = null
            state.errors = null
        })

       
    }
})

export const {reset} = sponsorSlice.actions
export default sponsorSlice.reducer