import {sponsorAuthApi} from "../../core/api/axios/baseAxios"
import { Routes } from "../../core/routes/apiRoutes";

const initializePayment = async (data) => {
    const response = await sponsorAuthApi.post(Routes.sponsorInitializePayment, data);
    const resData = response.data;
    return resData.data;
}

const subscribe = async (data) => {
    const response = await sponsorAuthApi.post(Routes.sponsorSubscribe, data);
    const resData = response.data;
    return resData.data;
}

const paymentService = {
    initializePayment,
    subscribe,
}

export default paymentService