import React, { useEffect, useState } from "react";
import {
	AdminModal,
	Badge,
	CustomDataTable,
	PopupMenu,
	SponsorDetailsPanel,
} from "../../../components";
import { DashboardRow } from "../../../styles/admin/CardBox.styled";
import { getSponsorsBadge } from "../../../misc/helpers";
import {
	deleteSponsor,
	getSponsors,
	reset,
} from "../../../features/sponsor/sponsorSlice";
import { Routes } from "../../../core/routes/apiRoutes";
import { useSelector } from "react-redux";
import moment from "moment";
import useResource from "../../../core/hooks/useResource";
import { LoadingBar } from "../../../components/loading_bar/LoadingBar";
import dataParser from "../../../misc/data_parser";
import Can from "../../../components/auth_gate/Can";
import { CREATE_AND_MANAGE_SPONSORS } from "../../../misc/constants";
const perPage = 30;
let modalData;
function Sponsors() {
	
	const [open, setOpen] = useState(false);

	const handleOpen = (id) => {
		modalData = tableData.filter((item) => item.email === id);
		if (modalData) {
			modalData = modalData[0];
			setOpen(true);
		}
	};

	const handleClose = () => setOpen(false);

	const prepareTableData = (response, page) => {
		var rows = [];
		const tempData = response.data;

		for (let i = 0; i < tempData.length; i++) {
			const itemData = tempData[i];
			const itemDataServiceUser = itemData?.service_user;
			var item = {
				id: i + 1 + page,
				sn: i + 1 + page,
				name: itemData.last_name + " " + itemData.first_name,
				email: itemData.email,
				plan: itemData.subscription?.subscription_type_name,
				service_user:itemDataServiceUser == null?null: 
					itemData?.service_user?.last_name +
					" " +
					itemData?.service_user?.first_name,
				dateCreated: moment.unix(itemData.joined_at).fromNow(),
				
				status: itemData.status,
			};

			item["profile"] =  dataParser.sponsorParser(itemData);

			item["serviceUserProfile"] = itemDataServiceUser == null ? null : dataParser.serviceUserParser(itemDataServiceUser);

			item["paymentHistory"] = itemData?.transactions;

			rows.push(item);
		}

		return rows;
	};

	const {
		onPrevPage,
		onNextPage,
		page,
		tableData,
		ref,
		isLoading,
		onDelete,
		onSearch,
	} = useResource({
		selector: useSelector((state) => state.sponsor),
		perPage: perPage,
		getReducer: getSponsors,
		resetReducer: reset,
		deleteReducer: deleteSponsor,
		renderTableData: prepareTableData,
		routes: {
			getRoute: Routes.getSponsorsUrl,
		},
	});

	const actions = Can(CREATE_AND_MANAGE_SPONSORS)? [
		{ title: "View Details", onClick: handleOpen },
		{ title: "Delete", onClick: onDelete },
	]:[{ title: "View Details", onClick: handleOpen }];

	useEffect(() => {
		document.title = "Sponsors - Greymate Care Admin";
	}, []);

	const columns = [
		{
			field: "sn",
			headerName: "S/N",
			minWidth: 10,
			flex: 1,
		},
		{
			field: "name",
			headerName: "Sponsor's Name",
			minWidth: 100,
			flex: 2,
		},
		{
			field: "email",
			headerName: "Email Address",
			minWidth: 100,
			flex: 2,
		},
		{
			field: "service_user",
			headerName: "Service User",
			minWidth: 100,
			flex: 2,
		},
		{
			field: "plan",
			headerName: "Plan",
			minWidth: 100,
			flex: 2,
		},
		{
			field: "status",
			headerName: "Status",
			minWidth: 100,
			flex: 2,
			sortable: false,
			renderCell: (params) => {
				const badge = getSponsorsBadge(params.row.status);
				return <Badge {...badge.badgeType}>{badge.badgeText}</Badge>;
			},
			disableClickEventBubbling: true,
		},
		{
			field: "actions",
			headerName: "",
			minWidth: 100,
			flex: 1,
			sortable: false,
			renderCell: (params) => {
				return <PopupMenu id={params.row.email} options={actions} />;
			},
			disableClickEventBubbling: true,
		},
	];

	return (
		<>
			<LoadingBar ref={ref} shadow={true} />
			<h1>Sponsors</h1>
			<DashboardRow>
				<CustomDataTable
					disableSelectionOnClick
					pageSize={perPage}
					pagination="false"
					rowsPerPageOptions={[]}
					loading={isLoading}
					columns={columns}
					rows={tableData}
					hideFooter={true}
					prev={onPrevPage}
					next={onNextPage}
					currentPage={page}
					onSearchChange={onSearch}
				/>
			</DashboardRow>
			<AdminModal open={open} onClose={handleClose}>
				{modalData !== null && (
					<SponsorDetailsPanel
						data={modalData}
						onSendMessage={handleClose}
						onDeny=""
					/>
				)}
			</AdminModal>
		</>
	);
}

export default Sponsors;
