import React, {Component} from 'react';
import {RiArrowRightLine, RiArrowLeftLine} from "react-icons/ri";
import Scr1 from '../../assets/screenshot1.png';
import Scr2 from '../../assets/screenshot2.png';
import Scr3 from '../../assets/screenshot3.png';
import {Fade} from 'react-awesome-reveal';

import './whats_new.css';
import ScreenshotCarousel from '../../components/screenshot_carousel/ScreenshotCarousel';
import { CTA } from '../../components';

export default class WhatsNew extends Component {

  constructor(props) {
    super(props);
    this.childRef = React.createRef();
  }

  render(){
    const screenshotList = [
      {
        name: "home",
        imgSrc: Scr1,
      },
      {
        name: "about",
        imgSrc: Scr2,
      },
      {
        name: "third",
        imgSrc: Scr3,
      },
      
    ];
    return (
      <div className='gmc__whatsnew section__padding'>
        <div className='gmc__whatsnew-content'>
          <Fade top><h1>What's More?</h1></Fade>
          <Fade top><p>With our mobile app, you get real time tracking and reports and can monitor the care from anywhere in the world.</p></Fade>
          <Fade top><CTA /></Fade>
          <Fade top><div className='gmc__whatsnew-content_slider-arrows_wrapper'>
            <div className='gmc__whatsnew-content_slider-arrows'>
              <button onClick={() => this.childRef.current.slickPrev()}><RiArrowLeftLine color="#fff" size={18} /></button>
              <button onClick={() => this.childRef.current.slickNext()}><RiArrowRightLine color="#fff" size={18} /></button>
            </div>
            
          </div>
          </Fade>
        </div>

        <div className='gmc__whatsnew-screenshots'>
          <ScreenshotCarousel list={screenshotList} ref={this.childRef}/>
        </div>

        <div className='gmc__whatsnew-content_slider-arrows_mobile'>
            <div className='gmc__whatsnew-content_slider-arrows'>
              <button onClick={() => this.childRef.current.slickPrev()}><RiArrowLeftLine color="#fff" size={18} /></button>
              <button onClick={() => this.childRef.current.slickNext()}><RiArrowRightLine color="#fff" size={18} /></button>
            </div>
            
          </div>
      </div>
    );
  }
}