import authApi from "../../core/api/axios/baseAxios"
import { Routes } from "../../core/routes/apiRoutes";

const getServiceUsers = async (url) => {
    const response = await authApi.get(url);
    const resData = response.data;
    return resData;
}

const getProfile = async (id) => {
    const response = await authApi.get(Routes.getServiceUsersUrl + `/show/${id}`);
    const resData = response.data;
    return resData;
}

const deleteServiceUser = async (email) => {
    const response = await authApi.delete(Routes.getServiceUsersUrl+`/${email}`);
    const resData = response.data;
    return resData;
}

const serviceUserService = {
    getServiceUsers,
    getProfile,
    deleteServiceUser,
}

export default serviceUserService