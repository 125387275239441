import React from 'react'
import { CircularProgress, IconButton, TextField } from '@mui/material'
import ClearIcon from '@mui/icons-material/Clear';
import SearchIcon from '@mui/icons-material/Search';

function SearchField(props) {
  return (
    <TextField
        variant="standard"
        value={props.value}
        onChange={props.onChange}
        fullWidth={props.fullWidth}
        placeholder="Search…"
        sx={{
          backgroundColor: '#fff',
          padding: '.5rem 1.5rem',
          borderRadius: '8px',
          color: 'var(--color-text-primary)',
          fontWeight: 500,
          fontSize: '14px',
          lineHeight: '24px',
          '& input': {
            padding: '.5rem 1.5rem',
            background: '#fff',
          }
        }}  
        InputProps={{
          disableUnderline: true, 
          startAdornment: <SearchIcon fontSize="small" />,
          endAdornment: (
            props.isLoading ? <CircularProgress /> :<IconButton
              title="Clear"
              aria-label="Clear"
              size="small"
              style={{ visibility: props.value ? 'visible' : 'hidden' }}
              onClick={props.clearSearch}
            >
              <ClearIcon fontSize="small" />
            </IconButton>
          ),
        }}
      />
  )
}

export default SearchField