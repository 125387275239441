import moment from "moment";
import { RiStarSFill, RiStarSLine } from "react-icons/ri";

const parseEmoticons = (mood) => {
    switch (mood) {
        case 1:
           return  '🙂';

           case 2:
           return  '😐';

           case 3:
           return  '☹️';
            
    
        default:
            return mood;
    }
}

const parseAggressionLevel = (level) => {
	switch (level) {
		case 1:
			return "🙂";
		case 2:
			return "😐";
		case 3:
			return "☹️";
		default:
			return "none";
	}
};

const parseBool = (value) => {
    switch (value) {
        case 1:
           return  "Yes";

           case 0:
           return  "No";

           case true:
           return  'Yes';

           case false:
           return  'No';
            
    
        default:
            return value;
    }
}

const parsePainLevel = (value) => {
    var data = "";
    for(var i = 1; i <= 5; i++){
        
        data += value >= i ?<RiStarSFill/>:<RiStarSLine/>;
    }
   return data;
}

const sponsorParser = (rawData) => {
    const itemData = rawData;
    const itemDataServiceUser = itemData?.service_user;
    const itemDataPreference = itemData?.preference;
    return {
        "Full Name": itemData.last_name +" "+itemData.first_name,
        Email: itemData.email,
        "Phone Number": itemData.phone_number,
        "Gender": itemData.gender,
        Plan: itemData.subscription?.subscription_type_name,
        "Services Needed": itemDataServiceUser?.profile?.services?.join(", "),
        "Preferred Start Date": moment(itemDataPreference?.start_date, "MM/DD/YYYY").format("DD/MM/YYYY"),
        "Preferred Start Time": itemDataPreference?.start_time,
        "Preferred Gender": itemDataPreference?.gender,
        "Preferred Language": itemDataPreference?.language?.join(", "),
        "Living Arrangements": itemDataPreference?.living_arrangements,
        "Extra Services": itemDataPreference?.extra_service?.join(", "),
        "Extra Items": itemDataPreference?.extra_items?.join(", "),
    }
}

const serviceUserParser = (itemDataServiceUser) => {
    return {
        "Service User Name": itemDataServiceUser?.last_name +" " +itemDataServiceUser?.first_name,
        Gender: itemDataServiceUser?.gender,
        "Date of Birth": itemDataServiceUser?.profile?.date_of_birth,
        Address: itemDataServiceUser?.address,
        "Address Description": itemDataServiceUser?.address_desc,
        "Special Conditions": itemDataServiceUser?.profile?.special_conditions?.join(", "),
        "Current Medication": itemDataServiceUser?.profile?.other_conditions || 'none',
        Mobility: itemDataServiceUser?.profile?.mobility,
        Alertness: itemDataServiceUser?.profile?.alertness,
        Aggression: parseAggressionLevel(
            itemDataServiceUser?.profile?.aggression_level
        ),
    }
}




const dataParser = {
    parseEmoticons,
    parseAggressionLevel,
    parsePainLevel,
    sponsorParser,
    serviceUserParser,
    parseBool,
};

export default dataParser;