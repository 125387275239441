import {createSlice, createAsyncThunk} from '@reduxjs/toolkit'
import { Routes } from '../../core/routes/apiRoutes'
import adminService from './adminService'

const initialState = {
    data: null,
    isError: false,
    isSuccess: false,
    isLoading: false,
    isCreateAdmin: false,
    message: '',
    errors: null,
}

//Get admin
export const getAdmins = createAsyncThunk(Routes.getAdminsUrl, async ( url, thunkAPI) => {
    try{
        return await adminService.getAdmins(url);
    }catch (error) {
        var errorData = {message: (error.response && error.response.data && error.response.data.message) || error.message || error.toString()}
        if(error.response.status === 422){
            errorData['errors'] = error.response.data.errors;
            
        }
        return thunkAPI.rejectWithValue(errorData);
    }
})

//create admin
export const createAdmin = createAsyncThunk(Routes.createAdminUrl, async ( formData, thunkAPI) => {
    try{
        return await adminService.createAdmin(formData);
    }catch (error) {
        var errorData = {message: (error.response && error.response.data && error.response.data.message) || error.message || error.toString()}
        if(error.response.status === 422){
            errorData['errors'] = error.response.data.errors;
            
        }
        return thunkAPI.rejectWithValue(errorData);
    }
})

//delete admin
export const deleteAdmin = createAsyncThunk(Routes.getAdminsUrl+"/delete", async ( email, thunkAPI) => {
    try{
        return await adminService.deleteAdmin(email);
    }catch (error) {
        var errorData = {message: (error.response && error.response.data && error.response.data.message) || error.message || error.toString()}
        if(error.response.status === 422){
            errorData['errors'] = error.response.data.errors;
            
        }
        return thunkAPI.rejectWithValue(errorData);
    }
})

//change Status
export const changeStatus = createAsyncThunk(Routes.changeAdminStatusUrl, async (data, thunkAPI) => {
    try{
        return await adminService.changeStatus(data.email,data.data);
    }catch (error) {
        var errorData = {message: (error.response && error.response.data && error.response.data.message) || error.message || error.toString()}
        if(error.response.status === 422){
            errorData['errors'] = error.response.data.errors;
            
        }
        return thunkAPI.rejectWithValue(errorData);
    }
})

//change Role
export const changeRole = createAsyncThunk(Routes.changeAdminRoleUrl, async (data, thunkAPI) => {
    try{
        return await adminService.changeRole(data.email,data.data);
    }catch (error) {
        var errorData = {message: (error.response && error.response.data && error.response.data.message) || error.message || error.toString()}
        if(error.response.status === 422){
            errorData['errors'] = error.response.data.errors;
            
        }
        return thunkAPI.rejectWithValue(errorData);
    }
})

//change password
export const changePassword = createAsyncThunk(Routes.changeAdminPasswordUrl, async (data, thunkAPI) => {
    try{
        return await adminService.changePassword(data);
    }catch (error) {
        var errorData = {message: (error.response && error.response.data && error.response.data.message) || error.message || error.toString()}
        if(error.response.status === 422){
            errorData['errors'] = error.response.data.errors;
            
        }
        return thunkAPI.rejectWithValue(errorData);
    }
})



export const adminSlice = createSlice({
    name: 'admin',
    initialState,
    reducers: {
        reset: (state) => {
            state.isLoading = false;
            state.isSuccess = false;
            state.isError = false;
            state.isCreateAdmin = false;
            state.message = '';
            state.errors = null;
            state.data = null;
        }
    }, 
    extraReducers: (builder) => {
        function sharedRejectedReducer(state, action){
            state.isLoading = false
            state.isError = true
            state.message = action.payload.message
            state.errors = action.payload.errors
        }
        function sharedPendingReducer(state, action){
            state.isLoading = true
        }

        builder

        .addCase(getAdmins.pending,sharedPendingReducer)
        .addCase(getAdmins.rejected, sharedRejectedReducer)
        .addCase(createAdmin.pending,sharedPendingReducer)
        .addCase(createAdmin.rejected, sharedRejectedReducer)
        .addCase(deleteAdmin.pending,sharedPendingReducer)
        .addCase(deleteAdmin.rejected, sharedRejectedReducer)
        .addCase(changeStatus.pending,sharedPendingReducer)
        .addCase(changeStatus.rejected, sharedRejectedReducer)
        .addCase(changeRole.pending,sharedPendingReducer)
        .addCase(changeRole.rejected, sharedRejectedReducer)
        .addCase(changePassword.pending,sharedPendingReducer)
        .addCase(changePassword.rejected, sharedRejectedReducer)

         //Get Admins
        .addCase(getAdmins.fulfilled, (state,action) => {
            state.isLoading = false
            state.isSuccess = true
            state.isError = false
            state.data = action.payload
            state.errors = null

        })

         //Create Admin
        .addCase(createAdmin.fulfilled, (state,action) => {
            state.isLoading = false
            state.isSuccess = true
            state.isError = false
            state.isCreateAdmin = true
            state.data = action.payload
            state.errors = null

        })

         //Delete Admin
         .addCase(deleteAdmin.fulfilled, (state,action) => {
            state.isLoading = false
            state.isSuccess = true
            state.message = action.payload.message
            state.isError = false
            state.errors = null
        })

        //change Status
        .addCase(changeStatus.fulfilled, (state,action) => {
            state.isLoading = false
            state.isSuccess = true
            state.message = action.payload.message
            state.isError = false
            state.errors = null
        })

        //change Status
        .addCase(changeRole.fulfilled, (state,action) => {
            state.isLoading = false
            state.isSuccess = true
            state.message = action.payload.message
            state.isError = false
            state.errors = null
        })

         //change password
         .addCase(changePassword.fulfilled, (state,action) => {
            state.isLoading = false
            state.isSuccess = true
            state.message = action.payload.message
            state.isError = false
            state.errors = null
        })

        
    }
})

export const {reset} = adminSlice.actions
export default adminSlice.reducer