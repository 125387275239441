import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { AdminModal } from "../../../components";
import { toast } from "react-toastify";
import { useForm } from "react-hook-form";
import {
	FormDropdownField,
	FormInputField,
} from "../../../components/custom_form_component/CustomFormComponent";
import { useSelector, useDispatch } from "react-redux";
import { LoadingBar } from "../../../components/loading_bar/LoadingBar";
import {
	showSubscriptionPlan,
	updateSubscriptionPlan,
	reset,
} from "../../../features/subscription_plans/subscriptionPlanSlice";
import {
	DetailsBody,
	DetailsFooter,
	DetailsHeader,
	DetailsHeaderDesc,
	DetailsPanelStyled,
} from "../../../styles/admin/DetailsPanel";
import { PanelForm, PanelRow } from "../../../styles/admin/Forms.styled";
import { FormPanelButton } from "../../../styles/FormPanel.Styled";

const statusOptions = [
	{ label: "Enabled", value: true },
	{ label: "Disabled", value: false },
];

const periodOptions = [
	{ label: "Daily", value: "day" },
	{ label: "Monthly", value: "month" },
	{ label: "Yearly", value: 'year'},
];


const formDefaults = {
	name: "",
	plan_description: "",
	period: "",
	period_value: "",
	subscription_value: "",
	plan_token: "",
	is_enabled: "",
};

function UpdateSubscriptionPlan() {
	const navigate = useNavigate();
	const { slug } = useParams();

	const handleClose = () => {
		navigate(-1);
	};
	
	

	const dispatch = useDispatch();
	const ref = React.useRef(null);

	const { data, isError, isSuccess, isLoading, message } = useSelector(
		(state) => state.subscriptionPlan
	);

	React.useEffect(() => {
		dispatch(showSubscriptionPlan(slug));
		
	}, [slug,dispatch]);

	

	const {
		handleSubmit,
		control,
		reset: resetForm,
		setValue,
		formState: { errors },
	} = useForm({
		defaultValues: formDefaults,
	});

	React.useEffect(() => {
		if (isError) {
			toast.error(message);
			ref.current.complete();
		}

		if (isSuccess) {
			ref.current.complete();
		}

		if (isSuccess && message) {
			toast.success(message);
			resetForm(formDefaults);
		}

		if (isSuccess && data) {
			setValue('name', data.data.name);
			setValue('plan_description', data.data.plan_description);
			setValue('period', data.data.period);
			setValue('period_value', data.data.period_value);
			setValue('subscription_value', data.data.subscription_value);
			setValue('plan_token', data.data.plan_tokens.paystack);
			setValue('is_enabled', data.data.is_enabled);
		}

		dispatch(reset());
	}, [data, isError, isSuccess, message, setValue, resetForm, dispatch]);

	const onSubmit = (data) => {
		if (!isLoading) {
			var formData = data;
			formData.plan_tokens = {
				paystack: data.plan_token,
				flutterwave: data.plan_token,
			};
			formData.slug = slug;
			delete formData.plan_token;
			ref.current.continuousStart();
			dispatch(updateSubscriptionPlan(formData));
		}
	};

	return (
		<>
			<AdminModal open={true} onClose={handleClose}>
				<LoadingBar ref={ref} shadow={true} />
				<DetailsPanelStyled as="form" onSubmit={handleSubmit(onSubmit)}>
					<DetailsHeader>
						<DetailsHeaderDesc>
							<h3>Update subscription plan</h3>
						</DetailsHeaderDesc>
					</DetailsHeader>
					<DetailsBody padding="2rem">
						<PanelForm>
							<PanelRow>
								<FormInputField
									name="name"
									label="Name"
									placeholder="Enter plan name"
									control={control}
									rules={{
										required: {
											value: "required",
											message: "Please enter a plan name",
										},
									}}
									errors={errors}
								/>
							</PanelRow>
							<PanelRow>
								<FormInputField
									name="plan_description"
									label="Description"
									placeholder="Enter a description"
									control={control}
									rules={{}}
									errors={errors}
								/>
							</PanelRow>
							<PanelRow>
								<FormDropdownField
									name="period"
									label="Plan Type"
									options={periodOptions}
									control={control}
									placeholder="Choose Plan Type"
									rules={{
										
									}}
									errors={errors}
								/>
							</PanelRow>
							<PanelRow>
								<FormInputField
									name="period_value"
									label="Plan Duration"
									type="number"
									placeholder="Enter plan duration"
									control={control}
									rules={{
										required: {
											value: "required",
											message: "Please enter a plan duration",
										},
									}}
									errors={errors}
								/>
							</PanelRow>
							<PanelRow>
								<FormInputField
									name="subscription_value"
									label="Plan Fee"
									type="number"
									placeholder="Enter amount"
									control={control}
									rules={{
										required: {
											value: "required",
											message: "Please enter a plan fee",
										},
									}}
									errors={errors}
								/>
							</PanelRow>
							<PanelRow>
								<FormInputField
									name="plan_token"
									label="Paystack Plan Code"
									placeholder="Enter the paystack plan code"
									control={control}
									rules={{
										required: {
											value: "required",
											message: "Please enter paystack plan code",
										},
									}}
									errors={errors}
								/>
							</PanelRow>
							<PanelRow>
								<FormDropdownField
									name="is_enabled"
									label="Status"
									options={statusOptions}
									control={control}
									placeholder="Choose Status"
									rules={{
										
									}}
									errors={errors}
								/>
							</PanelRow>
						</PanelForm>
					</DetailsBody>
					<DetailsFooter>
						<div></div>
						<div>
							<FormPanelButton type="submit" $primary>
								Update Subscription Plan
							</FormPanelButton>
						</div>
					</DetailsFooter>
				</DetailsPanelStyled>
			</AdminModal>
		</>
	);
}

export default UpdateSubscriptionPlan;
