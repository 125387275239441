import React from 'react'
import { RiCloseFill } from 'react-icons/ri'
import Modal from '@mui/material/Modal'
import './default_modal.css'

function DefaultModal(props) {
  return (
    <>
    <Modal
        open={props.open}
        onClose={props.onClose}
        aria-labelledby={props.title}
        aria-describedby={props.desc}
      >
       <div className='default__modal'>
          <div className='close__button' onClick={props.onClose}><RiCloseFill size={18} color="#748CAD"/></div>
          <div className='modal__content'>
            {props.children}
          </div>
       </div>
      </Modal>
    </>
  )
}

export default DefaultModal