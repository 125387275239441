import styled from "styled-components";


export const DashboardRow = styled.div`
    margin-top: 2rem;
    display: flex;
    flex-wrap: wrap;
    gap: 2rem;

    @media screen and (max-width: 1050px) {
      flex-direction: column;
      width: 100%;
      flex-wrap: nowrap;
    }
`;

export const ChartWrapper = styled.div`
 width: 100%;
`;

export const CardBoxStyled = styled.div`
  width: calc(${props => props.width} - 2rem);
  background: #fff;
  border-radius: 8px;
  border: 1px solid #edeff5; 
  @media screen and (max-width: 1050px) {
    width: 100%;
  }


`;

export const CardHeader = styled.div`
  padding: 1rem 2rem;
  display: ${props => props.show? 'flex' : 'none'};
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid rgba(0,0,0,.05);
`;

export const CardTitle = styled.h2`
  font-size: 18px;
`;

export const CardEx = styled.div`
  a {
    font-size: 14px;
    color: var(--color-text-secondary);
  }
`;

export const CardBody = styled.div`
  padding: 1rem;
  & h3 {
    font-weight: 800;
    font-size: 18px;
    line-height: 20px;
    }

    & h3 + p {
      font-weight: 400;
      font-size: 14px;
      line-height: 22px;
      margin-top: 1rem;
      color: #737A91;
    }
`;