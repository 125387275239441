import { Link } from "react-router-dom";
import React, {useState, useEffect } from "react";
import { publicGetSubscriptionPlans, reset} from "../../features/subscription_plans/subscriptionPlanSlice";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate, useLocation, useSearchParams } from "react-router-dom";
import logo from "../../assets/logo.png";
import Icon2 from "../../assets/picon2.svg";
import {
	FormErrorWrapper,
	FormPanel,
	FormPanelBody,
	FormPanelBodyHeader,
	FormPanelBodySection,
	FormPanelButton,
	FormPanelControl,
	FormPanelHeader,
    FormPanelWrapper,
} from "../../styles/FormPanel.Styled";
import { LoadingBar } from "../../components/loading_bar/LoadingBar";
import { initializePayment, subscribe, reset as paymentReset } from "../../features/sponsor/paymentSlice";
import { logout} from "../../features/sponsor/sponsorSlice";
import { toast } from "react-toastify";
import { AppRoutes } from "../../core/routes/routes";
import { Flex } from "../../styles/admin/DetailsPanel";
import { FormPricingPlan } from "../../components";
import usePublicResource from "../../core/hooks/usePublicResource";
import { Routes } from "../../core/routes/apiRoutes";
import { PlanSwitchLabel } from "../../styles/PlanCard.Styled";
import { PricingSwitch } from "../../styles/Switch.Styled";
import useIpDetails from "../../core/hooks/useIpDetails";

function SponsorHome() {
	const [planDuration, setPlanDuration] = useState("monthly");
	const togglePlanDuration = (checked) => {
		setPlanDuration(checked?'yearly':'monthly');
	}

	function sortByAmount(a, b){
		return a.amount - b.amount;
	  }
	  
	const label = { inputProps: { 'aria-label': 'Monthly' } };
	const prepareTableData = (response) => {
		var rows = [];
		const tempData = response.data;
    
		for (let i = 0; i < tempData.length; i++) {
			var item = {
				id: i + 1,
				sn: i + 1,
        icon: Icon2,
				name: tempData[i].name,
				description: tempData[i].description,
				period: tempData[i].period,
				amount: tempData[i].subscription_value,
				status: tempData[i].is_enabled?'active':'disabled',
				slug: tempData[i].slug,
        link: "/get-started",
			};

			rows.push(item);
		}

		return rows.sort(sortByAmount);
	};

	const {data:pricingData, ref} =
		usePublicResource({
			selector: useSelector((state) => state.subscriptionPlan),
			getReducer: publicGetSubscriptionPlans,
			resetReducer: reset,
			renderTableData: prepareTableData,
			routes: {
				getRoute: Routes.getSubscriptionPlansUrl,
			},
		});

	const {
		currency,
		exchangeRateResponse,
		} = useIpDetails({
		defaultCurrency: "NGN"
		});
	const [formErrors, setFormErrors] = useState(null);
    const [searchParams] = useSearchParams();
	const navigate = useNavigate();
	const location = useLocation();
	const redirectTo = location.state?.from?.pathname || AppRoutes.SponsorUrl;
	const dispatch = useDispatch();
    const sponsor = JSON.parse(localStorage.getItem('sponsor'));

	

    const { data:paymentData, subscriptionData, isError: paymentError, isSuccess:paymentSuccess, message:paymentMessage, errors:paymentErrors } = useSelector(
		(globalState) => globalState.payment
	);

	const { data:sponsorData, isError: sponsorError, isSuccess:sponsorSuccess, message:sponsorMessage } = useSelector(
		(globalState) => globalState.sponsor
	);

	useEffect(()=> {
		if (sponsorError) {
			toast.error(sponsorMessage);
			ref.current.complete();
		}

		if (sponsorSuccess) {
			ref.current.complete();
			navigate("/get-started");
		}
		 // eslint-disable-next-line
	}, [ref, navigate, dispatch, sponsorSuccess, sponsorError, sponsorData, sponsorMessage]);

    useEffect(()=>{
        const plan = searchParams.get("plan");
        const reference = searchParams.get("reference");
        if(reference && plan){
            const subscribePlan = (reference, plan) => {
                var data = {
                    "reference": reference,
                    "channel": "paystack",
                    "plan": plan,
                }
        
                ref.current.continuousStart();
                dispatch(subscribe(data));
            };
            subscribePlan(reference, plan);
        }
    }, [ref, dispatch, searchParams])

    useEffect(() => {
		if (paymentError) {
			toast.error(paymentMessage);
			ref.current.complete();

			if (paymentErrors) {
				var err = {};
				Object.keys(paymentErrors).map((key) => (err[key] = paymentErrors[key].join(", ")));
				setFormErrors(err);
			} else {
				setFormErrors(null);
			}
		}

		if (paymentSuccess && paymentData) {
			ref.current.complete();
			//navigate(redirectTo);
            window.location.href = paymentData.authorization_url;
		}

        if (paymentSuccess && subscriptionData) {
			ref.current.complete();
            toast.success("Subscription Successful");
			dispatch(paymentReset())
            navigate("/sponsor/success");
		}

		dispatch(paymentReset());
	}, [
		ref,
		paymentData,
        subscriptionData,
		paymentError,
		paymentSuccess,
		paymentMessage,
		paymentErrors,
		redirectTo,
		navigate,
		dispatch,
	]);

	const handlePlanSelection = (e) => {
        var data = {
            "amount": e.amount,
            "channel": ["card"],
            "metadata": {
                "payment_type": "subscription"
            },
            "callback_url": Routes.callbackUrl+e.slug,
            "plan": e.slug,
        }

        ref.current.continuousStart();
        dispatch(initializePayment(data));
	};

	function filterMonthly(plan){
		const acceptedPlans = ["day", "daily", "month", "monthly"];
		return acceptedPlans.includes(plan.period);
	}

	function filterYearly(plan){
		const acceptedPlans = ["year", "yearly"];
		return acceptedPlans.includes(plan.period);
	}

	const pricingPlansList  = () => {
		if(planDuration === "monthly"){
			return pricingData.filter(filterMonthly).map((plan) => (
				<FormPricingPlan
					plan={plan}
					key={plan.id}
					currency={currency} 
					rates={exchangeRateResponse?.rates}
					onPlanButton={()=>handlePlanSelection(plan)}
				/>
			));
		}

		if(planDuration === "yearly"){
			return pricingData.filter(filterYearly).map((plan) => (
				<FormPricingPlan
					plan={plan}
					key={plan.id}
					currency={currency} 
					rates={exchangeRateResponse?.rates}
					onPlanButton={()=>handlePlanSelection(plan)}
				/>
			));
		}

	}

	const logoutSponsor = (e) => {
		ref.current.continuousStart();
		dispatch(logout());
		
	}

	return (
		<>
			<LoadingBar ref={ref} shadow={true} />

			<FormPanelWrapper>
				<FormPanel>
					<FormPanelHeader>
						<Flex justifyContent="space-between">
							<Link to="/" alt="home">
								<img src={logo} alt="Greymate Care Logo" />
							</Link>

							<FormPanelButton href="#" onClick={logoutSponsor}>Logout</FormPanelButton>
						</Flex>
					</FormPanelHeader>

					{formErrors && (
						<FormErrorWrapper>
							{Object.keys(formErrors).map((key) => (
								<p>{formErrors[key]}</p>
							))}
						</FormErrorWrapper>
					)}

					<FormPanelBody>
						<FormPanelBodyHeader>
							<h1>Welcome, {sponsor ? `${sponsor.last_name}  ${sponsor.first_name}` : ''}</h1>
							<p>Please subscribe to one of our plans to get access.</p>
						</FormPanelBodyHeader>
						<FormPanelBodySection>
						<div style={{
						marginTop: "20px",
						marginBottom: "20px"
					}}>
						<Flex justifyContent="center">
							<PlanSwitchLabel $active={planDuration === "monthly"? true:false}>Monthly</PlanSwitchLabel>
							<PricingSwitch {...label}  onChange={(e, checked)=>togglePlanDuration(checked)}/>
							<PlanSwitchLabel $active={planDuration === "yearly"? true:false}>Yearly</PlanSwitchLabel>
						</Flex>
						
					</div>
							<FormPanelControl>
								<Flex flexWrap="wrap" gap="1rem" alignItems="stretch">
									{pricingPlansList()}
								</Flex>
							</FormPanelControl>
						</FormPanelBodySection>
					</FormPanelBody>
				</FormPanel>
			</FormPanelWrapper>
		</>
	);
}

export default SponsorHome;
