import {api} from "../../core/api/axios/baseAxios"
import { Routes } from "../../core/routes/apiRoutes";

const requestContact = async (data) => {
    const response = await api.post(Routes.createContactRequestUrl, data);
    const resData = response.data;
    return resData.data;
}

const contactRequestService = {
    requestContact,
}

export default contactRequestService