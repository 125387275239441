import styled from "styled-components";


export const FormPanelPlan = styled.div`
    min-width: 237px;
    flex: 1;
    padding: 10px;
    background: #F9F9F9;
    transition: .5s background ease-in;
    border-radius: 16px;

    &:hover, &.active {
        background: var(--color-text-primary);
    }

    @media screen and (max-width: 490px) {
       
    }
`;

export const FormPanelPlanWrapper = styled.div`
    width: 100%;
    min-height: 100%;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    background: #F9F9F9;
    border-radius: 16px;
    padding: 20px;

    @media screen and (max-width: 490px) {
      
    }
`;

export const FormPanelPlanDetails = styled.div`
    flex: 1;   
    display: flex;
    flex-direction: column;
    gap: 1rem;
`;

export const FormPanelPlanSubtitle = styled.p`
    font-weight: 700;
    font-size: 14px;
    line-height: 17px;
    text-transform: uppercase;
    color: var(--color-text-secondary);

    @media screen and (max-width: 490px) {
     
    }
`;

export const FormPanelPlanTitle = styled.h4`
    font-weight: 800;
    font-size: 24px;
    line-height: 32px;
   

    & span{
        font-weight: 500;
        font-size: 16px;
        line-height: 19px;
        color: var(--color-text-secondary);
    }

    @media screen and (max-width: 490px) {
       
    }
`;

export const FormPanelPlanDesc = styled.p`
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    color: var(--color-text-secondary);

    @media screen and (max-width: 490px) {

    }
`;

export const FormPanelPlanLink = styled.button`
    display: block;
    text-align: center;
    padding: .7rem .5rem;
    font-weight: 600;
    font-size: 12px;
    line-height: 140%;
    background: linear-gradient(0deg, #68C9F4 0%, #50ABED 100%);
    box-shadow: 0px 1px 1px rgba(22, 29, 37, 0.1), inset 0px 2px 0px rgba(255, 255, 255, 0.06);
    border-radius: 10px;
    color: #fff;
    text-transform: capitalize;
    cursor: pointer;
    border: none;
    outline: none;
`;