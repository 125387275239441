import React, {Component} from 'react';
import {PartnersCarousel} from '../../components';
import CNBC from '../../assets/cnbc.png';
import CNN from '../../assets/cnn.png';
import Forbes from '../../assets/forbes.png';
import Bella from '../../assets/bella.png';
import LSETF from '../../assets/lsetf.png';
import MWC from '../../assets/mwc.png';
import Guardian from '../../assets/guardian.png';
import './partners.css';
import {Fade} from 'react-awesome-reveal';

class Partners extends Component {
    render(){
        const partnersImages = [
            {
                name: "Hello",
                imgSrc: CNBC,
                url: "#partners",

            },
            {
                name: "CNN",
                imgSrc: CNN,
                url: "https://www.cnn.com/2021/01/08/africa/nigeria-techpreneurs-african-startups-spc-intl/index.html",

            },

            {
                name: "Forbes",
                imgSrc: Forbes,
                url: "https://www.forbesafrica.com/entrepreneurs/2021/03/19/meet-the-entrepreneur-who-is-changing-the-game-in-geriatrics/",

            },

            {
                name: "Bella Naija",
                imgSrc: Bella,
                url: "https://www.bellanaija.com/2017/10/sad-personal-experience-chika-madubuko-started-greymate-care-help-elderly-vulnerable-get-home-nursing-bellanaijawcw/",

            },

            {
                name: "LSETF",
                imgSrc: LSETF,
                url: "#partners",

            },

            {
                name: "MWC",
                imgSrc: MWC,
                url: "#partners",

            },

            {
                name: "The Guardian",
                imgSrc: Guardian,
                url: "https://guardian.ng/life/profiles-of-ten-young-nigerians-who-made-the-2019-forbes-list/",

            },
        ];

        return (
        <div className='gmc__partners section__padding'>
            <Fade top><p className='section__subtitle'>Greymate Care press and partners</p></Fade>
            <Fade top><h2>Don&rsquo;t take our word for it. <br/>These brands swear we&rsquo;re great</h2></Fade>
            <div className='gmc__partners-images'>
                <PartnersCarousel partners={partnersImages}/>
            </div>
        </div>
    );
    }
}

export default Partners