import authApi from "../../core/api/axios/baseAxios"
import { Routes } from "../../core/routes/apiRoutes";

const getAdmins = async (url) => {
    const response = await authApi.get(url);
    const resData = response.data;
    return resData;
}

const deleteAdmin = async (email) => {
    const response = await authApi.delete(Routes.getAdminsUrl+`/${email}`);
    const resData = response.data;
    return resData;
}

const createAdmin = async (data) => {
    const response = await authApi.post(Routes.createAdminUrl, data);
    const resData = response.data;
    return resData;
}

const changeStatus = async (email,data) => {
    const response = await authApi.put(Routes.changeAdminStatusUrl+`/${email}`, data);
    const resData = response.data;
    return resData;
}

const changeRole = async (email,data) => {
    const response = await authApi.put(Routes.changeAdminRoleUrl+`/${email}`, data);
    const resData = response.data;
    return resData;
}

const changePassword = async (data) => {
    const response = await authApi.put(Routes.changeAdminPasswordUrl, data);
    const resData = response.data;
    return resData;
}

const adminService = {
    getAdmins,
    createAdmin,
    deleteAdmin,
    changeStatus,
    changeRole,
    changePassword,
}

export default adminService