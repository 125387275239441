import { useEffect, useRef } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useNavigate, Link, useParams} from 'react-router-dom'
import logo from '../../../assets/logo.png';
import { DefaultTextField } from '../../../components/custom_form_component/CustomFormComponent'
import { LoadingBar } from '../../../components/loading_bar/LoadingBar'
import { toast } from 'react-toastify'
import {reset, resetSponsorPassword } from '../../../features/auth/authSlice'
import { FormError, FormPanel, FormPanelBody, FormPanelBodySection, FormPanelButton, FormPanelControl, FormPanelFooter, FormPanelHeader, FormPanelWrapper } from '../../../styles/FormPanel.Styled'
import { useForm, Controller } from "react-hook-form";
import { ErrorMessage } from '@hookform/error-message';


function SponsorResetPassword() {

    const { handleSubmit, control, reset: resetForm, watch, formState: { errors } } = useForm({
        defaultValues: {
            password: '',
            password_confirmation: '',
        }
    });

    const ref = useRef(null)
    const password = useRef({});
    password.current = watch("password", "");

    const navigate = useNavigate();
    const params = useParams();
    const dispatch = useDispatch();

    const {isError, isSuccess, isLoading, message } = useSelector((state) => state.auth)


    useEffect(() => {
        if (isError) {
            toast.error(message);
            ref.current.complete();
        }

        if (isSuccess) {
            resetForm();
            toast.success(message);
            ref.current.complete();
        }

        dispatch(reset());
    }, [isError, isSuccess, message, resetForm, navigate, dispatch])

    const onSubmit = (data) => {
        ref.current.continuousStart();
        data['token'] = params.token;
        dispatch(resetSponsorPassword(data))
    };


    return (
        <>
            <LoadingBar color="#f11946" ref={ref} shadow={true} />
            <FormPanelWrapper>
                <FormPanel as='form' onSubmit={handleSubmit(onSubmit)}>
                    <FormPanelHeader>
                        <FormPanelHeader center>
                            <Link to='/' alt="home"><img src={logo} alt="Greymate Care Logo" /></Link>
                            <h1>Reset your Greymate Sponsor Password</h1>
                        </FormPanelHeader>
                    </FormPanelHeader>
                    <FormPanelBody>
                        <FormPanelBodySection>
                            <FormPanelControl>
                                <Controller
                                    name="password"
                                    control={control}
                                    rules={{
                                        required: {
                                            value: 'required',
                                            message: 'Please enter a your password',
                                        }
                                    }}
                                    render={({ field: { onChange, value } }) => (
                                        <DefaultTextField label="New Password" type="password" name="password" placeholder='Enter password here' value={value} onChange={onChange} />
                                    )}
                                />
                                <ErrorMessage errors={errors} name="password" as={<FormError />} />
                            </FormPanelControl>
                            <FormPanelControl>
                                <Controller
                                    name="password_confirmation"
                                    control={control}
                                    rules={{
                                        required: {
                                            value: 'required',
                                            message: 'Please enter a your password',
                                        }, validate: {
                                            sameAsPassword: value =>
                                                value === password.current || "The passwords do not match"
                                        }
                                    }}
                                    render={({ field: { onChange, value } }) => (
                                        <DefaultTextField label="Confirm Password" type="password" name="password_confirmation" placeholder='Confirm password here' value={value} onChange={onChange} />
                                    )}
                                />
                                <ErrorMessage errors={errors} name="password_confirmation" as={<FormError />} />
                            </FormPanelControl>
                        </FormPanelBodySection>

                        <FormPanelFooter center>
                            <FormPanelButton fullWidth type="submit" disabled={isLoading}>{isLoading ? 'Loading...' : 'Reset Password'}</FormPanelButton>
                        </FormPanelFooter>
                    </FormPanelBody>



                </FormPanel>
            </FormPanelWrapper>

        </>

    )
}

export default SponsorResetPassword