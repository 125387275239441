import React from 'react'
import { RiFileTextLine } from 'react-icons/ri';
import { CREATE_AND_MANAGE_CAREGIVERS_APPLICATION } from '../../misc/constants';

import { DetailsBody, DetailsButton, DetailsDoc, DetailsFooter, DetailsHeader, DetailsHeaderDesc, DetailsHeaderImage, DetailsLabel, DetailsList, DetailsListWrapper, DetailsPanelStyled, DetailsTab, DetailsTabPanel, DetailsTabs, DetailsTabsHeader, DetailsThumbnail, DetailsValue } from '../../styles/admin/DetailsPanel';
import Can from '../auth_gate/Can';
import UserAvatar from '../user_avatar/UserAvatar';


function DetailsPanel(props) {
    const capitalizedFields = [
        "Certifications",
        "Skills",
        "Work Schedule",
        "Living Arrangements",
        "Contract Type",
        "Health conditions",
        "Gender",
        "Preferred Language",
    ];
    
  return (
    <DetailsPanelStyled>
        <DetailsHeader>
            <DetailsHeaderImage>
                <UserAvatar src={props.data.avatar} alt={props.data.name}/>
            </DetailsHeaderImage>
            <DetailsHeaderDesc>
                <h3>{props.data.name}</h3>
                <p>{props.data.email}</p>
            </DetailsHeaderDesc>
        </DetailsHeader>
        <DetailsBody>
            <DetailsTabs defaultValue={0}>
                <DetailsTabsHeader>
                    <DetailsTab first='true'>Personal Info</DetailsTab>
                    <DetailsTab>Payments</DetailsTab>
                    <DetailsTab>Skills & Experience</DetailsTab>
                    <DetailsTab last="true">Documents</DetailsTab>
                </DetailsTabsHeader>

                <DetailsTabPanel value={0}>
                    <DetailsListWrapper>
                        {Object.keys(props.data.profile).map((key) => 

                            <DetailsList key={key} >
                                <DetailsLabel>
                                    <span>{key}</span>
                                </DetailsLabel>
                                <DetailsValue style={{
                                textTransform: capitalizedFields.includes(key)?"capitalize":"initial",
                            }}>
                                    <span>{props.data.profile[key]}</span>
                                </DetailsValue>
                            </DetailsList>
                        )}
                    </DetailsListWrapper>


                </DetailsTabPanel>
                <DetailsTabPanel value={1}>
                    {Object.keys(props.data.payment).map((key) => 
                        <DetailsList key={key}>
                            <DetailsLabel>
                                <span>{key}</span>
                            </DetailsLabel>
                            <DetailsValue style={{
                                textTransform: capitalizedFields.includes(key)?"capitalize":"initial",
                            }}>
                                <span>{props.data.payment[key]}</span>
                            </DetailsValue>
                        </DetailsList>
                    )}
                </DetailsTabPanel>
                <DetailsTabPanel value={2}>
                {Object.keys(props.data.skills).map((key) => 
                    <DetailsList key={key}>
                        <DetailsLabel>
                            <span>{key}</span>
                        </DetailsLabel>
                        <DetailsValue style={{
                                textTransform: capitalizedFields.includes(key)?"capitalize":"initial",
                            }}>
                            <span>{props.data.skills[key]}</span>
                        </DetailsValue>
                    </DetailsList>
                )}
                </DetailsTabPanel>
                <DetailsTabPanel value={3}>
                    {props.data.documents.map((doc) => 
                        <DetailsDoc key={doc.name}>
                            <DetailsThumbnail>
                                {doc.type === 'jpg'?<img src={doc.link} alt="title" />:<RiFileTextLine size={30}/>}
                                <span>{doc.name}</span>
                            </DetailsThumbnail>
                            <div>
                                <a href={doc.link}>View</a>
                            </div>
                        </DetailsDoc>
                    )}
                </DetailsTabPanel>
            </DetailsTabs>
        </DetailsBody>
        {props.hideActions?null:<DetailsFooter>
            <div>
               
            </div>
            { Can(CREATE_AND_MANAGE_CAREGIVERS_APPLICATION) && <div>
                <DetailsButton marginRight="1rem" onClick={()=>props.onDeny(props.data.email)} $danger>Deny</DetailsButton>
                <DetailsButton  onClick={()=>props.onAccept(props.data.email)} $primary>Accept</DetailsButton>
            </div>}
        </DetailsFooter>}
    </DetailsPanelStyled>
  )
}

export default DetailsPanel