import React, { useRef, useState } from 'react'
import { RiAddFill, RiSubtractFill } from "react-icons/ri"
import './accordation.css'

const Accordation = (props) => {
    const [isActive, setIsActive] = useState(false);
    const contentEl = useRef();
  
    return (
      <div  className={`accordation ${isActive ?'active':''}`} >
        <div className='accordation__title' onClick={() => setIsActive(!isActive)}>
          <h4>{props.data.title}</h4>
          {isActive ?<RiSubtractFill color="#170F49" size={22}/>:<RiAddFill color="#170F49" size={22}/>}
        </div>
        <div ref={contentEl} className='accordation__copy' style={
        isActive
          ? { height: contentEl.current.scrollHeight }
          : { height: "0px" }
      }>
          <p>{props.data.content}</p>
        </div>
      </div>
    );
  }

export default Accordation