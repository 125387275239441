import React from "react";
import moment from "moment";
import {
	FormPanelBodyHeader,
	FormPanelBodySection,
	FormPanelBodySectionHeader,
	FormPanelButton,
	FormPanelControl,
	FormPanelFooter,
} from "../../../styles/FormPanel.Styled";
import { Flex } from "../../../styles/admin/DetailsPanel";
import { useSponsorFormState } from "../../../core/context/SponsorFormContext";
import {
	FormChipSelect,
	FormDateField,
	FormDropdownField,
	FormGoogleAutoCompleteField,
	FormInputField,
} from "../../../components/custom_form_component/CustomFormComponent";
import { useForm } from "react-hook-form";
import formValidator from "../../../misc/validator";

function ServiceUserProfile(props) {
	const {
		state: {
			service_user: {
				first_name,
				last_name,
				phone_number,
				gender,
				date_of_birth,
				address,
				address_desc,
				note,
				lat,
				lng,
				mobility,
				alertness,
				aggression_level,
				current_medication,
			},
		},
		dispatch,
	} = useSponsorFormState();
	const {
		handleSubmit,
		control,
		formState: { errors },
	} = useForm({
		defaultValues: {
			first_name: first_name,
			last_name: last_name,
			phone_number: phone_number,
			gender: gender,
			date_of_birth: date_of_birth?moment(date_of_birth,['MM/DD/YYYY']):date_of_birth,
			location: { address: address, lat: lat, lng: lng },
			address_desc: address_desc,
			note: note,
			lat: lat,
			lng: lng,
			mobility: mobility,
			alertness: alertness,
			aggression_level: aggression_level,
			current_medication: current_medication,
		},
	});

	const genderOptions = [
        {label: "Male", value:"male"},
        {label: "Female", value:"female"},
        {label: "Others", value:"others"}
    ]

	const mobilityList = [
		{ label: "Independent", value: "independent" },
		{ label: "Dependent", value: "dependent" },
	];

	const alertnessList = [
		{ label: "Confused", value: "confused" },
		{ label: "Alert", value: "alert" },
		{ label: "Unresponsive", value: "unresponsive" },
	];

	const aggressionLevels = [
		{ label: "🙂", value: 3 },
		{ label: "😐", value: 2 },
		{ label: "☹️", value: 1 },
	];

	const onSubmit = (data) => {
		data.address = data.location.address;
		data.lat = data.location.lat;
		data.lng = data.location.lng;
		data.date_of_birth = data.date_of_birth.format("MM/DD/YYYY");
		delete data.location;
		dispatch({ type: "UPDATE_SERVICE_USER_DATA", payload: data });
		props.onNextPage();
	};

	return (
		<form onSubmit={handleSubmit(onSubmit)}>
			<FormPanelBodyHeader>
				<h1>Tell us more about the care recipient</h1>
			</FormPanelBodyHeader>

			<FormPanelBodySection>
				<FormPanelBodySectionHeader>
					<h2>Personal Details</h2>
				</FormPanelBodySectionHeader>
				<FormPanelControl>
					<FormInputField
						name="first_name"
						label="First Name"
						placeholder="John"
						control={control}
						errors={errors}
					/>
				</FormPanelControl>
				<FormPanelControl>
					<FormInputField
						name="last_name"
						label="Last Name"
						placeholder="Doe"
						control={control}
						errors={errors}
					/>
				</FormPanelControl>
				<FormPanelControl>
                    <FormInputField
                        name="phone_number"
                        type="tel"
                        label="Phone Number"
                        placeholder="+23492039232"
                        control={control}
                        rules={{
                            required: {
                                value: "required",
                                message: "Please enter your phone number",
                            },
                            validate: formValidator.phoneValidator,
                        }}
                        errors={errors}
                    />
                </FormPanelControl>
                <FormPanelControl>
                    <FormDropdownField
                        name="gender"
                        label="Gender"
                        options={genderOptions}
                        control={control}
                        placeholder="Choose your gender"
                        rules={{
                            required: {
                                value: "required",
                                message: "Please select your gender",
                            },
                        }}
                        errors={errors}
                    />
                </FormPanelControl>
				<FormPanelControl>
					<FormDateField
						name="date_of_birth"
						label="Date of Birth"
						placeholder="Select Date of Birth"
						control={control}
						errors={errors}
					/>
				</FormPanelControl>
			</FormPanelBodySection>

			<FormPanelBodySection>
				<FormPanelBodySectionHeader>
					<h2>Location</h2>
				</FormPanelBodySectionHeader>
				<FormPanelControl>
					<FormGoogleAutoCompleteField
						name="location"
						label="Address of care recipient"
						control={control}
						placeholder="e.g No 5 Ago palace way"
						rules={{
							validate: {
								required: (value) => {
									return (
										!(
											value.address === null ||
											value.lat === null ||
											value.lng === null
										) || "Please select a valid service user address"
									);
								},
							},
						}}
						errors={errors}
					/>
				</FormPanelControl>
				<FormPanelControl>
					<FormInputField
						name="address_desc"
						label="Address Description"
						multiline={true}
						placeholder="e.g No 5 Ago palace way near House on the rock"
						control={control}
						errors={errors}
					/>
				</FormPanelControl>
			</FormPanelBodySection>
			<FormPanelBodySection>
				<FormPanelBodySectionHeader>
					<h2>Mobility</h2>
				</FormPanelBodySectionHeader>
				<FormPanelControl>
					<FormChipSelect
						name="mobility"
						isSmall={true}
						options={mobilityList}
						label=""
						rules={{
							required: {
								value: "required",
								message: "Please enter the care recipient mobility",
							},
						}}
						control={control}
						errors={errors}
					/>
				</FormPanelControl>
			</FormPanelBodySection>
			<FormPanelBodySection>
				<FormPanelBodySectionHeader>
					<h2>Alertness</h2>
				</FormPanelBodySectionHeader>
				<FormPanelControl>
					<FormChipSelect
						name="alertness"
						isSmall={true}
						options={alertnessList}
						label=""
						rules={{
							required: {
								value: "required",
								message: "Please enter the care recipient alertness",
							},
						}}
						control={control}
						errors={errors}
					/>
				</FormPanelControl>
			</FormPanelBodySection>

			<FormPanelBodySection>
				<FormPanelBodySectionHeader>
					<h2>Aggression level</h2>
				</FormPanelBodySectionHeader>
				<FormPanelControl>
					<FormChipSelect
						name="aggression_level"
						isSmall={true}
						options={aggressionLevels}
						label=""
						rules={{
							required: {
								value: "required",
								message:
									"Please enter the care recipient aggression towards people",
							},
						}}
						control={control}
						errors={errors}
					/>
				</FormPanelControl>
			</FormPanelBodySection>
			<FormPanelBodySection>
				<FormPanelBodySectionHeader>
					<h2>Other</h2>
				</FormPanelBodySectionHeader>
				<FormPanelControl>
					<FormInputField
						name="current_medication"
						label="Current medication known to you"
						rules={{}}
						multiline={true}
						placeholder="e.g Ibuprofen"
						control={control}
						errors={errors}
					/>
				</FormPanelControl>
				<FormPanelControl>
					<FormInputField
						name="note"
						label="Anything else we need to know?"
						rules={{}}
						multiline={true}
						placeholder="e.g She is diabetic"
						control={control}
						errors={errors}
					/>
				</FormPanelControl>
			</FormPanelBodySection>
			<FormPanelFooter>
				<Flex justifyContent="space-between">
					<FormPanelButton secondary onClick={props.onPrevPage}>
						Back
					</FormPanelButton>
					<FormPanelButton type="submit">Save and Continue</FormPanelButton>
				</Flex>
			</FormPanelFooter>
		</form>
	);
}

export default ServiceUserProfile;
