import React, { useRef } from "react";
import { toast } from "react-toastify";
import { useForm } from "react-hook-form";
import {
	FormInputField,
} from "../../../components/custom_form_component/CustomFormComponent";
import { useSelector, useDispatch } from "react-redux";
import { LoadingBar } from "../../../components/loading_bar/LoadingBar";
import {
	changePassword,
	reset,
} from "../../../features/admin/adminSlice";
import { PanelForm, PanelRow } from "../../../styles/admin/Forms.styled";
import { CardBox } from "../../../components";
import { CardBody, DashboardRow } from "../../../styles/admin/CardBox.styled";
import { DetailsButton, Flex } from "../../../styles/admin/DetailsPanel";

const formDefaults = {
	current_password: "",
	password: "",
	password_confirmation: "",
};

function Settings() {
	const {
		handleSubmit,
		control,
    watch,
		reset: resetForm,
		formState: { errors },
	} = useForm({
		defaultValues: formDefaults,
	});

	const dispatch = useDispatch();
	const ref = React.useRef(null);

	const { data, isError, isSuccess, isLoading, message } = useSelector(
		(state) => state.admin
	);

	React.useEffect(() => {
		if (isError) {
			toast.error(message);
			ref.current.complete();
		}

		if (isSuccess) {
			ref.current.complete();
		}

		if (isSuccess && message) {
			toast.success(message);
			resetForm(formDefaults);
		}

		dispatch(reset());
	}, [data, isError, isSuccess, message, resetForm, dispatch]);

  
	const onSubmit = (data) => {
		if (!isLoading) {
			ref.current.continuousStart();
			dispatch(changePassword(data));
		}
	};

  const password = useRef({});
  password.current = watch("password",password);

	return (
		<>
			<LoadingBar ref={ref} shadow={true} />
			<h1>Settings</h1>
			<DashboardRow>
				<CardBox width="50%">
					<CardBody>
						<h3>Change Password</h3>
						<p>
							Here you can change your password incase you’ve been compromised{" "}
						</p>
            <Flex margin="1rem 0"></Flex>
						<PanelForm as="form" onSubmit={handleSubmit(onSubmit)}>
							<PanelRow>
								<FormInputField
									name="current_password"
									label="Old Password"
                  type="password"
									placeholder="Enter your old password"
									control={control}
									rules={{
										required: {
											value: "required",
											message: "Please enter your old password",
										},
									}}
									errors={errors}
								/>
							</PanelRow>
              <PanelRow>
								<FormInputField
									name="password"
									label="New Password"
                  type="password"
									placeholder="Enter your new password"
									control={control}
									rules={{
										required: {
											value: "required",
											message: "Please enter your new password",
										},
									}}
									errors={errors}
								/>
							</PanelRow>
              <PanelRow>
								<FormInputField
									name="password_confirmation"
									label="Confirm Password"
                  type="password"
									placeholder="Enter your password again"
									control={control}
									rules={{
										required: {
											value: "required",
											message: "Please enter your password again",
										},
                    validate: {
                      sameAsPassword: (value) =>
                          value === password.current || "The passwords do not match",
                    },
									}}
									errors={errors}
								/>
							</PanelRow>
							
							<PanelRow>
								<DetailsButton type="submit" as="button" $primary>Reset Password</DetailsButton>
							</PanelRow>
						</PanelForm>
					</CardBody>
				</CardBox>
			</DashboardRow>
		</>
	);
}

export default Settings;
