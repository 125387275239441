import React, { useState, useEffect } from "react";
import moment from "moment";
import { useDispatch, useSelector} from "react-redux";
import { AdminModal, Badge, CustomDataTable, PopupMenu } from "../../../components";
import { DashboardRow } from "../../../styles/admin/CardBox.styled";
import { getTeamMembersBadge } from "../../../misc/helpers";
import { DetailsButton, Flex } from "../../../styles/admin/DetailsPanel";
import { LoadingBar } from "../../../components/loading_bar/LoadingBar";
import { getAdmins, deleteAdmin, createAdmin, changeStatus, reset, changeRole } from "../../../features/admin/adminSlice";
import { Routes } from "../../../core/routes/apiRoutes";
import useResource from "../../../core/hooks/useResource";
import ChangeTeamMemberStatus from "./ChangeTeamMemberStatus";
import ChangeTeamMemberRole from "./ChangeTeamMemberRole";
import CreateTeamMemberPanel from "./CreateTeamMemberPanel";
import useDependentResource from "../../../core/hooks/useDependentResource";
import { getRoles, reset as resetRoleData } from "../../../features/roles/roleSlice";
import Can from "../../../components/auth_gate/Can";
import { CREATE_AND_MANAGE_USERS } from "../../../misc/constants";


const perPage = 30;
let modalContent;
function TeamMembers() {
	const parseRoleResponse = (response) => {
		const tempData = response.data;
		const roleOptions = tempData.map((role) => {
			return {label: role.name, value: role.id}
		});
		return roleOptions;
	};

	const {
		data: roleOptions,
	} = useDependentResource({
		selector: useSelector((state) => state.role),
		getReducer: getRoles,
		resetReducer: resetRoleData,
		renderTableData: parseRoleResponse,
		routes: {
			getRoute: Routes.getRolesUrl,
		},
	});
	
	const [open, setOpen] = useState(false);
	const dispatch = useDispatch();

	const handleClose = () => setOpen(false);

	const onCreate = () => {
		modalContent =  <CreateTeamMemberPanel  roles={roleOptions}   onSubmit={createUserRequest} />;
		setOpen(true);
	};

	const onChangeRole = (user) => {
		modalContent =  <ChangeTeamMemberRole role={user.role} roles={roleOptions}  onSubmit={(formData) => updateRoleRequest({"email": user.email, data: formData})} />;
		setOpen(true);
	};

	const onChangeStatus = (user) => {
		modalContent =  <ChangeTeamMemberStatus status={user.status} onSubmit={(formData) => onChangeStatusRequest({"email": user.email, data: formData})} />;
		setOpen(true);
	};

	const createUserRequest = (formData) => {
		setOpen(false);
		ref.current.continuousStart();
		setNeedsReload(true);
		dispatch(createAdmin(formData));
	}

	const updateRoleRequest = (formData) => {
		console.log(formData);
		setOpen(false);
		ref.current.continuousStart();
		setNeedsReload(true);
		dispatch(changeRole({email: formData['email'], data: formData['data']}));
	}

	const onChangeStatusRequest = (formData) => {
		setOpen(false);
		ref.current.continuousStart();
		setNeedsReload(true);
		dispatch(changeStatus({email: formData['email'], data: formData['data']}));
	}

	const onDeleteClicked = (user) => {
		onDelete(user.email);
	}

	const prepareTableData = (response, page) => {
		var rows = [];
		const tempData = response.data;

		for (let i = 0; i < tempData.length; i++) {
			const roles = tempData[i].roles;
			var item = {
				id: i + 1 + page,
				sn: i + 1 + page,
				name: tempData[i].last_name + " " + tempData[i].first_name,
				email: tempData[i].email,
				role: roles?roles[0]:null,
				dateCreated: moment.unix(tempData[i].joined_at).fromNow(),
				status: tempData[i].status,
			};

			rows.push(item);
		}

		return rows;
	};

	
	

	const { onPrevPage, onNextPage, page, tableData, setNeedsReload, ref, isLoading, onDelete, onSearch} =
		useResource({
			selector: useSelector((state) => state.admin),
			perPage: perPage,
			getReducer: getAdmins,
			resetReducer: reset,
			deleteReducer: deleteAdmin,
			renderTableData: prepareTableData,
			routes: {
				getRoute: Routes.getAdminsUrl,
			},
		});

		

	const actions = Can(CREATE_AND_MANAGE_USERS)? [
		{ title: "Change Role", onClick: onChangeRole },
		{ title: "Change Status", onClick: onChangeStatus },
		{ title: "Delete", onClick: onDeleteClicked },
	]: [];

	const columns = [
		{
			field: "sn",
			headerName: "S/N",
			minWidth: 10,
			flex: 1,
		},
		{
			field: "name",
			headerName: "Name",
			minWidth: 100,
			flex: 2,
		},

		{
			field: "email",
			headerName: "Email Address",
			minWidth: 100,
			flex: 2,
		},
		{
			field: "role",
			headerName: "Role",
			minWidth: 100,
			flex: 2,
			renderCell: (params) => {
				const role = params.row.role;
				return role?role['name']:''
			},
		},
		{
			field: "dateCreated",
			headerName: "Date Created",
			minWidth: 100,
			flex: 2,
		},
		{
			field: "status",
			headerName: "Status",
			minWidth: 100,
			flex: 2,
			sortable: false,
			renderCell: (params) => {
				const badge = getTeamMembersBadge(params.row.status);
				return <Badge {...badge.badgeType}>{badge.badgeText}</Badge>;
			},
			disableClickEventBubbling: true,
		},
		{
			field: "actions",
			headerName: "",
			minWidth: 100,
			flex: 1,
			sortable: false,
			renderCell: (params) => {
				return <PopupMenu id={params.row} options={actions} />;
			},
			disableClickEventBubbling: true,
		},
	];

	useEffect(() => {
		document.title = "Team Members - Greymate Care Admin";
	}, []);

	return (
		<>
			<LoadingBar ref={ref} shadow={true} />
			<Flex justifyContent="space-between" alignItems="center">
				<h1>Team Members</h1>
				{Can(CREATE_AND_MANAGE_USERS) && <DetailsButton onClick={onCreate} $primary>
					Add Team Members
				</DetailsButton>}
			</Flex>

			<DashboardRow>
				<CustomDataTable
					disableSelectionOnClick
					pageSize={perPage}
					pagination="false"
					rowsPerPageOptions={[]}
					loading={isLoading}
					columns={columns}
					rows={tableData}
					hideFooter={true}
					prev={onPrevPage}
					next={onNextPage}
					currentPage={page}
					onSearchChange={onSearch}
				/>
			</DashboardRow>
			<AdminModal open={open} onClose={handleClose}>
				{modalContent}
			</AdminModal>
		</>
	);
}

export default TeamMembers;
