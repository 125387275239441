import authApi from "../../core/api/axios/baseAxios"
import { Routes } from "../../core/routes/apiRoutes";

const getCaregivers = async (url, status = 'pending') => {
    const response = await authApi.get(url, {
        params: {
            status: status,
          },
    });
    const resData = response.data;
    return resData;
}


const deleteCaregiver = async (email) => {
    const response = await authApi.delete(Routes.getCaregiverUrl+`/${email}`);
    const resData = response.data;
    return resData;
}

const acceptCaregiverApplication = async (email) => {
    const response = await authApi.put(Routes.getCaregiverUrl+`/accept/${email}`);
    const resData = response.data;
    return resData;
}

const denyCaregiverApplication = async (email) => {
    const response = await authApi.put(Routes.getCaregiverUrl+`/deny/${email}`);
    const resData = response.data;
    return resData;
}

const assignServiceUser = async (email, service_user_id, startDate, startTime, endTime) => {
    const response = await authApi.put(Routes.getCaregiverUrl+`/assign/${email}`, {
        'service_user_id': service_user_id,
        'start_date': startDate,
        'start_time': startTime,
        'end_time': endTime,
    });
    const resData = response.data;
    return resData;
}

const unassignServiceUser = async (email) => {
    const response = await authApi.put(Routes.getCaregiverUrl+`/unassign/${email}`);
    const resData = response.data;
    return resData;
}

const caregiverService = {
    getCaregivers,
    deleteCaregiver,
    acceptCaregiverApplication,
    denyCaregiverApplication,
    assignServiceUser,
    unassignServiceUser,
}

export default caregiverService