const Can  = (permission) => {
    let user = localStorage.getItem('user');
    

    if(!user){
        return false;
    }

    user = JSON.parse(user);

    return (user?.permissions || []).find((p) => p === permission) ? true : false;
};

export default Can;