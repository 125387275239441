import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Navbar, PricingPlan } from "../../components";
import { Footer } from "../../containers";
import "./pricing.css";
import BGHeader from "../../assets/bg-header.png";
import Icon2 from "../../assets/picon2.svg";
import { LoadingBar } from "../../components/loading_bar/LoadingBar";
import {
	publicGetSubscriptionPlans,
	reset,
} from "../../features/subscription_plans/subscriptionPlanSlice";
import usePublicResource from "../../core/hooks/usePublicResource";
import { Routes } from "../../core/routes/apiRoutes";
import { PricingSwitch } from "../../styles/Switch.Styled";
import { Flex } from "../../styles/admin/DetailsPanel";
import { PlanSwitchLabel } from "../../styles/PlanCard.Styled";
import useIpDetails from "../../core/hooks/useIpDetails";

function Pricing() {
	const [planDuration, setPlanDuration] = useState("monthly");
	const togglePlanDuration = (checked) => {
		setPlanDuration(checked ? "yearly" : "monthly");
	};

	function sortByAmount(a, b) {
		return a.amount - b.amount;
	}

	const prepareTableData = (response) => {
		var rows = [];
		const tempData = response.data;

		for (let i = 0; i < tempData.length; i++) {
			var item = {
				id: i + 1,
				sn: i + 1,
				icon: Icon2,
				name: tempData[i].name,
				description: tempData[i].description,
				period: tempData[i].period,
				amount: tempData[i].subscription_value,
				status: tempData[i].is_enabled ? "active" : "disabled",
				slug: tempData[i].slug,
				link: "/get-started",
			};

			rows.push(item);
		}

		return rows.sort(sortByAmount);
	};

	const { data, ref } = usePublicResource({
		selector: useSelector((state) => state.subscriptionPlan),
		getReducer: publicGetSubscriptionPlans,
		resetReducer: reset,
		renderTableData: prepareTableData,
		routes: {
			getRoute: Routes.getSubscriptionPlansUrl,
		},
	});

	const { currency, exchangeRateResponse } = useIpDetails({
		defaultCurrency: "NGN",
	});


	const curr = currency ?? "NGN";
	const amount = exchangeRateResponse?.rates
		? 2500 * exchangeRateResponse?.rates[curr]
		: 2500;

	useEffect(() => {
		document.title = "Pricing Plans - Greymate Care";
	}, []);

	function filterMonthly(plan) {
		const acceptedPlans = ["day", "daily", "month", "monthly"];
		return acceptedPlans.includes(plan.period);
	}

	function filterYearly(plan) {
		const acceptedPlans = ["year", "yearly"];
		return acceptedPlans.includes(plan.period);
	}

	const pricingPlansList = () => {
		if (planDuration === "monthly") {
			return data.filter(filterMonthly).map((plan) => (
				<div key={plan.id} className="gmc__pricing-plans-item">
					<PricingPlan
						currency={currency}
						rates={exchangeRateResponse?.rates}
						duration={planDuration}
						plan={plan}
					/>
				</div>
			));
		}

		if (planDuration === "yearly") {
			return data.filter(filterYearly).map((plan) => (
				<div key={plan.id} className="gmc__pricing-plans-item">
					<PricingPlan
						currency={currency}
						rates={exchangeRateResponse?.rates}
						duration={planDuration}
						plan={plan}
					/>
				</div>
			));
		}
	};

	const label = { inputProps: { "aria-label": "Monthly" } };

	return (
		<>
			<LoadingBar ref={ref} shadow={true} />
			<div className="gmc__pricing-background">
				<Navbar />
				<div
					className="gmc__pricing__header section__padding section__bg__lines"
					style={{
						backgroundImage: `url(${BGHeader})`,
					}}
				>
					<h1 className="section__heading">
						Choose a care plan that suits your <br />
						circumstances{" "}
					</h1>
					<p className="section__desc">
						Get a caregiver from as little as {Intl.NumberFormat('en-US', { style: 'currency', currency: curr, currencyDisplay: 'narrowSymbol', minimumFractionDigits: 0, maximumFractionDigits: 0 }).format(amount)}. <br />
						Your payment is protected with our <b>money-back guarantee</b> and
						<b> escrow system</b>. <br />
						Caregivers only receive payment after satisfactory service.
					</p>
					<div
						style={{
							marginTop: "80px",
						}}
					>
						<Flex justifyContent="center">
							<PlanSwitchLabel
								$active={planDuration === "monthly" ? true : false}
							>
								Monthly
							</PlanSwitchLabel>
							<PricingSwitch
								{...label}
								onChange={(e, checked) => togglePlanDuration(checked)}
							/>
							<PlanSwitchLabel
								$active={planDuration === "yearly" ? true : false}
							>
								Yearly
							</PlanSwitchLabel>
						</Flex>
					</div>
				</div>
			</div>

			<div className="section__padding gmc__pricing-plans">
				{pricingPlansList()}
			</div>
			<Footer />
		</>
	);
}

export default Pricing;
