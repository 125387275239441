import React from 'react'
import { StatBoxDesc, StatBoxStyled, StatBoxTitle } from '../../styles/admin/StatsBox.styled'

function StatsBox(props) {
  return (
    <StatBoxStyled>
        <div>
            <StatBoxTitle>{props.title}</StatBoxTitle>
            <StatBoxDesc>{props.value}</StatBoxDesc>
        </div>
        <div>
            <div  style={{padding:"1.5rem", borderRadius: '10px', color: "#fff", fontWeight: "800", backgroundColor: props.bgColor??'var(--color-primary)'}}>{props.monthlyValue}</div>
        </div>
    </StatBoxStyled>
  )
}

export default StatsBox