import React from 'react';
import {RiFacebookFill, RiTwitterFill, RiInstagramFill} from 'react-icons/ri'
import { Link } from 'react-router-dom'
import { CTA } from '../../components'
import {Fade} from 'react-awesome-reveal';
import './footer.css'

const Footer = () => {
  return (
    <div className='gmc__footer section__padding'>
      <div className='gmc__footer-wrapper'>
        
        <div className='gmc__footer-company'>
        <Fade top><h3>Greymate Care</h3></Fade>
        <Fade top><p>You send money home for care already. Let's make sure <br/>it gets into the right hands.</p></Fade>
        <Fade top><CTA /></Fade>
        </div>

        <div className='gmc__footer-links'>
        <Fade top><h3>Company</h3></Fade>
          <ul>
          <Fade top><li><Link to="/become-caregiver">Become a caregiver</Link></li></Fade>
            <Fade top><li><Link to="/about-us">About Us</Link></li></Fade>
            <Fade top><li><Link to="/pricing">Pricing</Link></li></Fade>
            <Fade top><li><Link to="/faqs">FAQs</Link></li></Fade>
            <Fade top><li><Link to="/contact-us">Contact Us</Link></li></Fade>
          </ul>
        </div>

        <div className='gmc__footer-links'>
           <Fade top><h3>More</h3></Fade>
          <ul>
             <Fade top><li><Link to='/home-care-emergencies'>For Home Care Agencies</Link></li></Fade>
             <Fade top><li><Link to='/for-employers'>For Employers</Link></li></Fade>
             <Fade top><li><Link to='/csrs-and-donations'>For CSRs/Donations</Link></li></Fade>
          </ul>
        </div>

        <div className='gmc__footer-socials'>
           <Fade top><h3>Socials</h3></Fade>
           <Fade top><ul>
             <li><a target="_blank" rel="noreferrer" href='https://facebook.com/greymatecare'><RiFacebookFill color="#fff"/></a></li>
             <li><a target="_blank" rel="noreferrer" href='https://twitter.com/greymatecare'><RiTwitterFill color="#fff"/></a></li>
             <li><a target="_blank" rel="noreferrer" href='https://instagram.com/greymatecare'><RiInstagramFill color="#fff"/></a></li>
          </ul>
          </Fade>
        </div>
      </div>
      <div className='gmc__footer-copy'>
        <div className='gmc__footer-copy_links'>
          <Link to='/terms-and-conditions'>Terms of Use</Link>
          <Link to='/privacy-policy'>Privacy Policy</Link>
        </div>

        <div className='gmc__footer-copy_text'>
          <p>&copy;Copyright 2022, All Rights Reserved</p>
        </div>
      </div>
    </div>
  )
}

export default Footer