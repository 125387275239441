import {useEffect, useRef} from 'react'
import { useSelector, useDispatch } from 'react-redux'
import {Link} from 'react-router-dom'
import logo from '../../../assets/logo.png';
import { DefaultTextField } from '../../../components/custom_form_component/CustomFormComponent'
import {LoadingBar} from '../../../components/loading_bar/LoadingBar'
import {toast} from 'react-toastify'
import {forgotPassword, reset} from '../../../features/auth/authSlice'
import { FormError, FormPanel, FormPanelBody, FormPanelBodySection, FormPanelButton, FormPanelControl, FormPanelFooter, FormPanelHeader, FormPanelWrapper } from '../../../styles/FormPanel.Styled'
import { useForm, Controller } from "react-hook-form";
import { ErrorMessage } from '@hookform/error-message';
import { AppRoutes } from '../../../core/routes/routes';
import formValidator from '../../../misc/validator';


function ForgotPassword() {

    const { handleSubmit,  control, reset: resetForm, formState: { errors } } = useForm({
        defaultValues: {
            email: '',
        }
    });

    const ref = useRef(null)
    const dispatch = useDispatch();

    const {isError, isSuccess, isLoading, message} = useSelector((state) => state.auth)
    

    useEffect(()=>{
        if(isError){
            toast.error(message)
            ref.current.complete()
        }

        if(isSuccess) {
            resetForm();
            toast.success(message)
            ref.current.complete()
        }

        dispatch(reset())
    }, [isError, isSuccess, resetForm, message, dispatch])
    
    const onSubmit = (data) => {
       
        
        ref.current.continuousStart();
       
        dispatch(forgotPassword(data))

    };

  
  return (
    <>
        <LoadingBar ref={ref} shadow={true} />
        <FormPanelWrapper>
            <FormPanel as='form' onSubmit={handleSubmit(onSubmit)}>
                <FormPanelHeader>
                    <FormPanelHeader center>
                        <Link to='/' alt="home"><img src={logo} alt="Greymate Care Logo" /></Link>
                        <h1>Forgot your Greymate Admin Password?</h1>
                        <p>Please fill out this form and an email will be sent to you with further instructions</p>
                    </FormPanelHeader>
                </FormPanelHeader>
                <FormPanelBody>
                    <FormPanelBodySection>
                        <FormPanelControl>
                            <Controller
                                name="email"
                                control={control}
                                rules={{ 
                                    required: {
                                        value: 'required',
                                        message: 'Please enter your email',
                                    }, 
                                    validate: formValidator.emailValidator,  
                                }}
                                render={({ field: { onChange, value }}) => (
                                    <DefaultTextField  label="Email" type="email" name="email" placeholder='you@example.com' onChange={onChange} value={value}/>
                                )}
                            />
                            <ErrorMessage errors={errors} name="email" as={<FormError />} />
                        </FormPanelControl>
                        <FormPanelControl>  
                            <p>Remember your password? <Link to={AppRoutes.LoginURL}>login</Link></p>
                        </FormPanelControl>
                    </FormPanelBodySection>

                    <FormPanelFooter center>
                        <FormPanelButton fullWidth type="submit" disabled={isLoading}>{isLoading?'Loading...':'Send Password Reset Link'}</FormPanelButton>
                    </FormPanelFooter>
                </FormPanelBody>

               

            </FormPanel>
        </FormPanelWrapper>
        
    </>
    
  )
}

export default ForgotPassword