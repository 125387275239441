import React, { useEffect } from 'react'
import { Navbar } from '../../components'
import { Footer } from '../../containers'
import './donations.css'

function Donations() {
  useEffect(() => {
    document.title = "CSRS or Donations - Greymate Care";
  }, []);

  return (
    <>
    <div className='gmc__donations-background'>
      <Navbar/>
      <div className='section__padding gmc__donations__header'>
          <div className='gmc__donations__header__content'>
              <p className='section__subtitle'>For CSRs/Donations</p>
              <h1 className='section__heading'>Looking for the perfect Mother’s Day or Christmas gift?</h1>
              <a target="__blank" href='https://paystack.com/pay/greymatecare-giftcard ' className='button__primary'>Purchase Gift Card(s)</a>
          </div>
          <div>
            <div className='section__quote'>
                <p>Is your organization looking for the perfect Corporate Social Responsibility (CSR) that is impactful and will leave memories in the hearts of the beneficiaries? </p>
            </div>
            <div className='section__quote_desc'>
              <p className='section__desc'>Simply purchase a gift card for the care recipient(s) and we’ll provide the services free of charge to the care recipient(s). If there are multiple recipients or a community outreach, please send us an email to <a href="mailto:partnerships@greymatecare.com">partnerships@greymatecare.com</a></p>
            </div>
            
          </div>
         
      </div>
    </div>
    <Footer />
    </>
  )
}

export default Donations