import React, { useEffect, useState } from 'react'
import { AdminModal, Badge, CreateDiscountPanel, CustomDataTable } from '../../../components';
import { DashboardRow } from '../../../styles/admin/CardBox.styled';
import { MenuBtn } from '../../../styles/admin/Admin.styled';
import {generateDiscountCodes, getDiscountBadge } from '../../../misc/helpers';
import { RiArrowRightSLine } from 'react-icons/ri';
import { DetailsButton, Flex } from '../../../styles/admin/DetailsPanel';
import ViewDiscountPanel from '../../../components/discount_panel/ViewDiscountPanel';

const rows = generateDiscountCodes();
let modalData;

function Discount() {
    const [open, setOpen] = useState(false);
    const [openCreateDiscount, setOpenCreateDiscount] = useState(false);

    const handleOpen = (data) => {
        modalData = data;
        setOpenCreateDiscount(false);
        setOpen(true);
    };
    const handleClose = () => setOpen(false);
    const handleCreateDiscount = () => {
      modalData = null;
      setOpenCreateDiscount(true);
      setOpen(true);
    };

    useEffect(() => {
        document.title = "Discount - Greymate Care Admin";
    }, []);


    const columns = [
        {
            field: "sn",
            headerName: "S/N",
            minWidth: 10,
            flex: 1
        },
        {
            field: 'codeName',
            headerName: 'Code Name',
            minWidth: 100,
            flex: 2
        },
        {
            field: 'dateCreated',
            headerName: 'Date Created',
            minWidth: 100,
            flex: 2
        },
        {
            field: 'percentage',
            headerName: 'Percentage',
            minWidth: 100,
            flex: 2
        },
        {
            field: 'code',
            headerName: 'code',
            minWidth: 100,
            flex: 2
        },
        {
            field: 'status',
            headerName: 'Status',
            minWidth: 100,
            flex: 2,
            sortable: false,
            renderCell: (params) => {
                const badge = getDiscountBadge(params.row.status);
                return (<Badge {...badge.badgeType}>{badge.badgeText}</Badge>);
            },
            disableClickEventBubbling: true,
        },
        {
            field: 'actions',
            headerName: '',
            minWidth: 100,
            flex: 1,
            sortable: false,
            renderCell: (params) => {
                return (
                    <MenuBtn onClick={() => handleOpen(params.row)}><RiArrowRightSLine size={14} /></MenuBtn>
                );
            },
            disableClickEventBubbling: true,
        },
    ];


    return (
        <>
            <Flex justifyContent="space-between" alignItems="center">
              <h1>Discount</h1>
              <DetailsButton as={'button'} onClick={handleCreateDiscount} primary>Create Code</DetailsButton>
            </Flex>

            <DashboardRow>
                <CustomDataTable
                    disableSelectionOnClick
                    pageSize={10}
                    rowsPerPageOptions={[10]}
                    columns={columns}
                    rows={rows}
                />
            </DashboardRow>
            <AdminModal open={open} onClose={handleClose}>
                {modalData && (
                    <ViewDiscountPanel data={modalData} onDisableCode={handleClose} onDeny="" />
                )}

                {openCreateDiscount && (
                    <CreateDiscountPanel onCreateCode={handleClose} />
                )}

            </AdminModal>
        </>

    )
}

export default Discount