import React, { useEffect, useState } from 'react';
import './pricing_slider.css';
import Slider from '@mui/material/Slider';
import usePublicResource from '../../core/hooks/usePublicResource';
import { useSelector } from 'react-redux';
import { publicGetSubscriptionPlans, reset } from '../../features/subscription_plans/subscriptionPlanSlice';
import { Routes } from '../../core/routes/apiRoutes';
import { LoadingBar } from '../../components/loading_bar/LoadingBar';
import { Flex } from '../../styles/admin/DetailsPanel';
import { PlanSwitchLabel } from '../../styles/PlanCard.Styled';
import { PricingSwitch } from '../../styles/Switch.Styled';

function PricingSlider({currency,
  exchangeRateResponse}) {
  const [planIndex, setPlanIndex] = useState(0);

  const [planDuration, setPlanDuration] = useState("monthly");
  const [currentPlan, setCurrentPlan] = useState({});
	const togglePlanDuration = (checked) => {
		setPlanDuration(checked ?'yearly' : 'monthly');
    
  }
  const label = { inputProps: { 'aria-label': 'Monthly' } };

  function sortByAmount(a, b){
    return a.amount - b.amount;
  }

  const prepareTableData = (response) => {
		var rows = [];
		const tempData = response.data;
    
		for (let i = 0; i < tempData.length; i++) {
			var item = {
				id: i,
				name: tempData[i].name,
				period: tempData[i].period,
				amount: tempData[i].subscription_value,
			};

			rows.push(item);
		}

		return rows.sort(sortByAmount);
	};

	const {data, ref} =
		usePublicResource({
			selector: useSelector((state) => state.subscriptionPlan),
			getReducer: publicGetSubscriptionPlans,
			resetReducer: reset,
			renderTableData: prepareTableData,
			routes: {
				getRoute: Routes.getSubscriptionPlansUrl,
			},
		});

	
  useEffect(()=>{
    if(data.length > 0){
      setCurrentPlan(getPlanData(marks[0]));
      setPlanIndex(0);
    }
   // eslint-disable-next-line
  }, [data, planDuration,exchangeRateResponse, currency]);
function filterMonthly(plan){
		const acceptedPlans = ["day", "daily", "month", "monthly"];
		return acceptedPlans.includes(plan.period);
	}

	function filterYearly(plan){
		const acceptedPlans = ["year", "yearly"];
		return acceptedPlans.includes(plan.period);
	}

  const planData = data.filter(planDuration === "monthly" ?filterMonthly:filterYearly);

  const marks = planData.map((e, index) => {
    return {
      value: index,
      amount: e.amount,
      period: e.period,
      label: e.name,
    };
  });

  const getPlanData = (plan) => {
    const curr = currency??"NGN";
    const rates = exchangeRateResponse?.rates;
    const amount = rates?(plan.amount * rates[curr]):plan.amount;

    return {
      amount: amount,
      period: plan.period,
    }

  }
  const handleSliderChange = (event, newValue) => {
    const plan = marks[newValue];
    setCurrentPlan(getPlanData(plan));
    setPlanIndex(newValue);
  };
  


  return (
    <>
    <LoadingBar ref={ref} shadow={true} />
    <div className='gmc__pricing_slider section__padding'>
      <div className='header'>
        <h2>Transparent & Affordable Pricing</h2>
        <p>Our pricing is based on how often you want the caregiver to be present. Drag the slider to see the pricing options.</p>
      </div>
     

      <div className='slider'>
        
          <div className='price-box' id='price-label'>
          {Intl.NumberFormat('en-US', { style: 'currency', currency: currency, currencyDisplay: 'narrowSymbol', minimumFractionDigits: 0, maximumFractionDigits: 0 }).format(currentPlan.amount)}<span> /{currentPlan.period}</span>
          </div>

       
      </div>

      <div className='switch'>
						<Flex justifyContent="center">
							<PlanSwitchLabel color="#fff" $active={planDuration === "monthly"? true:false}>Monthly</PlanSwitchLabel>
							<PricingSwitch {...label}  onChange={(e, checked)=>togglePlanDuration(checked)}/>
							<PlanSwitchLabel color="#fff" $active={planDuration === "yearly"? true:false}>Yearly</PlanSwitchLabel>
						</Flex>
						
					</div>
      <div className='slider'>
        <Slider
              aria-label="Pricing Plans"
              
              defaultValue={0}
              value={planIndex}
              step={1}
              max={planData.length - 1}
              valueLabelDisplay="off"
              color='primary'
              marks={marks}
              onChange={handleSliderChange}
            />
      </div>
    </div>
    </>
  )
}

export default PricingSlider