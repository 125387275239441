export const CREATE_AND_MANAGE_USERS = "Create and Manage Users";
export const VIEW_USERS = "View Users";
export const CREATE_AND_MANAGE_SPONSORS = "Create and Manage Sponsors";
export const VIEW_SPONSORS = "View Sponsors";
export const CREATE_AND_MANAGE_SERVICE_USERS = "Create and Manage Service Users";
export const VIEW_SERVICE_USERS = "View Service Users";
export const CREATE_AND_MANAGE_CAREGIVERS = "Create and Manage Caregivers";
export const VIEW_CAREGIVERS= "View Caregivers";
export const CREATE_AND_MANAGE_CAREGIVERS_APPLICATION = "Create and Manage Caregivers Application";
export const VIEW_CAREGIVERS_APPLICATION = "View Caregivers Application";
export const ASSIGN_CAREGIVERS = "Assign Caregivers";
export const UNASSIGN_CAREGIVERS= "Unassign Caregivers";
export const CREATE_AND_MANAGE_SUBSCRIPTION_PLANS = "Create and Manage Subscription Plans";
export const VIEW_SUBSCRIPTION_PLANS = "View Subscription Plans";
export const MANAGE_ACTIVITY_LOGS = "Manage Activity Logs";
export const VIEW_ACTIVITY_LOGS = "View Activity Logs";
export const CREATE_AND_MANAGE_FORM_REQUESTS = "Create and Manage Form Request";
export const VIEW_FORM_REQUESTS = "View Form Request";
export const VIEW_ANALYTICS = "View Analytics";
export const MANAGE_APP_SETTINGS = "Manage App Settings";
export const VIEW_APP_SETTINGS = "View App Settings";
export const CREATE_AND_MANAGE_ROLES_AND_PERMISSIONS = "Create and Manage Roles and Permissions";
export const VIEW_ROLES_AND_PERMISSIONS = "View Roles and Permissions";