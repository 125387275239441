import React from 'react';
import './header2.css';
import BannerCaregiver from '../../assets/banner-caregiver.svg';
import BGHeader from '../../assets/bg-header.png';
import {Fade} from 'react-awesome-reveal';

const Header2 = () => {
  return (
    <div className='gmc__header2 section__padding' id='home' style={{ 
      backgroundImage: `url(${BGHeader})` 
    }}>
      <div className='gmc__header2-content'>
        <Fade top><h1>Got what it takes <br />to be a care giver?</h1></Fade>
        <Fade top><p>Join us in changing the frontiers of home health in Africa</p></Fade>
        <Fade top><a href='#become-caregiver' className='button__primary'>Become a Caregiver</a></Fade>
      </div>
      
      <Fade right>
        <div className='gmc__header2-image'>
          <img src={BannerCaregiver} alt="Home"/>
          <div className='gmc__header-image-desc-1'>
            <p>We are fast changing the frontiers on home health in Africa</p>
          </div>
        </div>
      </Fade>
    </div>
  )
}

export default Header2