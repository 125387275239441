import {createSlice, createAsyncThunk} from '@reduxjs/toolkit'
import { Routes } from '../../core/routes/apiRoutes'
import contactRequestService from './contactService'

const initialState = {
    data: null,
    isError: false,
    isSuccess: false,
    isLoading: false,
    message: '',
    errors: null,
}

//Create Contact Request
export const createContactRequest = createAsyncThunk(Routes.createContactRequestUrl, async (data, thunkAPI) => {
    try{
        return await contactRequestService.requestContact(data);
    }catch (error) {
        var errorData = {message: (error.response && error.response.data && error.response.data.message) || error.message || error.toString()}
        if(error.response.status === 422){
            errorData['errors'] = error.response.data.errors;
            
        }
        return thunkAPI.rejectWithValue(errorData);
    }
})



export const contactRequestSlice = createSlice({
    name: 'contactRequest',
    initialState,
    reducers: {
        reset: (state) => {
            state.isLoading = false;
            state.isSuccess = false;
            state.isError = false;
            state.message = '';
            state.errors = null;
            state.data = null;
        }
    }, 
    extraReducers: (builder) => {
        builder
        //Initialize Payment
        .addCase(createContactRequest.pending, (state) => {
            state.isLoading = true
        })
        .addCase(createContactRequest.fulfilled, (state,action) => {
            state.isLoading = false
            state.isSuccess = true
            state.isError = false
            state.data = action.payload
            state.errors = null

        })
        .addCase(createContactRequest.rejected, (state, action) => {
            state.isLoading = false
            state.isError = true
            state.message = action.payload.message
            state.errors = action.payload.errors
        })
    }
})

export const {reset} = contactRequestSlice.actions
export default contactRequestSlice.reducer