import React, { Component } from 'react'
import './caregiver_reviews.css'
import MapBG from '../../assets/bg-map.png'
import { CommentCarousel } from '../../components'

export default class CaregiverReviews extends Component {
  render() {
    const commentList = [
        {
            comment: 'I enjoy working with Greymate, from the timely pay, the best on-call support and the training that prepares you or the field, Everything is just perfect.',
            author: 'Tolulope',
        },
        {
            comment: 'Working with Greymate is very emotionally fulfilling and I am in control of my time. My best part is gaining wisdom from the elderly I work with',
            author: 'Valentine',
        },
        {
            comment: 'I am happy to be a Greymate. My payment is guaranteed and I feel well-supported in the field by the on-call support and care advisory team',
            author: 'Loveth',
        },
    ]

    return (
      <div className='gmc__caregiver-reviews section__padding' style={{ background: `url(${MapBG}), linear-gradient(180deg, #F7F7FB 0%, rgba(247, 247, 251, 0) 16.05%)`,}}>
        <div className='gmc__caregiver-reviews-header'>
            <h2>Caregiver reviews</h2>
            <p>Hear from some of our caregivers</p>
        </div>
        <div className='gmc__caregiver-reviews_reviews' >
            <CommentCarousel list={commentList}/>
            
        </div>
        <div className='gmc__caregiver-reviews_footer'>
            <div className='gmc__caregiver-reviews_footer-content'>
                <p>With our caregiver perks program, you get benefits, shopping <br /> vouchers, career progression opportunities, and lots of other perks.</p>
            </div>
            <div className='gmc__caregiver-reviews_footer-link'>
                <a href='#hello' className='button__primary'>Get Started</a>
            </div>
        </div>
      </div>
    )
  }
}
