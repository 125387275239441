import { createGlobalStyle } from "styled-components";

const GlobalStyles  = createGlobalStyle`

    * {
        box-sizing: border-box;
        padding:  0;
        margin: 0;
        scroll-behavior: smooth;
        font-family: var(--font-family);
    }

    h1,h2,h3,h4,h5,h6 {
        font-family: var(--font-family-headers);
        color: var(--color-text-primary);
        font-weight: 800;
    }

    body {
        background: #fff;
    }

    a {
        color: unset;
        text-decoration: none;
    }
`;


export default GlobalStyles;