import { Link } from "react-router-dom";
import React, { useRef, useState, useEffect } from "react";
import { useSelector, useDispatch } from 'react-redux'
import {useNavigate, useLocation} from 'react-router-dom'
import logo from "../../../assets/logo.png";
import {
	useSponsorFormState,
} from "../../../core/context/SponsorFormContext";
import {
	FormErrorWrapper,
	FormPanel,
	FormPanelBody,
	FormPanelHeader,
	FormPanelIndicator,
	FormPanelIndicatorTitle,
	FormPanelIndicatorWrapper,
} from "../../../styles/FormPanel.Styled";
import { FormPanelPlanWrapper } from "../../../styles/FormPlanCard.Styled";
import Extra from "./Extra";
import Preferences from "./Preferences";
import Register from "./Register";
import ServiceUserProfile from "./ServiceUserProfile";
import SponsorProfile from "./SponsorProfile";
import { LoadingBar } from "../../../components/loading_bar/LoadingBar";
import {register, reset} from '../../../features/sponsor/sponsorSlice'
import {toast} from 'react-toastify';
import { AppRoutes } from "../../../core/routes/routes";

function PageIndicator(props) {
	var indicators = [];
	for (var i = 1; i <= props.totalPages; i++) {
		indicators.push(
			<FormPanelIndicator
				key={i}
				active={props.currentPage === i ? true : false}
			></FormPanelIndicator>
		);
	}

	return (
		<>
			<FormPanelIndicatorTitle>{`Step ${props.currentPage} of ${props.totalPages}`}</FormPanelIndicatorTitle>
			<FormPanelIndicatorWrapper>
				{indicators.map((indicator) => indicator)}
			</FormPanelIndicatorWrapper>
		</>
	);
}

function ShowPage(props) {
	switch (props.page) {
		case 1:
			return <Register onNextPage={props.onNextPage}></Register>;
		case 2:
			return (
				<SponsorProfile
					onNextPage={props.onNextPage}
					onPrevPage={props.onPrevPage}
				></SponsorProfile>
			);

		case 3:
			return (
				<ServiceUserProfile
					onNextPage={props.onNextPage}
					onPrevPage={props.onPrevPage}
				/>
			);
		case 4:
			return (
				<Preferences
					onNextPage={props.onNextPage}
					onPrevPage={props.onPrevPage}
				/>
			);
		case 5:
			return (
				<Extra
					onPrevPage={props.onPrevPage}
					onSaveAndPay={props.onSaveAndPay}
				/>
			);
		default:
		//nothing
	}
}

function SetupSponsorProfile() {
	const { state } = useSponsorFormState();
	const [page, setPage] = useState(1);
	const [formErrors, setFormErrors] = useState(null);
	const ref = useRef(null);

	const navigate = useNavigate();
    const location = useLocation();
    const redirectTo = location.state?.from?.pathname || AppRoutes.SponsorUrl;
    const dispatch = useDispatch();

    const {sponsor, isError, isSuccess, message, errors} = useSelector((globalState) => globalState.sponsor)
    
	
    useEffect(()=>{
        if(isError){
            toast.error(message)
            ref.current.complete()
           
            if(errors){
                var err = {};
                Object.keys(errors).map((key) => err[key] = errors[key].join(', '))
                setFormErrors(err)
            }else {
				setFormErrors(null);
			}
        }

        if(isSuccess && sponsor) {
            ref.current.complete()
			dispatch(reset())
            navigate(redirectTo)
        }

        dispatch(reset())
    }, [sponsor, isError, isSuccess, message, errors, redirectTo, navigate, dispatch])

	const onNextPage = () => {
		setPage(page + 1);
	};

	const onPrevPage = () => {
		setPage(page - 1);
	};

	const onChangePage = (i) => {
		if (page !== i) {
			setPage(i);
		}
	};

	const onSaveAndPay = () => {

		//PREPARE DATA
		var formData = state;
		formData = {...formData.registerData, ...formData}
		delete formData.registerData;

		//REGISTER SPONSOR
		ref.current.continuousStart();
		dispatch(register(formData));
	};

	return (
		<>
				<LoadingBar ref={ref} shadow={true} />

				<FormPanelPlanWrapper>
					<FormPanel>
						<FormPanelHeader>
							<Link to="/" alt="home">
								<img src={logo} alt="Greymate Care Logo" />
							</Link>
						</FormPanelHeader>

						{formErrors && (
							<FormErrorWrapper>
								{Object.keys(formErrors).map((key) => <p>{formErrors[key]}</p>)}
							</FormErrorWrapper>
						)}
						
						<FormPanelBody>
							<PageIndicator
								totalPages={5}
								currentPage={page}
								onChangePage={onChangePage}
							/>
							<ShowPage
								page={page}
								onNextPage={onNextPage}
								onPrevPage={onPrevPage}
								onSaveAndPay={onSaveAndPay}
							/>
						</FormPanelBody>
					</FormPanel>
				</FormPanelPlanWrapper>
		</>
	);
}

export default SetupSponsorProfile;
