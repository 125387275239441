import React from "react";
import { useForm } from "react-hook-form";
import {
	CheckBox,
	FormInputField,
} from "../custom_form_component/CustomFormComponent";
import {
	DetailsBody,
	DetailsFooter,
	DetailsHeader,
	DetailsHeaderDesc,
	DetailsPanelStyled,
} from "../../styles/admin/DetailsPanel";
import { PanelForm, PanelRow } from "../../styles/admin/Forms.styled";
import { FormPanelButton } from "../../styles/FormPanel.Styled";



function CreateRolePanel({ role, permissions,  onSubmit }) {
	const getDefaultSelectedPermissions = () => {
		 const permissionName = permissions.reduce((data, value) => {
			data = {...data, [value['name']]: false}
			return data;
		}, {});
		
		if(role){
			const rolePermissionsName = role.permissions.reduce((data, value) => {
				data = {...data, [value['name']]: true}
				return data;
			}, {});

			return {...permissionName, ...rolePermissionsName};
		}

		return permissionName;
	}
	
	const [selectedValue, setSelectedValue] = React.useState(getDefaultSelectedPermissions());
	const handleChange = (event) => {
        setSelectedValue({...selectedValue, [event.target.name]: event.target.checked});
    };

	const {
		handleSubmit,
		control,
		formState: { errors },
	} = useForm({
		defaultValues: {
			role_name: role?role['name']:"",
		},
	});

	const onLocalSubmit = (formData) => {
		const selectedPermissionsId = [];

		for (const property  in selectedValue) {
			if(selectedValue[property]){
				selectedPermissionsId.push(permissions.find(e => e.name === property)['id']);
			}
		}
		
		let data = {...formData, permissions: selectedPermissionsId};

		if(role){
			data = {"role": role.id, ...data};
		}

		onSubmit(data);
	}


	return (
        <>
		<DetailsPanelStyled as="form" onSubmit={handleSubmit(onLocalSubmit)}>
			<DetailsHeader>
				<DetailsHeaderDesc>
					<h3>{role?"Update Role":"Create Role"}</h3>
				</DetailsHeaderDesc>
			</DetailsHeader>
			<DetailsBody padding="2rem">
				<PanelForm>
					<PanelRow>
						<FormInputField
							name="role_name"
							label="Role Name"
							placeholder="eg. Marketing Manager"
							control={control}
							errors={errors}
						/>
					</PanelRow>
					
					<PanelRow>
						<h2 style={{marginBottom: '20px'}}>Permissions</h2>
							{
							permissions.map((permission) => 
							<div key={permission['id']}>
								<CheckBox
									checked={selectedValue[permission['name']]}
									onChange={handleChange}
									label= {permission['name']}
									name={permission['name']}
									inputProps={{ 'aria-label': 'Select '+permission['name'] }}
								/>
							</div>
							)
							}
					</PanelRow>
					
				</PanelForm>
			</DetailsBody>
			<DetailsFooter>
				<div></div>
				<div>
					<FormPanelButton type="submit" primary>
						{role?"Update Role":"Create Role"}
					</FormPanelButton>
				</div>
			</DetailsFooter>
		</DetailsPanelStyled>
        </>
	);
}

export default CreateRolePanel;
