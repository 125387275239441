import React, { useEffect } from 'react'
import { Navbar, RequestDemoModal, RequestMeetingModal } from '../../components'
import { Footer } from '../../containers'
import './employers.css'
import '../../containers/howto/howto.css'

import {ReactComponent as CheckCircle} from '../../assets/check_circle.svg'
import BGHeader from '../../assets/bg-header.png'

function Employers() {
  useEffect(() => {
    document.title = "Employers - Greymate Care";
  }, []);

  const [open, setOpen] = React.useState(false);
  const [openMeeting, setOpenMeeting] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const handleMeetingOpen = () => setOpenMeeting(true);
  const handleMeetingClose = () => setOpenMeeting(false);
  
  return (
    <>
    <div className='gmc__employers-background'>
      <Navbar/>
      <div className='section__padding gmc__employers__header'>
          <div className='gmc__employers__header__content'>
              <p className='section__subtitle'>FOR EMPLOYERS</p>
              <h1 className='section__heading'>Give your employees care <br />benefits that they’ll truly value</h1>
              <p className='section__desc'>80% of employees maintained they felt valued and would remain with employers with a home care benefit package <br />85% of employees attract and retain the best talents when the employees felt their work/life balance was paid attention to.</p>
          </div>
          <div>
            <div className='section__quote'>
                <p>Every year, businesses lose billions due to employee care-related obligations. 72% of their workforce, especially females will take time off work to care for an aging parent or child. </p>
            </div>
            <div className='section__quote_desc'>
              <p className='section__desc'>The Greymate Care Enterprise Benefits solution allows employees to get tailored home health plans for themselves, their child(ren), or an aging parent all through an easy-to-access platform.</p>
            </div>
            
          </div>
         
      </div>
    </div>
    <div className='section__padding'>
        <div className='gmc__howto dark' style={{ 
          background: `url(${BGHeader}) no-repeat, linear-gradient(0deg, #2B2D2F, #2B2D2F)` 
        }}>
          <div className='gmc__howto-content'>
                <h2>Benefits you get</h2>
                <p>With the benefits, it’s a two-way street, as not only the employees gain from the packages but the employers get to:</p>
                <button className="button__primary" onClick={handleOpen}>Request a Demo</button>
            </div>
            <div className='gmc__howto-instructions'>
                <div className='gmc__howto-instructions_item'>
                    <div className='gmc__howto-instructions_item-image'>
                        <CheckCircle/>
                    </div>
                    <div className='gmc__howto-instructions_item-content'>
                        <h4>Reduce absenteeism</h4>
                        <p>Absenteeism costs your organization both in revenue and productive man-hours. Take the stress  of caregiving and the consequent absenteeism off your employees so they focus on work. </p>
                    </div>
                </div>

                <div className='gmc__howto-instructions_item'>
                    <div className='gmc__howto-instructions_item-image'>
                        <CheckCircle/>
                    </div>
                    <div className='gmc__howto-instructions_item-content'>
                        <h4>Build a thriving care culture in the workplace</h4>
                    </div>
                </div>

                <div className='gmc__howto-instructions_item'>
                    <div className='gmc__howto-instructions_item-image'>
                        <CheckCircle/>
                    </div>
                    <div className='gmc__howto-instructions_item-content'>
                        <h4>Improve productivity</h4>
                    </div>
                </div>
            </div>
        </div>
        
      </div>
      <div className='section__padding'>
        <div className='gmc__howto dark' style={{ 
          background: `url(${BGHeader}) no-repeat, linear-gradient(0deg, #2B2D2F, #2B2D2F)` 
        }}>
        <div className='gmc__howto-content'>
            <h2>For Employees</h2>
            <p>if your company hasn’t subscribed to Greymate’s benefits yet, we can help you <br />talk to them. Click the button and we’ll set up a meeting with your employer.</p>
        </div>
        <div>
          <a href='#home' className='button__primary' onClick={handleMeetingOpen}>Get Started</a>
        </div>
      </div>
    </div>
      
    <Footer />
    <RequestDemoModal open={open} onClose={handleClose}/>
    <RequestMeetingModal open={openMeeting} onClose={handleMeetingClose}/>
    </>
  )
}

export default Employers