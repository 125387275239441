import styled, { css } from "styled-components";
import { AdminBody } from "./Admin.styled";

export const AdminSidebarStyled = styled.section`
	width: ${({ theme: { sizes } }) => sizes.sidebarWidthFull};
	background-color: ${({ theme: { colors } }) => colors.sidebarBgColor};
	z-index: 1000;
	position: fixed;
	top: 0;
	left: 0;
	bottom: 0;
	border-right: 1px solid #edeff5;
	overflow: hidden;
	transition: width 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
	//box-shadow: 0 0px 10px 0 rgba(0, 0, 0, 0.06);

	@media screen and (max-width: 768px) {
		left: calc(-10px - ${({ theme: { sizes } }) => sizes.sidebarWidthFull});
		width: ${({ theme: { sizes } }) => sizes.sidebarWidthFull};
		transition: 0.3s left ease-in-out;
	}

	${(props) =>
		props.collapse &&
		css`
			width: ${({ theme: { sizes } }) => sizes.sidebarWidthCollapse};

			${AdminSidebarHeader} {
				padding: 0;
				a {
					padding: 1rem;
				}

				a img {
					width: 100%;
				}
				h1 {
					display: none;
				}
			}

			+ ${AdminBody} {
				padding-left: ${({ theme: { sizes } }) => sizes.sidebarWidthCollapse};
			}

            ${MenuListName} {
                display: none;
            }

            ${MenuListLink} {
                padding:1rem 0 ;
                
                justify-content: center;
                align-items: center;
            }

            ${MenuListIcon} {
                display: flex;
                width: 100%;
                padding:0 .8rem;
                justify-content: center;
                align-items: center;
                
            }

            ${MenuGroup} {
               h3 {
                display: none;
               }
            }

            ${DropdownMenuList} {
                ul {
                    display: none;
                }

                i {
                    display: none;
                }
            }

			/* &:hover {
            width: ${({ theme: { sizes } }) => sizes.sidebarWidthFull};
            ${AdminSidebarHeader} {
                padding: 0 2rem;
                a {
                    padding: 0;
                    img {
                        display: block;
                        width: auto;
                    }
                }

                h1 {
                    display: block;
                }

                @media screen and (max-width: 768px) {
                    h1 {
                        display: none;
                    }
                }
            }
            
        } */

			@media screen and (max-width: 768px) {
				left: 0;
				width: ${({ theme: { sizes } }) => sizes.sidebarWidthFull};
				transition: 0.3s left ease-in-out;

                ${MenuListName} {
                    display: block;
                }

                ${MenuListLink} {
                    padding:1rem 0 ;
                    justify-content: center;
                    align-items: center;
                }

                ${MenuListIcon} {
                    display: block;
                    width: auto;
                    padding:0 .8rem;
                    
                }

                ${MenuGroup} {
                h3 {
                    display: block;
                }
                }

                ${DropdownMenuList} {
                    ul {
                        display: block;
                    }

                    i {
                        display: block;
                    }
                }

                + ${AdminBody} {
                    padding-left: 0;
                }
			}
		`};
`;

export const AdminPanelStyled = styled.div`
	width: 100%;
	display: flex;
`;

export const AdminSidebarHeader = styled.div`
	width: 100%;
	height: ${({ theme: { sizes } }) => sizes.sidebarHeaderHeight};
	padding: 0 2rem;
	display: flex;
	justify-content: center;
	align-items: center;
`;

export const AdminSidebarHeaderLogo = styled.div`
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;

	a {
		width: 100%;
		display: flex;
		justify-content: flex-start;
		align-items: center;
		gap: 1rem;
	}

	h1 {
		font-weight: 600;
		font-size: 18px;
		line-height: 22px;
		white-space: nowrap;
	}

	img {
		max-width: 52px;
	}

	@media screen and (max-width: 768px) {
		a {
			width: auto;
			
		}

		h1 {
			display: none;
		}

		img {
		}
	}
`;

export const AdminSidebarHeaderBtn = styled.div`
	width: 40px;
	height: 40px;
	background: #f5f5f7;
	border-radius: 50%;
	position: relative;
	cursor: pointer;
	transition: all 0.4s;
	display: none;
    margin-right: 1rem;

	&:hover {
		background: #e4e4e7;
	}

	svg {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		margin: 0;
		padding: 0;
	}

	@media screen and (max-width: 768px) {
		display: block;
	}
`;

export const AdminSidebarMenu = styled.nav`
	height: calc(100vh - ${({ theme: { sizes } }) => sizes.sidebarHeaderHeight});
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	padding: 0;
    padding-top: 2rem;
    overflow-y: auto;
`;

export const MenuListName = styled.span`
    flex: 1;
    color: #475063;
    font-weight: 400;
    font-size: 14px;
    line-height: 200%;
`;

export const MenuListLink = styled.a`
    display: flex;
    gap: 1rem;
    font-size: 15px;
    padding: 1rem;
    white-space: nowrap;
    
`;

export const MenuListIcon = styled.span`
    display: flex;
    justify-content: center;
    align-items: center;
    color:  rgba(115, 122, 145, 1);
`;


export const MenuGroup = styled.div`
    h3 {
        font-family: "Inter";
        margin-bottom: 2rem;
        font-size: 12px;
        font-weight: 600;
        line-height: 17px;
        color: ${({ theme: { colors } }) => colors.textColorDark};
        text-transform: uppercase;
        letter-spacing: 2px;
        padding: 0 1rem;
    }
`;

export const AdminSidebarMenuList = styled.ul`
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: .5rem;
	list-style: none;

	.active .name {
		font-weight: 700;
	}

	li {
        padding: 0;
        margin: 0;
	}

    li:hover > a {
        background: rgba(70, 175, 222, 0.09);
    }

	li > a.active {
		border-left: 3px solid ${({ theme: { colors } }) => colors.primaryColor};
		background: rgba(70, 175, 222, 0.09);

        ${MenuListName} {
            font-weight: 800;
            color: ${({ theme: { colors } }) => colors.primaryColor} !important;
        }

        ${MenuListIcon} {
            color: ${({ theme: { colors } }) => colors.primaryColor} !important;
        }
	}
`;

export const DropdownMenuList = styled.li`
    align-items: center;
    justify-content: center;
    /* &:hover {
        background: rgba(70, 175, 222, 0.09);
    } */
    & i {
        display: block;
        width: 20px;
        height: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        transition: transform .2s ease-in-out;
    }
   & ul {
        height: 0;
        overflow: hidden;
        list-style: none;
        
        transition: height .4s ease-in-out;
        li > a {
            padding-left: 2rem;
            border-left: 3px solid ${({ theme: { colors } }) => colors.primaryColor};
            background: none !important;
        }

        li:hover > a {
            font-weight: 800;
            background: transparent;
            
        }
    }

    & ul li a.active {
       padding: 1rem;
       padding-left: 2rem;
       
      
      
	}

    & ul li a.active ${MenuListName} {
        font-weight: 800;
        color: ${({ theme: { colors } }) => colors.primaryColor} !important;
    }

    ${(props) =>
		props.collapse &&
		css`
            i {
                transform: rotate(90deg);
            }
            
            ul {
                height: auto;
                
            }
        `};
    
`;

export const AdminSidebarMenuFooter = styled.div`
    padding-bottom: 5rem;
    border-top: 1px solid #EDEFF5;
`;