import {  CircularProgress } from '@mui/material';
import moment from 'moment';
import React from 'react'
import {RiArrowLeftSLine, RiArrowRightSLine } from 'react-icons/ri';
import { useSelector } from 'react-redux';
import useGetResource from '../../core/hooks/useGetResource';
import { getServiceUserProfile, reset } from '../../features/service_user/serviceUserSlice';
import dataParser from '../../misc/data_parser';
import { getAssignCaregiversBadge } from '../../misc/helpers';
import { MenuBtn } from '../../styles/admin/Admin.styled';
import { DetailsBody,  DetailsHeader, DetailsHeaderDesc, DetailsHeaderImage, DetailsLabel, DetailsList, DetailsListWrapper, DetailsPanelStyled, DetailsTab, DetailsTabPanel, DetailsTabs, DetailsTabsHeader, DetailsValue, Flex, ServiceUserDesc } from '../../styles/admin/DetailsPanel';
import Badge from '../badge/Badge';
import { LoadingBar } from '../loading_bar/LoadingBar';
import UserAvatar from '../user_avatar/UserAvatar';

const AssignedContext = React.createContext();

function UserDocumentDetails(props) {
    return (
        <>
             {Object.keys(props.report).map((key) => 
                <DetailsList key={key}>
                    <DetailsLabel>
                        <span>{key}</span>
                    </DetailsLabel>
                    <DetailsValue>
                        <span>{props.report[key]}</span>
                    </DetailsValue>
                </DetailsList>
            )}
        </>
    );
}

function DetailListWrapper(props){
    return (
        <DetailsList>
            <DetailsLabel>
                <span>{props.label}</span>
            </DetailsLabel>
            <DetailsValue>
                <span>{props.value}</span>
            </DetailsValue>
        </DetailsList>
    );
}

function DocumentRow(props){
    return (
        <DetailsList>
            <div>
                <DetailsLabel>
                    <span>{props.label}</span>
                </DetailsLabel>
                <DetailsValue>
                    <span>{props.value}</span>
                </DetailsValue>
            </div>
            <div>
                <MenuBtn onClick={props.onReportClick}><RiArrowRightSLine size={14} /></MenuBtn>
            </div>
        </DetailsList>
    );
}


function UserDetails() {
    const {serviceUser, report} = React.useContext(AssignedContext);
    const stateData = serviceUser;
    const setStateReport = report[1];


    return (
        <DetailsTabs defaultValue={0}>
            <DetailsTabsHeader>
                <DetailsTab first='true'>Assign To</DetailsTab>
                <DetailsTab last="true">Care Reports</DetailsTab>
            </DetailsTabsHeader>

            <DetailsTabPanel value={0}>
                <DetailsListWrapper>
                    <h3>Service user details</h3>
                    <ServiceUserDesc>
                        {stateData.userProfile && Object.keys(stateData?.userProfile).map((key) => 
                            <DetailListWrapper label={key} key={stateData.userProfile[key]} value={stateData.userProfile[key]}/>
                        )}
                    </ServiceUserDesc>
                    <div style={{marginTop: '1rem'}}></div>
                    <h3>Sponsor</h3>
                    <ServiceUserDesc>
                        {stateData.sponsorsProfile && Object.keys(stateData.sponsorsProfile).map((key) => 
                            <DetailListWrapper label={key } key={stateData.sponsorsProfile[key]} value={stateData.sponsorsProfile[key]}/>
                        )}
                    </ServiceUserDesc>
                </DetailsListWrapper>
            </DetailsTabPanel>
            <DetailsTabPanel value={1}>
                <DetailsListWrapper>
                    {stateData.careReports && stateData.careReports.map((doc) => 
                        <DocumentRow label={doc.title} key={doc.title} value={doc.dateSubmitted} onReportClick={()=>setStateReport(doc)}/>
                    )}
                </DetailsListWrapper>
            </DetailsTabPanel>
        </DetailsTabs>
    );
}

function DocumentPanel() {
    const state = React.useContext(AssignedContext);
    const [stateReport, setStateReport] = state.report;

    return (
        <DetailsPanelStyled>
            <DetailsHeader>
                <Flex>
                    <DetailsHeaderImage>
                        <MenuBtn onClick={()=>setStateReport(null)}><RiArrowLeftSLine size={14} /></MenuBtn>
                    </DetailsHeaderImage>
                    <DetailsHeaderDesc>
                        <h3>{stateReport.title}</h3>
                        <p>{stateReport.dateSubmitted}</p>
                    </DetailsHeaderDesc>
                </Flex>
                <div></div>
            </DetailsHeader>
            <DetailsBody padding="1rem 2rem">
                <UserDocumentDetails report={stateReport.report}/>
            </DetailsBody>
        </DetailsPanelStyled>
    );
}

function OptionalPanel({condition, children}) {
    if(condition){
        return <Flex alignItems="center" justifyContent="center"><CircularProgress /></Flex>;
    }else {
        return children; 
    }
}

function InfoPanel() {
    const {caregiver, isLoading} = React.useContext(AssignedContext);
    const badge = getAssignCaregiversBadge(caregiver.status);

    return (
        <DetailsPanelStyled>
            <DetailsHeader>
                <Flex>
                    <DetailsHeaderImage>
                       <UserAvatar src={caregiver.avatar} alt={caregiver.name}/>
                    </DetailsHeaderImage>
                    <DetailsHeaderDesc>
                        <h3>{caregiver.name}</h3>
                        <p>{caregiver.email}</p>
                    </DetailsHeaderDesc>
                </Flex>
                <div>
                    <Badge {...badge.badgeType}>{badge.badgeText}</Badge>
                </div>
            </DetailsHeader>
            <OptionalPanel condition={isLoading}>
                <DetailsBody>
                    <UserDetails/>
                </DetailsBody>
                
            </OptionalPanel>
            
        </DetailsPanelStyled>
    );
}


function AssignedDetailsPanel({data}) {
    const [report, setReport] = React.useState(null);

    const parseResponse = (response) => {
		
		const tempData = response.data;
		const sponsorData = tempData?.sponsor;
        const reports = tempData?.reports;
        const reportData = reports ? reports.map(e => {return{
            
            title: "CARE REPORT",
            dateSubmitted: "Submitted on " + moment.unix(e.created_at).format('LLL'),
            report: {
                "Mood": dataParser.parseEmoticons(e.mood),
                "Pain level": e.pain_level,
                "Heart Rate": e.heart_rate,
                "Blood pressure": e.blood_pressure,
                "Sugar level": e.sugar_level,
                "Medication details": e.medication_details,
                "Appetite": dataParser.parseEmoticons(e.appetite),
                "Bowel movement": dataParser.parseBool(e.bowel_movement),
                "Exercise": dataParser.parseBool(e.excercise),
                "Personal care": e.personal_care_details,
                "Any other event?": e.note,
            }
            
            
        }}):[];
        
        var item = {
            'userProfile': {
                "Name": tempData.last_name + " " + tempData.first_name,
                "Special Conditions": tempData?.profile?.special_conditions.join(", "),
                "Location": tempData?.address,
            },

            'sponsorsProfile': {
                "Name": sponsorData?.last_name + " " + sponsorData?.first_name,
                "Email address": sponsorData?.email,
                "Plan": sponsorData?.subscription?.subscription_type_name,
            },
            'careReports': reportData,
        };
		return item;
	};

    const {
		responseData,
		isLoading,
        ref,
	} = useGetResource({
        id: data.serviceUserId,
		selector: useSelector((state) => state.serviceUser),
		getReducer: getServiceUserProfile,
		resetReducer: reset,
		parseData: parseResponse,
        
	});
    
 

    return(
        <>
        <LoadingBar ref={ref}/>
        <AssignedContext.Provider value={{caregiver: data, serviceUser: responseData, isLoading: isLoading, report: [report, setReport]}}>
            {report != null ? 
                <DocumentPanel />
                :
                <InfoPanel/>
            };
        </AssignedContext.Provider >
        </>
    );
    
}

export default AssignedDetailsPanel