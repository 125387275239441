import React, { Component } from 'react'
import { Navbar } from '../../components'
import { Footer } from '../../containers'
import './about.css'

import { ReactComponent as Icon1 } from '../../assets/ficon1.svg'
import { ReactComponent as Icon2 } from '../../assets/ficon2.svg'
import { ReactComponent as Icon3 } from '../../assets/ficon3.svg'
import { ReactComponent as Icon4 } from '../../assets/ficon4.svg'


export default class About extends Component {
    componentDidMount(){
        document.title = "About Us - Greymate Care";
    }
    render() {
        return (
            <>
                <div className='gmc__about__gradient'>
                    <Navbar />
                    <div className='section__padding gmc__about__header'>
                        <div className='gmc__about__header__content'>
                            <p className='section__subtitle'>Reclaim all the time spent caring for your loved one</p>
                            <h1 className='section__heading'>We are Africa’s ace tech-enabled home health platform and we connect you/your loved ones to a vetted caregiver for professional attention right in the comfort of your home.</h1>
                        </div>
                        <div className='section__quote'>
                            <p>Our digital platform provides a seamless interface to find a caregiver to provide home care services such as companionship, personal care, and medication reminders to name a few.</p>
                        </div>
                    </div>
                </div>
                <div className='section__padding gmc__about__features'>
                    <div className='gmc__about__feature gmc__about__features__header'>
                        <p className='section__subtitle'>Why use greymate</p>
                        <h3 className='section__heading'>With Greymate <br/>Care, you are <br/>assured of</h3>
                    </div>
                    <div className='gmc__about__feature'>
                        <Icon1/>
                        <h4>Visibility & Transparency</h4>
                        <p>You’ll never be in the dark about care delivery. Through our robust real-time reporting technology, you can follow the care adherence from anywhere in the world. </p>
                    </div>

                    <div className='gmc__about__feature'>
                        <Icon2/>
                        <h4>Safety and Security</h4>
                        <p>All our caregivers have been health screened. Through our partnerships with the Nigerian Police, we ensure all caregivers have been vetted and background checked. Your most valued items are safe and insured up to the tune of N5m in the rare event of a loss or breakage.</p>
                    </div>

                    <div className='gmc__about__feature'>
                        <Icon3/>
                        <h4>Training</h4>
                        <p>Our acceptance rate is less than 18%, all our caregivers have undergone a stringent hiring and training process and only the best make it to the final stage in the hiring process. All caregivers have a First Aid/CPR certification covered by our North American and European partners. </p>
                    </div>

                    <div className='gmc__about__feature'>
                        <Icon4/>
                        <h4>Escrow</h4>
                        <p>We stand in as escrow between you and the caregivers so their salaries are only remitted upon completion and review of services provided</p>
                    </div>
                </div>
                <Footer/>
            </>
        )
    }
}
