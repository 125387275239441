import styled from "styled-components";

export const StatsBoxWrapper = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
    gap: 2rem;
    margin-top: 3rem;
`;

export const StatBoxStyled = styled.div`
    width: 350px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 2rem;
    background: #fff;
    padding: 1rem 2rem;
    border-radius: 8px;
    //box-shadow: 0 0 40px rgba(0,0,0, .04);
    border: 1px solid #edeff5;

    @media screen and (max-width: 1050px) {
      width: 100%;
    }
`;

export const StatBoxTitle = styled.span`
  display: inline-block;
  font-size: 15px;
  line-height: 22px;
  font-weight: 500;
  color: var(--color-text-secondary);
  margin-bottom: 1rem;
`;

export const StatBoxDesc = styled.p`
font-weight: 800;
font-size: 18px;
color: var(--color-text-primary); 
`;