import React from "react";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import TextField from "@mui/material/TextField";
import Radio from "@mui/material/Radio";
import Checkbox from "@mui/material/Checkbox";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { styled } from "@mui/system";
import FormControlLabel from "@mui/material/FormControlLabel";
import RadioGroup from "@mui/material/RadioGroup";
import { Controller } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import { FormChip, FormError } from "../../styles/FormPanel.Styled";
import Autocomplete from "@mui/material/Autocomplete";
import Chip from '@mui/material/Chip';
import { Flex } from "../../styles/admin/DetailsPanel";
import GoogleAutoComplete from "../google_auto_complete/GoogleAutoComplete";
import { TimePicker } from '@mui/x-date-pickers/TimePicker';

const StyledSelect = styled(Select, {})`
	font-family: var(--font-family);
	width: 100%;
	border: 1px solid #edf2f7;
	border-radius: 4px;
	background: #f7f9fc;
	margin: 0;
	font-size: 14px;
	line-height: 20px;
	color: rgba(60, 83, 102, 1);

	& .MuiOutlinedInput-notchedOutline  {
		border: none;
	}

	.MuiInputLabel-root {
		display: block;
		font-size: 16px;
		line-height: 20px;
		color: #3c5366;
		margin-bottom: 0.7rem;
		outline: none;
	}

	
	& svg {
		right: 1rem;
	}

	&::before,
	&:hover::before {
		border: none;
	}
`;

// const StyledDefaultTextField = styled(TextField, {})`
//     font-family: var(--font-family);
//     width: 100%;
//     background: #F7F9FC;
//     border: 1px solid #EDF2F7;
//     border-radius: 4px;
//     margin: 0;
//     font-size: 14px;
//     line-height: 20px;
//     color: rgba(60, 83, 102, 1);
//     outline: none;
//     padding: 1rem;

//     & input {
//       padding: 0 !important;
//       margin: 0;
//       border: none !important;
//     }

//     & svg {
//         right: 1rem;
//         width: 18px;
//         color: #3c5366;
//     }

//     &::before, &:hover::before {
//         border: none;
//     }
// `;

const StyledTextField = styled(TextField, {})`
	font-family: var(--font-family);
	width: 100%;
	margin: 0;
	font-size: 14px;
	line-height: 20px;
	outline: none;

	& .MuiOutlinedInput-notchedOutline  {
		border: none;
	}

	& input,
	& .MuiSelect-select {
		display: block;
		border: 1px solid #edf2f7;
		border-radius: 4px;
		color: rgba(60, 83, 102, 1);
		background: #f7f9fc;
		padding: 1rem;
		margin-top: 0.3rem;
	}

	& label {
		font-family: var(--font-family);
		display: block;
		font-size: 16px;
		line-height: 20px;
		color: #3c5366;
		margin-bottom: 0.7rem;
	}

	& .MuiInputLabel-root {
		font-family: var(--font-family);
	}

	& svg {
		right: 1rem;
		width: 18px;
		color: #3c5366;
	}

	&::before,
	&:hover::before {
		border: none;
	}
`;

const StyledAutoComplete = styled(Autocomplete, {})`
	font-family: var(--font-family);
	width: 100%;
	margin: 0;
	font-size: 14px;
	line-height: 20px;
	outline: none;

	& .MuiOutlinedInput-notchedOutline  {
		border: none;
	}

	& input,
	& .MuiSelect-select {
		display: block;
		border: 1px solid #edf2f7;
		border-radius: 4px;
		color: rgba(60, 83, 102, 1);
		background: #f7f9fc;
		padding: 1rem;
		margin-top: 0.3rem;
	}

	& label {
		display: block;
		font-size: 16px;
		line-height: 20px;
		color: #3c5366;
		margin-bottom: 0.7rem;
	}

	&::before,
	&:hover::before {
		border: none;
	}
`;

const StyledRadioButton = styled(Radio, {})`
	& svg {
		width: 16px;
		height: 16px;
		background-color: transparent;
		border: 1px solid #d9d9d9;
		border-radius: 16px;
	}

	& svg path {
		display: none;
	}

	&.Mui-checked svg {
		background-color: #1890ff;
		border-color: #1890ff;
	}

	&.Mui-checked .MuiIconButton-label:after {
		position: absolute;
		display: table;
		border: 2px solid #fff;
		border-top: 0;
		border-left: 0;
		transform: rotate(45deg) translate(-50%, -50%);
		opacity: 1;
		transition: all 0.2s cubic-bezier(0.12, 0.4, 0.29, 1.46) 0.1s;
		content: "";
		top: 50%;
		left: 39%;
		width: 5.71428571px;
		height: 9.14285714px;
	}
	&.MuiCheckbox-indeterminate .MuiIconButton-label:after {
		width: 8px;
		height: 8px;
		background-color: #1890ff;
		transform: none;
		top: 39%;
		border: 0;
	}
`;

const StyledCheckbox = styled(Checkbox, {})`
	& svg {
		width: 16px;
		height: 16px;
		background-color: transparent;
		border: 1px solid #d9d9d9;
	
	}

	& svg path {
		display: none;
	}

	&.Mui-checked svg {
		background-color: #1890ff;
		border-color: #1890ff;
	}

	&.Mui-checked .MuiIconButton-label:after {
		position: absolute;
		display: table;
		border: 2px solid #fff;
		border-top: 0;
		border-left: 0;
		transform: rotate(45deg) translate(-50%, -50%);
		opacity: 1;
		transition: all 0.2s cubic-bezier(0.12, 0.4, 0.29, 1.46) 0.1s;
		content: "";
		top: 50%;
		left: 39%;
		width: 5.71428571px;
		height: 9.14285714px;
	}
	&.MuiCheckbox-indeterminate .MuiIconButton-label:after {
		width: 8px;
		height: 8px;
		background-color: #1890ff;
		transform: none;
		top: 39%;
		border: 0;
	}
`;

const StyledRadioLabel = styled(FormControlLabel, {})`
	& .MuiFormControlLabel-label {
		font-weight: 500;
		font-size: 14px;
		line-height: 18px;
		color: #3c5366;
	}
`;

export function SelectMenu(props) {
	return (
		<StyledTextField
			variant="standard"
			value={props.value}
			onChange={(e) => props.onChange(e.target.value)}
			fullWidth={props.fullWidth}
			label={props.label}
			placeholder={props.placeholder}
			InputLabelProps={{
				shrink: true,
			}}
			InputProps={{
				disableUnderline: true,
				endAdornment: props.InputProps?.endAdornment,
			}}
			select
		>
			<MenuItem value="none" disabled>
				{props.placeholder}
			</MenuItem>
			{props.menuList.map((item) => (
				<MenuItem key={item.value} value={item.value}>
					{item.label}
				</MenuItem>
			))}
		</StyledTextField>
	);
}

export function DatePicker(props) {
	return (
		<LocalizationProvider dateAdapter={AdapterMoment}>
			<DesktopDatePicker
				{...props}
				variant="standard"
				label={props.label}
				inputFormat={props.inputFormat ?? "DD/MM/YYYY"}
				value={props.value}
				onChange={props.handleChange}
				renderInput={(params) => <DefaultTextField {...params} value={props.value} type="date" />}
			/>
		</LocalizationProvider>
	);
}

export function RadioButton(props) {
	return (
		<StyledRadioButton
			checked={props.checked}
			onChange={props.handleChange}
			value={props.value}
			name={props.name}
			inputProps={{ "aria-label": "Select " + props.name }}
		/>
	);
}

export function CheckBox(props) {
	return (
		<StyledRadioLabel
			control={
				<StyledCheckbox
					checked={props.checked}
					onChange={props.onChange}
					value={props.value}
					name={props.name}
					inputProps={{ "aria-label": "Check " + props.name }}
				/>
			}
			label={props.label}
		/>
	);
}
export function CustomTimePicker(props) {
	return (
		<LocalizationProvider dateAdapter={AdapterMoment}>
			<TimePicker
				{...props}
				inputFormat={props.inputFormat ?? "MM/DD/YYYY"}
				value={props.value}
				onChange={props.handleChange}
				renderInput={(params) => <DefaultTextField {...params} />}
			/>
		</LocalizationProvider>
	);
}

export function DefaultTextField(props) {
	return (
		<StyledTextField
			{...props}
			variant="standard"
			value={props.value}
			onChange={props.onChange}
			fullWidth={props.fullWidth}
			placeholder={props.placeholder}
			label={props.label}
			InputLabelProps={{
				shrink: true,
			}}
			InputProps={{
				disableUnderline: true,
				endAdornment: props.InputProps?.endAdornment,
			}}
		/>
	);
}

export function DefaultAutoCompleteTextField(props) {
	return (
		<StyledTextField
			{...props}
			variant="standard"
			value={props.value}
			onChange={props.onChange}
			fullWidth={props.fullWidth}
			placeholder={props.placeholder}
			label={props.label}
			InputLabelProps={{
				shrink: true,
			}}
			
			InputProps={{
				disableUnderline: true,
				...props.InputProps
			}}
		/>
	);
}

export function RadioList(props) {
	return (
		<>
		<label htmlFor={props.name}>{props.label}</label>
		<RadioGroup id={props.name} name={props.name} value={props.value} onChange={props.onChange}>
			{props.list.map((item) => (
				<StyledRadioLabel
					key={item.value}
					value={item.value}
					label={item.label}
					control={<RadioButton />}
				></StyledRadioLabel>
			))}
		</RadioGroup>
		</>
	);
}

export const FormInputField = ({
	name,
	label,
	placeholder,
	type,
	rules,
	multiline,
	maxRows,
	control,
	errors,
}) => {
	return (
		<>
			<Controller
				name={name}
				control={control}
				rules={
					rules ?? {
						required: {
							value: "required",
							message: "Please enter your " + label.toLowerCase(),
						},
					}
				}
				render={({ field: { onChange, value } }) => (
					<DefaultTextField
						label={label}
						type={type ?? "text"}
						name={name}
						multiline={multiline ?? false}
          				//maxRows={maxRows ?? null}
						rows= {maxRows ?? null}
						placeholder={placeholder}
						onChange={onChange}
						value={value}
					/>
				)}
			/>
			<ErrorMessage errors={errors} name={name} as={<FormError />} />
		</>
	);
};

export const FormCheckBoxField = ({ name, label, rules, control, errors }) => {
	return (
		<>
			<Controller
				name={name}
				control={control}
				rules={
					rules ?? {
						required: {
							value: "required",
							message: "Please enter your" + label,
						},
					}
				}
				render={({ field: { onChange, value } }) => (
					<CheckBox
						label={label}
						name={name}
						onChange={onChange}
						value={value}
					/>
				)}
			/>
			<ErrorMessage errors={errors} name={name} as={<FormError />} />
		</>
	);
};

export const FormDropdownField = ({
	name,
	label,
	placeholder,
	options,
	rules,
	control,
	errors,
}) => {
	return (
		<>
			<Controller
				name={name}
				control={control}
				rules={
					rules ?? {
						required: {
							value: "required",
							message: "Please enter your" + label,
						},
					}
				}
				render={({ field: { onChange, value } }) => (
					<SelectMenu
						menuList={options}
						placeholder={placeholder}
						label={label}
						value={value}
						onChange={onChange}
					></SelectMenu>
				)}
			/>
			<ErrorMessage errors={errors} name={name} as={<FormError />} />
		</>
	);
};

export const FormDateField = ({ name, label, inputFormat, rules, control, errors }) => {
	return (
		<>
			<Controller
				name={name}
				control={control}
				rules={
					rules ?? {
						required: {
							value: "required",
							message: "Please enter your " + label.toLowerCase(),
						},
					}
				}
				render={({ field: { onChange, value } }) => (
					<DatePicker inputFormat={inputFormat} label={label} value={value} handleChange={onChange} />
				)}
			/>
			<ErrorMessage errors={errors} name={name} as={<FormError />} />
		</>
	);
};

export const FormTimeField = ({ name, label, inputFormat, rules, control, errors }) => {
	return (
		<>
			<Controller
				name={name}
				control={control}
				rules={
					rules ?? {
						required: {
							value: "required",
							message: "Please enter your " + label.toLowerCase(),
						},
					}
				}
				render={({ field: { onChange, value } }) => (
					<CustomTimePicker inputFormat={inputFormat} label={label} value={value} handleChange={onChange} />
				)}
			/>
			<ErrorMessage errors={errors} name={name} as={<FormError />} />
		</>
	);
};

export const FormRadioGroupField = ({
	name,
	label,
	options,
	rules,
	control,
	errors,
}) => {
	return (
		<>
			<Controller
				name={name}
				control={control}
				rules={
					rules ?? {
						required: {
							value: "required",
							message: "Please enter your" + label,
						},
					}
				}
				render={({ field: { onChange, value } }) => (
					<RadioList label={label} value={value} list={options} onChange={onChange} />
				)}
			/>
			<ErrorMessage errors={errors} name={name} as={<FormError />} />
		</>
	);
};

export const FormMultiSelectField = ({
	name,
	label,
	options,
	rules,
	placeholder,
	control,
	errors,
}) => {
	return (
		<>
			<Controller
				name={name}
				control={control}
				rules={
					rules ?? {
						required: {
							value: "required",
							message: "Please enter your" + label,
						},
					}
				}
				render={({ field: { onChange, value } }) => (
					<StyledAutoComplete
						variant="standard"
						multiple
						limitTags={10}
						id={name}
						options={options}
						getOptionLabel={(option) => option.label}
						renderInput={(params) => (
							<StyledTextField
								{...params}
								label={label}
								placeholder={placeholder}
							/>
						)}
					/>
				)}
			/>
			<ErrorMessage errors={errors} name={name} as={<FormError />} />
		</>
	);
};
export const FormMultiSelectChipField = ({
	name,
	label,
	options,
	rules,
	placeholder,
	control,
	errors,
}) => {
	return (
		<>
			<label htmlFor={name}>{label}</label>
			<Controller
				name={name}
				control={control}
				rules={
					rules ?? {
						required: {
							value: "required",
							message: "Please enter your" + label,
						},
					}
				}
				render={({ field: { onChange, value } }) => (
						<StyledSelect
							id={name}
							multiple
							value={value}
							onChange={onChange}
							renderValue={(selected) => (
								<Flex gap="1rem" flexWrap="wrap">
									{selected.map((value) => (
										<Chip key={value} label={value}></Chip>
									))}
								</Flex>
							)}
							MenuProps={{
								PaperProps: {
								  style: {
									maxHeight: 48 * 4.5 + 8,
									width: 250,
								  },
								},
							  }}
						>
							<MenuItem value="none" disabled>
								{placeholder}
							</MenuItem>
							{options.map((item) => (
								
							
							
							<MenuItem key={item.value} value={item.value}>
								{item.label}
							</MenuItem>
							
						))}
						</StyledSelect>
					
				)}
			/>
			<ErrorMessage errors={errors} name={name} as={<FormError />} />
		</>
	);
};

export const FormAutoCompleteField = ({name, label, placeholder, options, rules, control, errors }) => {
	const optionsSort = options.map((option) => {
		const firstLetter = option.label[0].toUpperCase();
		return {
		  firstLetter: /[0-9]/.test(firstLetter) ? '0-9' : firstLetter,
		  ...option,
		};
	  });

	return (
		<>
			<label>{label}</label>
			<Controller
				name={name}
				control={control}
				rules={rules}
				render={({ field: { onChange, value } }) => (
					<Autocomplete
						id={label}
						options={optionsSort.sort((a, b) => -b.firstLetter.localeCompare(a.firstLetter))}
						groupBy={(option) => option.firstLetter}
						getOptionLabel={(option) => option.label}
						value={value}
						onChange={onChange}
						renderInput={(params) => <TextField {...params} placeholder={placeholder} label={label} />}
						/>
				)}
			/>
			<ErrorMessage errors={errors} name={name}  as={<FormError />} />
		</>
	);
};

export const FormGoogleAutoCompleteField = ({name, label, placeholder, rules, control, errors }) => {
	return (
		<>
			<label>{label}</label>
			<Controller
				name={name}
				control={control}
				rules={rules}
				render={({ field: { onChange, value } }) => (
					<GoogleAutoComplete name="name" label={label} value={value} placeholder={placeholder} onChange={onChange} />
				)}
			/>
			<ErrorMessage errors={errors} name={name}  as={<FormError />} />
		</>
	);
};

export const FormChipMultiSelect = ({name, options, rules, control, errors,isSmall }) => {
	return (
		<>
			<Controller
				name={name}
				control={control}
				rules={rules}
				render={({ field: { onChange, value } }) => (
					<ChipMultiSelect options={options} value={value} onChange={onChange} isSmall={isSmall} />
				)}
			/>
			<ErrorMessage errors={errors} name={name}  as={<FormError />} />
		</>
	);
};

export const FormChipSelect = ({name, options, rules, control, errors, isSmall }) => {
	return (
		<>
			<Controller
				name={name}
				control={control}
				rules={rules}
				render={({ field: { onChange, value } }) => (
					<ChipSelect options={options} value={value} onChange={onChange} isSmall={isSmall} />
				)}
			/>
			<ErrorMessage errors={errors} name={name}  as={<FormError />} />
		</>
	);
};

export const ChipMultiSelect = ({ options, value, onChange, isSmall }) => {
	const isSelected  = (selectedValue) => {
		if(value.length < 1){
			return false;
		}

		var condition = [...value].includes(selectedValue);
		
		return condition;
	}

	const handleChange = (event, selectedValue) => {
		event.preventDefault();
		if(isSelected(selectedValue)){
            const filteredArray = [...value].filter(function(e) { return e !== selectedValue })
            onChange([...filteredArray]);
			
        }else {
            onChange([...value, selectedValue]);
        }
	}
	return (
		<>
		<Flex gap="1rem" flexWrap="wrap">
		{options.map((item) => 
			<FormChip tabIndex="0" key={item.value} onClick={(e)=>handleChange(e, item.value)} selected={isSelected(item.value)} small={isSmall??false}>
				{!isSmall && item.icon}
				{item.label}
			</FormChip>
		)}
		</Flex>
		</>
	);
};

export const ChipSelect = ({ options, value, onChange, isSmall }) => {
	const isSelected  = (selectedValue) => {
		return value === selectedValue;
	}

	const handleChange = (event, selectedValue) => {
		event.preventDefault();
		onChange(selectedValue);
	}
	return (
		<>
		<Flex gap="1rem" flexWrap="wrap">
		{options.map((item) =>

			<FormChip tabIndex="0" key={item.value} onClick={(e)=>handleChange(e, item.value)} selected={isSelected(item.value)} small={isSmall}>
				{!isSmall && item.icon}
				{item.label}
			</FormChip>
			
		)}
		</Flex>
		</>
	);
};
