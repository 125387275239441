import React from 'react'
import './pricing_plan.css'

function PricingPlan(props) {
  const currency = props.currency??"NGN";
  const amount = props.rates?(props.plan.amount * props.rates[currency]):props.plan.amount;
  const period = props.plan.period;


  return (
    <>
    <div className={`gmc__pricing__plan ${props.plan.isActive?'active':''}`}>
        <div className='gmc__pricing__plan_cover'>
          <div className='gmc_pricing_plan_wrap'>
            <div className='gmc__pricing__image'>
                <img src={props.plan.icon} alt={props.plan.name}/>
            </div>
            <p className='gmc__pricing__plan__subtitle'>{props.plan.name}</p>
            <h4 className='gmc__pricing__plan__title'>{Intl.NumberFormat('en-US', { style: 'currency', currency: currency, currencyDisplay: 'narrowSymbol', minimumFractionDigits: 0, maximumFractionDigits: 0 }).format(amount)}<span> /{period}</span></h4>
            <p className='gmc__pricing__plan__desc'>{props.plan.description}</p>
            </div>

            <div>
            <a href={props.plan.link}>Choose {props.plan.name}</a>
            </div>
           
        </div>
    </div>
    </>
  )
}

export default PricingPlan