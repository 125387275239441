import React, {useEffect} from 'react'
import {Header, Features, WhatsNew, Footer, Partners, PricingSlider, Testimonial} from '../../containers'
import { Navbar } from '../../components';
import './home.css'
import useIpDetails from '../../core/hooks/useIpDetails';

function Home() {
    useEffect(()=> {
        document.title = "Greymate Care - Caregiving Services in Nigeria";
    }, []);
    const {
		currency,
		exchangeRateResponse,
		} = useIpDetails({
		defaultCurrency: "NGN"
		});


        return (
            <div className="App">
                <Navbar/>
                <Header currency={currency} exchangeRateResponse={exchangeRateResponse} />
                <Partners />
                <PricingSlider currency={currency} exchangeRateResponse={exchangeRateResponse}/>
                <Features />
                <Testimonial/>
                <WhatsNew/>
                <Footer />
            </div>
        );
}

export default Home