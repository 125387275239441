import React from "react";
import { ThemeProvider } from "styled-components";

const theme = {
    colors: {
        primaryColor: "#46AFDE",
        bodyBgColor: "#FAFBFF",
        sidebarBgColor: "#fff",
        textColorDark: "#737A91",
        textHeaderColor: "#170F49",
    },

    sizes: {
        sidebarWidthFull: "280px",
        sidebarWidthCollapse: "70px",
        sidebarHeaderHeight: "120px",
    }
};

const AppTheme = ({ children }) => (
  <ThemeProvider theme={theme}>{children}</ThemeProvider>
);

export default AppTheme