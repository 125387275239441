import React, { useEffect } from 'react'
import { Navbar } from '../../components'
import { Footer } from '../../containers'
import './contact_us.css'

import BGHeader from '../../assets/bg-header.png';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { LoadingBar } from '../../components/loading_bar/LoadingBar';
import { FormInputField } from '../../components/custom_form_component/CustomFormComponent';
import formValidator from '../../misc/validator';
import {
	createContactRequest,
	reset,
} from "../../features/contact/contactSlice";

const formDefaults = {
  name: "",
  email: "",
  phone: "",
  subject: "",
  message: "",
};

function ContactUs() {

 

  const {
		handleSubmit,
		control,
		reset: resetForm,
		formState: { errors },
	} = useForm({
		defaultValues: formDefaults,
	});

	const dispatch = useDispatch();
	const ref = React.useRef(null);

	const { data, isError, isSuccess, message } = useSelector(
		(state) => state.contactRequest
	);
	useEffect(() => {
		if (isError) {
			toast.error(message);
			ref.current.complete();
		}

		if (isSuccess) {
			ref.current.complete();
			toast.success("Message Sent Successfully!");
			resetForm(formDefaults);
		}

		dispatch(reset());
	}, [data, isError, isSuccess, message, resetForm, dispatch]);
  
	const onSubmit = (data) => {
		ref.current.continuousStart();
		dispatch(createContactRequest(data));
	};

	useEffect(() => {
		document.title = "Contact Us - Greymate Care";
	}, []);


  return (
    <>
    <LoadingBar ref={ref} shadow={true} />
    <div className='gmc__contact-us-background'>
      <Navbar/>
      <div className='gmc__contact-us__header section__padding section__bg__lines'  style={{ 
      backgroundImage: `url(${BGHeader})` 
    }}>
        <h1 className='section__heading'>Need to reach us?</h1>
        <p className='section__desc'>
Please send us a direct message using the form below. <br/> You can also call us at <a href="tel:+2349090904226">09090904226</a> or e-mail us at <a href="mailto:info@greymatecare.com">info@greymatecare.com</a>.</p>
      </div>
      <div className='section__padding gmc__contact__form'>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className='form__row'>
              <FormInputField
								name="name"
								label="Full name"
								placeholder="Your full name"
								control={control}
								errors={errors}
							/>
          </div>

          <div className='form__row'>
            <FormInputField
								name="email"
								label="Email"
								placeholder="Your email address"
								type="email"
								rules={{
									required: {
										value: "required",
										message: "Please enter your email",
									},
									validate: formValidator.emailValidator,
								}}
								control={control}
								errors={errors}
							/>
          </div>
		  <div className='form__row'>
            <FormInputField
								name="phone"
								label="Phone Number"
								placeholder="Your phone number"
								type="tel"
								rules={{
									required: {
										value: "required",
										message: "Please enter your phone number",
									},
									validate: formValidator.phoneValidator,
								}}
								control={control}
								errors={errors}
							/>
          </div>

          <div className='form__row'>
            <FormInputField
								name="subject"
								label="Subject"
								placeholder="eg. can i buy you coffee"
								control={control}
								errors={errors}
							/>
          </div>

          <div className='form__row'>
            <FormInputField
								name="message"
								label="Message"
								placeholder="Type your question or feedback here"
                multiline={true}
                maxRows={8}
								control={control}
								errors={errors}
							/>
          </div>


          <div className='form__buttons'>
            <button className='button__primary' type='submit'>Send Message</button>
          </div>
        </form>
      </div>
    </div>
    <Footer/>
    </>
  )
}

export default ContactUs