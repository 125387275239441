import { TabUnstyled } from "@mui/base";
import TabsUnstyled from '@mui/base/TabsUnstyled';
import TabsListUnstyled from '@mui/base/TabsListUnstyled';
import TabPanelUnstyled from '@mui/base/TabPanelUnstyled';
import styled, { css } from "styled-components";

export const DetailsPanelStyled = styled.div`
    display: flex;
    flex-direction: column;
    height: 100vh;
`;

export const DetailsHeader = styled.div`
    display: flex;
    align-items: center;
    gap: 2rem;
    border-bottom: 1px solid #EDEFF5;
    padding: 2rem;
`;
export const DetailsHeaderImage = styled.div`
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    border-radius: 50%;

    img {
        width: 100%;
    }
`;
export const DetailsHeaderDesc = styled.div`
    h3 {
        font-size: 18px;
        line-height: 24px;
        margin-bottom: .2rem;
    }

    p {
        font-weight: 500;
        font-size: 14px;
        line-height: 16px;
        color: #737A91;
    }
`;

export const DetailsBody = styled.div`
    flex: 1;
    padding: ${(props) => props.padding || 0};
`;

export const DetailsTab = styled(TabUnstyled)`
    font-family: var(--font-family);
    border: 1px solid #D9D9D9;
    background: #fff;
    outline: none;
    box-shadow: none;
    padding: 1rem;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    color: #737A91;
    cursor: pointer;

    & + & {
        border-left: 0;
    }

    &.Mui-selected {
        border-color: #3885B5;
        font-weight: 500;
        color: #3885B5;
    }

    & +  &.Mui-selected {
        border-left: 1px solid #3885B5;
    }

    &:hover {
        color: #3885B5;
    }

    ${(props) => props.first && css`
        border-radius: 8px 0px 0px 8px;
    `}

    ${(props) => props.last && css`
        border-radius: 0px 8px 8px 0px;
    `}
   
    
    
`;

export const DetailsTabs = styled(TabsUnstyled) `
    margin-top: 2rem;
    
`;

export const DetailsTabsHeader = styled(TabsListUnstyled) `
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 1px;
    margin-top: 1px;
   
    
`;

export const DetailsTabPanel = styled(TabPanelUnstyled)`
    padding: 0 2rem;
    margin-top: 2rem;
`;

export const DetailsList = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    padding: 1rem;
    border-bottom: 1px solid #EDEFF5;
    margin-bottom: 1rem;
`;
export const DetailsLabel = styled.div`
    flex: 1;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    /* identical to box height, or 157% */


    color: #737A91;
`;

export const DetailsValue = styled.div`
    flex: 2;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    text-align: right;
    color: #170F49;
`;

export const DetailsDoc = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    padding: 1rem;
    background: rgba(56, 133, 181, 0.04);
    border-radius: 4px;
    margin-bottom: 1rem;

    a {
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        color: #3885B5;
    }
`;

export const DetailsThumbnail = styled.div`
    display: flex;
    align-items: center;
    gap: 1rem;

    img {
        width: 30px;
        height: 30px;
    }

    span {
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        color: #170F49;
    }
`

export const DetailsListWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 1rem;
`;

export const DetailsFooter = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 1rem;
    background: #FFFFFF;
    box-shadow: 0px 4px 18px rgba(0, 0, 0, 0.1);
    padding: 2rem;

    
    
`;

export const DetailsButton = styled.a`
    padding: 1rem 3rem;
    box-shadow: 0px 1px 1px rgba(22, 29, 37, 0.1), inset 0px 2px 0px rgba(255, 255, 255, 0.06);
    border-radius: 10px;
    font-weight: 500;
    font-size: 12px;
    line-height: 20px;
    color: #3885B5;
    background: linear-gradient(0deg, #F6F7F9 0%, #FFFFFF 100%);
    border: 1px solid #EDF2F7;
    margin-right: ${(props)=> props.marginRight || 0};
    cursor: pointer;

    svg {
        margin-left: .2rem;
    }

    ${(props) => props.$primary && css`
        background: #46AFDE;
        color: #fff;
        font-weight: 600;
        border: none;
    `}

    ${(props) => props.$danger && css`
        background: #DE0303;
        color: #fff;
        font-weight: 600;
        //box-shadow: none;
        border: none;
        
    `}
`;

export const Flex = styled.div`
    display: flex;
    flex-direction: ${(props) => props.direction || 'row'};
    justify-content: ${(props) => props.justifyContent || 'flex-start'};
    align-items: ${(props) => props.alignItems || 'center'};
    flex-wrap: ${(props) => props.flexWrap || 'nowrap'};
    gap: ${(props) => props.gap || '1rem'};
    margin: ${(props) => props.margin || "0px"};
`;
export const Box = styled.div`
   background-color: ${(props) => props.backgroundColor || 'none'};
   background: #FFFFFF;
   border: 1px solid #EDECF0;
   border-radius: 8px;
   margin: 1rem 0;
`;

export const UserCheckList = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    border-bottom: 1px solid #EDEFF5;
    padding: 1rem;

    img {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        overflow: hidden;
    }

    span {
        font-size: 14px;
        line-height: 24px;
    }
`;

export const ServiceUserDesc = styled.div`
    padding: 1rem;
    background: #F7F9FC;
    border: 1px solid #EDF2F7;
    border-radius: 16px;
`;

export const CheckboxStyle = {
    '& MuiButtonBase-root MuiCheckbox-root svg': {
        borderRadius: '0px  !important',
    },

    '& svg': {
        width: 16,
        height: 16,
        backgroundColor: 'transparent',
        border: '1px solid #d9d9d9',
        borderRadius: '0px',
    },

    '& svg path': {
        display: 'none',
      },

      '&.Mui-checked svg': {
        backgroundColor: '#1890ff',
        borderColor: '#1890ff',
      },

      '&.Mui-checked .MuiIconButton-label:after': {
        position: 'absolute',
        display: 'table',
        border: '2px solid #fff',
        borderTop: 0,
        borderLeft: 0,
        transform: 'rotate(45deg) translate(-50%,-50%)',
        opacity: 1,
        transition: 'all .2s cubic-bezier(.12,.4,.29,1.46) .1s',
        content: '""',
        top: '50%',
        left: '39%',
        width: 5.71428571,
        height: 9.14285714,
      },
      '&.MuiCheckbox-indeterminate .MuiIconButton-label:after': {
        width: 8,
        height: 8,
        backgroundColor: '#1890ff',
        transform: 'none',
        top: '39%',
        border: 0,
      },
};

export const RadioStyle = {
    '& svg': {
        width: 16,
        height: 16,
        backgroundColor: 'transparent',
        border: '1px solid #d9d9d9',
        
    },

    '& svg path': {
        display: 'none',
      },

      '&.Mui-checked svg': {
        backgroundColor: '#1890ff',
        borderColor: '#1890ff',
      },

      '&.Mui-checked .MuiIconButton-label:after': {
        position: 'absolute',
        display: 'table',
        border: '2px solid #fff',
        borderTop: 0,
        borderLeft: 0,
        transform: 'rotate(45deg) translate(-50%,-50%)',
        opacity: 1,
        transition: 'all .2s cubic-bezier(.12,.4,.29,1.46) .1s',
        content: '""',
        top: '50%',
        left: '39%',
        width: 5.71428571,
        height: 9.14285714,
      },
      '&.MuiCheckbox-indeterminate .MuiIconButton-label:after': {
        width: 8,
        height: 8,
        backgroundColor: '#1890ff',
        transform: 'none',
        top: '39%',
        border: 0,
      },
};
