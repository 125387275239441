import { faker } from '@faker-js/faker';
import moment from 'moment';
import { RiLoginBoxFill, RiUserAddFill } from 'react-icons/ri';

export function escapeRegExp(value) {
    return value.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');
  }

export function generateCaregiversApplications() {
    var rows = [];

    for (let i = 1; i < 100; i++) {
        var caregiver = {
            id: i,
            sn: i,
            name: faker.name.findName(),
            firstName: faker.name.firstName(),
            lastName: faker.name.lastName(),
            email: faker.internet.email(),
            phone: faker.phone.number('+234 ## ### ## ##'),
            dateSubmitted: moment(faker.date.recent()).format('l'),
            status: faker.helpers.arrayElement(['approved', 'rejected', 'pending',]),
        };
        caregiver['profile'] =  generateCaregiverProfile(caregiver);
        rows.push(caregiver);
    }
    return rows;
}

export function generateAssignCaregivers() {
    var rows = [];

    for (let i = 1; i < 100; i++) {
        var caregiver = {
            id: i,
            sn: i,
            name: faker.name.findName(),
            firstName: faker.name.firstName(),
            lastName: faker.name.lastName(),
            email: faker.internet.email(),
            phone: faker.phone.number('+234 ## ### ## ##'),
            dateSubmitted: moment(faker.date.recent()).format('l'),
            dateApproved: moment(faker.date.recent()).format('l'),
            status: faker.helpers.arrayElement(['assigned', 'unassigned',]),
            userProfile: {
                "Name": faker.name.findName(),
                "Special Conditions": "Stroke",
                "Location": "51 Ogudu Road, Ojota Lagos",
            },
            sponsorsProfile: {
                "Name": faker.name.findName(),
                "Email address": "you@example.com",
                "Plan": "Mondays to Sundays",
            },
            careReports:[
                {
                    title: "RP-001",
                    dateSubmitted: "Submitted on March 27, 2022",
                    report: {
                        "Mood": "☹",
                        "Alertness": "Alert️",
                        "Pain level": "Guaranty Trust Bank",
                        "Heart Rate": "80bpm️",
                        "Blood pressure": "120/80mmHg",
                        "Sugar level": "7mmol/L",
                        "Medication details": "Took according to content of doesette",
                        "Appetite": "☹️",
                        "Bowel movement": "Yes",
                        "Exercise": "Yes",
                        "Personal care": "Helped to take shower. Change into fresh clothes",
                        "Any other event?": "None",
                    }
                    
                },
                {
                    title: "RP-002",
                    dateSubmitted: "Submitted on March 30, 2022",
                    report: {
                        "Mood": "☹",
                        "Alertness": "Alert️",
                        "Pain level": "Guaranty Trust Bank",
                        "Heart Rate": "80bpm️",
                        "Blood pressure": "120/80mmHg",
                        "Sugar level": "7mmol/L",
                        "Medication details": "Took according to content of doesette",
                        "Appetite": "☹️",
                        "Bowel movement": "Yes",
                        "Exercise": "Yes",
                        "Personal care": "Helped to take shower. Change into fresh clothes",
                        "Any other event?": "None",
                    }
                    
                },
            ],
        };
        caregiver['profile'] =  generateCaregiverProfile(caregiver);
        rows.push(caregiver);
    }
    return rows;
}

export function generateServiceUsers() {
    var rows = [];

    for (let i = 1; i < 100; i++) {
        var user = {
            id: i,
            sn: i,
            name: faker.name.findName(),
            firstName: faker.name.firstName(),
            lastName: faker.name.lastName(),
            email: faker.internet.email(),
            phone: faker.phone.number('+234 ## ### ## ##'),
            profilePicture: `https://i.pravatar.cc/150?u=${faker.internet.email()}`,
            sponsor: faker.name.findName(),
            plan: faker.helpers.arrayElement(['Always 24/7', 'Everyday', 'Weekdays + Sat', '3 Days a Week']),
            createdAt: moment(faker.date.recent()).format('l'),
            status: faker.helpers.arrayElement(['assigned', 'unassigned',]),
            
        };

        user['profile'] =  {
            "Full Name": user.name,
            "Email": user.email,
            "Sponsor": user.sponsor,
            'Plan': user.plan,
            'Bio': faker.lorem.paragraph(3),
        };

        user['caregiver'] =  {
            "Caregiver's Name": faker.name.findName(),
            'Gender': faker.name.gender(true),
            'Address': faker.address.streetAddress(true),
            'Certifications': faker.name.jobTitle(),
            'Years of Experience': faker.helpers.arrayElement(['Less than a year', '1-3 Years', '3-5 Years', '5+ Years of Experience']),
            'Skills': faker.company.catchPhraseNoun(),
            'Health conditions': 'None',
            'Work Schedule': "Mon-Friday (8am - 9pm)",
            'Living Arrangements': faker.helpers.arrayElement(['Living with care recipent', 'Per Time Live In', 'Personal Living Arrangements']),
            'Contract Type': faker.helpers.arrayElement(['full time', 'per time']),
        };

        user['paymentHistory'] =  [
            {
                "Date": moment(faker.date.recent()).format('l'),
                "Amount": faker.finance.amount(),
                "Status": faker.helpers.arrayElement(['successful', 'failed',]),
                "Description": faker.helpers.arrayElement(['Always 24/7', 'Everyday', 'Weekdays + Sat', '3 Days a Week']),
                "Download": "https://somelink.com/document.pdf",
            },
            {
                "Date": moment(faker.date.recent()).format('l'),
                "Amount": faker.finance.amount(),
                "Status": faker.helpers.arrayElement(['successful', 'failed',]),
                "Description": faker.helpers.arrayElement(['Always 24/7', 'Everyday', 'Weekdays + Sat', '3 Days a Week']),
                "Download": "https://somelink.com/document.pdf",
            },
            {
                "Date": moment(faker.date.recent()).format('l'),
                "Amount": faker.finance.amount(),
                "Status": faker.helpers.arrayElement(['successful', 'failed',]),
                "Description": faker.helpers.arrayElement(['Always 24/7', 'Everyday', 'Weekdays + Sat', '3 Days a Week']),
                "Download": "https://somelink.com/document.pdf",
            },
            {
                "Date": moment(faker.date.recent()).format('l'),
                "Amount": faker.finance.amount(),
                "Status": faker.helpers.arrayElement(['successful', 'failed',]),
                "Description": faker.helpers.arrayElement(['Always 24/7', 'Everyday', 'Weekdays + Sat', '3 Days a Week']),
                "Download": "https://somelink.com/document.pdf",
            },
        ];
        rows.push(user);
    }
    return rows;
}

export function generateSponsors() {
    var rows = [];

    for (let i = 1; i < 100; i++) {
        var sponsor = {
            id: i,
            sn: i,
            sponsorsName: faker.name.findName(),
            email: faker.internet.email(),
            serviceUser: faker.name.findName(),
            plan: faker.helpers.arrayElement(['Always 24/7', 'Everyday', 'Weekdays + Sat', '3 Days a Week']),
            status: faker.helpers.arrayElement(['active', 'inactive',]),
        };

        sponsor['profile'] =  {
            "Full Name": sponsor.sponsorsName,
            "Email": sponsor.email,
            "Plan": sponsor.plan,
            "Services Needed": "Housekeeping",
        };

        sponsor['serviceUserProfile'] =  {
            "Service User Name": faker.name.findName(),
            'Gender': faker.name.gender(true),
            'Date of Birth': moment(faker.date.birthdate({ min: 45, max: 90, mode: 'age' })).format('l'),
            'Address': faker.address.streetAddress(true),
            'Special Conditions': "None",
            'Current Medication': "Advil", 
            'Mobility': "Independent", 
            'Alertness': "Confused", 
            'Aggression': "😐", 
        };

        sponsor['paymentHistory'] =  [
            {
                "Date": moment(faker.date.recent()).format('l'),
                "Amount": faker.finance.amount(),
                "Status": faker.helpers.arrayElement(['successful', 'failed',]),
                "Description": faker.helpers.arrayElement(['Always 24/7', 'Everyday', 'Weekdays + Sat', '3 Days a Week']),
                "Download": "https://somelink.com/document.pdf",
            },
            {
                "Date": moment(faker.date.recent()).format('l'),
                "Amount": faker.finance.amount(),
                "Status": faker.helpers.arrayElement(['successful', 'failed',]),
                "Description": faker.helpers.arrayElement(['Always 24/7', 'Everyday', 'Weekdays + Sat', '3 Days a Week']),
                "Download": "https://somelink.com/document.pdf",
            },
            {
                "Date": moment(faker.date.recent()).format('l'),
                "Amount": faker.finance.amount(),
                "Status": faker.helpers.arrayElement(['successful', 'failed',]),
                "Description": faker.helpers.arrayElement(['Always 24/7', 'Everyday', 'Weekdays + Sat', '3 Days a Week']),
                "Download": "https://somelink.com/document.pdf",
            },
            {
                "Date": moment(faker.date.recent()).format('l'),
                "Amount": faker.finance.amount(),
                "Status": faker.helpers.arrayElement(['successful', 'failed',]),
                "Description": faker.helpers.arrayElement(['Always 24/7', 'Everyday', 'Weekdays + Sat', '3 Days a Week']),
                "Download": "https://somelink.com/document.pdf",
            },
        ];
        rows.push(sponsor);
    }

    return rows;
}

export function generateCaregiverProfile(caregiverDetails) {
    return {
        id: caregiverDetails.sn,
        name: caregiverDetails.name,
        email: caregiverDetails.email,
        profile: {
            'Full Name': caregiverDetails.name,
            'Phone Number': faker.phone.number('+234 ## ### ## ##'),
            'Gender': faker.name.gender(true),
            'Date of Birth': moment(faker.date.birthdate({ min: 23, max: 65, mode: 'age' })).format('l'),
            'Address': faker.address.streetAddress(true),
            'Bio': faker.lorem.paragraph(3),
        },
        payment: {
            'Account Name': caregiverDetails.name,
            'Account Number': faker.finance.account(10),
            'Bank': faker.company.companyName(),
        },
        skills: {
            'Certifications': faker.name.jobTitle(),
            'Years of Experience': faker.helpers.arrayElement(['Less than a year', '1-3 Years', '3-5 Years', '5+ Years of Experience']),
            'Skills': faker.company.catchPhraseNoun(),
            'Health conditions': 'None',
            'Work Schedule': "Mon-Friday (8am - 9pm)",
            'Living Arrangements': faker.helpers.arrayElement(['Living with care recipent', 'Per Time Live In', 'Personal Living Arrangements']),
            'Contract Type': faker.helpers.arrayElement(['full time', 'per time']),
        },
        documents: [
            {
                'name': 'image234.jpg',
                'type': 'jpg',
                'link': faker.image.business(),
            },
            {
                'name': 'my-resume.pdf',
                'type': 'pdf',
                'link': faker.image.business(),
            },
        ],
    }
}

export function generateDiscountCodes() {
    var rows = [];

    for (let i = 1; i < 100; i++) {
        var item = {
            id: i,
            sn: i,
            codeName: faker.random.word(),
            dateCreated: moment(faker.date.recent()).format('l'),
            percentage: faker.datatype.number(100) + "%",
            code: faker.random.alphaNumeric(8),
            status: faker.helpers.arrayElement(['enabled', 'disabled',]),
        };

        rows.push(item);
    }

    return rows;
}

export function generateTeamMembers() {
    var rows = [];

    for (let i = 1; i < 100; i++) {
        var item = {
            id: i,
            sn: i,
            name: faker.name.findName(),
            email: faker.internet.email(),
            role: faker.helpers.arrayElement(['admin', 'customer support', 'hr']),
            dateCreated: moment(faker.date.recent()).format('l'),
            status: faker.helpers.arrayElement(['active', 'deactivated', 'pending']),
        };

        rows.push(item);
    }

    return rows;
}

export function getCaregiversBadge(param) {
    let badgeType;
    let badgeText;
    switch (param) {
        case 'approved':
            badgeType =  { 'success': 'success' };
            badgeText = "Approved";
            break;
        case 'pending':
            badgeType = { 'warning': 'warning' };
            badgeText = "Pending";
            break;
        case 'rejected':
            badgeType = { 'danger': 'danger' };
            badgeText = "Rejected";
            break;
        default:
            badgeType = { 'disabled': 'disabled' };
            badgeText = "Disabled";
    }

    return {
        badgeType: badgeType,
        badgeText: badgeText,
    };
}

export function getTeamMembersBadge(param) {
    let badgeType;
    let badgeText;
    switch (param) {
        case 'active':
            badgeType =  { 'success': 'success' };
            badgeText = "Active";
            break;
        case 'pending':
            badgeType = { 'warning': 'warning' };
            badgeText = "Pending";
            break;
        case 'deactivated':
            badgeType = { 'danger': 'danger' };
            badgeText = "Deactivated";
            break;
        default:
            badgeType = { 'warning': 'warning' };
            badgeText = "Disabled";
    }

    return {
        badgeType: badgeType,
        badgeText: badgeText,
    };
}

export function getTransactionBadge(param) {
    let badgeType;
    let badgeText;
    switch (param) {
        case 'successful':
            badgeType =  { 'success': 'success' };
            badgeText = "Successful";
            break;
        case 'failed':
            badgeType = { 'warning': 'warning' };
            badgeText = "Failed";
            break;
        case 'canceled':
            badgeType = { 'danger': 'danger' };
            badgeText = "Canceled";
            break;
        default:
            badgeType = { 'disabled': 'disabled' };
            badgeText = "Disabled";
    }

    return {
        badgeType: badgeType,
        badgeText: badgeText,
    };
}

export function getDiscountBadge(param) {
    let badgeType;
    let badgeText;
    switch (param) {
        case 'enabled':
            badgeType =  { 'success': 'success' };
            badgeText = "Enabled";
            break;
        case 'disabled':
            badgeType = { 'warning': 'warning' };
            badgeText = "Disabled";
            break;
        case 'canceled':
            badgeType = { 'danger': 'danger' };
            badgeText = "Canceled";
            break;
        default:
            badgeType = { 'danger': 'danger' };
            badgeText = "Inactive";
    }

    return {
        badgeType: badgeType,
        badgeText: badgeText,
    };
}

export function getAssignCaregiversBadge(param) {
    let badgeType;
    let badgeText;
    switch (param) {
        case 'assigned':
            badgeType =  { 'success': 'success' };
            badgeText = "Assigned";
            break;
        case 'unassigned':
            badgeType = { 'warning': 'warning' };
            badgeText = "Unassigned";
            break;
        case 'blocked':
            badgeType = { 'danger': 'danger' };
            badgeText = "Blocked";
            break;
        default:
            badgeType = { 'disabled': 'disabled' };
            badgeText = "Disabled";
    }

    return {
        badgeType: badgeType,
        badgeText: badgeText,
    };
}

export function getCaregiversJobStatusBadge(param) {
    let badgeType;
    let badgeText;
    switch (param) {
        case 'accepted_job':
            badgeType =  { 'success': 'success' };
            badgeText = "Job Accepted";
            break;
        case 'pending':
            badgeType = { 'warning': 'warning' };
            badgeText = "Awaiting Feedback";
            break;
        case 'rejected_job':
            badgeType = { 'danger': 'danger' };
            badgeText = "Rejected";
            break;
        default:
            badgeType = { 'danger': 'danger' };
            badgeText = "-";
    }

    return {
        badgeType: badgeType,
        badgeText: badgeText,
    };
}

export function getSponsorsBadge(param) {
    let badgeType;
    let badgeText;
    switch (param) {
        case 'active':
            badgeType =  { 'success': 'success' };
            badgeText = "Active";
            break;
        case 'inactive':
            badgeType = { 'warning': 'warning' };
            badgeText = "InActive";
            break;
        case 'rejected':
            badgeType = { 'danger': 'danger' };
            badgeText = "Rejected";
            break;
        default:
            badgeType = { 'disabled': 'disabled' };
            badgeText = "Disabled";
    }

    return {
        badgeType: badgeType,
        badgeText: badgeText,
    };
}

export function getMeetingRequestsBadge(param) {
    let badgeType;
    let badgeText;
    switch (param) {
        case 'pending':
            badgeType =  { 'warning': 'warning' };
            badgeText = "Pending";
            break;
        case 'in_progress':
            badgeType = { 'warning': 'warning' };
            badgeText = "In Progress";
            break;
        case 'attended':
            badgeType = { 'success': 'success' };
            badgeText = "Attended";
            break;
        default:
            badgeType = { 'disabled': 'disabled' };
            badgeText = "Unknown";
    }

    return {
        badgeType: badgeType,
        badgeText: badgeText,
    };
}

export function generateDataset(){
    const labels = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August'];
    const chartData = {
      labels: labels,
      datasets: [
        {
          label: 'Service Users',
          data: labels.map(() => faker.datatype.number({ min: 0, max: 1000 })),
          fill: true,
          backgroundColor: [
            'rgba(255, 99, 132, 0.2)',
            'rgba(148, 119, 90, 0.2)',
            'rgba(46, 182, 67, 0.2)',
            'rgba(75, 192, 192, 0.2)',
            'rgba(54, 162, 235, 0.2)',
            'rgba(153, 102, 255, 0.2)',
            'rgba(201, 203, 207, 0.2)'
          ],
          borderColor: [
            'rgb(255, 99, 132)',
            'rgb(255, 159, 64)',
            'rgb(255, 205, 86)',
            'rgb(75, 192, 192)',
            'rgb(54, 162, 235)',
            'rgb(153, 102, 255)',
            'rgb(201, 203, 207)'
          ],
          borderWidth: 1
        },
  
        {
          label: 'Sponsors',
          data: labels.map(() => faker.datatype.number({ min: 0, max: 1000 })),
          fill: true,
          backgroundColor: [
            'rgba(255, 99, 132, 0.2)',
            'rgba(255, 159, 64, 0.2)',
            'rgba(255, 205, 86, 0.2)',
            'rgba(75, 192, 192, 0.2)',
            'rgba(54, 162, 235, 0.2)',
            'rgba(153, 102, 255, 0.2)',
            'rgba(201, 203, 207, 0.2)'
          ],
          borderColor: [
            'rgb(255, 99, 132)',
            'rgb(255, 159, 64)',
            'rgb(255, 205, 86)',
            'rgb(75, 192, 192)',
            'rgb(54, 162, 235)',
            'rgb(153, 102, 255)',
            'rgb(201, 203, 207)'
          ],
          borderWidth: 1
        }
      ]
    };

    return chartData;
}

export function generateFakeActivities() {
    const activities = [
        {
          key: 1,
          title: "Joseph signed in",
          desc: "Recent sign in from joseph",
          date: "11:00am",
          icon: <RiLoginBoxFill size={14}/>,
          href: "#home"
        },
        {
          key: 2,
          title: "Charles assigned Ruth to Jaykay",
          desc: "Ruth is to start on the 8th of July",
          date: "8:00am",
          icon: <RiUserAddFill size={14}/>,
          href: "#home"
        },
        {
          key: 3,
          title: "Jaykay signed up as a sponsor",
          desc: "Jaykay bought the Always 24/7 plan",
          date: "8:00am",
          icon: <RiUserAddFill size={14}/>,
          href: "#home"
        },
        {
          key: 4,
          title: "Charles accepted Lydia to be a care giver",
          desc: "Jaykay bought the Always 24/7 plan",
          date: "8:00am",
          icon: <RiUserAddFill size={14}/>,
          href: "#home"
        },
        {
          key: 5,
          title: "Charles assigned Ruth to Jaykay",
          desc: "Jaykay bought the Always 24/7 plan",
          date: "8:00am",
          icon: <RiUserAddFill size={14}/>,
          href: "#home"
        }
      ];
      
      return activities;
}