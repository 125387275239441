import authApi, {api} from "../../core/api/axios/baseAxios"
import { Routes } from "../../core/routes/apiRoutes";

const requestMeeting = async (data) => {
    const response = await api.post(Routes.createMeetingRequestUrl, data);
    const resData = response.data;
    return resData.data;
}

const getRequests = async (url) => {
    const response = await authApi.get(url);
    const resData = response.data;
    return resData;
}

const deleteRequest = async (id) => {
    const response = await authApi.delete(Routes.getMeetingRequestsUrl+`/${id}`);
    const resData = response.data;
    return resData;
}

const changeStatus = async (id,data) => {
    const response = await authApi.put(Routes.changeMeetingRequestStatusUrl+`/${id}`, data);
    const resData = response.data;
    return resData;
}

const meetingRequestService = {
    requestMeeting,
    getRequests,
    deleteRequest,
    changeStatus,
}

export default meetingRequestService