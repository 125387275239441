import {Radio} from '@mui/material';
import React from 'react'
import {  getAssignCaregiversBadge } from '../../misc/helpers';
import { Box, DetailsBody, DetailsButton, DetailsFooter, DetailsHeader, DetailsHeaderDesc, DetailsHeaderImage, DetailsPanelStyled, Flex, RadioStyle, UserCheckList } from '../../styles/admin/DetailsPanel';
import Badge from '../badge/Badge';
import SearchField from '../search_field/SearchField';
import UserAvatar from '../user_avatar/UserAvatar';
import useSearchResource from '../../core/hooks/useSearchResource';
import { useSelector } from 'react-redux';
import { getServiceUsers, reset } from '../../features/service_user/serviceUserSlice';
import { Routes } from '../../core/routes/apiRoutes';
import moment from 'moment/moment';
import { FormPanelControl } from '../../styles/FormPanel.Styled';
import { useForm } from 'react-hook-form';
import { FormDateField, FormTimeField } from '../custom_form_component/CustomFormComponent';
import Can from '../auth_gate/Can';
import { ASSIGN_CAREGIVERS } from '../../misc/constants';


function AssignPanel({data, onAssign}) {
    const [searchText, setSearchText] = React.useState('');
    const [selectedValue, setSelectedValue] = React.useState(0);
    
    const parseResponse = (response, page) => {
		var rows = [];
		const tempData = response.data;

		for (let i = 0; i < tempData.length; i++) {
			const itemData = tempData[i];
			var item = {
				id: itemData.id,
				sn: i + 1 + page,
				name: itemData.last_name + " " + itemData.first_name,
				avatar: itemData.avatar,
				dateCreated: moment.unix(itemData.joined_at).fromNow(),
				status: itemData.status,
                badge: getAssignCaregiversBadge(itemData.status),
			};
			rows.push(item);
		}

		return rows;
	};

    const {
		responseData,
		isLoading,
		onSearch,
	} = useSearchResource({
		selector: useSelector((state) => state.serviceUser),
		getReducer: getServiceUsers,
		resetReducer: reset,
		parseData: parseResponse,
		routes: {
			getRoute: Routes.getServiceUsersUrl,
		},
	});


    const {
		handleSubmit,
		control,
		formState: { errors },
	} = useForm({
		defaultValues: {
			start_date:  '',
			start_time: null,
			end_time: '',
		},
	});

    const handleChange = (event) => {
        setSelectedValue(parseInt(event.target.value));
    };

    const requestSearch = (searchValue) => {
        setSearchText(searchValue);
        onSearch(searchValue);
    };

    const onSubmit = (data) => {
        data.start_date = data.start_date.format("MM/DD/YYYY");
        data.start_time = data.start_time.format("hh:mm A");
        data.end_time = data.end_time.format("hh:mm A");
	    onAssign(selectedValue, data.start_date, data.start_time, data.end_time);
	};

    const badge = getAssignCaregiversBadge(data.status);

    return (
        <DetailsPanelStyled as="form" onSubmit={handleSubmit(onSubmit)}>
            <DetailsHeader>
                <Flex>
                    <DetailsHeaderImage>
                        <UserAvatar src={data.avatar} alt={data.name}/>
                    </DetailsHeaderImage>
                    <DetailsHeaderDesc>
                        <h3>{data.name}</h3>
                        <p>{data.email}</p>
                    </DetailsHeaderDesc>
                </Flex>
                <div>
                    <Badge {...badge.badgeType}>{badge.badgeText}</Badge>
                </div>

            </DetailsHeader>
            <DetailsBody padding="2rem" >
                

                <h3>Select Service User</h3>

                <Box backgroundColor="#fff">
                    <SearchField
                        fullWidth
                        value={searchText}
                        isLoading={isLoading}
                        onChange={(event) => requestSearch(event.target.value)}
                        clearSearch={() => requestSearch('')}
                    />
                </Box>

                



                {responseData.map((user) =>
                    
                    <UserCheckList key={user.id}>
                        <Flex>
                            <UserAvatar src={user.avatar} alt={user.name}/>
                            <span>{user.name}</span>
                            <span> <Badge {...user.badge.badgeType}>{user.badge.badgeText}</Badge></span>
                        </Flex>
                        <div>
                            <Radio
                                sx={RadioStyle}
                                checked={selectedValue === user.id}
                                onChange={handleChange}
                                value={user.id}
                                name="radio-buttons"
                                inputProps={{ 'aria-label': 'Select '+user.name }}
                            />
                        </div>

                    </UserCheckList>
                )}

                <h3 style={{marginTop: '1.5rem'}}>Set Schedule</h3>
                <div style={{marginTop: '1.5rem'}}>
                <FormPanelControl>
					<FormDateField
						name="start_date"
						label="Start date"
						placeholder="Caregiver Start Date"
						control={control}
						errors={errors}
					/>
				</FormPanelControl>

                <FormPanelControl>
                    <FormTimeField
						name="start_time"
						label="Start time"
						placeholder="Caregiver Start Time"
                        inputFormat="hh:mm A"
						control={control}
						errors={errors}
					/>
				</FormPanelControl>
                <FormPanelControl>
                    <FormTimeField
						name="end_time"
						label="End time"
						placeholder="Caregiver End Time"
                        inputFormat="hh:mm A"
						control={control}
						errors={errors}
					/>
				</FormPanelControl>
                </div>


            </DetailsBody>
            { Can(ASSIGN_CAREGIVERS) && <DetailsFooter>
                <div>

                </div>
                <div>
                    <DetailsButton as='button' type="submit" $primary>Assign Now</DetailsButton>
                </div>
            </DetailsFooter>}
        </DetailsPanelStyled>
    )
}

export default AssignPanel