import {DataGrid} from '@mui/x-data-grid';
import { styled } from '@mui/material/styles';

const checkboxStyle =  {
  '& .MuiCheckbox-root svg': {
    width: 16,
    height: 16,
    backgroundColor: 'transparent',
    border: `1px solid #d9d9d9`,
    borderRadius: 2,
  },
  '& .MuiCheckbox-root svg path': {
    display: 'none',
  },
  '& .MuiCheckbox-root.Mui-checked:not(.MuiCheckbox-indeterminate) svg': {
    backgroundColor: '#1890ff',
    borderColor: '#1890ff',
  },
  '& .MuiCheckbox-root.Mui-checked .MuiIconButton-label:after': {
    position: 'absolute',
    display: 'table',
    border: '2px solid #fff',
    borderTop: 0,
    borderLeft: 0,
    transform: 'rotate(45deg) translate(-50%,-50%)',
    opacity: 1,
    transition: 'all .2s cubic-bezier(.12,.4,.29,1.46) .1s',
    content: '""',
    top: '50%',
    left: '39%',
    width: 5.71428571,
    height: 9.14285714,
  },
  '& .MuiCheckbox-root.MuiCheckbox-indeterminate .MuiIconButton-label:after': {
    width: 8,
    height: 8,
    backgroundColor: '#1890ff',
    transform: 'none',
    top: '39%',
    border: 0,
  },
    };
  
  export const StyledDataGrid = styled(DataGrid)(({ theme }) => ({
    width: '100%',
    fontFamily: "Inter",
    WebkitFontSmoothing: 'auto',
    letterSpacing: 'normal',
    border: 'none',

    '& .MuiDataGrid-main': {
      background: '#fff',
      borderRadius: '8px',
      border: '1px solid #edeff5',
    },

    '.MuiDataGrid-columnHeader:focus-within': {
      outline: 'none',
    }, 
  
    '& .MuiDataGrid-columnsContainer': {
      
    },
    '& .MuiDataGrid-iconSeparator': {
      display: 'none',
    },
    '& .MuiDataGrid-columnHeader, .MuiDataGrid-cell, .MuiDataGrid-cell:focus-within': {
      outline: 'none',
      paddingLeft: '2rem',
    },

    '& .MuiDataGrid-columnsContainer, .MuiDataGrid-cell': {
      border: 'none',
      borderBottom: '1px solid #EAEDF3',
      color: '#737A91',
      fontSize: '14px',
      lineHeight: '22px',
    },
  
    '& .MuiDataGrid-row': {
      width: '100%',
    },
    
    '& .MuiDataGrid-row:hover': {
      background: "rgba(220, 220, 220,.1)"
    },
    '& .MuiPaginationItem-root:not(.MuiPaginationItem-ellipsis)': {
      borderRadius: '8px',
      border: '1px solid #E2E2EA',
      
    },
  
    '&.MuiDataGrid-root .MuiDataGrid-columnHeader:focus, &.MuiDataGrid-root .MuiDataGrid-cell:focus': {
        outline: 'none',
    },
  
    '& .MuiDataGrid-iconButtonContainer': {
      
    },
  
    '& .MuiDataGrid-columnHeaderTitleContainer': {
      width: '100%',
      justifyContent: 'space-between',
    },
  
    '& .MuiDataGrid-columnHeaders': {
      width: '100%',
      background: '#F6F7F9',
      borderRadius: '8px 8px 0px 0px',
      border: 'none',
      
    },
  
    '& .MuiDataGrid-columnHeadersInner': {
      
    },
  
    '& .MuiDataGrid-footerContainer': {
      padding: '1rem',
      border: 'none'
    },
  
    '& .MuiDataGrid-columnHeaderTitleContainerContent': {
      textTransform: 'uppercase',
      fontWeight: 500,
      fontSize: '12px',
      lineHeight: '18px',
      color: 'var(--color-text-primary)',
      outline: 'none',
    },
  
    '& .super-app-theme--header': {
        
    
    },
  
    '& .super-app-theme--row': {
      
     
    },
    ...checkboxStyle,
  }));