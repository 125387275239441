import { useState,useRef, useEffect } from 'react'
import {useDispatch } from 'react-redux'
import {toast} from 'react-toastify'

const usePublicResource = ({
    selector,
    getReducer,
    resetReducer,
    renderTableData,
    routes: {
        getRoute
    }
}) => {
    const [tableData, setTableData] = useState([]);
    const ref = useRef(null)
    const dispatch = useDispatch();

    const {
        data,
        isError,
        isLoading,
        isSuccess,
        message
    } = selector;


    useEffect(() => {
        if (isError) {
            toast.error(message)
            ref.current.complete()
        }

        if (isSuccess) {
            ref.current.complete();
        }

        if (isSuccess && message) {
            toast.success(message);
        }

        if (isSuccess && data) {
            prepareTableData(data);
        }

        dispatch(resetReducer())
        // eslint-disable-next-line
    }, [data, isError, isSuccess, message])

    const prepareTableData = (response) => {
        setTableData(renderTableData(response));
    }

    useEffect(() => {
        ref.current.continuousStart();
        dispatch(getReducer(getRoute));
        // eslint-disable-next-line
    }, []);

    return {
        data: tableData,
        ref: ref,
        isLoading: isLoading,
    };
};

export default usePublicResource;