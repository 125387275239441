import React, { useEffect } from 'react'
import { Navbar } from '../../components';
import { Footer } from '../../containers';
import './terms.css'

import BGHeader from '../../assets/bg-header.png';

function Terms() {
  useEffect(() => {
    document.title = "Terms and Conditions - Greymate Care";
  }, []);
  return (
    <>
    <div className='gmc__privacy-background'>
      <Navbar/>
      <div className='gmc__contact-us__header section__padding section__bg__lines'  style={{ 
        backgroundImage: `url(${BGHeader})` 
      }}>
        <h1 className='section__heading'>Terms and Conditions</h1>

       
      </div>
    </div>
    <div className='section__padding gmc__privacy_body gmc__terms_body '>
        <h3>Terms</h3>
        <ol>
          <li><p>These terms of use (“Terms”) apply to and govern your access to and use of the online platforms (collectively, the “Platforms” and each a “Platform”) which are operated by or for or on behalf of Greymate Services Limited (“Greymate Care”, “we”, “us”) for the purposes of Greymate Care providing services to its customers. The Platforms include the website <a href="https://www.greymatecare.com">https://www.greymatecare.com</a> and the mobile applications and such other online platforms as Greymate Care may introduce from time to time. </p></li>
          <li><p>By accessing a Platform, you acknowledge that you have read and understood the Terms and agree to be bound by them in respect of the access and use of such Platform.</p></li>
          <li><p>If you do not agree to these Terms, you should immediately stop using the Platforms and, where applicable, remove them from any computer on which you have accessed a Platform, or mobile device to which you have loaded a Platform and which you have registered to access a Platform (“Device”).</p></li>
          <li><p>Greymate Care periodically reviews the Terms and reserves the right to modify the Terms at any time in its sole discretion, without any notice to you. Such modifications shall take effect immediately upon the posting of the modified Terms. You agree to review the Terms regularly to be aware of such modifications. When the nature of the changes to these Terms would require us to provide a summary of the changes to these Terms or an updated version of these Terms, we will do so. Your continued access to or use of a Platform after such modifications shall be deemed to constitute your conclusive acceptance of the modified Terms in respect of the use of such Platform.</p></li>
          <li><p>Additional terms and conditions may apply to specific portions or features of a Platform, including contests, promotions or other similar features, all of which terms and conditions shall be incorporated into these Terms by reference. In addition, certain content or services provided through a Platform may be accompanied by their own licensing terms and conditions. If there is any conflict between any of the provisions of these Terms and any provision of such terms and conditions, the provisions of these Terms shall prevail.</p></li>
          <li><p>Greymate Care shall be entitled at any time to add, change or withdraw any of the functions available or to be made available on any or all of the Platforms at its own discretion.</p></li>
          <li><p>Your access to or use of the Platforms will be subject to any other guidelines, procedures, policies and regulations which may from time to time be prescribed, introduced, varied and/or amended by Greymate Care, including Greymate Care's Privacy Policy (which can be found at <a href="https://greymatecare.com/privacy-policy">https://greymatecare.com/privacy-policy</a>).</p></li>
          <li><p>By accessing any of the Platforms, you represent and warrant that:</p>
            <ol type="i">
              <li><p>you are of legal age of majority to contract in the jurisdiction in which you reside and in any event, no less than 18 years of age; and</p></li>
              <li><p>you are legally capable and permitted to accede to the Terms.</p></li>
            </ol>
          </li>
          <li>
            <p>These Terms shall be void where prohibited by applicable law and you shall have no rights under the Terms in such circumstances.</p>
          </li>
        </ol>
        <h3>User Profile</h3>
        <ol>
          <li><p>You must register an account (“User Profile”) on a Platform if you wish to use certain of the services available through the Platform (including to make a booking request for in-home care services from a care professional). </p></li>
          <li>
            <p>You will need to create a username and password to register your User Profile. As part of the registration of a User Profile, Greymate Care may require you to complete a verification process (in such manner as may be determined by Greymate Care from time to time) to verify the information which you have provided to Greymate Care. You agree to provide truthful, accurate and complete information when you register your User Profile (including in connection with any verification process) and you agree to maintain and update any information so provided. Any false identification, email address, telephone contact number or otherwise misleading information about your identity is strictly forbidden and Greymate Care shall be entitled to delete your User Profile in such circumstances. </p>
          </li>
          <li>
            <p>By registering a User Profile, you represent that the information provided to Greymate Care is truthful, accurate and complete and that you will ensure that the information you have provided remains current and accurate. Your information can be updated by logging in to your User Profile and making the necessary changes.</p>
          </li>
          <li>
            <p>Your User Profile is personal to you and is not transferable to third parties. You must only register one User Profile. Multiple accounts will be deleted. Greymate Care reserves the right in its sole discretion to delete any User Profile it believes to be duplicative. </p>
          </li>
          <li>
            <p>A User Profile registered on a Platform will be available for use across all Platforms, and you shall be able to access and use any Platform and/or the services available through any Platform using your registered User Profile.</p>
          </li>
          <li>
            <p>You are responsible for safekeeping and maintaining the confidentiality of your username and password. You shall not disclose to any person your username or password and shall not permit or authorise any person to use your username or password for any purpose whatsoever. You agree to take reasonable measures and all due care to protect your username and password against misuse by third parties.  You will not record your Password on your Device or on any article normally carried with your Device and which is liable to loss or theft with your Device</p>
          </li>
          <li>
            <p>Greymate Care may treat any access to or use of a Platform and/or the services available through a Platform through your User Profile as access or use by you, notwithstanding that it may have been accessed or used by any other person without your knowledge or authority. You shall be personally responsible and liable for any such access and use (including all information posted on or transmitted via any or all of the Platforms by anyone using your username and password and any payments due for any services acquired through any or all the Platforms by anyone using your username and password).</p>
          </li>
          <li>
            <p>Should you learn that your User Profile has been used in an unauthorised manner to access or use any of the Platforms and/or the services available through any of the Platforms or effect any transaction in connection with such access or use (“Unauthorised Use”), you shall:</p>
            <ol type='i'>
              <li>
                <p>immediately notify Greymate Care of the Unauthorised Use;</p>
              </li>
              <li>
                <p>at Greymate Care's request, make a police report of the Unauthorised Use;</p>
              </li>
              <li>
                <p>provide Greymate Care with any other information relating to the Unauthorised Use as Greymate Care may request; and </p>
              </li>
              <li>
                <p>immediately change the password to your User Profile.</p>
              </li>
            </ol>
          </li>
          <li>
            <p>Greymate Care may notify you of matters related to any or all of the Platforms or the services available through any or all of the Platforms (including transactional matters relating to such services) by sending an email message to the email address listed under your User Profile, an electronic message to the mailbox assigned to your User Profile or a letter via postal mail to the mailing address listed under your User Profile.</p>
          </li>
          <li>
            <p>Greymate Care reserves the right in its sole discretion to limit, suspend or delete your User Profile if Greymate Care determines in its sole discretion that you have breached any of these Terms.  You acknowledge and agree that you may lose saved data or your personal settings on the platforms, should we limit, suspend or delete your User Profile and that we are not responsible for any such loss.</p>
          </li>
        </ol>
        <h3>Privacy</h3>
        <ol>
          <li>
            <p>During your access to or use of a Platform or any of the services available through a Platform, Greymate Care may collect your personal information (including information you enter into your User Profile). </p>
          </li>
          <li>
            <p>The personal information collected by Greymate Care will be maintained, used, disclosed, stored and/or transferred in accordance with Greymate Care's Privacy Policy. You can access the Privacy Policy at <a href="https://greymatecare.com/privacy-policy">https://greymatecare.com/privacy-policy</a>.</p>
          </li>
        </ol>
        <p>Any information that you disclose in the public areas of any of the Platforms such as message boards and forums becomes public information. You should exercise caution when disclosing your personal information in this way.</p>
      <h3>Acceptable Use</h3>
      <ol>
        <li>
          <p>You shall be responsible for any content that you post or transmit on or through any of the Platforms. </p>
        </li>
        <li>
          <p>If you have a User Profile, you can post ratings of service providers (“Ratings”) on the Platforms. Ratings will identify the User Profile that has posted the Rating. Unjustified multiple Ratings, self-Ratings and other forms of Ratings which attempt to have a distorting influence on the Rating system are prohibited and may be deleted. </p>
        </li>
        <li>
          <p>The following provisions shall apply to all content (including Ratings) posted on or transmitted on or through any of the Platforms:</p>
          <ol type='i'>
            <li><p>Content must be based on demonstrable facts, must be written fairly and objectively and must not contain any content which is offensive or abusive, liable to criminal prosecution or otherwise unlawful. </p></li>
            <li>
              <p>Content which violates the requirements set out in the clause above will not be published or may be deleted.</p>
            </li>
            <li>
              <p>By posting or transmitting any Content on any of the Platforms, you grant Greymate Care, free of charge, a non-exclusive, royalty-free, worldwide, irrevocable, perpetual licence to use, store, copy, modify or otherwise exploit or deal with the content in any manner it sees fit, including using it for further services, passing it on to third parties or publishing it.</p>
            </li>

          </ol>
          
        </li>
        <li>
          <p>You agree not to cause, nor knowingly allow others to cause, any nuisance, annoyance, or inconvenience, whether to Greymate Care or any of the users of any of the Platforms by any means. </p>
        </li>
        <li>
          <p>You agree to use the Platforms in a manner consistent with all applicable laws and regulations. Specifically, you will not and will not permit any person to use any of the Platforms in any way:</p>
          <ol type='i'>
            <li>
              <p>which breaches any of these Terms;</p>
            </li>
            <li>
              <p>to post, transmit, distribute, link to, or solicit content that contains any advertising and promotional message;</p>
            </li>
            <li>
              <p>which violates or infringes any laws, regulatory requirements or codes applicable in Nigeria;</p>
            </li>
            <li>
              <p>which infringes or violates any copyright, trademark or any other intellectual property, personal or proprietary rights of any person, or violates any obligation of confidence or any other proprietary right;</p>
            </li>
            <li>
              <p>to post, transmit, distribute, link to, or solicit content that contains any obscene, offensive, defamatory or otherwise actionable material;</p>
            </li>
            <li>
              <p>which undermines the security or integrity of any of the Platforms, including without limitation by importing any spyware, viruses, Trojan horses, worms, keystroke loggers, time bombs or other disabling devices or malicious computer software intended to detrimentally interfere, damage, surreptitiously intercept or expropriate such equipment, systems or networks;</p>
            </li>
            <li>
              <p>to post, transmit, distribute, link to, or solicit content that contains any errors, whether technical or otherwise;</p>
            </li>
            <li>
              <p>to post, transmit, distribute, link to, or solicit content that contains any material in any form that would otherwise render Greymate Care liable or expose Greymate Care to any proceedings whatsoever;</p>
            </li>
            <li>
              <p>to access and use the Platforms in a manner that could interfere with the Platforms or the access and use of the Platforms by other users;</p>
            </li>
            <li>
              <p>to resell, sub-licence, resupply, communicate or otherwise make available to a third party the Platforms;</p>
            </li>
            <li>
              <p>to remove or tamper with any copyright, trade mark or other proprietary notice of ours or any other entity on the Platforms; or</p>
            </li>
            <li>
              <p>to commit or encourage a criminal offence.</p>
            </li>
            <li>
              <p>You will:</p>
              <ol type='i'>
                <li>
                  <p>prevent anyone else seeing your Password being entered into any Device; and</p>
                </li>
                <li>
                  <p>comply with all applicable laws in relation to your access to and use of the Platforms.</p>
                </li>
              </ol>
            </li>
          </ol>
        </li>
        <li>
          <p>You shall not use any robot, spider, or other automatic or manual device or process to monitor or copy any portion of any content contained in any of the Platforms without Greymate Care's express written permission.</p>
        </li>
        <li>
          <p>You shall not use any device, software or routine to interfere or attempt to interfere with the proper working of any of the Platforms, or to impose an unreasonable or disproportionately large load on the servers serving any of the Platforms or any network or website connected to the Platforms.</p>
        </li>
        <li>
          <p>You acknowledge that some of the content available on the Platforms may be posted on or via the Platforms by third parties, including content provided by blogs or reviews or comments made by users of the Platforms. Greymate Care shall not be responsible for the accuracy of or any errors or omissions in any content available on any of the Platforms.</p>
        </li>
        <li>
          <p>Greymate Care shall have the right (but not the obligation) in its sole discretion to monitor, refuse or remove any content that is available on any of the Platforms. Without limiting the foregoing, Greymate Care shall have the right to remove any content that violates these Terms or is otherwise objectionable. </p>
        </li>
        <li>
          <p>Greymate Care assumes no responsibility for monitoring any of the Platforms for inappropriate content. If at any time Greymate Care chooses, at its sole discretion, to monitor any of the Platforms, Greymate Care nonetheless assumes no responsibility for the content available on any of such Platforms.</p>
        </li>
      </ol>  
      <h3>Intellectual Property</h3>
        <ol>
          <li>
            <p>Content available on the Platforms (including information, communications, software, images, sounds contained on or available through the Platforms) is provided by Greymate Care, its content providers and third party licensors. The intellectual property rights to or over the contents available on the Platforms belong to Greymate Care, its content providers and/or third party licensors. All rights are reserved. Subject to Clause 5.2, you must not reproduce, modify, transfer, distribute, republish, download, post, or transmit in any form or by any means, including electronic, mechanical photocopying or recording, any of the content available on any of the Platforms without the prior written permission of Greymate Care. Without prejudice to the foregoing, you agree that the material and content contained within or available through the Platforms may not be used for commercial purposes or distributed commercially.</p>
          </li>
          <li>
            <p>Notwithstanding Clause 5.1, you may view, store, print and display the content available on the Platforms solely for your personal, non-commercial use. </p>
          </li>
          <li>
            <p>Your access to or use of the Platforms should not be construed as granting, by implication, estoppel or otherwise, any licence or right to use the trademarks, tradenames, logos or service marks appearing on any of the Platforms without Greymate Care's prior written consent. You may not, without Greymate Care's prior written consent, use any of such trademarks, tradenames, logos or service marks as a hyperlink to Greymate Care's website or any of the Platforms or any other website or platform.</p>
          </li>
          <li>
            <p>You may not copy, reproduce, modify, translate, decompile, disassemble, reverse engineer or otherwise attempt to derive the source code for the computer systems and other technology that operate any of the Platforms or create derivative works based on any of the Platforms. For the purposes of these Terms, “reverse engineering” shall include the examination or analysis of any of the Platforms to determine the source code, structure, organisation, internal design, algorithms or encryption devices of any of such Platform's underlying technology.</p>
          </li>
        </ol>
      <h3>Disclaimer of Warranties</h3>
      <ol>
        <li>
          <p>The use of any and all of the Platforms is at your own risk. </p>
        </li>
        <li>
          <p>Greymate Care takes certain industry-accepted precautions to secure the Platforms or portions of the Platforms. However, Greymate Care does not warrant that: </p>
          <ol>
            <li>
              <p>the access to or use of the Platforms will meet your requirements;</p>
            </li>
            <li>
              <p>the Platforms will always be available;</p>
            </li>
            <li>
              <p>the access to or use of the Platforms will be uninterrupted, timely, secure, error-free or virus-free, or free from other invasive or damaging code;</p>
            </li>
            <li>
              <p>the quality of any products, services, information, or other material (including the content and services) purchased or obtained by you through your access to or use of the Platforms will meet your expectations; or</p>
            </li>
            <li>
              <p>any errors in the Platforms will be corrected.</p>
            </li>
          </ol>
        </li>
        <li>
          <p>By accessing and using any of the Platforms, you understand and agree that:</p>
          <ol type='i'> 
            <li>
              <p>You assume total responsibility and risk for your access to and use of the Platforms. Greymate Care does not make any express or implied warranties, representations or endorsements whatsoever with regard to any of the Platforms or any content or service provided through any of the Platforms, and shall not be liable for any cost or damage arising directly or indirectly from any such transaction. The content and services available on the Platforms are provided to you on an “as is, as available” basis without warranty or condition of any kind. </p>
            </li>
            <li>
              <p>Any content downloaded, uploaded or otherwise obtained through your access to and use of any of the Platforms is done at your own risk and discretion. It is solely your responsibility to evaluate the accuracy, completeness and usefulness of all opinions, advice, services, and other information provided through the Platforms.</p>
            </li>
          </ol>
        </li>
      </ol>
      <h3>Indemnity</h3>
      <ol>
        <li>
          <p>To the extent permitted by applicable law, you agree to indemnify and hold harmless Greymate Care, its shareholders, directors, officers, employees, agents and representatives (collectively, the “Indemnified Parties”), from and against any and all claims, demands, actions, judgments, losses, liabilities, damages, costs and expenses of whatever nature (including legal costs on a full indemnity basis) which any or all of the Indemnified Parties may incur or suffer as a result of, arising out of or in connection with:</p>
          <ol type='i'>
            <li>
              <p>your access to or use of any of the Platforms;</p>
            </li>
            <li>
              <p>your breach of any of these Terms; or</p>
            </li>
            <li>
              <p>your infringement or violation of the rights of any third party,</p>
            </li>
            <li>
              <p>except to the extent arising from our breach of any of our warranties or obligations in these Terms.</p>
            </li>
          </ol>
        </li>
      </ol>
      <p>The obligations under this Clause 7 shall survive any termination of your relationship with Greymate Care or your access to or use of the Platforms. Greymate Care reserves the right to assume the defence and control of any matter subject to indemnification by you, in which event you will cooperate with Greymate Care in asserting any available defences.</p>
      <h3>Limitation of Liability</h3>
      <ol>
        <li><p>To the extent permitted by law, and subject to clause 8.3, our liability for breach of any condition, warranty or guarantee imposed by statute that cannot be excluded and your sole and exclusive remedy in relation to such breach will be limited to (at our election): </p>
          <ol type='i'>
            <li>
              <p>in the case of goods:</p>
              <ol type='i'>
                <li>
                  <p>replacement or repair of the goods or supplying the equivalent goods again; or</p>
                </li>
                <li>
                  <p>paying the cost of replacing or repairing the goods or of acquiring equivalent goods;</p>
                </li>
              </ol>
            </li>
            <li>
              <p>in the case of services:</p>
              <ol type='i'>
                <li>
                  <p>supplying the services again; or</p>
                </li>
                <li>
                  <p>paying the cost of having the services supplied again.</p>
                </li>
              </ol>
            </li>
          </ol>
        </li>
        <li><p>To the extent permitted by applicable law, and subject to clause 8.3, Greymate Care shall not be liable to you in any way whatsoever for any loss, damages or costs, whether in contract or tort (including negligence or breach of statutory duty) or otherwise arising out of or in connection with your access to or use of any of the Platforms or other content or services provided through any of the Platforms except to the extent arising from our breach of any of our warranties or obligations in these Terms.</p></li>
        <li>
          <p>Nothing in this clause 8 excludes, restricts or modifies:</p>
          <ol type='i'>
            <li>
              <p>i.	the application of any consumer guarantee in the Nigerian Consumer law (Consumer Guarantee) or any similar provision in the law of any State or Territory that cannot be excluded, restricted or modified (Similar Provision);</p>
            </li>
            <li>
              <p>the exercise of any right conferred by a Consumer Guarantee or Similar Provision;  or</p>
            </li>
            <li>
              <p>our liability under any Consumer Guarantee or Similar Provision.</p>
            </li>
          </ol>
        </li>
      </ol>
      <h3>Third-Party Websites and Third-Party Content</h3>
      <ol>
        <li>
          <p>The Platforms may contain hyperlinks or other redirection tools to websites or applications that are owned or operated by third parties. Such websites or applications are not controlled by Greymate Care and do not form part of any of the Platforms. If you choose to use such hyperlinks or redirection tools to access such websites or applications, you agree to review and accept such websites' or applications' terms and conditions of use before accessing such websites or applications. You access such third-party websites and applications at your own risk. </p>
        </li>
        <li>
          <p>Greymate Care does not assume any responsibility for material created or published by such third party websites or applications, and by providing a link to such third party websites or applications, Greymate Care does not imply that Greymate Care endorses the websites or applications or the products or services referenced in such websites or applications. You acknowledge that Greymate Care has no control over, excludes all liability for and cannot be deemed to have endorsed the content of any material on the Internet which can be accessed by using the Platforms.</p>
        </li>
        <li>
          <p>You agree that caching, hyperlinking to, and framing of Greymate Care's website or any of its contents are strictly prohibited.</p>
        </li>
        <li>
          <p>Greymate Care reserves all rights to disable any links to, or frames of any website containing inappropriate, profane, defamatory, infringing, obscene, indecent or unlawful topics, names, material or information, or material or information that violates any written law, any applicable intellectual property or proprietary, privacy or publicity rights.</p>
        </li>

      </ol>
      <h3>Location data</h3>
      <ol>
        <li>
          <p>You may grant the Platforms permission to access, collect, and use the real-time geographic location of your Device, determined in accordance with your Device settings (“Location Data”). Location Data allow us to provide you with the location-based functions and features of the Platforms and other location-based services we may offer from time to time. If you do not want us to access, collect or use your Location Data, please disable this feature on your Device. </p>
        </li>
      </ol>
      <h3>Dispute Resolution</h3>
      <ol>
        <li>
          <p>These Terms shall be governed by and construed in accordance with the laws of Nigeria.</p>
        </li>
        <li>
          <p>Notwithstanding anything to the contrary in these Terms, Greymate Care may at any time without regard to any notice periods required by the provisions in these Terms, and as often as is necessary or appropriate, seek any injunctive relief or measures, or any interlocutory, provisional or interim relief or measures, from any court of competent jurisdiction.</p>
        </li>
      </ol>
      <h3>Direct electronic marketing</h3>
      <ol>
        <li>
          <p>It is our policy to only send marketing material by electronic communications to customers who give us permission. By agreeing to these Terms you give us this permission unless you have already told us that you withdraw your consent. If we send you an electronic communication other than a communication containing no more than factual information (for example, an email advising you that these Terms have changed), we will give you the option of not receiving further communications of this nature. This will allow you to opt-out of receiving electronic communications containing marketing material at any time.</p>
        </li>
      </ol>

      <h3>General</h3>
      <ol>
        <li>
          <p>No failure or delay by Greymate Care in exercising any right or remedy under these Terms shall operate as a waiver of such right or remedy, nor will any single or partial exercise of any right or remedy under these Terms preclude any other or further exercise thereof or the exercise of any other right or remedy. </p>
        </li>
        <li>
          <p>If any provision in these Terms shall be, or at any time shall become invalid, illegal or unenforceable in any respect, such invalidity, illegality or unenforceability shall not in any way affect or impair any other provision of these Terms but these Terms shall be construed as if such invalid or illegal or unenforceable provision had never been contained in these Terms.</p>
        </li>
        <li>
          <p>You shall not assign, transfer or subcontract, or purport to assign, transfer or subcontract, any of your rights, interests or obligations under these Terms without the prior written consent of Greymate Care. Greymate Care shall be entitled to assign, transfer or subcontract any or all of its rights, interests and obligations under these Terms to any third party without your prior written consent.</p>
        </li>
      </ol>

    </div>
    <Footer/>
    </>
  )
}

export default Terms