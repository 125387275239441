import {useEffect, useRef} from 'react'
import { useSelector, useDispatch } from 'react-redux'
import {useNavigate, useLocation} from 'react-router-dom'
import {Link} from 'react-router-dom'
import logo from '../../../assets/logo.png';
import { DefaultTextField } from '../../../components/custom_form_component/CustomFormComponent'
import {LoadingBar} from '../../../components/loading_bar/LoadingBar'
import {toast} from 'react-toastify'
import {login, reset} from '../../../features/auth/authSlice'
import { FormError, FormPanel, FormPanelBody, FormPanelBodySection, FormPanelButton, FormPanelControl, FormPanelFooter, FormPanelHeader, FormPanelWrapper } from '../../../styles/FormPanel.Styled'
import { AppRoutes } from '../../../core/routes/routes';
import { useForm, Controller } from "react-hook-form";
import { ErrorMessage } from '@hookform/error-message';
import formValidator from '../../../misc/validator';


function Login() {

    const { handleSubmit,  control, formState: { errors } } = useForm({
        defaultValues: {
            email: '',
            password: '',
        }
    });

    const ref = useRef(null)

    const navigate = useNavigate();
    const location = useLocation();
    const redirectTo = location.state?.from?.pathname || AppRoutes.AdminUrl;
    const dispatch = useDispatch();

    const {user, isError, isSuccess, isLoading, message} = useSelector((state) => state.auth)
    

    useEffect(()=>{
        if(isError){
            toast.error(message)    
            ref.current.complete()
        }

        if(isSuccess || user) {
            ref.current.complete()
            navigate(redirectTo)
        }

        dispatch(reset())
    }, [user, isError, isSuccess, message, redirectTo, navigate, dispatch])
    
    const onSubmit = (data) => {
       
        
        ref.current.continuousStart();
       
        dispatch(login(data))

    };

  
  return (
    <>
        <LoadingBar ref={ref} shadow={true} />
        <FormPanelWrapper>
            <FormPanel as='form' onSubmit={handleSubmit(onSubmit)}>
                <FormPanelHeader>
                    <FormPanelHeader center>
                        <Link to='/' alt="home"><img src={logo} alt="Greymate Care Logo" /></Link>
                        <h1>Welcome back to Greymate Admin</h1>
                        <p>Log in to your workspace to continue</p>
                    </FormPanelHeader>
                </FormPanelHeader>
                <FormPanelBody>
                    <FormPanelBodySection>
                        <FormPanelControl>
                            <Controller
                                name="email"
                                control={control}
                                rules={{ 
                                    required: {
                                        value: 'required',
                                        message: 'Please enter your email',
                                    }, 
                                    validate: formValidator.emailValidator,
                                }}
                                render={({ field: { onChange, value }}) => (
                                    <DefaultTextField  label="Email" type="email" name="email" placeholder='you@example.com' onChange={onChange} value={value}/>
                                )}
                            />
                            <ErrorMessage errors={errors} name="email" as={<FormError />} />
                        </FormPanelControl>
                        <FormPanelControl>
                            <Controller
                                name="password"
                                control={control}
                                rules={{ required: {
                                    value: 'required',
                                    message: 'Please enter a your password',
                                }}}
                                render={({ field: { onChange, value }}) => (
                                    <DefaultTextField  label="Password" type="password" name="password" placeholder='Enter password here' autoComplete="current-password" value={value} onChange={onChange}/>
                                )}
                            />
                            <ErrorMessage errors={errors} name="password" as={<FormError />} />
                        </FormPanelControl>
                        <FormPanelControl>  
                            <p>Forgot your password? reset <Link to={AppRoutes.ForgotPasswordURL}>here</Link></p>
                        </FormPanelControl>
                    </FormPanelBodySection>

                    <FormPanelFooter center>
                        <FormPanelButton fullWidth type="submit" disabled={isLoading}>{isLoading?'Loading...':'Login'}</FormPanelButton>
                    </FormPanelFooter>
                </FormPanelBody>

               

            </FormPanel>
        </FormPanelWrapper>
        
    </>
    
  )
}

export default Login