import React, { useEffect } from 'react'
import { RequestDemoModal, Navbar } from '../../components'
import { Footer } from '../../containers'
import { ReactComponent as CheckMark } from '../../assets/check.svg'

import './home_care.css'

function HomeCare() {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

   useEffect(() => {
    document.title = "Home Care Agencies - Greymate Care";
  }, []);
  
  return (
    <>
    <div className='gmc__homecare-background'>
      <Navbar/>
      <div className='section__padding gmc__homecare__header '>
        <div className='gmc__homecare__header__cover'>
          <p className='section__subtitle'>For Home Care Agencies</p>
          <h1 className='section__heading'>Eliminate staff shortage <br/>and other operational hassles</h1>
          <p className='section__desc'>With the Greymate Care enterprise solution. Take advantage of the automation solution and reduce admin overhead so you can fully concentrate on providing excellent care for your patients and maximize profit. Let us help you</p>
          <button className='button__primary' onClick={handleOpen}>Request a Demo</button>
        </div>
        <div className='gmc__homecare__checklist'>
          <div className='gmc__homecare__checklist__item'>
            <CheckMark />
            <p>Automate scheduling through our<br/> enterprise platform</p>
          </div>
          <div className='gmc__homecare__checklist__item'>
            <CheckMark />
            <p>Gain visibility to tens of thousands <br/>of customers</p>
          </div>
          <div className='gmc__homecare__checklist__item'>
            <CheckMark />
            <p>Manage scheduling conflicts</p>
          </div>
          <div className='gmc__homecare__checklist__item'>
            <CheckMark />
            <p>Easily send out callouts to your staff using a simplified process</p>
          </div>
          <div className='gmc__homecare__checklist__item'>
            <CheckMark />
            <p>Access qualified on-demand staff in the event of a shortage</p>
          </div>
        </div>
        
      </div>
    </div>
    <Footer/>
    <RequestDemoModal open={open} onClose={handleClose}/>
    </>
  )
}

export default HomeCare