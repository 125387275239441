import React, {useEffect} from 'react'
import { StatsBoxWrapper } from '../../../styles/admin/StatsBox.styled';
import { ActivityCard, CardBox, StatsBox, AdminChart } from '../../../components';
import { DashboardRow, ChartWrapper } from '../../../styles/admin/CardBox.styled';
import { LoadingBar } from '../../../components/loading_bar/LoadingBar';
import { getDashboardData, reset } from '../../../features/dashboard/dashboardSlice';
import { Routes } from '../../../core/routes/apiRoutes';
import { useSelector } from 'react-redux';
import { RiLoginBoxFill } from 'react-icons/ri';
import usePublicResource from '../../../core/hooks/usePublicResource';


function Dashboard() {

  const prepareTableData = (response) => {
		var rows = [];
		const tempData = response.data.activities;
    
		for (let i = 0; i < tempData.length; i++) {
			var item = {
				key: i + 1,
				sn: i + 1,
				title: tempData[i].title,
				desc: tempData[i].subtitle,
				date: tempData[i].created,
        icon: <RiLoginBoxFill size={14}/>,
			};

			rows.push(item);
		}
    var responseData = {
      ...response.data,
      'activities': rows,
    };
    
		return responseData;
    
	};

	const {data, ref} =
		usePublicResource({
			selector: useSelector((state) => state.activityLog),
			getReducer: getDashboardData,
			resetReducer: reset,
			renderTableData: prepareTableData,
			routes: {
				getRoute: Routes.getDashboardDataUrl,
			},
		});



	useEffect(() => {
		document.title = "Dashboard - Greymate Care";
	}, []);

  
  
  const activityCards = !data.activities ? []:data.activities.map((activity) =>
    <ActivityCard {...activity} key={activity.key}/>
  );

  //const serviceUserChartData = data.serviceusers?.chartData;
  const sponsorsChartData = data.sponsors?.chartData;
  const caregiversChartData = data.caregivers?.chartData;
  const usersChartData = data.users?.chartData;



  const labels = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
  const chartData = {
    labels: labels,
    datasets: [
     
      {
        label: 'Caregivers',
        data: caregiversChartData ? Object.values(caregiversChartData) : [],
        fill: true,
        backgroundColor: [
          'rgba(255, 99, 132, 0.2)',
          'rgba(148, 119, 90, 0.2)',
          'rgba(46, 182, 67, 0.2)',
          'rgba(75, 192, 192, 0.2)',
          'rgba(54, 162, 235, 0.2)',
          'rgba(153, 102, 255, 0.2)',
          'rgba(201, 203, 207, 0.2)'
        ],
        borderColor: [
          'rgb(255, 99, 132)',
          'rgb(255, 159, 64)',
          'rgb(255, 205, 86)',
          'rgb(75, 192, 192)',
          'rgb(54, 162, 235)',
          'rgb(153, 102, 255)',
          'rgb(201, 203, 207)'
        ],
        borderWidth: 1
      },
      {
        label: 'Admins',
        data: usersChartData ? Object.values(usersChartData) : [],
        fill: true,
        backgroundColor: [
          'rgba(255, 99, 132, 0.2)',
          'rgba(148, 119, 90, 0.2)',
          'rgba(46, 182, 67, 0.2)',
          'rgba(75, 192, 192, 0.2)',
          'rgba(54, 162, 235, 0.2)',
          'rgba(153, 102, 255, 0.2)',
          'rgba(201, 203, 207, 0.2)'
        ],
        borderColor: [
          'rgb(255, 99, 132)',
          'rgb(255, 159, 64)',
          'rgb(255, 205, 86)',
          'rgb(75, 192, 192)',
          'rgb(54, 162, 235)',
          'rgb(153, 102, 255)',
          'rgb(201, 203, 207)'
        ],
        borderWidth: 1
      },

      {
        label: 'Sponsors',
        data:   sponsorsChartData ? Object.values(sponsorsChartData) : [],
        fill: true,
        backgroundColor: [
          'rgba(255, 99, 132, 0.2)',
          'rgba(255, 159, 64, 0.2)',
          'rgba(255, 205, 86, 0.2)',
          'rgba(75, 192, 192, 0.2)',
          'rgba(54, 162, 235, 0.2)',
          'rgba(153, 102, 255, 0.2)',
          'rgba(201, 203, 207, 0.2)'
        ],
        borderColor: [
          'rgb(255, 99, 132)',
          'rgb(255, 159, 64)',
          'rgb(255, 205, 86)',
          'rgb(75, 192, 192)',
          'rgb(54, 162, 235)',
          'rgb(153, 102, 255)',
          'rgb(201, 203, 207)'
        ],
        borderWidth: 1
      }
    ]
  };


  return (
    <>
    <LoadingBar ref={ref} shadow={true} />
      <h1>Dashboard</h1>

      <StatsBoxWrapper>
        <StatsBox title="Available Caregivers" value={data?.caregivers?.all} monthlyValue={data?.caregivers?.monthlyCount} bgColor="#696CD6"  />
        <StatsBox title="Service Users" value={data?.serviceusers?.all} monthlyValue={data?.serviceusers?.monthlyCount} bgColor="#FFBE01" labelPrefix="+" />
        <StatsBox title="Registered Sponsors" value={data?.sponsors?.all} monthlyValue={data?.sponsors?.monthlyCount} bgColor="#65E2BE" labelPrefix="+" />
        <StatsBox title="Team Members" value={data?.users?.all} monthlyValue={data?.users?.monthlyCount} labelPrefix="" />
      </StatsBoxWrapper>

      <DashboardRow>
        <CardBox title="Statistics" href="#home" linkText="View All" width="60%">
          <ChartWrapper>
            <AdminChart data={chartData}/>
          </ChartWrapper>
        </CardBox>

        <CardBox title="Recent Activities" width="40%">
         {activityCards}
        </CardBox>
      </DashboardRow>
    </>

  )
}

export default Dashboard