export const Routes = {
    // baseUrl: "http://localhost/api",
    // callbackUrl: "http://localhost:3000/sponsor/welcome?plan=",
    baseUrl: "https://api.greymatecare.com/api",
    callbackUrl: "https://greymatecare.com/sponsor/welcome?plan=",
    
    loginUrl: "/admin/login",
    logoutUrl: "/admin/logout",
    forgotPasswordUrl: "/admin/password/request",
    resetPasswordUrl: "/admin/password/reset",

    sponsorResetPasswordUrl: "/sponsor/password/reset",
    caregiverResetPasswordUrl: "/caregiver/password/reset",

    //Sponsor
    sponsorRegisterUrl: "/sponsor/register",
    sponsorInitializePayment: "/sponsor/payments/paystack/initialize",
    sponsorSubscribe: "sponsor/subscriptions/subscribe",
    sponsorLogoutUrl: "sponsor/logout",

    //sponsor - admin
    getSponsorsUrl: "/sponsor",
    searchSponsorUrl: "/sponsor/search",

    //caregiver - admin
    getCaregiverUrl: "/caregiver",
    searchCaregiverUrl: "/caregiver/search",

    //service user -admin
    getServiceUsersUrl: "/service-user",
    searchServiceUserUrl: "/service-user/search",

    //activityLogs
    getActivityLogs: "/activity-logs",
    
    //dashboard data
    getDashboardDataUrl: "/dashboard-data",

    //Demo Request
    createDemoRequestUrl: "/demo-request/store",
    getDemoRequestsUrl: "/admin/demo-request",
    searchDemoRequestUrl: "/admin/demo-request/search",
    changeDemoRequestStatusUrl: "/admin/demo-request/change-status",

    //Meeting Request
    createMeetingRequestUrl: "/meeting-request/store",
    getMeetingRequestsUrl: "/admin/meeting-request",
    searchMeetingRequestUrl: "/admin/meeting-request/search",
    changeMeetingRequestStatusUrl: "/admin/meeting-request/change-status",

    //Contact Request
    createContactRequestUrl: "/contact-request/store",


    //admins
    getAdminsUrl: "/admin",
    createAdminUrl: "/admin/store",
    searchAdminUrl: "/admin/search",
    changeAdminStatusUrl: "/admin/update",
    changeAdminRoleUrl: "/admin/update/role",
    changeAdminPasswordUrl: "/admin/change-password",

    //roles
    getRolesUrl: "/role",
    createRoleUrl: "/role/store",
    searchRoleUrl: "/role/search",
    updateRoleUrl: "/role/update",
    
   

     //subscriptionPlans
     getSubscriptionPlansUrl: "/subscription-plans",
     showSubscriptionPlanUrl: "/admin/subscription-plans/show",
     createSubscriptionPlanUrl: "/admin/subscription-plans/store",
     deleteSubscriptionPlanUrl: "/admin/subscription-plans/delete",
     updateSubscriptionPlanUrl: "/admin/subscription-plans/update",


     
}