import authApi, {api} from "../../core/api/axios/baseAxios"
import { Routes } from "../../core/routes/apiRoutes";

const login = async (data) => {
    const response = await api.post(Routes.loginUrl, data);
    
    const resData = response.data;

    if(response.status === 200 && resData.status === 'success'){
        localStorage.setItem('user', JSON.stringify(resData.user));
        localStorage.setItem('token', resData.token);
    }

    return resData.user;
    
}

const forgotPassword = async (data) => {
    const response = await api.post(Routes.forgotPasswordUrl, data);
    
    const resData = response.data;

    return resData;
}

const resetPassword = async (token, data) => {
    const response = await api.post(Routes.resetPasswordUrl + `/${token}`, data);
    
    const resData = response.data;

    return resData;
}

const sponsorResetPassword = async (token, data) => {
    const response = await api.post(Routes.sponsorResetPasswordUrl + `/${token}`, data);
    
    const resData = response.data;

    return resData;
}

const caregiverResetPassword = async (token, data) => {
    const response = await api.post(Routes.caregiverResetPasswordUrl + `/${token}`, data);
    
    const resData = response.data;

    return resData;
}




const logout = async () => {
    const response = await authApi.post(Routes.logoutUrl);
    
    const resData = response.data;

    if(response.status === 200 && resData.status === 'success'){
        logoutLocal()
    }

    return resData;
    
}

const logoutLocal = async () => {
    localStorage.removeItem('user');
    localStorage.removeItem('token');  
}

const authService = {
    login,
    logout,
    logoutLocal,
    forgotPassword,
    resetPassword,
    sponsorResetPassword,
    caregiverResetPassword,
}

export default authService