import React from 'react';
import Icon1 from '../../assets/ficon_1.svg';
import Icon2 from '../../assets/ficon_2.svg';
import Icon3 from '../../assets/ficon_3.svg';
import Icon4 from '../../assets/ficon_4.svg';
import Icon5 from '../../assets/ficon_5.svg';
import parse from 'html-react-parser';
import './features.css';
import {Fade} from 'react-awesome-reveal';

const Features = () => {
  const featuresList = [
    {
      id: 1,
      icon: Icon1,
      name: "Download the app",
      desc:  `Download our app from the <br /> <a target="__blank" href='https://play.google.com/store/apps/details?id=com.greymatecare&pli=1' aria-label='get app from playstore'>Google Playstore</a> or <a href="https://apps.apple.com/app/greymate-care/id6444747049">App store</a>`,
    },

    {
      id: 2,
      icon: Icon2,
      name: "Select your preferred services",
      desc:  `Choose from a range of services and we’ll take it from there`,
    },

    {
      id: 3,
      icon: Icon3,
      name: "Build the caregiver profile",
      desc:  `Build the caregiver profile and fund your account to confirm the booking.`,
    },

    {
      id: 4,
      icon: Icon4,
      name: "We develop a care plan for you",
      desc:  `Our matching technology develops the care plan and matches you to the perfect caregiver`,
    },

    {
      id: 5,
      icon: Icon5,
      name: "Our care giver is assigned to you",
      desc:  `Sit back and relax as the caregiver shows up at your door`,
    }
  ];


  return (
    <div className='gmc__features section__padding'>
      <h2>How it works</h2>
      <p>Getting a Care Expert in your home has never <br/> been easier.</p>

      <div className='gmc__features-list'>
        {featuresList.map((item, index)=>(
       
          
       <Fade key={item.id} top>
        <div className='gmc__features-list-item' key={item.name}>
            <div className='gmc__features-list-item_image'>
              <img src={item.icon} alt={item.name}/>
            </div>
            <h4>{item.name}</h4>
            <p>{parse(item.desc)}</p>
          </div>
          </Fade>
        ))}
        
      </div>
    </div>
  )
}

export default Features