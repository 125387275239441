import React from 'react'
import { BrowserRouter, Routes, Route } from "react-router-dom";
import {
	BecomeCaregiver,
	Home,
	About,
	Pricing,
	FAQ,
	HomeCare,
	Employers,
	Donations,
	Terms,
	PrivacyPolicy,
	ContactUs,
	Login,
	Dashboard,
	Sponsors,
	CaregiversApplication,
	AssignCaregivers,
	ServiceUsers,
	Discount,
	Activity,
	Settings,
	TeamMembers,
	ForgotPassword,
	ResetPassword,
	SubscriptionPlans,
	CreateSubscriptionPlan,
	UpdateSubscriptionPlan,
	SponsorResetPassword,
	CaregiverResetPassword,
	PageNotFound,
	MeetingRequests,
	DemoRequests,
	Roles,

} from "./screens";
import { Admin, Main } from "./layouts";
import "./index.css";
import ScrollToTop from "./components/scroll_to_top/scrollToTop";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { RedirectIfAuth, RequireAuth } from './components';
import GetStarted from './screens/auth/get_started/GetStarted';
import SponsorHome from './screens/sponsor/SponsorHome';
import SuccessfulPayment from './screens/sponsor/SuccessfulPayment';


function App() {

	function authenticate(){
        return new Promise(resolve => setTimeout(resolve, 500)) // 2 seconds
    }

	React.useEffect(() => {
		authenticate().then(() => {
            const ele = document.getElementById('ipl-progress-indicator');
            const outer = document.getElementById('outter-element');
            if(ele){
              // fade out
              ele.classList.add('available')
              setTimeout(() => {
                // remove from DOM
                outer.innerHTML = '';
              }, 2000)
            }
          })
	}, [])
	
  return (
    <>
        <BrowserRouter>
			<ScrollToTop>
				<Routes>
					<Route path="/" >

						<Route element={<Main />}>
							<Route index element={<Home />} />
							<Route path="become-caregiver" element={<BecomeCaregiver />} />
							<Route path="about-us" element={<About />} />
							<Route path="pricing" element={<Pricing />} />
							<Route path="faqs" element={<FAQ />} />
							<Route path="home-care-emergencies" element={<HomeCare />} />
							<Route path="for-employers" element={<Employers />} />
							<Route path="csrs-and-donations" element={<Donations />} />
							<Route path="terms-and-conditions" element={<Terms />} />
							<Route path="privacy-policy" element={<PrivacyPolicy />} />
							<Route path="contact-us" element={<ContactUs />} />
						</Route>
						
						{/* Sponsor Routes */}
						<Route element={<RedirectIfAuth type="sponsor"/>}>
							<Route path="get-started" element={<GetStarted />} />
						</Route>

						<Route element={<RequireAuth type="sponsor"/>}>
							<Route path="sponsor/welcome" element={<SponsorHome />} />
						</Route>
						<Route path="sponsor/success" element={<SuccessfulPayment />} />
						<Route path="sponsor/reset-password/:token" element={<SponsorResetPassword />} />
						<Route path="caregiver/reset-password/:token" element={<CaregiverResetPassword />} />

						{/* Auth Routes */}
						<Route path="/admin" element={<Admin />}>
							<Route element={<RequireAuth/>}>
								<Route index element={<Dashboard />} />
								<Route path="caregivers/applications" element={<CaregiversApplication />} />
								<Route path="caregivers/assign" element={<AssignCaregivers />} />
								<Route path="sponsors" element={<Sponsors />} />
								<Route path="service-users" element={<ServiceUsers />} />
								<Route path="discount" element={<Discount />} />
								<Route path="activity" element={<Activity />} />

								<Route path="subscription-plans">
									<Route index element={<SubscriptionPlans />} />
									<Route path="create" element={<CreateSubscriptionPlan />} />
									<Route path="update/:slug" element={<UpdateSubscriptionPlan />} />
								</Route>

								<Route path="team-members">
									<Route index element={<TeamMembers />} />
								</Route>
								<Route path="roles">
									<Route index element={<Roles />} />
									
								</Route>
								<Route path="requests/demo-requests" element={<DemoRequests />} />
								<Route path="requests/meeting-requests" element={<MeetingRequests />} />
								
								
								<Route path="settings" element={<Settings />} />
							</Route>
						</Route>
						<Route path="/admin">
							<Route element={<RedirectIfAuth/>}>
								<Route path="login" element={<Login />} />
								<Route path="forgot-password" element={<ForgotPassword />} />
								<Route path="reset-password/:token" element={<ResetPassword />} />
							</Route>
						</Route>
					</Route>
					<Route path="*" element={<PageNotFound />} />
				</Routes>
			</ScrollToTop>
		</BrowserRouter>
        <ToastContainer theme="colored" />
        
    </>
  )
}

export default App