import React from 'react';
import './header.css';
import BannerHome from '../../assets/banner_home.svg';
import BGHeader from '../../assets/bg-header.png';
import { Link } from 'react-router-dom';
import {Fade} from 'react-awesome-reveal';

const Header = ({currency,
  exchangeRateResponse}) => {

    const curr = currency??"NGN";
    const amount = exchangeRateResponse?.rates?(2500 * exchangeRateResponse?.rates[curr]):2500;
  return (
    <div className='gmc__header section__padding' id='home' style={{ 
      backgroundImage: `url(${BGHeader})` 
    }}>
      <div className='gmc__header-content'>
        <Fade top>
          <h1>Care for your <br/>parents</h1>
        </Fade>
        <Fade top> <p>From as little as {Intl.NumberFormat('en-US', { style: 'currency', currency: curr, currencyDisplay: 'narrowSymbol', minimumFractionDigits: 0, maximumFractionDigits: 0 }).format(amount)}</p></Fade>
        <Fade top><Link to="/get-started" className='button__primary'>Find a Caregiver</Link></Fade>
      </div>
      <Fade right><div className='gmc__header-image'>
        <img src={BannerHome} alt="Home"/>
        <div className='gmc__header-image-desc-1'>
          <p>We are fast changing the frontiers on home health in Africa</p>
        </div>

        <div className='gmc__header-image-desc-2'>
          <p>We care for your loved ones</p>
        </div>
      </div>
      </Fade>
    </div>
  )
}

export default Header