import React from 'react'

import {FormPanelBodyHeader, FormPanelBodySection, FormPanelBodySectionHeader, FormPanelButton, FormPanelControl, FormPanelFooter } from '../../../styles/FormPanel.Styled';
import { useSponsorFormState } from "../../../core/context/SponsorFormContext";
import { useForm } from "react-hook-form";
import { FormChipMultiSelect } from '../../../components/custom_form_component/CustomFormComponent';
import { Flex } from '../../../styles/admin/DetailsPanel';

const careList = [
    {label: 'Nursing Care', value: "nursing care"},
    {label: 'Occupational Therapy', value: "occupational therapy"},
    {label: 'Speech Therapy', value: "speech therapy"},
    {label: 'Home Doctor', value: "home doctor"},
    {label: 'Physiotherapy', value: "physiotherapy"},
    {label: 'Medication Delivery', value: "medication delivery"},
    {label: 'Tele-consult a Doctor', value: "tele-consult a doctor"},
    {label: 'Home Test', value: "home test"},
];

const comeWithItems = [
    {label: 'Adult Diapers', value: "adult diapers"},
    {label: 'BP testing', value: "bp testing"},
    {label: 'Thermometer', value: "thermometer"},
];
function Extra(props) {
    const {
		state: {
			preference: {
				extra_service,
				extra_items,
			},
		},
		dispatch,
	} = useSponsorFormState();

	const {
		handleSubmit,
		control,
		formState: { errors },
	} = useForm({
		defaultValues: {
			extra_service: extra_service,
			extra_items:  extra_items,
		},
	});

    const onSubmit = (data) => {
	    dispatch({ type: "UPDATE_PREFERENCE_DATA", payload: data });
		props.onSaveAndPay();
	};

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <FormPanelBodyHeader>
                <h1>Extra</h1>
                <p>Please note that these services are not covered in the fees paid, and will be charged seperately.</p>
            </FormPanelBodyHeader>
            
            <FormPanelBodySection>
                <FormPanelBodySectionHeader>
                    <h2>Which of these do you need?</h2>
                </FormPanelBodySectionHeader>
                <FormPanelControl>
                <FormChipMultiSelect
						name="extra_service"
                        isSmall={true}
						options={careList}
						label="Extra Services Needed"
						rules={{}}
						control={control}
						errors={errors}
					/>
                </FormPanelControl>
            </FormPanelBodySection>

            <FormPanelBodySection>
                <FormPanelBodySectionHeader>
                    <h2>Would you like us to come with any of the following?</h2>
                </FormPanelBodySectionHeader>
                <FormPanelControl>
                    <FormChipMultiSelect
						name="extra_items"
                        isSmall={true}
						options={comeWithItems}
						label="Extra Items"
						rules={{}}
						control={control}
						errors={errors}
					/>
                </FormPanelControl>
            </FormPanelBodySection>
            <FormPanelControl>
                <label>
                    By clicking the “Create your account” button, you agree to Greymate
                    Care terms of acceptable use.
                </label>
            </FormPanelControl>
            <FormPanelFooter>
				<Flex justifyContent="space-between">
					<FormPanelButton secondary onClick={props.onPrevPage}>
						Back
					</FormPanelButton>
					<FormPanelButton type="submit">Create Your Account</FormPanelButton>
				</Flex>
			</FormPanelFooter>
        </form>
    )
}

export default Extra