import React from 'react'
import { FormPanelPlan, FormPanelPlanDesc, FormPanelPlanDetails, FormPanelPlanLink, FormPanelPlanSubtitle, FormPanelPlanTitle, FormPanelPlanWrapper } from '../../styles/FormPlanCard.Styled'

function FormPricingPlan(props) {

  const currency = props.currency ?? "NGN";
  const amount = props.rates?(props.plan.amount * props.rates[currency]):props.plan.amount;
  const period = props.plan.period;
  
  return (
    <FormPanelPlan className={props.plan.selected?'active':''}>
        <FormPanelPlanWrapper>
          <FormPanelPlanDetails>
            <div>
                <img src={props.plan.icon} alt={props.plan.name}/>
            </div>
            <FormPanelPlanSubtitle>{props.plan.name}</FormPanelPlanSubtitle>
            <FormPanelPlanTitle>{Intl.NumberFormat('en-US', { style: 'currency', currency: currency, currencyDisplay: 'narrowSymbol', minimumFractionDigits: 0, maximumFractionDigits: 0 }).format(amount)}<span> /{period}</span></FormPanelPlanTitle>
            <FormPanelPlanDesc>{props.plan.description}</FormPanelPlanDesc>
          </FormPanelPlanDetails>
          <FormPanelPlanLink onClick={props.onPlanButton}>Choose {props.plan.name}</FormPanelPlanLink>
        </FormPanelPlanWrapper>
    </FormPanelPlan>
  )
}

export default FormPricingPlan