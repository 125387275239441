import authApi from "../../core/api/axios/baseAxios"

const getDashboardData = async (url) => {
    const response = await authApi.get(url);
    const resData = response.data;
    return resData;
}

const dashboardService = {
    getDashboardData,
}

export default dashboardService