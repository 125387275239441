import axios from "axios";
import { Routes } from "../../routes/apiRoutes";
import { reqInterceptor, sponsorReqInterceptor } from "../interceptor/RequestInterceptor";

export const api = axios.create({
  baseURL: Routes.baseUrl,
  headers: {
    'Accept': 'application/json',
    'Content-Type': 'application/json',
  }
});

const authApi = axios.create({
  baseURL: Routes.baseUrl,
  headers: {
    'Accept': 'application/json',
    'Content-Type': 'application/json',
  }
});

export const sponsorAuthApi = axios.create({
  baseURL: Routes.baseUrl,
  headers: {
    'Accept': 'application/json',
    'Content-Type': 'application/json',
  }
});

authApi.interceptors.request.use(reqInterceptor, (err) =>
  console.log(err)
);

sponsorAuthApi.interceptors.request.use(sponsorReqInterceptor, (err) =>
  console.log(err)
);



export default authApi;

