import { useState,useRef, useEffect } from 'react'
import {useDispatch } from 'react-redux'
import {toast} from 'react-toastify'

const useResource = ({
    selector,
    getReducer,
    resetReducer,
    deleteReducer,
    renderTableData,
    perPage,
    routes: {
        getRoute,
    }
}) => {
    const [needsReload, setNeedsReload] = useState(false);
    const [isSearchData, setIsSearchData] = useState(false);
    const [tableData, setTableData] = useState([]);
    const [searchTableData, setSearchTableData] = useState([]);
    const [rawData, setRawData] = useState([]);
    const [page, setPage] = useState(0);
    const ref = useRef(null)
    const dispatch = useDispatch();

    const {
        data,
        isError,
        isLoading,
        isSuccess,
        message
    } = selector;


    useEffect(() => {
        if (isError) {
            toast.error(message)
            ref.current.complete()
        }

        if (isSuccess) {
            ref.current.complete();
        }

        if (isSuccess && needsReload) {
            ref.current.continuousStart();
            setNeedsReload(false);
            dispatch(getReducer(getRoute));
        }

        if (isSuccess && message) {
            toast.success(message);
        }

        if (isSuccess && data) {
            prepareTableData(data);
            setRawData(data);
        }

        dispatch(resetReducer())

        // eslint-disable-next-line
    }, [data, isError, isSuccess, message, dispatch])

    const prepareTableData = (response) => {
        if(isSearchData){
            setSearchTableData(renderTableData(response, page));
        }else {
            setTableData(renderTableData(response, page));
        }
        
    }

    useEffect(() => {
        ref.current.continuousStart();
        dispatch(getReducer(getRoute));
        // eslint-disable-next-line
    }, [dispatch]);




    const onNextPage = () => {
        if(!isLoading){
            if (rawData?.links.next) {
                ref.current.continuousStart();
                setPage(page + perPage);
                dispatch(getReducer(rawData.links.next));
            }
        }
    }

    const onPrevPage = () => {
        if(!isLoading){
            if (rawData?.links.prev) {
                ref.current.continuousStart();
                setPage(page - perPage)
                dispatch(getReducer(rawData.links.prev));
            }
        }
    }

    const onDelete = (id) => {
        ref.current.continuousStart();
        setNeedsReload(true);
        dispatch(deleteReducer(id));
    }

    const onSearch = (value) => {
        if(value.length > 3){
            ref.current.continuousStart();
            setIsSearchData(true);
            dispatch(getReducer(getRoute+"/search?q="+encodeURI(value)));
        }else {
            setSearchTableData([]);
            setIsSearchData(false);
        }
	};

    return {
        page: page,
        onPrevPage: onPrevPage,
        onNextPage: onNextPage,
        onDelete: onDelete,
        tableData: isSearchData?searchTableData:tableData,
        ref: ref,
        perPage: perPage,
        isLoading: isLoading,
        onSearch: onSearch,
        setNeedsReload: setNeedsReload,
    };
};

export default useResource;