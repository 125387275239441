import * as React from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import parse from "autosuggest-highlight/parse";
import throttle from "lodash/throttle";
import { styled } from "@mui/system";

const StyledAutoComplete = styled(Autocomplete, {})`
	font-family: var(--font-family);
	width: 100%;
	margin-top: 0.3rem;
	font-size: 14px;
	line-height: 20px;
	outline: none;
	border: 1px solid #edf2f7;
	border-radius: 4px;
	color: rgba(60, 83, 102, 1);
	background: #f7f9fc;

	& .MuiInput-input {
		
	}

	& .MuiOutlinedInput-notchedOutline  {
		border: none;
		
	}

	& input {
		display: block;
		padding: 1rem !important;
		border: none !important;
		border-radius: 4px;
		color: rgba(60, 83, 102, 1);
		background: #f7f9fc;
		
	}

	& label {
		display: block;
		font-size: 16px;
		line-height: 20px;
		color: #3c5366;
		margin-bottom: 0.7rem;
	}

	& .MuiAutocomplete-endAdornment {
		width: 18px;
		right: 1rem;
		color: #3c5366;
	}

	&::before,
	&:hover::before {
		border: none;
	}
`;


const GOOGLE_MAPS_API_KEY = "AIzaSyDDulenqyZgmWJtEYvtRte4BT9mgclibtY";

function loadScript(src, position, id) {
	if (!position) {
		return;
	}

	const script = document.createElement("script");
	script.setAttribute("async", "");
	script.setAttribute("id", id);
	script.src = src;
	position.appendChild(script);
}

const autocompleteService = { current: null };
const geocoderService = { current: null };

export default function GoogleAutoComplete({placeholder, value: propsValue, label, onChange}) {
	const [value, setValue] = React.useState(null);
	const [inputValue, setInputValue] = React.useState(propsValue?.address??'');
	const [options, setOptions] = React.useState([]);
	const loaded = React.useRef(false);

	if (typeof window !== "undefined" && !loaded.current) {
		if (!document.querySelector("#google-maps")) {
			loadScript(
				`https://maps.googleapis.com/maps/api/js?key=${GOOGLE_MAPS_API_KEY}&libraries=places`,
				document.querySelector("head"),
				"google-maps"
			);
		}

		loaded.current = true;
	}

	const fetch = React.useMemo(
		() =>
			throttle((request, callback) => {
				request.componentRestrictions = {country: 'ng'};
				autocompleteService.current.getPlacePredictions(request, callback);
			}, 200),
		[]
	);

	const fetchPlace = React.useMemo(
		() =>
			throttle((request, callback) => {
				geocoderService.current.geocode(request, callback);
			}, 200),
		[]
	);

	React.useEffect(() => {
		let active = true;

		if (!autocompleteService.current && window.google) {
			autocompleteService.current =
				new window.google.maps.places.AutocompleteService();
		}

		if (!geocoderService.current && window.google) {
			geocoderService.current =
				new window.google.maps.Geocoder();
		}
		if (!autocompleteService.current) {
			return undefined;
		}

		if (!geocoderService.current) {
			return undefined;
		}

		if (inputValue === "") {
			setOptions(value ? [value] : []);
			return undefined;
		}

		fetch({ input: inputValue }, (results) => {
			if (active) {
				let newOptions = [];

				if (value) {
					newOptions = [value];
				}

				if (results) {
					newOptions = [...newOptions, ...results];
				}

				setOptions(newOptions);
			}
		});

		return () => {
			active = false;
		};
	}, [value, inputValue, fetch]);

	return (
		<StyledAutoComplete
			getOptionLabel={(option) =>
				typeof option === "string" ? option : option.description
			}
			filterOptions={(x) => x}
			options={options}
			autoComplete
			includeInputInList
			filterSelectedOptions
			value={value}
			onChange={(event, newValue, reason) => {
				setOptions(newValue ? [newValue, ...options] : options);
				setValue(newValue);
				if(reason === 'selectOption'){
					fetchPlace({ placeId: newValue.place_id }, (results, status) => {
						if (status === 'OK') {
							if(results.length > 0 && results[0].geometry !== null && results[0].geometry.location !== null){
								const result = results[0];
								onChange({
									address: newValue.description, 
									lat: result.geometry.location.lat(),
									lng: result.geometry.location.lng()
								})
							}
							
						}
					});
					
				}
			}}
			onInputChange={(event, newInputValue) => {
				setInputValue(newInputValue);
			}}
			renderInput={(params) => (
				<TextField variant="standard" {...params}   InputLabelProps={{
					shrink: true,
				}}
				
				InputProps={{
					disableUnderline: true,
					...params.InputProps
				}} value={inputValue} fullWidth />
			)}
			renderOption={(props, option) => {
				const matches =
					option.structured_formatting.main_text_matched_substrings;
				const parts = parse(
					option.structured_formatting.main_text,
					matches.map((match) => [match.offset, match.offset + match.length])
				);

				return (
					<li {...props}>
						<Grid container alignItems="center">
							<Grid item>
								<Box
									component={LocationOnIcon}
									sx={{ color: "text.secondary", mr: 2 }}
								/>
							</Grid>
							<Grid item xs>
								{parts.map((part, index) => (
									<span
										key={index}
										style={{
											fontWeight: part.highlight ? 700 : 400,
										}}
									>
										{part.text}
									</span>
								))}

								<Typography variant="body2" color="text.secondary">
									{option.structured_formatting.secondary_text}
								</Typography>
							</Grid>
						</Grid>
					</li>
				);
			}}
		/>
	);
}
