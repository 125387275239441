import moment from 'moment';
import React from 'react'
import { getAssignCaregiversBadge } from '../../misc/helpers';

import { DetailsBody, DetailsHeader, DetailsHeaderDesc, DetailsLabel, DetailsList, DetailsListWrapper, DetailsPanelStyled, DetailsTab, DetailsTabPanel, DetailsTabs, DetailsTabsHeader, DetailsValue, Flex } from '../../styles/admin/DetailsPanel';
import { ModalTable } from '../../styles/admin/Table.styled';
import Badge from '../badge/Badge';

function ServiceUserPanel(props) {
    const badge = getAssignCaregiversBadge(props.data.status);

  return (
    <DetailsPanelStyled>
        <DetailsHeader>
            <Flex>
                {/* <DetailsHeaderImage>
                    <img src={`https://i.pravatar.cc/150?u=${props.data.email}`} alt={props.data.sponsorsName} />
                </DetailsHeaderImage> */}
                <DetailsHeaderDesc>
                    <h3>{props.data.name}</h3>
                    <p>{props.data.email}</p>
                </DetailsHeaderDesc>
            </Flex>
            <div>
                <Badge {...badge.badgeType}>{badge.badgeText}</Badge>
            </div>
        </DetailsHeader>
        <DetailsBody>
            <DetailsTabs defaultValue={0}>
                <DetailsTabsHeader>
                    <DetailsTab first='true'>Personal Info</DetailsTab>
                    {props.data.status === "assigned" && <DetailsTab>Assigned Caregiver</DetailsTab>}
                    <DetailsTab last="true">Payment History</DetailsTab>
                </DetailsTabsHeader>

                <DetailsTabPanel value={0}>
                    <DetailsListWrapper>
                        {Object.keys(props.data.profile).map((key) => 
                            <DetailsList key={props.data.profile[key]}>
                                <DetailsLabel>
                                    <span>{key}</span>
                                </DetailsLabel>
                                <DetailsValue>
                                    <span>{props.data.profile[key]}</span>
                                </DetailsValue>
                            </DetailsList>
                        )}
                    </DetailsListWrapper>


                </DetailsTabPanel>
                {props.data.status === "assigned" && 
                <DetailsTabPanel value={1}>
                    {Object.keys(props.data.caregiver).map((key) => 
                        <DetailsList key={props.data.caregiver[key]}>
                            <DetailsLabel>
                                <span>{key}</span>
                            </DetailsLabel>
                            <DetailsValue>
                                <span>{props.data.caregiver[key]}</span>
                            </DetailsValue>
                        </DetailsList>
                    )}
                </DetailsTabPanel>
                }
                
                <DetailsTabPanel value={props.data.status === 'assigned' ?2:1}>
                <ModalTable>
                    <thead>
                        <tr>
                            <th>Date</th>
                            <th>Amount</th>
                            <th>Status</th>
                            <th>Description</th>
                            {/* <th>Download</th> */}
                        </tr>
                    </thead>
                    <tbody>
                    {props.data.paymentHistory.map((payment, index) => 
                        
                        <tr key={index}>
                            <td>{moment.unix(payment["paid_at"]).fromNow()}</td>
                            <td>{payment["amount"]}</td>
                            <td><Badge success>Success</Badge></td>
                            <td>{payment["comment"]}</td>
                            {/* <td><a href={payment["Download"]}>Download</a></td> */}
                        </tr>
                    )}
                    </tbody>
                </ModalTable>
                    
                </DetailsTabPanel>
            </DetailsTabs>
        </DetailsBody>
        
    </DetailsPanelStyled>
  )
}

export default ServiceUserPanel