export const AppRoutes = {
    //Guest
    HomeURL: "/",
    LoginURL: "/admin/login",
    AdminUrl: "/admin",
    ForgotPasswordURL: "/admin/forgot-password",
    ResetPasswordURL: "/admin/reset-password",

    //Admin
    changeMemberStatusUrl: "change-status",

     //SubscriptionPlan
     updateSubscriptionPlanUrl: "update",

    //Sponsor
    SponsorUrl: "/sponsor/welcome",
    SponsorRegisterUrl: "/get-started",
}