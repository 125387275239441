import React, {Component} from 'react'
import { CTA } from '../../components';
import './howto.css'
import {Fade} from 'react-awesome-reveal';

import {ReactComponent as CheckCircle} from '../../assets/check_circle.svg'


export default class HowTo extends Component {

    render() {
        return (
             <div id="become-caregiver" className='gmc__howto section__padding'>
                <div className='gmc__howto-content'>
                    <Fade top><h2>Become a Caregiver</h2></Fade>
                    <Fade top><p>Join us in changing the frontiers of home health in Africa. Be in charge of your work schedule as you sign up for the most fulfilling and impactful time of your career. All you need is a willingness to learn and a compassionate personality.</p></Fade>
                    <Fade top><CTA/></Fade>
                </div>
                <div className='gmc__howto-instructions'>
                     <Fade right><div className='gmc__howto-instructions_item'>
                        <div className='gmc__howto-instructions_item-image'>
                            <CheckCircle/>
                        </div>
                        <div className='gmc__howto-instructions_item-content'>
                            <h4>Download the app</h4>
                            <p>Download the app, sign up, ace your interview with the hiring team and earn the Greymate Care badge and certifications upon successful completion.</p>
                        </div>
                    </div></Fade>

                    <Fade right><div className='gmc__howto-instructions_item'>
                        <div className='gmc__howto-instructions_item-image'>
                            <CheckCircle/>
                        </div>
                        <div className='gmc__howto-instructions_item-content'>
                            <h4>Pass the checks</h4>
                            <p>Pass the criminal/reference background checks and health screenings</p>
                        </div>
                    </div></Fade>

                    <Fade right><div className='gmc__howto-instructions_item'>
                        <div className='gmc__howto-instructions_item-image'>
                            <CheckCircle/>
                        </div>
                        <div className='gmc__howto-instructions_item-content'>
                            <h4>Get Matched</h4>
                            <p>Relax as you get matched with a service user that fits your profile.</p>
                        </div>
                    </div></Fade>
                </div>
             </div>
        );
    }
}