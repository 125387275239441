import React from "react";
import { ReactComponent as S4 } from "../../../assets/s4.svg";
import { ReactComponent as Bathing } from "../../../assets/bathing.svg";
import { ReactComponent as Bed } from "../../../assets/bed.svg";
import { ReactComponent as Companionship } from "../../../assets/companionship.svg";
import { ReactComponent as Dressing } from "../../../assets/dressing.svg";
import { ReactComponent as Exercise } from "../../../assets/exercise.svg";
import { ReactComponent as Housekeeping } from "../../../assets/housekeeping.svg";
import { ReactComponent as Meal } from "../../../assets/meal.svg";
import { ReactComponent as Memory } from "../../../assets/memory.svg";
import { ReactComponent as Toileting } from "../../../assets/toileting.svg";
import { ReactComponent as Transferring } from "../../../assets/transferring.svg";
import { ReactComponent as Transportation } from "../../../assets/transportation.svg";
import { ReactComponent as Groceries } from "../../../assets/groceries.svg";
import {
	FormPanelBodyHeader,
	FormPanelBodySection,
	FormPanelBodySectionHeader,
	FormPanelButton,
	FormPanelControl,
	FormPanelFooter,
} from "../../../styles/FormPanel.Styled";
import { Flex } from "../../../styles/admin/DetailsPanel";
import { useSponsorFormState } from "../../../core/context/SponsorFormContext";
import {
	FormChipMultiSelect,
	FormDropdownField,
	FormInputField,
} from "../../../components/custom_form_component/CustomFormComponent";
import { useForm } from "react-hook-form";

function SponsorProfile(props) {
	const relationships = [
		{ label: "My mother",  value: "my mother" },
		{ label: "My father",  value: "my father" },
		{ label: "My mother-in-law",  value: "my mother-in-law" },
		{ label: "My father-in-law",  value: "my father-in-law" },
		{ label: "My grandmother",  value: "my grandmother" },
		{ label: "My grandfather",  value: "my grandfather" },
		{ label: "My wife",  value: "my wife" },
		{ label: "My husband",  value: "my husband" },
		{ label: "My daughter",  value: "my daughter" },
		{ label: "My son",  value: "my son" },
		{ label: "My sister",  value: "my sister" },
		{ label: "My brother",  value: "my brother" },
		{ label: "Myself (female)",  value: "myself (female)" },
		{ label: "Myself(male)",  value: "myself(male)" },
		{ label: "My friend (female)",  value: "my friend (female)" },
		{ label: "My friend (male)",  value: "my friend (male)" },
		{ label: "CSR/Donation to a group",  value: "msr/donation to a group" },
		{ label: "Others", value: "others" },
	];

	const services = [
		{ label: "Housekeeping", value: "housekeeping", icon: <Housekeeping/> },
		{ label: "Companionship", value: "companionship", icon: <Companionship/> },
		{ label: "Meal preparation", value: "meal preparation", icon: <Meal/> },
		{ label: "Medication mgt", value: "medication mgt", icon: <S4/> },
		{ label: 'Bathing', value: 'bathing', icon: <Bathing/> },
		{ label: 'Dressing and Grooming', value: 'dressing and grooming', icon: <Dressing/> },
		{ label: 'Exercise', value: 'exercise', icon: <Exercise/> },
		{ label: 'Bed bath', value: 'bed bath', icon: <Bed/> },
		{ label: 'Groceries and shopping', value: 'groceries and shopping', icon: <Groceries/> },
		{ label: 'Light housekeeping', value: 'light housekeeping', icon: <Housekeeping/> },
		{ label: 'Medication reminders', value: 'medication reminders', icon: <S4/> },
		{ label: 'Memory Exercise', value: 'memory exercise', icon: <Memory/> },
		{ label: 'Toileting/Incontinence Care', value: 'toileting/incontinence care', icon: <Toileting/> },
		{ label: 'Transferring and Mobility', value: 'transferring and mobility', icon: <Transferring/> },
		{ label: 'Transportation', value: 'transportation', icon: <Transportation/> },
	];

    const conditions = [
		{ label: "Stroke", value: "stroke"},
		{ label: "Cancer", value: "cancer" },
		{ label: "Parkinson's", value: "parkinson's"},
		{ label: "Diabetes", value: "diabetes" },
		{ label: "Dementia", value: "dementia" },
		{ label: "Alzheimer's ", value: "alzheimer's" },
		{ label: "Others", value: "others" },
	];

	const {
		state: {
			service_user: {
				relationship,
				special_conditions,
                services: formServices,
				other_conditions,
			},
		},
		dispatch,
	} = useSponsorFormState();

	const {
		handleSubmit,
		control,
		formState: { errors },
	} = useForm({
		defaultValues: {
			relationship: relationship,
			services: formServices,
			special_conditions: special_conditions,
			other_conditions: other_conditions,
		},
	});

	const onSubmit = (data) => {
		dispatch({ type: "UPDATE_SERVICE_USER_DATA", payload: data });
		props.onNextPage();
	};

	return (
		<form onSubmit={handleSubmit(onSubmit)}>
			<FormPanelBodyHeader>
				<h1>
					Kindly fill the form below to <br /> become a sponsor
				</h1>
			</FormPanelBodyHeader>

			<FormPanelBodySection>
				<FormPanelBodySectionHeader>
					<h2>Personal Details</h2>
				</FormPanelBodySectionHeader>
				<FormPanelControl>
					<FormDropdownField
						name="relationship"
						label="Who needs care?"
						options={relationships}
						control={control}
						placeholder="Choose who needs care?"
						rules={{
							required: {
								value: "required",
								message:
									"Please select your relationship with the service user",
							},
						}}
						errors={errors}
					/>
				</FormPanelControl>
				
			</FormPanelBodySection>

			<FormPanelBodySection>
				<FormPanelBodySectionHeader>
					<h2>Select the services you need?</h2>
				</FormPanelBodySectionHeader>
				<FormPanelControl>
					<FormChipMultiSelect
						name="services"
                      
						options={services}
						rules={{
							required: {
								value: "required",
								message:
									"Please the services you require",
							},
						}}
						placeholder="Select your services"
						control={control}
						errors={errors}
					/>
				</FormPanelControl>
			</FormPanelBodySection>
			<FormPanelBodySection>
				<FormPanelBodySectionHeader>
					<h2>Any special conditions?</h2>
				</FormPanelBodySectionHeader>
				<FormPanelControl>
                    <FormChipMultiSelect
						name="special_conditions"
                        isSmall={true}
						options={conditions}
						label="Any Special Conditions"
						rules={{}}
						control={control}
						errors={errors}
					/>
				</FormPanelControl>
				<FormPanelControl>
                    <FormInputField
                            name="other_conditions"
                            label="Other Conditions"
                            type="text"
                            placeholder="Enter more conditions separating them with a comma."
                            multiline={true}
                            maxRows={4}
                            rules={{}}
                            control={control}
                            errors={errors}
                        />
				</FormPanelControl>
			</FormPanelBodySection>
			<FormPanelFooter>
                <Flex justifyContent="space-between">
                <FormPanelButton secondary onClick={props.onPrevPage}>Back</FormPanelButton>
				<FormPanelButton type="submit">
					Save and Continue
				</FormPanelButton>
                </Flex>
				
			</FormPanelFooter>
		</form>
	);
}

export default SponsorProfile;
