import React from 'react'
import { ActionLink, ActivityCardStyled, ActivityDesc, ActivityDetail, ActivityIcon } from '../../styles/admin/ActivityCard.styled'

function ActivityCard(props) {
  return (
    <>
        <ActivityCardStyled>
            <ActivityDetail>
                <ActivityIcon>
                {props.icon}
                </ActivityIcon>
                <ActivityDesc>
                    <span>{props.date}</span>
                    <h4>{props.title}</h4>
                    <p>{props.desc}</p>
                </ActivityDesc>
            </ActivityDetail>
            <ActionLink>
                {props.href && <a href={props.href}>View</a>}
            </ActionLink>
        </ActivityCardStyled>
    </>
  )
}

export default ActivityCard