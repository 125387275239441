import React from 'react'
import './testimonial.css'
import MapBG from '../../assets/bg-map.png'

function Testimonial() {
    return (
        <div className='gmc__testimonial section__padding' style={{ background: `url(${MapBG}), linear-gradient(180deg, #F7F7FB 0%, rgba(247, 247, 251, 0) 16.05%)`,}}>
          <div className='gmc__testimonial-header'>
              <h2>We cared for their parents <br/> and they love us</h2>
              <p>Maria has been a Greymate Care customer for over a year. She talks about her experience using our service and app.</p>
          </div>
          <div className='gmc__testimonial-video' >
            <div className='gmc__testimonial-video-wrapper'>

            <iframe className="video-iframe"  src="https://www.youtube.com/embed/pr6dj7SUdhw" title="YouTube video player" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
            </div>
          </div>
          
        </div>
      )
}

export default Testimonial
