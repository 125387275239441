import {createSlice, createAsyncThunk} from '@reduxjs/toolkit'
import { Routes } from '../../core/routes/apiRoutes'
import roleService from './roleService'

const initialState = {
    data: null,
    isError: false,
    isSuccess: false,
    isLoading: false,
    isCreateRole: false,
    message: '',
    errors: null,
}

//Get - Requires Admin Authentication
export const getRoles = createAsyncThunk(Routes.getRolesUrl, async ( url, thunkAPI) => {
    try{
        return await roleService.getRoles(url);
    }catch (error) {
        var errorData = {message: (error.response && error.response.data && error.response.data.message) || error.message || error.toString()}
        if(error.response.status === 422){
            errorData['errors'] = error.response.data.errors;
            
        }
        return thunkAPI.rejectWithValue(errorData);
    }
});

//create role
export const createRole = createAsyncThunk(Routes.createRoleUrl, async ( formData, thunkAPI) => {
    try{
        return await roleService.createRole(formData);
    }catch (error) {
        var errorData = {message: (error.response && error.response.data && error.response.data.message) || error.message || error.toString()}
        if(error.response.status === 422){
            errorData['errors'] = error.response.data.errors;
            
        }
        return thunkAPI.rejectWithValue(errorData);
    }
});

//delete role
export const deleteRole = createAsyncThunk(Routes.getRolesUrl+"/delete", async ( id, thunkAPI) => {
    try{
        return await roleService.deleteRole(id);
    }catch (error) {
        var errorData = {message: (error.response && error.response.data && error.response.data.message) || error.message || error.toString()}
        if(error.response.status === 422){
            errorData['errors'] = error.response.data.errors;
            
        }
        return thunkAPI.rejectWithValue(errorData);
    }
});

//update role
export const updateRole = createAsyncThunk(Routes.updateRoleUrl, async (data, thunkAPI) => {
    try{
        return await roleService.updateRole(data);
    }catch (error) {
        var errorData = {message: (error.response && error.response.data && error.response.data.message) || error.message || error.toString()}
        if(error.response.status === 422){
            errorData['errors'] = error.response.data.errors;
            
        }
        return thunkAPI.rejectWithValue(errorData);
    }
});

export const roleSlice = createSlice({
    name: 'roles',
    initialState,
    reducers: {
        reset: (state) => {
            state.isLoading = false;
            state.isSuccess = false;
            state.isError = false;
            state.isCreateRole = false;
            state.message = '';
            state.errors = null;
            state.data = null;
        }
    }, 
    extraReducers: (builder) => {
        function sharedRejectedReducer(state, action){
            state.isLoading = false
            state.isError = true
            state.message = action.payload.message
            state.errors = action.payload.errors
        }
        function sharedPendingReducer(state, action){
            state.isLoading = true
        }

        builder

        .addCase(getRoles.pending,sharedPendingReducer)
        .addCase(getRoles.rejected, sharedRejectedReducer)
        .addCase(createRole.pending,sharedPendingReducer)
        .addCase(createRole.rejected, sharedRejectedReducer)
        .addCase(deleteRole.pending,sharedPendingReducer)
        .addCase(deleteRole.rejected, sharedRejectedReducer)
        .addCase(updateRole.pending,sharedPendingReducer)
        .addCase(updateRole.rejected, sharedRejectedReducer)

         //Get
         .addCase(getRoles.fulfilled, (state,action) => {
            state.isLoading = false
            state.isSuccess = true
            state.isError = false
            state.data = action.payload
            state.errors = null

        })

       //Create Admin
       .addCase(createRole.fulfilled, (state,action) => {
            state.isLoading = false
            state.isSuccess = true
            state.isError = false
            state.message = action.payload.message
            state.isCreateRole = true
            state.errors = null
        })

        //Delete Admin
        .addCase(deleteRole.fulfilled, (state,action) => {
            state.isLoading = false
            state.isSuccess = true
            state.message = action.payload.message
            state.isError = false
            state.errors = null
        })

        //change Status
        .addCase(updateRole.fulfilled, (state,action) => {
            state.isLoading = false
            state.isSuccess = true
            state.message = action.payload.message
            state.isError = false
            state.errors = null
        })
    }
})

export const {reset} = roleSlice.actions
export default roleSlice.reducer