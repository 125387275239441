

const emailValidator = (email) => {
    
    const isValid =// eslint-disable-next-line no-useless-escape
    /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
        email
    );;
    
    if (!isValid) {
        
        return "Not a valid email address";
    }

    return isValid;
};

const phoneValidator = (email) => {
    
    const isValid =// eslint-disable-next-line no-useless-escape
    /^(?:(?:(?:\+?234(?:1)?|01)*)?(?:\(\d{3}\)|\d{3})|\d{4})(?:\W*\d{3})?\W*\d{4}$/.test(
        email
    );;
    
    if (!isValid) {
        
        return "Not a valid phone number";
    }

    return isValid;
};

const formValidator = {
    emailValidator,
    phoneValidator
};

export default formValidator;