import { useState,useRef, useEffect } from 'react'
import {useDispatch } from 'react-redux'
import {toast} from 'react-toastify'

const useGetResource = ({
    id,
    selector,
    resetReducer,
    getReducer,
    parseData,
}) => {
    const [responseData, setResponseData] = useState([]);
    const ref = useRef(null)
    const dispatch = useDispatch();

    const {
        data,
        isError,
        isLoading,
        isSuccess,
        message
    } = selector;


    useEffect(() => {
        if (isError) {
            toast.error(message)
            ref.current.complete()
        }

        if (isSuccess) {
            ref.current.complete();
        }

        if (isSuccess && message) {
            toast.success(message);
        }

        if (isSuccess && data) {
            prepareTableData(data);
        }

        dispatch(resetReducer())
        // eslint-disable-next-line
    }, [data, isError, isSuccess, message])

    const prepareTableData = (response) => {
        setResponseData(parseData(response));
    }

    useEffect(() => {
        ref.current.continuousStart();
        dispatch(getReducer(id));
        
    }, [dispatch, id, getReducer]);

    return {
        responseData: responseData,
        ref: ref,
        isLoading: isLoading,
    };
};

export default useGetResource;