import { Link } from "react-router-dom";
import React from "react";
import logo from "../../assets/logo.png";
import {
	FormPanel,
	FormPanelBody,
	FormPanelHeader,
	FormPanelSuccess,
	FormPanelWrapper,
} from "../../styles/FormPanel.Styled";
import Success from '../../assets/success.png';
import { Flex } from "../../styles/admin/DetailsPanel";
import { CTA } from "../../components";

function SuccessfulPayment() {

	return (
		<>
			<FormPanelWrapper>
				<FormPanel>
					<FormPanelHeader>
						<Link to="/" alt="home">
							<img src={logo} alt="Greymate Care Logo" />
						</Link>
					</FormPanelHeader>
					
					<FormPanelBody>
					<Flex alignItems="center" justifyContent="center">
						<FormPanelSuccess>
							<img src={Success} alt="Successful Payment"/>
							<h1>Payment successful</h1>
							<p>You have successfully subscribed to our plan.Please download the app below to manage your account.</p>
							<CTA />
						</FormPanelSuccess>
					</Flex>
					</FormPanelBody>
				</FormPanel>
			</FormPanelWrapper>
		</>
	);
}

export default SuccessfulPayment;
