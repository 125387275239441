import React,  {useEffect} from "react";
import DefaultModal from "../default_modal/DefaultModal";
import "./request_meeting_modal.css";
import { toast } from "react-toastify";
import { useForm } from "react-hook-form";
import { FormInputField } from "../custom_form_component/CustomFormComponent";
import { useSelector, useDispatch } from "react-redux";
import { LoadingBar } from "../loading_bar/LoadingBar";
import {
	createMeetingRequest,
	reset,
} from "../../features/request_meeting/meetingRequestSlice";
import formValidator from "../../misc/validator";

const formDefaults = {
	name: "",
	email: "",
	job_title: "",
	company_name: "",
	contact_person_name: "",
	contact_person_email: "",
	contact_person_phone_number: "",
};

function RequestMeetingModal({ open, onClose }) {
	const {
		handleSubmit,
		control,
		reset: resetForm,
		formState: { errors },
	} = useForm({
		defaultValues: formDefaults,
	});

	const dispatch = useDispatch();
	const ref = React.useRef(null);

	const { data, isError, isSuccess, isLoading, message } = useSelector(
		(state) => state.meetingRequest
	);
	React.useEffect(() => {
		if (isError) {
			toast.error(message);
			ref.current.complete();
		}

		if (isSuccess && data) {
			ref.current.complete();
			toast.success("Meeting Request Sent Successfully!");
			resetForm(formDefaults);
			onClose();
		}

		dispatch(reset());
	}, [data, isError, isSuccess, message, onClose, resetForm, dispatch]);
	const onSubmit = (data) => {
		ref.current.continuousStart();
		dispatch(createMeetingRequest(data));
	};

	useEffect(() => {
		document.title = "Request Meeting - Greymate Care";
	}, []);

	return (
		<>
			<LoadingBar ref={ref} shadow={true} />
			<DefaultModal
				title="Request a meeting"
				desc="Fill the form to request a meeting"
				open={open}
				onClose={onClose}
			>
				<h2 className="default__modal_title">Request a meeting</h2>
				<div className="request__model__form">
					<form onSubmit={handleSubmit(onSubmit)}>
						<div className="form__row">
							<FormInputField
								name="name"
								label="Full Name"
								placeholder="eg. John"
								control={control}
								errors={errors}
							/>
						</div>

						<div className="form__row">
							<FormInputField
								name="job_title"
								label="Job Title"
								placeholder="Enter Job Title"
								control={control}
								errors={errors}
							/>
						</div>
						
						<div className="form__row">
							<FormInputField
								name="email"
								label="Your Email"
								type="email"
								rules={{
									required: {
										value: "required",
										message: "Please enter your email",
									},
									validate: formValidator.emailValidator,
								}}
								placeholder="e.g you@example.com"
								control={control}
								errors={errors}
							/>
						</div>

						<div className="form__row">
							<FormInputField
								name="company_name"
								label="Company Name"
								placeholder="Enter Company Name"
								control={control}
								errors={errors}
							/>
						</div>

						<div className="form__row">
							<FormInputField
								name="contact_person_name"
								label="Contact Person's Name"
								placeholder="eg. John"
								control={control}
								errors={errors}
							/>
						</div>

						<div className="form__row">
							<FormInputField
								name="contact_person_email"
								label="Contact Person's Email"
								type="email"
								rules={{
									required: {
										value: "required",
										message: "Please enter contact person's email",
									},
									validate: formValidator.emailValidator,
								}}
								placeholder="e.g you@example.com"
								control={control}
								errors={errors}
							/>
						</div>

						<div className="form__row">
							<FormInputField
								name="contact_person_phone_number"
								label="Contact Person Phone Number"
								rules={{
									required: {
										value: "required",
										message: "Please enter contact person's phone number",
									},
									validate: formValidator.phoneValidator,
								}}
								type="tel"
								placeholder="e.g +23494394493"
								control={control}
								errors={errors}
							/>
						</div>

						<div className="form__buttons">
							<button
								className="button__primary"
								type="submit"
								disabled={isLoading}
							>
								Submit Details
							</button>
						</div>
					</form>
				</div>
			</DefaultModal>
		</>
	);
}

export default RequestMeetingModal;
