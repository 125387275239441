import { useState, useEffect, useRef } from 'react'
import {useDispatch } from 'react-redux'
import {toast} from 'react-toastify'
import debounce from "lodash/debounce";

const useSearchResource = ({
    selector,
    getReducer,
    resetReducer,
    parseData,
    routes: {
        getRoute,
    }
}) => {
    
    const [responseData, setResponseData] = useState([]);
    const dispatch = useDispatch();

    const {
        data,
        isError,
        isLoading,
        isSuccess,
        message
    } = selector;


    useEffect(() => {
        if (isError) {
            toast.error(message)
        }

        if (isSuccess) {
            
        }

        if (isSuccess && message) {
            toast.success(message);
        }

        if (isSuccess && data) {
            prepareTableData(data);
        }

        dispatch(resetReducer())
        // eslint-disable-next-line
    }, [data, isError, isSuccess, message])

    const prepareTableData = (response) => {
        setResponseData(parseData(response));
    }

    const debouncedSearch = useRef(
		debounce(async (searchValue) => {
            dispatch(getReducer(getRoute+"/search?q="+encodeURI(searchValue)));
		}, 1000)
	).current;


    const onSearch = (value) => {
        if(value.length > 3){
            debouncedSearch(value);
        }
	};

    useEffect(() => {
		return () => {
			debouncedSearch.cancel();
		};
	}, [debouncedSearch]);

    return {
        
        responseData: responseData,
        isLoading: isLoading,
        onSearch: onSearch,
    };
};

export default useSearchResource;