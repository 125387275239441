import moment from 'moment';
import React from 'react'
import { getSponsorsBadge } from '../../misc/helpers';

import { DetailsBody, DetailsButton, DetailsFooter, DetailsHeader, DetailsHeaderDesc, DetailsLabel, DetailsList, DetailsListWrapper, DetailsPanelStyled, DetailsTab, DetailsTabPanel, DetailsTabs, DetailsTabsHeader, DetailsValue, Flex } from '../../styles/admin/DetailsPanel';
import { ModalTable } from '../../styles/admin/Table.styled';
import Badge from '../badge/Badge';

function SponsorDetailsPanel(props) {
    const badge = getSponsorsBadge(props.data.status);
    const capitalizedFields = [
        "Services Needed",
        "Special Conditions",
        "Mobility",
        "Alertness",
        "Gender",
        "Preferred Gender",
        "Preferred Language",
        "Living Arrangements",
        "Extra Services",
        "Extra Items",
    ];

  return (
    <DetailsPanelStyled>
        <DetailsHeader>
            <Flex>
                
                <DetailsHeaderDesc>
                    <h3>{props.data.name}</h3>
                    <p>{props.data.email}</p>
                </DetailsHeaderDesc>
            </Flex>
            <div>
                <Badge {...badge.badgeType}>{badge.badgeText}</Badge>
            </div>
        </DetailsHeader>
        <DetailsBody>
            <DetailsTabs defaultValue={0}>
                <DetailsTabsHeader>
                    <DetailsTab first='true'>Personal Info</DetailsTab>
                    {props.data.serviceUserProfile && <DetailsTab>Service User</DetailsTab>}
                    <DetailsTab last="true">Payment History</DetailsTab>
                </DetailsTabsHeader>

                <DetailsTabPanel value={0}>
                    <DetailsListWrapper>
                        {Object.keys(props.data.profile).map((key) => 
                            <DetailsList key={props.data.profile[key]}>
                                <DetailsLabel>
                                    <span>{key}</span>
                                </DetailsLabel>
                                <DetailsValue  
                                style={{
                                textTransform: capitalizedFields.includes(key)?"capitalize":"initial",
                                }}>
                                    <span>{props.data.profile[key]}</span>
                                </DetailsValue>
                            </DetailsList>
                        )}
                    </DetailsListWrapper>


                </DetailsTabPanel>
                {props.data.serviceUserProfile && <DetailsTabPanel value={1}>
                    {Object.keys(props.data.serviceUserProfile).map((key) => 
                        <DetailsList key={props.data.serviceUserProfile[key]}>
                            <DetailsLabel>
                                <span>{key}</span>
                            </DetailsLabel>
                            <DetailsValue
                            style={{
                                textTransform: capitalizedFields.includes(key)?"capitalize":"initial",
                                }}>
                                <span>{props.data.serviceUserProfile[key]}</span>
                            </DetailsValue>
                        </DetailsList>
                    )}
                </DetailsTabPanel>} 
                <DetailsTabPanel value={2}>
                <ModalTable>
                    <thead>
                        <tr>
                            <th>Date</th>
                            <th>Amount</th>
                            <th>Status</th>
                            <th>Description</th>
                            {/* <th>Download</th> */}
                        </tr>
                    </thead>
                    <tbody>
                    {props.data.paymentHistory.map((payment, index) => 
                        
                        <tr key={index}>
                            <td>{moment.unix(payment["paid_at"]).fromNow()}</td>
                            <td>{payment["amount"]}</td>
                            <td><Badge success>Success</Badge></td>
                            <td>{payment["comment"]}</td>
                            {/* <td><a href={payment["Download"]}>Download</a></td> */}
                        </tr>
                    )}
                    </tbody>
                </ModalTable>
                    
                </DetailsTabPanel>
            </DetailsTabs>
        </DetailsBody>
        <DetailsFooter>
            <div>
               
            </div>
            <div>
                <DetailsButton  as="a"  href={"mailto:"+props.data.email} $primary>Send a message</DetailsButton>
            </div>
        </DetailsFooter>
    </DetailsPanelStyled>
  )
}

export default SponsorDetailsPanel