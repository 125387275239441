import React, { useEffect } from 'react'
import {Accordation, Navbar} from '../../components'
import {Footer} from '../../containers'
import './faq.css'
import BGHeader from '../../assets/bg-header.png';

function FAQ() {
  useEffect(() => {
    document.title = "FAQs - Greymate Care";
  }, []);

  const accordationData = [
    {
      title: "What services do you provide?",
      content: "We provide the best home care services to the elderly, persons living with disabilities, and post-surgery recovery cases. Upon signing up you get a 3-man team- a care advisor, caregiver, and quality assurance lead to ensure you or your loved one gets the best care RIGHT IN THE COMFORT OF YOUR HOME. Our services include but are not limited to Dementia/Alzheimer’s care, post-surgery care, elderly care, Cancer/Stroke/Diabetes/Skin and Wound care, meal preparation, vital sign management, medication reminders, personal care, incontinence care, companionship and medical escort to doctor’s appointments",
    },
    {
      title: "What training/certification do the caregivers possess?",
      content: "Our caregivers have First Aid/CPR training, Vital signs monitoring, Manual Handling, Emergency support, Fall prevention/Home Safety, Documentation and Communication, Medication management, and personal care training.",
    },
    {
      title: "What if I don't like the caregiver I was matched with? ",
      content: "Our matching algorithm matches care recipients to the most suitable caregiver. However, in the rare event, you prefer a different caregiver to the one deployed, we would be happy to change to a different one (subject to availability)",
    },
    {
      title: "What do I do if I have complaints about my care?",
      content: "Our support team is always available to resolve any issues and can be reached at 09090904226.",
    },
    {
      title: "Does Greymate Care provide live-in/overnight/24-hour services?",
      content: "Yes, we provide overnight shifts anywhere from 7 pm-7 am. We also have live-in caregivers who can cover either daytime or overnight shifts but not both. For 24-hour uninterrupted care, our platinum care covers round-the-clock care provided by two caregivers in split 12-hour shifts.",
    },
    {
      title: "What happens when I cancel?",
      content: "In the event of interrupted services due to unforeseen circumstances such as death or readmission, we will refund any unused funds already charged to the account.",
    },
    {
      title: "I want to care for both parents. Can one caregiver cover both?",
      content: "To provide the best care and prevent caregiver burnout, we advise one caregiver per parent. We have discounted rates for 2 service users in the same household.",
    },
    {
      title: "Can I inspect the care home?",
      content: "Greymate Care is a digital home health platform that connects you to the best care providers RIGHT IN THE COMFORT OF YOUR HOME. Upon selection of preferred services, our algorithm matches you to the appropriate provider, and the services are provided in the home of the care recipient(s)."
    }
  
  ]

  const accordationList = accordationData.map((data) =>  <Accordation data={data} key={data.title}/>);
  return (
    <>
    <div className='gmc__faq-background'>
      <Navbar/>
      <div className='gmc__pricing__header section__padding section__bg__lines'  style={{ 
      backgroundImage: `url(${BGHeader})` 
    }}>
        <h1 className='section__heading'>Frequently asked Questions</h1>
        <p className='section__desc'>Have questions? We are here to help</p>
      </div>
      <div className='section__padding gmc__questions'>
        <div className='gmc__questions__cover'>
          {accordationList}
        </div>
      </div>
    </div>
    <Footer/>
    </>
  )
}

export default FAQ