import React, { useEffect, useState } from 'react'
import { AdminModal, Badge, CustomDataTable, DetailsPanel, PopupMenu } from '../../../components';
import { DashboardRow } from '../../../styles/admin/CardBox.styled';
import moment from "moment";
import useResource from "../../../core/hooks/useResource";
import { getCaregiversBadge } from '../../../misc/helpers';
import { LoadingBar } from "../../../components/loading_bar/LoadingBar";
import { deleteCaregiver, getCaregivers, acceptCaregiverApplication, denyCaregiverApplication, reset } from '../../../features/caregiver/caregiverSlice';
import { Routes } from '../../../core/routes/apiRoutes';
import { useDispatch, useSelector } from 'react-redux';
import Can from '../../../components/auth_gate/Can';
import { CREATE_AND_MANAGE_CAREGIVERS_APPLICATION } from '../../../misc/constants';

const perPage = 30;
let modalData;

function CaregiversApplication() {
    const dispatch = useDispatch();
    const [open, setOpen] = useState(false);

	const handleOpen = (id) => {
		modalData = tableData.filter((item) => item.email === id);
		if (modalData) {
			modalData = modalData[0].profile;
			setOpen(true);
		}
	};

	const handleClose = () => setOpen(false);
   

	const prepareTableData = (response, page) => {
		var rows = [];
		const tempData = response.data;


		for (let i = 0; i < tempData.length; i++) {
			const itemData = tempData[i];
			const itemProfile = tempData[i]?.profile;
			const itemBankDetails = tempData[i]?.bank_details;
			const itemPreference = tempData[i]?.preference;
			var item = {
				id: i + 1 + page,
				sn: i + 1 + page,
                avatar: itemData.avatar,
				name: itemData.last_name + " " + itemData.first_name,
				email: itemData.email,
				phone: itemData.phone_number,
				gender: itemData.gender,
                dateSubmitted: moment.unix(itemData.joined_at).fromNow(),
				status: itemData.approval_status,
			};

			item["profile"] = {
                name: item.name,
                email: item.email,
                avatar: item.avatar,
                profile: {
                    'Full Name': item.name,
                    'Phone Number': item.phone,
                    'Gender': item.gender,
                    'Date of Birth': moment(itemProfile.date_of_birth).format('l'),
                    'Address': itemProfile.address,
                    'Bio': itemProfile.bio,
                    "Guarantors Name": itemProfile.guarantors_name,
                    "Guarantors Phone Number": itemProfile.guarantors_phone_number,
                    "Guarantors Address": itemProfile.guarantors_address,
                },
                payment: {
                    'Account Name': itemBankDetails.account_name,
                    'Account Number': itemBankDetails.account_number,
                    'Bank': itemBankDetails.bank_name,
                },
                skills: {
                    'Certifications': itemProfile?.certifications?.join(", "),
                    'Years of Experience': itemProfile?.experience,
                    'Skills': itemProfile?.services?.join(', '),
                    'Health conditions': itemProfile?.conditions?.join(', '),
                    'Work Schedule':itemPreference?.availability?.join(', '),
                    'Living Arrangements':itemPreference?.living_arrangements?.join(', '),
                    'Contract Type':itemPreference.contract_types?.join(', '),
                },
                documents: [
                    {
                        'name': 'Photo ID',
                        'type': 'jpg',
                        'link': itemProfile.photo_id,
                    },
                    {
                        'name': 'Certificate',
                        'type': 'jpg',
                        'link': itemProfile.certificate,
                    },
                    {
                        'name': 'Proof of Address',
                        'type': 'jpg',
                        'link': itemProfile.proof_of_address,
                    },
                ],
			};

			rows.push(item);
		}

		return rows;
	};

	const {
		onPrevPage,
		onNextPage,
		page,
		tableData,
		ref,
		isLoading,
		onDelete,
		onSearch,
        setNeedsReload,
	} = useResource({
		selector: useSelector((state) => state.caregiver),
		perPage: perPage,
		getReducer: getCaregivers,
		resetReducer: reset,
		deleteReducer: deleteCaregiver,
		renderTableData: prepareTableData,
		routes: {
			getRoute: Routes.getCaregiverUrl,
		},
	});

    const onAccept = (email) => {
        dispatch(acceptCaregiverApplication(email));
        setNeedsReload(true);
        setOpen(false);
    }

    const onDeny = (email) => {
        dispatch(denyCaregiverApplication(email));
        setNeedsReload(true);
        setOpen(false);
    }

	const actions = Can(CREATE_AND_MANAGE_CAREGIVERS_APPLICATION) ? [
		{ title: "View Details", onClick: handleOpen },
		{ title: "Delete", onClick: onDelete },
	]: [
        { title: "View Details", onClick: handleOpen },
    ];

    useEffect(() => {
        document.title = "Caregivers Applications - Greymate Care Admin";
    }, []);


    const columns = [
        {
            field: "sn",
            headerName: "S/N",
            minWidth: 40,
            
        },
        {
            field: 'name',
            headerName: 'Name',
            minWidth: 100,
            flex: 1
        },
        {
            field: 'email',
            headerName: 'Email Address',
            minWidth: 100,
            flex: 2
        },
        {
            field: 'gender',
            headerName: 'Gender',
            minWidth: 100,
            flex: 1
        },
        {
            field: 'phone',
            headerName: 'Phone Number',
            minWidth: 100,
            flex: 1
        },
        {
            field: 'dateSubmitted',
            headerName: 'Date Submitted',
            minWidth: 100,
            flex: 1
        },
        {
			field: "status",
			headerName: "Status",
			minWidth: 100,
			flex: 2,
			sortable: false,
			renderCell: (params) => {
				const badge = getCaregiversBadge(params.row.status);
				return <Badge {...badge.badgeType}>{badge.badgeText}</Badge>;
			},
			disableClickEventBubbling: true,
		},
		{
			field: "actions",
			headerName: "",
			minWidth: 100,
			flex: 1,
			sortable: false,
			renderCell: (params) => {
				return <PopupMenu id={params.row.email} options={actions} />;
			},
			disableClickEventBubbling: true,
		},
    ];


    return (
        <>
           

            <LoadingBar ref={ref} shadow={true} />
            <h1>Caregivers Applications</h1>
			<DashboardRow>
				<CustomDataTable
					disableSelectionOnClick
					pageSize={perPage}
					pagination="false"
					rowsPerPageOptions={[]}
					loading={isLoading}
					columns={columns}
					rows={tableData}
					hideFooter={true}
					prev={onPrevPage}
					next={onNextPage}
					currentPage={page}
					onSearchChange={onSearch}
				/>
			</DashboardRow>
            <AdminModal open={open} onClose={handleClose}>
                {modalData && (
                    <DetailsPanel data={modalData} onEdit={handleClose} onAccept={onAccept} onDeny={onDeny} />
                )}

            </AdminModal>
        </>

    )
}

export default CaregiversApplication