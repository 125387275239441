import { configureStore } from "@reduxjs/toolkit/"

import authReducer from "../features/auth/authSlice"
import demoRequestSlice from "../features/request_demo/demoRequestSlice"
import meetingRequestSlice from "../features/request_meeting/meetingRequestSlice"
import paymentSlice from "../features/sponsor/paymentSlice"
import sponsorReducer from "../features/sponsor/sponsorSlice"
import caregiverReducer from "../features/caregiver/caregiverSlice"
import adminSlice from "../features/admin/adminSlice"
import roleSlice from "../features/roles/roleSlice"
import subscriptionPlanSlice from "../features/subscription_plans/subscriptionPlanSlice"
import serviceUserSlice from "../features/service_user/serviceUserSlice"
import activityLogSlice from "../features/activity_log/activityLogSlice"
import dashboardSlice from "../features/dashboard/dashboardSlice"
import contactRequestSlice from "../features/contact/contactSlice"

export const store = configureStore({
    reducer: {
        auth: authReducer,
        sponsor: sponsorReducer,
        caregiver: caregiverReducer,
        payment: paymentSlice,
        demoRequest: demoRequestSlice,
        meetingRequest: meetingRequestSlice,
        contactRequest: contactRequestSlice,
        admin: adminSlice,
        role: roleSlice,
        subscriptionPlan: subscriptionPlanSlice,
        serviceUser: serviceUserSlice,
        activityLog: activityLogSlice,
        dashboard: dashboardSlice,
    },
})