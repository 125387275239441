import * as React from "react";
import { FaEllipsisH } from 'react-icons/fa';
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { MenuBtn } from "../../styles/admin/Admin.styled";

export default function PopupMenu({id, options}) {
	const [anchorEl, setAnchorEl] = React.useState(null);
	const open = Boolean(anchorEl);
	const handleClick = (event) => {
		setAnchorEl(event.currentTarget);
	};
	const handleClose = (callback) => {
		setAnchorEl(null);
        callback(id);
	};

	return (
		<div>
			<MenuBtn
				aria-controls={open ? "basic-menu" : undefined}
				aria-haspopup="true"
				aria-expanded={open ? "true" : undefined}
                onClick={handleClick}
			>
				<FaEllipsisH  size={14} color="#737A91" />
			</MenuBtn>
			<Menu
				id="basic-menu"
				anchorEl={anchorEl}
				open={open}
				onClose={handleClose}
				MenuListProps={{
					"aria-labelledby": "basic-button",
				}}
			>
                {options.map((item)=> <MenuItem key={item.title} onClick={()=>handleClose(item.onClick)}>{item.title}</MenuItem>)}
			</Menu>
		</div>
	);
}
