import React from 'react'

import { DetailsBody, DetailsFooter, DetailsHeader, DetailsHeaderDesc, DetailsPanelStyled } from '../../styles/admin/DetailsPanel';
import { FormPanelButton } from '../../styles/FormPanel.Styled';
import { FormDropdownField } from '../custom_form_component/CustomFormComponent';
import { useForm } from 'react-hook-form';
import { PanelForm, PanelRow } from '../../styles/admin/Forms.styled';

const statusOptions = [
	{ label: "Pending", value: "pending" },
	{ label: "In Progress", value: "in_progress" },
	{ label: "Attended", value: "attended" },
];

function ChangeMeetingStatusPanel(props) {
    const {
		handleSubmit,
		control,
		formState: { errors },
	} = useForm({
		defaultValues: {
            status: props.status,
        },
	});
    
	const onSubmit = (data) => {
		
		props.changeStatus(props.data.id,data);
		
	};

  return (
    <>
        <DetailsPanelStyled as="form" onSubmit={handleSubmit(onSubmit)}>
            <DetailsHeader>
                <DetailsHeaderDesc>
                    <h3>Change Status</h3>
                </DetailsHeaderDesc>
            </DetailsHeader>
            <DetailsBody padding="2rem">
                <PanelForm>
                    <PanelRow>
                        <FormDropdownField
                            name="status"
                            label="Status"
                            options={statusOptions}
                            control={control}
                            placeholder="Choose Status"
                            rules={{
                                required: {
                                    value: "required",
                                    message: "Please select a status",
                                },
                            }}
                            errors={errors}
                        />
                    </PanelRow>
                </PanelForm>
            </DetailsBody>
            <DetailsFooter>
                <div></div>
                <div>
                    <FormPanelButton type="submit" primary>
                        Change Status
                    </FormPanelButton>
                </div>
            </DetailsFooter>
        </DetailsPanelStyled>
    </>
    
  )
}

export default ChangeMeetingStatusPanel