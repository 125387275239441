import React, {Component} from 'react';
import SlickSlider from "react-slick";
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import QuotesBG from '../../assets/bg-quotes.png'
import './comment_carousel.css';

export default class CommentCarousel extends Component {
   
    render() {
        const settings = {
            dots: true,
            infinite: true,
            slidesToShow: 3,
            slidesToScroll: 1,
            centerMode: false,
            speed: 500,
            arrows: false,
            cssEase: "linear",
            
            responsive: [
                {
                    breakpoint: 1700,
                    settings: {
                        slidesToShow: 2,
                        centerMode: false,
                    }
                },
                {
                    breakpoint: 1700,
                    settings: {
                        slidesToShow: 2,
                        centerMode: false,
                    }
                },
                {
                    breakpoint: 1050,
                    settings: {
                        slidesToShow: 1,
                        centerMode: false,
                        dots: true,
                    }
                }
            ]
        }

        return (
            <SlickSlider {...settings} className="gmc__comment-slider">
                {this.props.list.map((card, index) => (
                    <div className='gmc__comment-slider-item'>
                        <div className='gmc__caregiver-reviews_review' style={{ background: `url(${QuotesBG}), #fff`}}> 
                            <p className='gmc__caregiver-reviews_review-comment'>{card.comment}</p>
                            <p className='gmc__caregiver-reviews_review-author'>- {card.author}</p>
                        </div>
                    </div>
                     
                ))}
            </SlickSlider>
        );

        
    }
}