import {createSlice, createAsyncThunk} from '@reduxjs/toolkit'
import { Routes } from '../../core/routes/apiRoutes'
import dashboardService from './dashboardService'

const initialState = {
    data: null,
    isError: false,
    isSuccess: false,
    isLoading: false,
    message: '',
    errors: null,
}

//Get - Requires Admin Authentication
export const getDashboardData = createAsyncThunk(Routes.getActivityLogs, async ( url, thunkAPI) => {
    try{
        return await dashboardService.getDashboardData(url);
    }catch (error) {
        var errorData = {message: (error.response && error.response.data && error.response.data.message) || error.message || error.toString()}
        if(error.response.status === 422){
            errorData['errors'] = error.response.data.errors;
            
        }
        return thunkAPI.rejectWithValue(errorData);
    }
})

export const dashboardSlice = createSlice({
    name: 'dashboard',
    initialState,
    reducers: {
        reset: (state) => {
            state.isLoading = false;
            state.isSuccess = false;
            state.isError = false;
            state.message = '';
            state.errors = null;
        }
    }, 
    extraReducers: (builder) => {
        function sharedRejectedReducer(state, action){
            state.isLoading = false
            state.isError = true
            state.message = action.payload.message
            state.errors = action.payload.errors
        }
        function sharedPendingReducer(state, action){
            state.isLoading = true
        }

        builder
       
        .addCase(getDashboardData.pending,sharedPendingReducer)
        .addCase(getDashboardData.rejected, sharedRejectedReducer)

         //Get
         .addCase(getDashboardData.fulfilled, (state,action) => {
            state.isLoading = false
            state.isSuccess = true
            state.isError = false
            state.data = action.payload
            state.errors = null

        })
    }
})

export const {reset} = dashboardSlice.actions
export default dashboardSlice.reducer