import React from "react";
import { useForm } from "react-hook-form";
import {
	FormDropdownField,
	FormInputField,
} from "../../../components/custom_form_component/CustomFormComponent";
import {
	DetailsBody,
	DetailsFooter,
	DetailsHeader,
	DetailsHeaderDesc,
	DetailsPanelStyled,
} from "../../../styles/admin/DetailsPanel";
import { PanelForm, PanelRow } from "../../../styles/admin/Forms.styled";
import { FormPanelButton } from "../../../styles/FormPanel.Styled";
import formValidator from "../../../misc/validator";

function CreateTeamMemberPanel({ roles, onSubmit }) {


	const {
		handleSubmit,
		control,
		formState: { errors },
	} = useForm({
		defaultValues:  {
			first_name: "",
			last_name: "",
			email: "",
			phone_number: "",
			status: "",
			role: "",
		},
	});

	return (
        <>
		<DetailsPanelStyled as="form" onSubmit={handleSubmit(onSubmit)}>
			<DetailsHeader>
				<DetailsHeaderDesc>
					<h3>Add team member</h3>
				</DetailsHeaderDesc>
			</DetailsHeader>
			<DetailsBody padding="2rem">
				<PanelForm>
					<PanelRow>
						<FormInputField
							name="first_name"
							label="First Name"
							placeholder="eg. John"
							control={control}
							errors={errors}
						/>
					</PanelRow>
					<PanelRow>
						<FormInputField
							name="last_name"
							label="Last Name"
							placeholder="eg. Chris"
							control={control}
							errors={errors}
						/>
					</PanelRow>
					<PanelRow>
						<FormInputField
							name="email"
							label="Email Address"
							type="email"
							rules={{
								required: {
									value: "required",
									message: "Please enter email address",
								},
								validate: formValidator.emailValidator,
							}}
							placeholder="e.g you@example.com"
							control={control}
							errors={errors}
						/>
					</PanelRow>
					<PanelRow>
						<FormInputField
							name="phone_number"
							label="Phone Number"
							rules={{
								required: {
									value: "required",
									message: "Please enter phone number",
								},
								validate: formValidator.phoneValidator,
							}}
							type="tel"
							placeholder="e.g +23494394493"
							control={control}
							errors={errors}
						/>
					</PanelRow>
					
                    <PanelRow>
						<FormDropdownField
							name="role"
							label="Role"
							options={roles}
							control={control}
							placeholder="Choose role"
							rules={{
								required: {
									value: "required",
									message: "Please select role",
								},
							}}
							errors={errors}
						/>
					</PanelRow>
				</PanelForm>
			</DetailsBody>
			<DetailsFooter>
				<div></div>
				<div>
					<FormPanelButton type="submit" primary>
						Create Team Account
					</FormPanelButton>
				</div>
			</DetailsFooter>
		</DetailsPanelStyled>
        </>
	);
}

export default CreateTeamMemberPanel;
