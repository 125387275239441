import React, {  useRef } from "react";
import { useForm, } from "react-hook-form";
import {
    FormCheckBoxField,
    FormDropdownField,
    FormInputField,
} from "../../../components/custom_form_component/CustomFormComponent";

import {
    FormPanelBodyHeader,
    FormPanelButton,
    FormPanelControl,
    FormPanelFooter,
    FormPanelBodySection,
} from "../../../styles/FormPanel.Styled";
import { useSponsorFormState } from "../../../core/context/SponsorFormContext";
import formValidator from "../../../misc/validator";

function Register(props) {
    const {
        state: {
            registerData: {
                first_name,
                last_name,
                email,
                phone_number,
                gender,
                password: passwordValue,
                password_confirmation,
                agree,
            },
        },
        dispatch,
    } = useSponsorFormState();
    const {
        handleSubmit,
        control,
        watch,
        formState: { errors },
    } = useForm({
        defaultValues: {
            first_name: first_name,
            last_name: last_name,
            email: email,
            phone_number: phone_number,
            gender: gender,
            password: passwordValue,
            password_confirmation: password_confirmation,
            agree: agree,
        },
    });

    const password = useRef({});
    password.current = watch("password",password);

    const onSubmit = (data) => {
        dispatch({ type: "REGISTER_DATA_CHANGE", payload: data });
        props.onNextPage();
    };

    const genderOptions = [
        {label: "Male", value:"male"},
        {label: "Female", value:"female"},
        {label: "Others", value:"others"}
    ]

    

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <FormPanelBodyHeader>
                <h1>Get Started as a Sponsor</h1>
            </FormPanelBodyHeader>

            <FormPanelBodySection>
                <FormPanelControl>
                    <FormInputField
                        name="first_name"
                        label="First Name"
                        placeholder="John"
                        control={control}
                        errors={errors}
                    />
                </FormPanelControl>
                <FormPanelControl>
                    <FormInputField
                        name="last_name"
                        label="Last Name"
                        placeholder="Doe"
                        control={control}
                        errors={errors}
                    />
                </FormPanelControl>
                <FormPanelControl>
                    <FormInputField
                        name="email"
                        type="email"
                        label="Email Address"
                        placeholder="you@example.com"
                        control={control}
                        rules={{
                            required: {
                                value: "required",
                                message: "Please enter your email",
                            },
                            validate: formValidator.emailValidator,
                        }}
                        errors={errors}
                    />
                </FormPanelControl>
                <FormPanelControl>
                    <FormInputField
                        name="phone_number"
                        type="phone_number"
                        label="Phone Number"
                        placeholder="+23492039232"
                        control={control}
                        rules={{
                            required: {
                                value: "required",
                                message: "Please enter your phone number",
                            },
                            validate: formValidator.phoneValidator,
                        }}
                        errors={errors}
                    />
                </FormPanelControl>
                <FormPanelControl>
                    <FormDropdownField
                        name="gender"
                        label="Gender"
                        options={genderOptions}
                        control={control}
                        placeholder="Choose your gender"
                        rules={{
                            required: {
                                value: "required",
                                message: "Please select your gender",
                            },
                        }}
                        errors={errors}
                    />
                </FormPanelControl>
                <FormPanelControl>
                    <FormInputField
                        name="password"
                        label="New Password"
                        type="password"
                        placeholder="Enter password here"
                        control={control}
                        errors={errors}
                    />
                </FormPanelControl>
                <FormPanelControl>
                    <FormInputField
                        name="password_confirmation"
                        label="Confirm Password"
                        type="password"
                        placeholder="Enter password here"
                        rules={{
                            required: {
                                value: "required",
                                message: "Please enter a your password",
                            },
                            validate: {
                                sameAsPassword: (value) =>
                                    value === password.current || "The passwords do not match",
                            },
                        }}
                        control={control}
                        errors={errors}
                    />
                </FormPanelControl>
                <FormPanelControl>
                    <FormCheckBoxField
                        name="agree"
                        control={control}
                        label="I consent to the collection and processing of my personal data in line with data regulations as described in the Greymate Care Privacy Policy"
                        rules={{
                            required: {
                                value: "required",
                                message: "We need your consent to continue",
                            },
                        }}
                        errors={errors}
                    />
                </FormPanelControl>
            </FormPanelBodySection>
            <FormPanelFooter>
                <FormPanelButton type="submit">Next</FormPanelButton>
            </FormPanelFooter>
        </form>
    );
}

export default Register;
