 const countriesToLocal = {
	US: 'en-US',
	CA: 'en-CA',
	AU: 'en-AU',
	DE: 'de-DE',
	FR: 'fr-FR',
	IE: 'en-IE',
	NL: 'nl-NL',
	GB: 'en-GB',
	JP: 'ja-JP',
	NZ: 'en-NZ',
	HK: 'zh-HK',
	SG: 'zh-SG',
	DK: 'da-DK',
	NGN: 'en-NG',
}; 


export default countriesToLocal
