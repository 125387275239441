import React from "react";
import { GridToolbarExport } from "@mui/x-data-grid";
import { FaSortDown, FaSortUp } from "react-icons/fa";
import { StyledDataGrid } from "../../styles/admin/StyledDataGrid.styled";
import styled from "styled-components";
import SearchField from "../search_field/SearchField";
import { escapeRegExp } from "../../misc/helpers";
import { Flex } from "../../styles/admin/DetailsPanel";
import { RiArrowRightSLine, RiArrowLeftSLine } from "react-icons/ri";
import { MenuBtn } from "../../styles/admin/Admin.styled";
import debounce from "lodash/debounce";

const ToolbarWrapper = styled.div`
	width: 100%;
	display: flex;
	justify-content: flex-end;
	padding: 1rem;
	gap: 2rem;
`;

function QuickSearchToolbar(props) {
	return (
		<ToolbarWrapper>
			<GridToolbarExport
				sx={{
					backgroundColor: "white",
					padding: ".5rem 1rem",
					borderRadius: "8px",
					color: "var(--color-text-primary)",
					fontWeight: 500,
					fontSize: "14px",
					lineHeight: "14px",
				}}
			/>

			<SearchField
				value={props.value}
				onChange={props.onChange}
				clearSearch={props.clearSearch}
			/>
		</ToolbarWrapper>
	);
}

export function SortedDescendingIcon() {
	return <FaSortDown size={12} color="#00000099" />;
}

export function SortedAscendingIcon() {
	return <FaSortUp size={12} color="#00000099" />;
}

function CustomDataTable(props) {
	const [searchText, setSearchText] = React.useState("");
	const [rows, setRows] = React.useState(props.rows);

	const debouncedSearch = React.useRef(
		debounce(async (searchValue) => {
			props.onSearchChange(searchValue);
		}, 1000)
	).current;

	const requestSearch = (searchValue) => {
		setSearchText(searchValue);

		if (props.searchType === "local") {
			const searchRegex = new RegExp(escapeRegExp(searchValue), "i");
			const filteredRows = props.rows.filter((row) => {
				return Object.keys(row).some((field) => {
					return searchRegex.test(row[field]?.toString());
				});
			});
			setRows(filteredRows);
		} else {
			debouncedSearch(searchValue);
		}
	};

	React.useEffect(() => {
		return () => {
			debouncedSearch.cancel();
		};
	}, [debouncedSearch]);

	React.useEffect(() => {
		setRows(props.rows);
	}, [props.rows]);

	const handleOnPrev = () => {
		if (props.prev) {
			props.prev();
		}
	};

	const handleOnNext = () => {
		if (props.next) {
			props.next();
		}
	};

	const pageNumber =
		props.currentPage === 0
			? props.currentPage + 1
			: props.currentPage / props.pageSize + 1;
	const pageStart = props.currentPage + 1;
	const pageEnd = pageStart + (rows.length - 1);

	return (
		<>
			<div style={{ display: "flex", width: "100%", overflowX: "auto" }}>
				<div style={{ flexGrow: 1 }}>
					<StyledDataGrid
						{...props}
						rows={rows}
						rowHeight={70}
						disableColumnMenu
						autoHeight
						components={{
							ColumnSortedDescendingIcon: SortedDescendingIcon,
							ColumnSortedAscendingIcon: SortedAscendingIcon,
							Toolbar: QuickSearchToolbar,
						}}
						componentsProps={{
							toolbar: {
								quickFilterProps: { debounceMs: 50000 },
								value: searchText,
								onChange: (event) => requestSearch(event.target.value),
								clearSearch: () => requestSearch(""),
							},
						}}
					/>
					{!props.removePagination && (
						<Flex
							justifyContent="flex-end"
							alignItems="center"
							margin="1rem 0px"
						>
							<div>
								Page {`${pageNumber} | Showing ${pageStart} - ${pageEnd}`}
							</div>
							<Flex justifyContent="flex-end">
								<MenuBtn onClick={() => handleOnPrev()} pagination>
									<RiArrowLeftSLine size={18} />
								</MenuBtn>
								<MenuBtn onClick={() => handleOnNext()} pagination>
									<RiArrowRightSLine size={18} />
								</MenuBtn>
							</Flex>
						</Flex>
					)}
				</div>
			</div>
		</>
	);
}

export default CustomDataTable;
