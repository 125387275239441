import styled, { css } from "styled-components";



export const AdminBody = styled.main`
	width: 100%;
	padding-left: ${({ theme: { sizes } }) => sizes.sidebarWidthFull};
	background: #f5f7fc;
	min-height: 100vh;
	transition: 0.3s padding-left;

	@media screen and (max-width: 768px) {
		padding-left: 0;
	}
`;

export const AdminHeader = styled.header`
    display: flex;
    justify-content: space-between;
    align-items: center;
    /* background: #fff;
    border-bottom: 1px solid #edeff5; */
    height: 60px;
`;

export const AdminHeaderLinks = styled.div`
    display: flex;
`

export const MenuBtn = styled.button`
    display: block;
    width: 35px;
	height: 35px;
	background: #f5f5f7;
	border-radius: 50%;
	position: relative;
	cursor: pointer;
	transition: background 0.4s;
    border: none;
    margin-left: 1rem;

    ${props => props.pagination && css`
        background: #fff;
        width: 45px;
        height: 45px;
    `}

	&:hover {
		background: #e4e4e7;
	}

	svg {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		margin: 0;
		padding: 0;
	}
`;

export const LoggedInUser = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.2rem;
    outline: none;
    background: none;
    border: none;
    padding: 1rem;

    span {
        color: #475063;
        font-weight: 400;
        font-size: 14px;
        line-height: 200%;
    }
`;
export const LogoutBtn = styled.button`
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.2rem;
    outline: none;
    background: none;
    border: none;
    border-left: 1px solid #edeff5;
    padding: 1rem;
    margin-right: 1rem;
    
    cursor: pointer;    
    span {
        color: #475063;
        font-weight: 400;
        font-size: 14px;
        line-height: 200%;
    }
    
`;

export const AdminContentArea = styled.div`
    padding: 2rem;
`;
