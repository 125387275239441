import {Navigate, Outlet} from 'react-router-dom';
import { AppRoutes } from '../../core/routes/routes';

const RedirectIfAuth = ({type}) => {
    const typeKey = type==='sponsor'?'sponsor':'user';
    const redirectUrl = type==='sponsor'?AppRoutes.SponsorUrl:AppRoutes.AdminUrl;
    const auth = localStorage.getItem(typeKey);

    

    return (auth
        ? <Navigate to={redirectUrl}/>
        : <Outlet />
    );
}

export default RedirectIfAuth;