import React from 'react'

import { DetailsBody, DetailsButton, DetailsFooter, DetailsHeader, DetailsHeaderDesc, DetailsPanelStyled } from '../../styles/admin/DetailsPanel';
import { PanelForm, PanelRow } from '../../styles/admin/Forms.styled';

function CreateDiscountPanel(props) {

  return (
    <DetailsPanelStyled>
        <DetailsHeader>
            <DetailsHeaderDesc>
                <h3>Create Code</h3>
            </DetailsHeaderDesc>
        </DetailsHeader>
        <DetailsBody padding="2rem">
            <PanelForm>
                <PanelRow>
                    <label>Enter code name</label>
                    <input type="text" placeholder="e.g Christmas bonus"/>
                </PanelRow>
                <PanelRow>
                    <label>Select percentage</label>
                    <input type="text" placeholder="e.g 15%"/>
                </PanelRow>
            </PanelForm>
        </DetailsBody>
        <DetailsFooter>
            <div>
               
            </div>
            <div>
                <DetailsButton  onClick={props.onCreateCode} primary>Create Code</DetailsButton>
            </div>
        </DetailsFooter>
    </DetailsPanelStyled>
  )
}

export default CreateDiscountPanel