import styled from 'styled-components';

export const ActivityCardStyled = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  padding: 0 1rem;
  padding-bottom: 2rem;
`;
export const ActivityDetail = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  gap: 1rem;
  position: relative;
`;
export const ActivityIcon = styled.div`
  position: relative;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  background: #E1E9F4;
  border-radius: 50%;

  &::before {
    content: '';
    position: absolute;
    width: 1px;
    height: 40px;
    background: #C7CAD3;
    top: 54px;
    left: 50%;
    transform: translateX(-50%);
    
  }

  svg {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

`;
export const ActivityDesc = styled.div`
  display: flex;
  flex-direction: column;
  
  span {
    font-weight: 400;
    font-size: 12px;
    line-height: 200%;
    color: #475063;
  }

  h4 {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 200%;
    margin:  0;
  }

  p {
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    color: #475063;
  }
`;
export const ActionLink = styled.div`
  a {
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    color: #3885B5;
  }
`;