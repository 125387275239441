import React, { Component } from 'react'
import { Outlet } from "react-router-dom"
import './main.css'



export default class Main extends Component {
  render() {
    return (
      <>
        <Outlet />
      </>
    )
  }
}