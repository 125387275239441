import React from "react";
import { useForm } from "react-hook-form";
import {
	FormDropdownField,
} from "../../../components/custom_form_component/CustomFormComponent";
import {
	DetailsBody,
	DetailsFooter,
	DetailsHeader,
	DetailsHeaderDesc,
	DetailsPanelStyled,
} from "../../../styles/admin/DetailsPanel";
import { PanelForm, PanelRow } from "../../../styles/admin/Forms.styled";
import { FormPanelButton } from "../../../styles/FormPanel.Styled";

const statusOptions = [
	{ label: "Active", value: "active" },
	{ label: "Pending", value: "pending" },
	{ label: "Deactivate", value: "deactivated" },
];

function ChangeTeamMemberStatus({status, onSubmit}) {
	const {
		handleSubmit,
		control,
		formState: { errors },
	} = useForm({
		defaultValues: {
			status: status,
		},
	});

	return (
		<>
				<DetailsPanelStyled as="form" onSubmit={handleSubmit(onSubmit)}>
					<DetailsHeader>
						<DetailsHeaderDesc>
							<h3>Change Status</h3>
						</DetailsHeaderDesc>
					</DetailsHeader>
					<DetailsBody padding="2rem">
						<PanelForm>
							<PanelRow>
								<FormDropdownField
									name="status"
									label="Status"
									options={statusOptions}
									control={control}
									placeholder="Choose Status"
									rules={{
										required: {
											value: "required",
											message: "Please select a status",
										},
									}}
									errors={errors}
								/>
							</PanelRow>
						</PanelForm>
					</DetailsBody>
					<DetailsFooter>
						<div></div>
						<div>
							<FormPanelButton type="submit" primary>
								Change Account Status
							</FormPanelButton>
						</div>
					</DetailsFooter>
				</DetailsPanelStyled>
		</>
	);
}

export default ChangeTeamMemberStatus;
