import styled, { css } from "styled-components";

export const FormPanelWrapper = styled.main`
    background-color: #FAFAFE;
    min-height: 100vh;
`;

export const FormPanelBanner = styled.div`
    width: 100%;
    max-width: ${props => props.width || '440px'};
    background: linear-gradient(0deg, rgba(25, 118, 210, 0) -4.42%, rgba(25, 118, 210, 0.08) 100%);
    border-radius: 8px;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;

    @media screen and (max-width: 1050px) {
        & {
            display: none;
        }
    }
`;

export const BannerDesc = styled.div`
    text-align: center;
    position: absolute;
    padding: 1rem;
    background: radial-gradient(100% 100% at 0% 0%, rgba(223, 227, 239, 0.104) 0%, rgba(255, 255, 255, 0.104) 0.01%, rgba(255, 255, 255, 0.176) 100%);
    box-shadow: inset -5px -5px 250px rgba(255, 255, 255, 0.02);
    border: 3px solid rgba(104, 201, 244, 0.102);    
    border-radius: 8px;

    & p {
        font-size: 12px;
        line-height: 17px;
        letter-spacing: 0.01em;
        color: var(--color-text-primary);
    }

    ${props => props.top && css`
        top: 20px;
        left: 50%;
        transform: translateX(-50%);
    `};

    ${props => props.bottom && css`
        bottom: 20px;
        left: 50%;
        transform: translateX(-50%);
    `};
    
`;
export const FormPanel = styled.div`
    display: flex;
    flex-direction: column;
    gap: 2rem;
    padding: ${props => props.padding || '4rem 0'};
    width: 100%;
    max-width: ${props => props.width || '590px'};
    margin: 0 auto;

    @media screen and (max-width: 1443px) {
        & {
            width: 100%;
            padding: 2rem;
        }
    }
`;

export const FormPanelHeader = styled.div`
    & h1 {
        font-weight: 700;
        font-size: 20px;
        line-height: 30px;
        margin: .4rem 0;
    }

    & p {
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        color: var(--color-text-primary);
    }

    ${props => props.center && css`
        & {
            text-align: center;
        }
        & h1 {
            text-align: center;
        }

        & p {
           text-align: center;
        }
    `}
`;

export const FormBodyWrapper = styled.div`
    width: 100%;
    flex: 1;
    padding: ${props => props.padding || '4rem 0'};
    @media screen and (max-width: 650px) {
        & {
            padding: 1rem;
        }
    }
`;
export const FormPanelBody = styled.div`
    
    padding: 2rem;
    background: #FFFFFF;
    box-shadow: 0px 2px 4px rgba(12, 26, 75, 0.04), 0px 4px 20px -2px rgba(50, 50, 71, 0.08);
    border-radius: 10px;
`;

export const FormErrorWrapper  = styled.div`
    padding: 1rem;
    border-radius: 10px;
    background-color: #f8d7da;
    border-color: #f5c6cb;
    box-shadow: 0px 2px 4px rgba(12, 26, 75, 0.04), 0px 4px 20px -2px rgba(50, 50, 71, 0.08);
    margin-bottom: 1rem;
    
    & p {
        font-size: 14px;
        line-height: 1.5;
        color: #721c24;
        padding: .2rem;
    }
`;

export const FormPanelBodyHeader = styled.div`
    display: flex;
    flex-direction: column;
    gap: 1rem;
    margin-bottom: 2rem;
    
    & h1 {
        font-weight: 800;
        font-size: 18px;
        line-height: 24px;
    }

    & p {
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        color: var(--color-text-primary);
    }

    ${props => props.center && css`
        & {
            text-align: center;
        }
        & h1 {
            text-align: center;
        }

        & p {
           text-align: center;
        }
    `}
`;

export const FormPanelSuccess = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: .5rem;
    padding: 3rem 0;

    & h1 {
        font-weight: 600;
        font-size: 18px;
        line-height: 24px;
    }

    & p {
        font-size: 16px;
        line-height: 24px;
        text-align: center;
        color: #737A91;
        margin-bottom: 1.5rem;
    }
`;

export const FormPanelBodySection = styled.div`
    margin-top: .5rem;
`;

export const FormPanelBodySectionHeader = styled.div`
    padding-bottom: 1.5rem;
    & h2 {
        font-weight: 800;
        font-size: 16px !important;
        line-height: 24px;
    }
`;

export const FormPanelControl = styled.div`
    padding-bottom: 1.5rem;

    & label {
        font-size: 12px;
        line-height: 20px;
        color: #3C5366;
        margin-bottom: .3rem;
    };

    & input[type="text"],
    & input[type="email"], 
    & input[type="date"], 
    & textarea {
        display: block;
        width: 100%;
        background: #F7F9FC;
        border: 1px solid #EDF2F7;
        border-radius: 4px;
        padding: 1rem;
        font-size: 14px;
        line-height: 20px;
        color: rgba(60, 83, 102, 1);
        outline: none;
    }

    & p {
        font-size: 13px;
        line-height: 22px;
        color: var(--color-text-primary);
    }

    & a {
        color: var(--color-primary);
    }
`;

export const FormPanelIndicator = styled.div`
    position: relative;
    width: 96.4px;
    height: 1rem;
    font-weight: 500;
    font-size: 12px;
    line-height: 20px;
    color: var(--color-text-primary);
    cursor: pointer;


    &::before {
        content: "";
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        height: 2px;
        background: ${(props) => props.active ?  '#3885B5' : 'rgba(56, 133, 181, 0.24)'};
        border-radius: 100px;
    }

    &hover::before {
        background: rgba(56, 133, 181, 0.74);
    }
`;

export const FormPanelIndicatorWrapper = styled.div`
    display: flex;
    gap: 1rem;
    margin-bottom: 2rem;
`;

export const FormPanelIndicatorTitle = styled.div`
    font-weight: 500;
    font-size: 12px;
    line-height: 20px;
    color: var(--color-text-primary);
   
`;

export const FormChip = styled.div`
    flex: 1;
    min-width: calc(50% -  1rem);
    padding: 1.3rem 1rem;
    background: linear-gradient(0deg, #F6F7F9 0%, #FFFFFF 100%);
    border: 1px solid #EDEFF5;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    &:hover {
        background: #F6F7F9;
    }

    
    ${(prop) => prop.small && css`
        padding: 1rem;
        min-width: auto;
        flex: none;
        font-weight: 500;
        font-size: 14px;
        line-height: 140%;
        background: linear-gradient(0deg, #F6F7F9 0%, #FFFFFF 100%);
        border: 1px solid #EDF2F7;
        box-shadow: 0px 1px 1px rgba(22, 29, 37, 0.05), inset 0px 2px 0px rgba(255, 255, 255, 0.05);
        border-radius: 10px;
    `};

    ${(prop) => prop.selected && css`
        background: linear-gradient(0deg, #F6F7F9 0%, #FFFFFF 100%);
        border: 2px solid #3885B5;
        box-shadow: 0px 1px 1px rgba(22, 29, 37, 0.05), inset 0px 2px 0px rgba(255, 255, 255, 0.05);
        border-radius: 10px;
    `};

    
    & span {
        font-weight: 500;
        font-size: 14px;
        line-height: 140%;
        color: #3C5366;
    }

    & svg {
        margin-bottom: .5rem;
    }
`;

export const FormPanelFooter = styled.div`
    margin-top: 1rem;
`;

export const FormPanelButton = styled.button`
    ${props => props.fullWidth && css`
        & {
            width: 100%;
        }
    `}

    
    display:  block;
    padding: 1.4rem;
    box-shadow: 0px 1px 1px rgba(22, 29, 37, 0.1), inset 0px 2px 0px rgba(255, 255, 255, 0.06);
    border-radius: 10px;
    background: var(--color-primary);
    color: #fff;
    transition: background 1s;
    outline: none;
    border: none;
    cursor: pointer;

    ${props => props.disabled && css`
        & {
            background-color: grey;
        }
    `}

    ${props => props.secondary && css`
        & {
            background-color: white;
            color: var(--color-text-primary);
            border: 1px solid rgba(0,0,0, .1);
        }
    `}

&:hover {
    background: var(--color-text-primary);
}
`;

export const FormError = styled.span`
    font-size: 10px;
    color: red;
`;


