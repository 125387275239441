import {createSlice, createAsyncThunk} from '@reduxjs/toolkit'
import { Routes } from '../../core/routes/apiRoutes'
import paymentService from './paymentService'
import sponsorService from './sponsorService'

const initialState = {
    data: null,
    subscriptionData: null,
    isError: false,
    isSuccess: false,
    isLoading: false,
    message: '',
    errors: null,
}

//Initialize payment transaction
export const initializePayment = createAsyncThunk(Routes.sponsorInitializePayment, async (data, thunkAPI) => {
    try{
        return await paymentService.initializePayment(data);
    }catch (error) {
        var errorData = {message: (error.response && error.response.data && error.response.data.message) || error.message || error.toString()}
        if(error.response.status === 422){
            errorData['errors'] = error.response.data.errors;
            
        }
        return thunkAPI.rejectWithValue(errorData);
    }
})

export const subscribe = createAsyncThunk(Routes.sponsorSubscribe, async (data, thunkAPI) => {
    try{
        const subData =  await paymentService.subscribe(data);
        await sponsorService.logout();
        return subData;
    }catch (error) {
        var errorData = {message: (error.response && error.response.data && error.response.data.message) || error.message || error.toString()}
        if(error.response.status === 422){
            errorData['errors'] = error.response.data.errors;
            
        }
        return thunkAPI.rejectWithValue(errorData);
    }
})



export const paymentSlice = createSlice({
    name: 'payment',
    initialState,
    reducers: {
        reset: (state) => {
            state.isLoading = false;
            state.isSuccess = false;
            state.isError = false;
            state.message = '';
            state.errors = null;
            state.subscriptionData = null;
        }
    }, 
    extraReducers: (builder) => {
        builder
        //Initialize Payment
        .addCase(initializePayment.pending, (state) => {
            state.isLoading = true
        })
        .addCase(initializePayment.fulfilled, (state,action) => {
            state.isLoading = false
            state.isSuccess = true
            state.isError = false
            state.data = action.payload
            state.errors = null

        })
        .addCase(initializePayment.rejected, (state, action) => {
            state.isLoading = false
            state.isError = true
            state.message = action.payload.message
            state.errors = action.payload.errors
        })

        //Subscribe to plan
        .addCase(subscribe.pending, (state) => {
            state.isLoading = true
        })
        .addCase(subscribe.fulfilled, (state,action) => {
            state.isLoading = false
            state.isSuccess = true
            state.isError = false
            state.subscriptionData = action.payload
            state.errors = null

        })
        .addCase(subscribe.rejected, (state, action) => {
            state.isLoading = false
            state.isError = true
            state.message = action.payload.message
            state.errors = action.payload.errors
        })
    }
})

export const {reset} = paymentSlice.actions
export default paymentSlice.reducer