import React, {Component} from 'react';
import PlayBtn from '../../assets/play_btn.svg';
import AppleBtn from '../../assets/apple_btn.svg';
import './cta.css';

export default class CTA extends Component {

  render(){
    return (
      <div className='gmc__cta-content_buttons'>
        <a target="__blank" href='https://play.google.com/store/apps/details?id=com.greymatecare&pli=1' aria-label='get app from playstore'>
          <img src={PlayBtn} alt="Play Store Button"/>
        </a>
        <a href='https://apps.apple.com/app/greymate-care/id6444747049'  aria-label='get app from appstore'>
          <img src={AppleBtn} alt="Apple Store Button"/>
        </a>
      </div>
    );
  }
}