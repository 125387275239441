import {useLocation, Navigate, Outlet} from 'react-router-dom';
import { AppRoutes } from '../../core/routes/routes';

const RequireAuth = ({type}) => {
    const typeKey = type==='sponsor'?'sponsor':'user';
    const redirectUrl = type==='sponsor'?AppRoutes.SponsorRegisterUrl:AppRoutes.LoginURL;
    const auth = localStorage.getItem(typeKey);
    const location = useLocation();
    

    return (auth
        ? <Outlet />
        : <Navigate to={redirectUrl} state={{from: location}} replace/>
    );
}

export default RequireAuth;