import React, {useEffect} from 'react'
import { CategoryTitle, NavMenu, RoleDetails, RoleMain, RoleNav, RolePermissionsHeader, RolePermissionsList, RolePermissionsWrapper, RoleTitleHeader, RoleWrapper } from '../../../styles/admin/RoleLayout.styled';
import { LoadingBar } from '../../../components/loading_bar/LoadingBar';
import { getRoles, deleteRole, updateRole, createRole, reset } from '../../../features/roles/roleSlice';
import { Routes } from '../../../core/routes/apiRoutes';
import { RiAddFill, RiDeleteBin2Fill, RiEdit2Fill } from 'react-icons/ri';
import { useDispatch, useSelector } from 'react-redux';
import { useState } from 'react';
import useResource from '../../../core/hooks/useResource';
import { AdminModal } from '../../../components';
import CreateRolePanel from '../../../components/create_role_panel/CreateRolePanel';
import { CREATE_AND_MANAGE_ROLES_AND_PERMISSIONS } from '../../../misc/constants';
import Can from '../../../components/auth_gate/Can';


function RoleNavSection({activeRole,  roles, onClick, children}){
	return (
		<>
			<div>
				<CategoryTitle>Default Roles</CategoryTitle>
				{roles && roles['default'] && <NavMenu>
					<ul>
						{roles['default'].map((role)=> <li key={role['id']} className={(activeRole && activeRole['id'] === role['id']) ? 'active':''} onClick={(e) => onClick(role)}><button className="role-link">{role['name']}</button></li>)}
					</ul>
				</NavMenu>}
			</div>

			<div>
				<CategoryTitle>Custom Roles</CategoryTitle>
				{roles && roles['custom'] && <NavMenu>
					<ul>
						{roles['custom'].map((role)=> <li key={role['id']} className={(activeRole && activeRole['id'] === role['id']) ? 'active':''} onClick={(e) => onClick(role)}><button className="role-link">{role['name']}</button></li>)}
						{children}
					</ul>
				</NavMenu>}
			</div>
		</>
	);
}

function RoleMainSection({activeRole, onEdit, onDelete}){
	return (
		<>
			{activeRole && <RoleMain>
			<div className="role-page">
				<RoleTitleHeader>
					<div>
						<h4>{activeRole['name']}</h4>
						<p>This role applies to {activeRole['name'].toLowerCase()} only </p>
					</div>
					{(!activeRole['default'] && Can(CREATE_AND_MANAGE_ROLES_AND_PERMISSIONS)) && <div>
						<a onClick={(e) => {e.preventDefault(); onEdit(activeRole)}} href="#daa"><RiEdit2Fill />Edit</a>
						<a href='#hello' onClick={(e) => {e.preventDefault(); onDelete(activeRole['id'])}}><RiDeleteBin2Fill/> Delete</a>
					</div>}
				</RoleTitleHeader>
				{/* <RoleTitleSubHeader>
					<span><RiTeamFill /> Team Members with this role (1): Daniel Aigbe, James Adu, Bola Ahmed</span>
				</RoleTitleSubHeader> */}
			
				<RoleDetails>
					<RolePermissionsWrapper positive={true}>
						<RolePermissionsHeader>
							<h5>What this role can access</h5>
						</RolePermissionsHeader>
						<RolePermissionsList>
							<ul>
								{activeRole.permissions.map((permission) => <li key={permission['id']}><b>Can</b> {permission['name']}</li>)}
							</ul>
						</RolePermissionsList>
					</RolePermissionsWrapper>

					<RolePermissionsWrapper  negative={true}>
						<RolePermissionsHeader >
							<h5>What this role cannot access</h5>
						</RolePermissionsHeader>
						<RolePermissionsList>
							<ul>
								{activeRole.deniedPermissions.map((permission) => <li key={permission['id']}><b>Cannot</b> {permission['name']}</li>)}
							</ul>
						</RolePermissionsList>
					</RolePermissionsWrapper>
				</RoleDetails>
			</div>
		</RoleMain>}
		</>
	);
}


let modalContent;
function Roles() {
	const dispatch = useDispatch();
	const [open, setOpen] = useState(false);
	const [selectedRole, setSelectedRole] = useState(null);

	const handleClose = () => setOpen(false);

	const onCreate = () => {
		modalContent =  <CreateRolePanel  permissions={tableData['permissions']}  onSubmit={createRoleRequest} />;
		setOpen(true);
	};

	const onEdit = (role) => {
		modalContent =  <CreateRolePanel role={role}   permissions={tableData['permissions']}  onSubmit={updateRoleRequest} />;
		setOpen(true);
	};

	const createRoleRequest = (formData) => {
		setOpen(false);
		ref.current.continuousStart();
		setNeedsReload(true);
		dispatch(createRole(formData));
	}

	const updateRoleRequest = (formData) => {
		setOpen(false);
		ref.current.continuousStart();
		setNeedsReload(true);
		dispatch(updateRole(formData));
	}

  const prepareData = (response) => {
		var rows = {};
		const tempData = response.data;

		const groupedRow = tempData.reduce((group, role) => {
			group['default'] = group['default'] ?? [];
			group['custom'] = group['custom'] ?? [];

			if(role['default'] === true){
				group['default'].push(role);
			}else {
				group['custom'].push(role);
			}
			return group;
		}, {});

		rows['grouped'] = groupedRow;
		rows['permissions'] = tempData.find(role => role['name'] === 'Admin')['permissions'];

		return rows;
	};

	const {tableData, ref, onDelete, setNeedsReload} =
		useResource({
			selector: useSelector((state) => state.role),
			getReducer: getRoles,
			resetReducer: reset,
			deleteReducer: deleteRole,
			renderTableData: prepareData,
			routes: {
				getRoute: Routes.getRolesUrl,
			},
		});

	

	useEffect(() => {
		document.title = "Roles and Permissions - Greymate Care";
	}, []);

	const onRoleSelect = (role) => {
		const deniedPermissions = tableData['permissions'].filter(item => {
			const permissionsIds = role.permissions.map(e => e['id']);
			return !permissionsIds.includes(item['id']);
		});

		setSelectedRole({...role, 'deniedPermissions': deniedPermissions});
	}


  return (
    <>
		<LoadingBar ref={ref} shadow={true} />
		<h1>Roles and Permissions</h1>

		
		
		{tableData && <RoleWrapper>
		<RoleNav>
			<RoleNavSection activeRole={selectedRole} roles={tableData['grouped']} onClick={onRoleSelect}>
				{Can(CREATE_AND_MANAGE_ROLES_AND_PERMISSIONS) && <li className="role-nav-btn"><button onClick={onCreate}><RiAddFill /> Add Custom Role</button></li>}
			</RoleNavSection>
			
		</RoleNav>
		<RoleMainSection activeRole={selectedRole} onDelete={onDelete} onEdit={onEdit} />
		</RoleWrapper>}

		<AdminModal open={open} onClose={handleClose}>
			{modalContent}
		</AdminModal>
    </>

  )
}




export default Roles