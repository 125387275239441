import React from 'react'
import { Line } from 'react-chartjs-2'
import { Chart as ChartJS, CategoryScale, LineElement, PointElement, LinearScale, Title, Tooltip, Legend, Filler } from 'chart.js'
ChartJS.register(CategoryScale, LineElement, PointElement, LinearScale, Title, Tooltip, Legend, Filler);

function AdminChart(props) {
    const options = {
        responsive: true,
        maintainAspectRatio: false,
        
        indexAxis: 'x',
        scales: {
            x: {
            beginAtZero: true
            }
        }
    };
    
  return (
    <Line
        {...props}
        width="100%"
        height="500px"
        options={options}
    />
  )
}

export default AdminChart