import React, { useEffect } from 'react'
import { Navbar } from '../../components';
import { Footer } from '../../containers';
import './privacy_policy.css'

import BGHeader from '../../assets/bg-header.png';

function PrivacyPolicy() {
  useEffect(() => {
    document.title = "Privacy Policy - Greymate Care";
  }, []);
  return (
    <>
    <div className='gmc__privacy-background'>
      <Navbar/>
      <div className='gmc__contact-us__header section__padding section__bg__lines'  style={{ 
        backgroundImage: `url(${BGHeader})` 
      }}>
        <h1 className='section__heading'>Privacy Policy</h1>
        
      </div>
    </div>
    <div className='section__padding gmc__privacy_body '>
        <p>
							Greymate Care is committed to keeping your information private in accordance with international and domestic best practices for the protection of data. This Privacy Policy explains what information we receive from you, how we receive it and what we do with such information. It also has information about how we process, store, use, transfer and delete that information.
						</p>
	 
						<p>
							While this Privacy Policy is in line with the European Union General Data Protection Regulation (GDPR) and the Nigeria Data Protection Regulation (NDPR), our aim is not just to comply with these regulations but also to earn your trust.
						</p>
	 
						<p>
							By using our service, you give your consent to process your data in accordance with these policies and our Terms of Use.
						</p>
						<br/>
						<h4>What do we mean by “Personal information?”</h4>
	 
						<p>
							“Personal information” means any information that you provide us that we could use to identify an individual. It does not extend to information that is encoded or publicly available information that has been combined with non-public information.
						</p>
	 
						<br/>
						<h4>What Information we collect </h4>
	 
						<p>
							From Individuals – we request for your user name, password, phone number, email address.
						</p>
	 
						<br/>
						<h4>What we access in user’s device </h4>
	 
						<p>
							Greymate Care seeks users’ permission and when granted can access the following in user’s device:
							<br/>
	 
							Push notifications for SMS and in-app messages: to receive notification and SMS to accept of decline the invite.
							<br/>
	 
							Camera: for uploading of profile pictures,
	 
							Files / photo gallery: for uploading picture and files.
	 
	 
						</p>
	 
						<br/>
						<h4>How we use the information we collect:</h4>
	 
						<p>
							We use the information we collect to provide the Service to you. We are committed to showing you contents that are relevant and personal to you and in order to do that, it is necessary for us to use your information to:
						</p>
	 
						<p>
							- To operate and maintain your account, and to provide you with access to the use of the Apps and Services that you may request from time to time. Your username, email address and password are used to identify you when you sign into the Platform. Your device-IDs are used to ensure that you are in control of the devices that have access to your Account.
							<br/>
							- To provide you with technical support, update or rectify your account <br/>
							- To respond to your questions and comments. <br/>
							- To prevent or take action against activities that are, or maybe, in breach of our Terms of service or applicable law
							<br/>
							- For marketing, sales and promotional activities <br/>
							- For product development, to build higher quality and more useful services <br/>
							- For security purposes and other purposes stated in these policies
						</p>
	 
						<p>
							By submitting Your Information to us, you give us your Consent to use Your Information for the above purposes. Be assured that where we decide other options for processing your data, we will inform you.
						</p>
	 
						<br/>
	 
						<h4>
							Our Security Measures and Information about Data Retention
						</h4>
	 
						<p>
							We take steps to protect your Personal Information from unauthorized access and against unlawful processing, accidental loss, destruction and damages. To ensure the safety of Your Information, we have secured our web services to run within a virtual private All your key information is properly encrypted and secured.
						</p>
	 
						<p>
							We have qualified and highly trained staff who are specialized in the management of keeping Your Information safe and will always attend to you whenever you encounter problems when accessing the Greymate Care Platform.
						</p>
	 
						<p>
							Unfortunately, no method of transmission of information over the internet or electronic storage is not completely secure and although we will take steps to protect your Personal Information, we cannot guarantee the absolute security of your personal information. We shall however inform you where we notice any data breach.
						</p>
	 
						<p>
							We only keep your Personal Information online for you to access it easily and will be stored for as long as the purposes for which they were collected apply. We may delete Your Information after 2 years of inactivity from you. Inactivity is the day that marks the end of your last subscription to the service. However, we may retain Your Personal Information where there is a need for legal necessaries like invoices, audit logs, subscription information etc.
						</p>
	 
						<br/>
	 
						<h4>
							Profile Security
						</h4>
	 
						<p>
							We require you to create an account which entails you setting up a profile on the Greymate Care Platform. You are solely responsible for maintaining the confidentiality as it relates to information like authentication details of your account, access details of your account on the platform and your password. We advise that any device used to access this site should be kept secured to prevent unauthorized persons having access to your card or account details, profile details, Password and other information.  We are not liable for any loss you suffer from your inability to prevent unauthorized persons having access to your device, password, card or account details and other information.
						</p>
	 
						<br/>
	 
						<h4>
							Confidential /Disclosure of information
						</h4>
	 
						<p>
							The information you provide us and your captured Information will be kept confidential and we shall only disclose such information in accordance with the following below;
						</p>
	 
						<p>
							Service providers, Vendors, and Contractors (collectively, “Service providers”):- We may disclose Your Information to our service providers that assist us in providing the services to you. These service providers are necessary, therefore their access and use of Your Information is limited. They are obligated and shall keep all of Your Information confidential and will not disclose it to any persons. Our service providers are based in all parts of the world. Be rest assured that their access to Your Information is limited and they will not disclose Your Information.
						</p>
	 
						<p>
							Legal Disclosure: - Your Information may be disclosed to a court or Governmental authority to respond to subpoenas, court orders, or other legal processes; to establish or exercise our legal rights, to investigate, prevent or take action regarding illegal or suspected illegal activities or to protect our property; to protect other users, to defend against legal claims; or as otherwise required by law.
						</p>
	 
						<p>
							Employees, and Sub-processors:- We may disclose Your Information to our employees, representatives, agents and affiliates provided that such third party shall take measures to keep the information confidential and abide by this terms of service.
						</p>
	 
						<p>
							We also reserve the right to transfer Your Information in the event we sell or transfer all or a portion of our business to a different owner. Where we transfer our rights to a different partner, we will ensure that the new owner complies with this privacy policy.
						</p>
						<br/>
						<h4>
							Marketing and Opt-Out Policy
						</h4>
	 
						<p>
							By providing you our services, you will receive newsletters, marketing information; roadmaps for this service and other notification which enable you to enjoy better services from us. You can decide not to receive the aforementioned notices by clicking the unsubscribe link on the messages. Kindly notify the means in which our marketing details may be sent (SMS, phone calls, email) to support@greymatecare.com.  You also have the ability to opt out at any time from the use of your personal information for direct marketing purposes by sending us a request to opt out of direct marketing.
						</p>
	 
						<p>
							Based on the expectation of a successful engagement, You give us permission to use your name & logo in our marketing materials. You also give us permission to create a customer success story explaining usage of the product and provide customer reference calls upon request.
						</p>
	 
						<br/>
	 
						<h4>
							Rights to Your Information
						</h4>
	 
						<p>
							As a user, you have certain rights/control over the information you submit to us.  You have the right;
						</p>
	 
						<p>
							- to access and confirm Your Information. <br/>
							- to withdraw your consent form processing Your Information (this does not affect already processed information). <br/>
							- to rectify or update any inaccurate or outdated information. <br/>
							- to know the purpose for processing Your Information. <br/>
							- to restrict the processing of Your Information. <br/>
							- to erase any information we hold about you. <br/>
							- to request for a copy of the information we keep about you. <br/>
							- to object/refuse Your Information for direct marketing. <br/>
						</p>
	 
						<p>
							When you make a request to exercise any of the above rights, we shall provide you with your personal information and other necessary information as requested by you. You agree to provide us with any necessary information we ask you to confirm your identity.
						</p>
	 
						<p>
							We reserve the right to charge you or refuse your request where we notice that your request is repetitive or excessive.
						</p>
	 
						<p>
							Your rights to an erasure of Your Information does not apply to legal necessaries like invoices, audit logs, subscription information, and Your Information archived on our backup systems which we shall securely isolate and protect the use of it from any further processing, to the extent required by applicable law etc.
						</p>
						<br/>
	 
						<h4>
							Ownership of Data and other Property
						</h4>
	 
						<p>
							As between you and us, you own all rights to the information you provide us. When you use this service you give us a license to use, host and store your data. The rights you grant us in this license are for the limited purpose of operating, promoting, improving our services and to develop new ones. The license continues after you stop using our service and the license shall be used in accordance with the terms of these policies. Ensure that you have the necessary rights you grant us to make use of this data.
						</p>
						<br/>
	 
						<h4>
							Limitation of Liability
						</h4>
	 
						<p>
							Where you notice any data breach, kindly inform us immediately. Parties agree to settle any dispute relating to breach of data by negotiation. To the maximum extent permitted by applicable law, our liability for whatever cause, whether in contract or in tort, or otherwise, will be limited to money damages and shall not exceed the amount corresponding to the last 2 months invoiced to or paid by you for the service. We shall not be liable for any claim if no written notice is received by us within one month after the occurrence of the breach.
						</p>
	 
						<p>
							Where a jurisdiction does not provide for limitation of liabilities or exclusion of liability, in such case, our liability shall be the minimum permitted under such applicable law.
						</p>
	 
						<br/>
	 
						<h4>
							Controller and Processor
						</h4>
	 
						<p>
							You are the Controller and we are the processor. We also have sub-processors. These sub-processors assist us in providing these services to you. They include: <br/>
							- Google Analytics <br/>
							- Cloudinary <br/>
							- Paystack <br/>
						</p>
						
	 
						<br/>
						<h4>
							Change in a Privacy Policy
						</h4>
	 
						<p>
							In order to conform to recent laws and regulations we may decide to change this policy. When we do, we may give you notice of such update. However, we advise that you constantly visit these policies, any time you decide to make use of our service. We will display the last date of which the policy was updated. Your continued use of this service after the effective date of such changes constitutes your acceptance of such changes.
						</p>
						<br/>
						<h4>
							Questions, Suggestions, and Complaints
						</h4>
	 
						<p>
						If you would like to receive further information about this policy or any of our safeguards or if you have any privacy-related questions, suggestions, unresolved problems, or complaints you may contact via:
							<br/>
	 
							Email: <a href="mailto:support@greymatecare.com">support@greymatecare.com</a><br/>
							Phone number: <a href="tel:09090904226">09090904226</a><br/>
							Or visit us at: <a href="mailto:support@greymatecare.com">support@greymatecare.com</a><br/>
	 
							<br/>
							You can also contact our Data Protection Officer @ <a href="mailto:support@greymatecare.com">support@greymatecare.com</a>
						</p>
            </div>
    <Footer/>
    </>
  )
}

export default PrivacyPolicy