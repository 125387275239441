import React,  {useEffect} from "react";
import DefaultModal from "../default_modal/DefaultModal";
import "./request_demo_modal.css";
import { toast } from "react-toastify";
import { useForm } from "react-hook-form";
import { FormInputField } from "../custom_form_component/CustomFormComponent";
import { useSelector, useDispatch } from "react-redux";
import { LoadingBar } from "../loading_bar/LoadingBar";
import {
	createDemoRequest,
	reset,
} from "../../features/request_demo/demoRequestSlice";
import formValidator from "../../misc/validator";

const formDefaults = {
	name: "",
	phone_number: "",
	email: "",
	organization: "",
	job_title: "",
};

function RequestDemoModal({ open, onClose }) {
	const {
		handleSubmit,
		control,
		reset: resetForm,
		formState: { errors },
	} = useForm({
		defaultValues: formDefaults,
	});

	const dispatch = useDispatch();
	const ref = React.useRef(null);

	const { data, isError, isSuccess, isLoading, message } = useSelector(
		(state) => state.demoRequest
	);
	React.useEffect(() => {
		if (isError) {
			toast.error(message);
			ref.current.complete();
		}

		if (isSuccess && data) {
			ref.current.complete();
			toast.success("Demo Request Sent Successfully!");
			resetForm(formDefaults);
			onClose();
		}

		dispatch(reset());
	}, [data, isError, isSuccess, message, onClose, resetForm, dispatch]);
	const onSubmit = (data) => {
		ref.current.continuousStart();
		dispatch(createDemoRequest(data));
	};

	useEffect(() => {
		document.title = "Request Demo - Greymate Care";
	}, []);

	return (
		<>
			<LoadingBar ref={ref} shadow={true} />
			<DefaultModal
				title="Request a demo"
				desc="Fill the form to request a demo"
				open={open}
				onClose={onClose}
			>
				<h2 className="default__modal_title">Request a demo</h2>
				<div className="request__model__form">
					<form onSubmit={handleSubmit(onSubmit)}>
						<div className="form__row">
							<FormInputField
								name="name"
								label="Full Name"
								placeholder="eg. John"
								control={control}
								errors={errors}
							/>
						</div>

						<div className="form__row">
							<FormInputField
								name="phone_number"
								label="Phone Number"
								rules={{
									required: {
										value: "required",
										message: "Please enter your phone number",
									},
									validate: formValidator.phoneValidator,
								}}
								type="tel"
								placeholder="e.g +23494394493"
								control={control}
								errors={errors}
							/>
						</div>

						<div className="form__row">
							<FormInputField
								name="email"
								label="Work Email"
								type="email"
								rules={{
									required: {
										value: "required",
										message: "Please enter your work email",
									},
									validate: formValidator.emailValidator,
								}}
								placeholder="e.g you@example.com"
								control={control}
								errors={errors}
							/>
						</div>

						<div className="form__row">
							<FormInputField
								name="organization"
								label="Organization"
								rules={{}}
								placeholder="Enter Organization"
								control={control}
								errors={errors}
							/>
						</div>

						<div className="form__row">
							<FormInputField
								name="job_title"
								label="Job Title"
								rules={{}}
								placeholder="Enter Job Title"
								control={control}
								errors={errors}
							/>
						</div>

						<div className="form__buttons">
							<button
								className="button__primary"
								type="submit"
								disabled={isLoading}
							>
								Submit Details
							</button>
						</div>
					</form>
				</div>
			</DefaultModal>
		</>
	);
}

export default RequestDemoModal;
