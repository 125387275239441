import React, { createContext, useReducer, useContext } from "react";

const ContactFormContext = createContext();

function formReducer(state, action) {
  switch (action.type) {
    case "REGISTER_DATA_CHANGE":
      return { ...state, registerData: action.payload };
    case "UPDATE_SERVICE_USER_DATA":
      return { ...state, service_user: {...state.service_user, ...action.payload} };
    case "UPDATE_PREFERENCE_DATA":
      return { ...state, preference: {...state.preference, ...action.payload} };
    default:
      throw new Error();
  }
}

const initialState = {
  registerData: {
    first_name: "",
    last_name: "",
    email: "",
    phone_number: '',
    gender: '',
    password: "",
    password_confirmation: "",
    agree: "",
  },
  service_user: {
      first_name: '',
      last_name: '',
      phone_number: '',
      gender: '',
      date_of_birth: '',
      relationship: '',
      address: '',
      address_desc: '',
      lat: null,
      lng: null,
      services: [],
      special_conditions: [],
      other_conditions: '',
      note: '',
      mobility: '',
      alertness: '',
      aggression_level: null,
      current_medication: '',
  },
  preference: {
    start_date: '',
    start_time: '',
    gender: '',
    language: [],
    living_arrangements: '',
    extra_service: '',
    extra_items: '',
},
};

export const SponsorFormProvider = function({ children }) {
  const [state, dispatch] = useReducer(formReducer, initialState);

  return (
    <ContactFormContext.Provider value={{ state, dispatch }}>
      {children}
    </ContactFormContext.Provider>
  );
};

export function useSponsorFormState() {
  const context = useContext(ContactFormContext);

  if (context === undefined) {
    throw new Error(
      "useSponsorFormState must be used within a SponsorFormProvider"
    );
  }

  return context;
}
