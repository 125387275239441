import {createSlice, createAsyncThunk} from '@reduxjs/toolkit'
import { Routes } from '../../core/routes/apiRoutes'
import demoRequestService from './requestDemoService'

const initialState = {
    data: null,
    isError: false,
    isSuccess: false,
    isLoading: false,
    message: '',
    errors: null,
}

//Create Demo Request
export const createDemoRequest = createAsyncThunk(Routes.createDemoRequestUrl, async (data, thunkAPI) => {
    try{
        return await demoRequestService.requestDemo(data);
    }catch (error) {
        var errorData = {message: (error.response && error.response.data && error.response.data.message) || error.message || error.toString()}
        if(error.response.status === 422){
            errorData['errors'] = error.response.data.errors;
            
        }
        return thunkAPI.rejectWithValue(errorData);
    }
})


//Get requests
export const getDemoRequests = createAsyncThunk(Routes.getDemoRequestsUrl, async ( url, thunkAPI) => {
    try{
        return await demoRequestService.getRequests(url);
    }catch (error) {
        var errorData = {message: (error.response && error.response.data && error.response.data.message) || error.message || error.toString()}
        if(error.response.status === 422){
            errorData['errors'] = error.response.data.errors;
            
        }
        return thunkAPI.rejectWithValue(errorData);
    }
})

//delete request
export const deleteDemoRequest = createAsyncThunk(Routes.getDemoRequestsUrl+"/delete", async ( id, thunkAPI) => {
    try{
        return await demoRequestService.deleteRequest(id);
    }catch (error) {
        var errorData = {message: (error.response && error.response.data && error.response.data.message) || error.message || error.toString()}
        if(error.response.status === 422){
            errorData['errors'] = error.response.data.errors;
            
        }
        return thunkAPI.rejectWithValue(errorData);
    }
})

//change Status
export const changeStatus = createAsyncThunk(Routes.changeDemoRequestStatusUrl, async (data, thunkAPI) => {
    try{
        return await demoRequestService.changeStatus(data.id,data.data);
    }catch (error) {
        var errorData = {message: (error.response && error.response.data && error.response.data.message) || error.message || error.toString()}
        if(error.response.status === 422){
            errorData['errors'] = error.response.data.errors;
            
        }
        return thunkAPI.rejectWithValue(errorData);
    }
})




export const demoRequestSlice = createSlice({
    name: 'demoRequest',
    initialState,
    reducers: {
        reset: (state) => {
            state.isLoading = false;
            state.isSuccess = false;
            state.isError = false;
            state.message = '';
            state.errors = null;
            state.data = null;
        }
    }, 
    extraReducers: (builder) => {
        function sharedRejectedReducer(state, action){
            state.isLoading = false
            state.isError = true
            state.message = action.payload.message
            state.errors = action.payload.errors
        }
        function sharedPendingReducer(state, action){
            state.isLoading = true
        }

        builder
        .addCase(createDemoRequest.pending,sharedPendingReducer)
        .addCase(createDemoRequest.rejected, sharedRejectedReducer)
        .addCase(getDemoRequests.pending,sharedPendingReducer)
        .addCase(getDemoRequests.rejected, sharedRejectedReducer)
        .addCase(deleteDemoRequest.pending,sharedPendingReducer)
        .addCase(deleteDemoRequest.rejected, sharedRejectedReducer)
        .addCase(changeStatus.pending,sharedPendingReducer)
        .addCase(changeStatus.rejected, sharedRejectedReducer)
       
        .addCase(createDemoRequest.fulfilled, (state,action) => {
            state.isLoading = false
            state.isSuccess = true
            state.isError = false
            state.data = action.payload
            state.errors = null
        })

        .addCase(deleteDemoRequest.fulfilled, (state,action) => {
            state.isLoading = false
            state.isSuccess = true
            state.message = action.payload.message
            state.isError = false
            state.errors = null
        })

        .addCase(getDemoRequests.fulfilled, (state,action) => {
            state.isLoading = false
            state.isSuccess = true
            state.message = action.payload.message
            state.isError = false
            state.errors = null
            state.data = action.payload
        })

        //change Status
        .addCase(changeStatus.fulfilled, (state,action) => {
            state.isLoading = false
            state.isSuccess = true
            state.message = action.payload.message
            state.isError = false
            state.errors = null
        })
    }
})

export const {reset} = demoRequestSlice.actions
export default demoRequestSlice.reducer