import React from 'react'
import {getDiscountBadge } from '../../misc/helpers';

import { DetailsBody, DetailsButton, DetailsFooter, DetailsHeader, DetailsHeaderDesc, DetailsLabel, DetailsList, DetailsPanelStyled, DetailsValue, Flex } from '../../styles/admin/DetailsPanel';
import Badge from '../badge/Badge';

function DetailListWrapper(props){
    return (
        <DetailsList>
            <DetailsLabel>
                <span>{props.label}</span>
            </DetailsLabel>
            <DetailsValue>
                <span>{props.value}</span>
            </DetailsValue>
        </DetailsList>
    );
}

function ViewDiscountPanel(props) {
    const badge = getDiscountBadge(props.data.status);

  return (
    <DetailsPanelStyled>
        <DetailsHeader>
            <Flex>
                <DetailsHeaderDesc>
                    <h3>{props.data.codeName}</h3>
                </DetailsHeaderDesc>
            </Flex>
            <div>
                <Badge {...badge.badgeType}>{badge.badgeText}</Badge>
            </div>
        </DetailsHeader>
        <DetailsBody padding="2rem">
            <DetailListWrapper label="Name" value={props.data.codeName}/>
            <DetailListWrapper label="Date created" value={props.data.dateCreated}/>
            <DetailListWrapper label="Percentage" value={props.data.percentage}/>
            <DetailListWrapper label="Code" value={props.data.code}/>
        </DetailsBody>
        <DetailsFooter>
            <div>
               
            </div>
            <div>
                <DetailsButton  onClick={props.onDisableCode} marginRight="2rem" danger>Disable Code</DetailsButton>
                <DetailsButton  onClick={props.onDeleteCode} primary>Delete Code</DetailsButton>
            </div>
        </DetailsFooter>
    </DetailsPanelStyled>
  )
}

export default ViewDiscountPanel