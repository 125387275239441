import { useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import {reset } from "../../../features/auth/authSlice";
import { useForm, Controller } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";

import logo from "../../../assets/logo.png";
import BannerHome from "../../../assets/banner_home.svg";
import {
	BannerDesc,
	FormBodyWrapper,
	FormError,
	FormPanel,
	FormPanelBanner,
	FormPanelBody,
	FormPanelBodySection,
	FormPanelBodySectionHeader,
	FormPanelButton,
	FormPanelControl,
	FormPanelFooter,
	FormPanelHeader,
	FormPanelWrapper,
} from "../../../styles/FormPanel.Styled";
import { CheckBox, DefaultTextField } from "../../../components/custom_form_component/CustomFormComponent";
import { Flex } from "../../../styles/admin/DetailsPanel";
import formValidator from "../../../misc/validator";

function Register() {
	const {
		handleSubmit,
		control,
		watch,
		reset: resetForm,
		formState: { errors },
	} = useForm({
		defaultValues: {
			first_name: "",
			last_name: "",
			email: "",
			password: "",
			confirmPassword: "",
			agree: "",
		},
	});

	const password = useRef({});
	password.current = watch("password", "");

	const ref = useRef(null);
	const dispatch = useDispatch();

	const { isError, isSuccess, message } = useSelector(
		(state) => state.auth
	);

	useEffect(() => {
		if (isError) {
			toast.error(message);
			ref.current.complete();
		}

		if (isSuccess) {
			resetForm();
			toast.success(message);
			ref.current.complete();
		}

		dispatch(reset());
	}, [isError, isSuccess, resetForm, message, dispatch]);

	const onSubmit = (data) => {
		
	};

	return (
		<>
			<FormPanelWrapper>
				<FormPanel width="1201px" as="form" onSubmit={handleSubmit(onSubmit)}>
					<FormPanelHeader>
						<FormPanelHeader>
							<Link to="/" alt="home">
								<img src={logo} alt="Greymate Care Logo" />
							</Link>
						</FormPanelHeader>
					</FormPanelHeader>
					<FormPanelBody>
						<Flex alignItems="stretch">
							<FormPanelBanner>
								<img src={BannerHome} alt="Home" />
								<BannerDesc top>
									<p>
										We are fast changing the frontiers on home health in Africa
									</p>
								</BannerDesc>

								<BannerDesc bottom>
									<p>We care for your loved ones</p>
								</BannerDesc>
							</FormPanelBanner>
							<FormBodyWrapper padding="4rem 3rem">
								<FormPanelBodySection>
									<FormPanelBodySectionHeader>
										<h1>Get Started as a Sponsor</h1>
									</FormPanelBodySectionHeader>
									<FormPanelControl>
										<Controller
											name="first_name"
											control={control}
											rules={{
												required: {
													value: "required",
													message: "Please enter your first name",
												},
											}}
											render={({ field: { onChange, value } }) => (
												<DefaultTextField
													label="First Name"
													type="text"
													name="first_name"
													placeholder="John"
													onChange={onChange}
													value={value}
												/>
											)}
										/>
										<ErrorMessage
											errors={errors}
											name="first_name"
											as={<FormError />}
										/>
									</FormPanelControl>
									<FormPanelControl>
										<Controller
											name="last_name"
											control={control}
											rules={{
												required: {
													value: "required",
													message: "Please enter your last name",
												},
											}}
											render={({ field: { onChange, value } }) => (
												<DefaultTextField
													label="Last Name"
													type="text"
													name="last_name"
													placeholder="Doe"
													onChange={onChange}
													value={value}
												/>
											)}
										/>
										<ErrorMessage
											errors={errors}
											name="last_name"
											as={<FormError />}
										/>
									</FormPanelControl>
									<FormPanelControl>
										<Controller
											name="email"
											control={control}
											rules={{
												required: {
													value: "required",
													message: "Please enter your email",
												},
												validate: formValidator.emailValidator,
											}}
											render={({ field: { onChange, value } }) => (
												<DefaultTextField
													label="Email"
													type="email"
													name="email"
													placeholder="you@example.com"
													onChange={onChange}
													value={value}
												/>
											)}
										/>
										<ErrorMessage
											errors={errors}
											name="email"
											as={<FormError />}
										/>
									</FormPanelControl>
									<FormPanelControl>
										<Controller
											name="password"
											control={control}
											rules={{
												required: {
													value: "required",
													message: "Please enter a your password",
												},
											}}
											render={({ field: { onChange, value } }) => (
												<DefaultTextField
													label="New Password"
													type="password"
													name="password"
													placeholder="Enter password here"
													value={value}
													onChange={onChange}
												/>
											)}
										/>
										<ErrorMessage
											errors={errors}
											name="password"
											as={<FormError />}
										/>
									</FormPanelControl>
									<FormPanelControl>
										<Controller
											name="password_confirmation"
											control={control}
											rules={{
												required: {
													value: "required",
													message: "Please enter a your password",
												},
												validate: {
													sameAsPassword: (value) =>
														value === password.current ||
														"The passwords do not match",
												},
											}}
											render={({ field: { onChange, value } }) => (
												<DefaultTextField
													label="Confirm Password"
													type="password"
													name="password_confirmation"
													placeholder="Confirm password here"
													value={value}
													onChange={onChange}
												/>
											)}
										/>
										<ErrorMessage
											errors={errors}
											name="password_confirmation"
											as={<FormError />}
										/>
									</FormPanelControl>
									<FormPanelControl>
									<Controller
											name="agree"
											control={control}
											rules={{
												required: {
													value: "required",
													message: "dafafafa",
												},
											}}
											render={({ field: { onChange, value } }) => (
												<CheckBox label="I consent to the collection and processing of my personal data in line with data regulations as described in the Greymate Care Privacy Policy" name="agree"  onChange={onChange} value={value}/>
											)}
										/>
										<ErrorMessage
											errors={errors}
											name="agree"
											as={<FormError />}
										/>
										
									</FormPanelControl>
									<FormPanelControl>
										<label>By clicking the “Create your account” button, you agree to Greymate Care terms of acceptable use.</label>
									</FormPanelControl>
									<FormPanelFooter center>
										<FormPanelButton fullWidth type="submit">Next</FormPanelButton>
									</FormPanelFooter>
									
								</FormPanelBodySection>
							</FormBodyWrapper>
						</Flex>
					</FormPanelBody>
				</FormPanel>
			</FormPanelWrapper>
		</>
	);
}

export default Register;
