import React, { useEffect, useState } from "react";
import {
	AdminModal,
	Badge,
	CustomDataTable,
	PopupMenu,
	ServiceUserPanel,
} from "../../../components";
import { DashboardRow } from "../../../styles/admin/CardBox.styled";
import {
	deleteServiceUser,
	getServiceUsers,
	reset,
} from "../../../features/service_user/serviceUserSlice";
import { Routes } from "../../../core/routes/apiRoutes";
import { useSelector } from "react-redux";
import moment from "moment";
import useResource from "../../../core/hooks/useResource";
import { LoadingBar } from "../../../components/loading_bar/LoadingBar";
import { getAssignCaregiversBadge } from "../../../misc/helpers";
import Can from "../../../components/auth_gate/Can";
import { CREATE_AND_MANAGE_SERVICE_USERS } from "../../../misc/constants";

const perPage = 30;
let modalData;
function ServiceUsers() {
    const [open, setOpen] = useState(false);

	const handleOpen = (id) => {
		modalData = tableData.filter((item) => item.id === id);
		if (modalData) {
			modalData = modalData[0];
			setOpen(true);
		}
	};

	const handleClose = () => setOpen(false);

	const prepareTableData = (response, page) => {
		var rows = [];
		const tempData = response.data;

		for (let i = 0; i < tempData.length; i++) {
			const itemData = tempData[i];
			const itemDataSponsor = itemData?.sponsor;
			const itemCaregiver = itemData?.caregiver;
			var item = {
				id: itemData.id,
				sn: i + 1 + page,
				name: itemData.last_name + " " + itemData.first_name,
				email: itemDataSponsor?.email,
				plan: itemDataSponsor?.subscription?.subscription_type_name,
				sponsor: !itemDataSponsor ?'':
                    itemDataSponsor?.last_name +
					" " +
					itemDataSponsor?.first_name,
				dateCreated: moment.unix(itemData.joined_at).fromNow(),
				status: itemData.status,
			};

            item['profile'] =  {
                "Full Name": item.name,
                "Email": item.email,
                "Sponsor": item.sponsor,
                'Plan': item.plan,
                'Bio': itemData?.profile?.note,
            };
    
            item['caregiver'] =  {
				"Full Name":  itemCaregiver?.last_name + " " + itemCaregiver?.first_name,
				"Gender":  itemCaregiver?.gender,
				"Phone Number":  itemCaregiver?.phone_number,
			};

			item["paymentHistory"] = itemDataSponsor?.transactions;

			rows.push(item);
		}

		return rows;
	};

	const {
		onPrevPage,
		onNextPage,
		page,
		tableData,
		ref,
		isLoading,
		onDelete,
		onSearch,
	} = useResource({
		selector: useSelector((state) => state.serviceUser),
		perPage: perPage,
		getReducer: getServiceUsers,
		resetReducer: reset,
		deleteReducer: deleteServiceUser,
		renderTableData: prepareTableData,
		routes: {
			getRoute: Routes.getServiceUsersUrl,
		},
	});

	const actions = Can(CREATE_AND_MANAGE_SERVICE_USERS)?[
		{ title: "View Details", onClick: handleOpen },
		{ title: "Delete", onClick: onDelete },
	] : [{ title: "View Details", onClick: handleOpen }];

    useEffect(() => {
        document.title = "Service Users - Greymate Care Admin";
    }, []);


    const columns = [
        {
            field: "sn",
            headerName: "S/N",
            minWidth: 10,
            flex: 1
        },
        {
            field: 'name',
            headerName: 'Full Name',
            minWidth: 100,
            flex: 2
        },
        {
            field: 'email',
            headerName: 'Sponsor\'s Email',
            minWidth: 100,
            flex: 2
        },
        {
            field: 'sponsor',
            headerName: 'Sponsor\'s Name',
            minWidth: 100,
            flex: 2
        },
        
        {
            field: 'plan',
            headerName: 'Plan',
            minWidth: 100,
            flex: 2
        },
        {
            field: 'status',
            headerName: 'Status',
            minWidth: 100,
            flex: 2,
            sortable: false,
            renderCell: (params) => {
                const badge = getAssignCaregiversBadge(params.row.status);
                return (<Badge {...badge.badgeType}>{badge.badgeText}</Badge>);
            },
            disableClickEventBubbling: true,
        },
        {
			field: "actions",
			headerName: "",
			minWidth: 100,
			flex: 1,
			sortable: false,
			renderCell: (params) => {
				return <PopupMenu id={params.row.id} options={actions} />;
			},
			disableClickEventBubbling: true,
		},
    ];


    return (
        <>
            <LoadingBar ref={ref} shadow={true} />
            <h1>Service Users</h1>

            <DashboardRow>
                <CustomDataTable
					disableSelectionOnClick
					pageSize={perPage}
					pagination="false"
					rowsPerPageOptions={[]}
					loading={isLoading}
					columns={columns}
					rows={tableData}
					hideFooter={true}
					prev={onPrevPage}
					next={onNextPage}
					currentPage={page}
					onSearchChange={onSearch}
				/>
            </DashboardRow>
            <AdminModal open={open} onClose={handleClose}>
                {modalData !== null && (
                    <ServiceUserPanel data={modalData} onSendMessage={handleClose} onDeny="" />
                )}

            </AdminModal>
        </>

    )
}

export default ServiceUsers