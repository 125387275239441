import {createSlice, createAsyncThunk} from '@reduxjs/toolkit'
import { Routes } from '../../core/routes/apiRoutes'
import meetingRequestService from './requestMeetingService'

const initialState = {
    data: null,
    isError: false,
    isSuccess: false,
    isLoading: false,
    message: '',
    errors: null,
}

//Create Meeting Request
export const createMeetingRequest = createAsyncThunk(Routes.createMeetingRequestUrl, async (data, thunkAPI) => {
    try{
        return await meetingRequestService.requestMeeting(data);
    }catch (error) {
        var errorData = {message: (error.response && error.response.data && error.response.data.message) || error.message || error.toString()}
        if(error.response.status === 422){
            errorData['errors'] = error.response.data.errors;
            
        }
        return thunkAPI.rejectWithValue(errorData);
    }
})

//Get requests
export const getMeetingRequests = createAsyncThunk(Routes.getMeetingRequestsUrl, async ( url, thunkAPI) => {
    try{
        return await meetingRequestService.getRequests(url);
    }catch (error) {
        var errorData = {message: (error.response && error.response.data && error.response.data.message) || error.message || error.toString()}
        if(error.response.status === 422){
            errorData['errors'] = error.response.data.errors;
            
        }
        return thunkAPI.rejectWithValue(errorData);
    }
})

//delete request
export const deleteMeetingRequest = createAsyncThunk(Routes.getMeetingRequestsUrl+"/delete", async ( id, thunkAPI) => {
    try{
        return await meetingRequestService.deleteRequest(id);
    }catch (error) {
        var errorData = {message: (error.response && error.response.data && error.response.data.message) || error.message || error.toString()}
        if(error.response.status === 422){
            errorData['errors'] = error.response.data.errors;
            
        }
        return thunkAPI.rejectWithValue(errorData);
    }
})

//change Status
export const changeStatus = createAsyncThunk(Routes.changeMeetingRequestStatusUrl, async (data, thunkAPI) => {
    try{
        return await meetingRequestService.changeStatus(data.id,data.data);
    }catch (error) {
        var errorData = {message: (error.response && error.response.data && error.response.data.message) || error.message || error.toString()}
        if(error.response.status === 422){
            errorData['errors'] = error.response.data.errors;
            
        }
        return thunkAPI.rejectWithValue(errorData);
    }
})



export const meetingRequestSlice = createSlice({
    name: 'meetingRequest',
    initialState,
    reducers: {
        reset: (state) => {
            state.isLoading = false;
            state.isSuccess = false;
            state.isError = false;
            state.message = '';
            state.errors = null;
            state.data = null;
        }
    }, 
    extraReducers: (builder) => {
        function sharedRejectedReducer(state, action){
            state.isLoading = false
            state.isError = true
            state.message = action.payload.message
            state.errors = action.payload.errors
        }
        function sharedPendingReducer(state, action){
            state.isLoading = true
        }

        builder

        .addCase(createMeetingRequest.pending,sharedPendingReducer)
        .addCase(createMeetingRequest.rejected, sharedRejectedReducer)
        .addCase(getMeetingRequests.pending,sharedPendingReducer)
        .addCase(getMeetingRequests.rejected, sharedRejectedReducer)
        .addCase(deleteMeetingRequest.pending,sharedPendingReducer)
        .addCase(deleteMeetingRequest.rejected, sharedRejectedReducer)
        .addCase(changeStatus.pending,sharedPendingReducer)
        .addCase(changeStatus.rejected, sharedRejectedReducer)

        .addCase(createMeetingRequest.fulfilled, (state,action) => {
            state.isLoading = false
            state.isSuccess = true
            state.isError = false
            state.data = action.payload
            state.errors = null
        })

        .addCase(deleteMeetingRequest.fulfilled, (state,action) => {
            state.isLoading = false
            state.isSuccess = true
            state.message = action.payload.message
            state.isError = false
            state.errors = null
        })

        .addCase(getMeetingRequests.fulfilled, (state,action) => {
            state.isLoading = false
            state.isSuccess = true
            state.message = action.payload.message
            state.isError = false
            state.errors = null
            state.data = action.payload
        })

        //change Status
        .addCase(changeStatus.fulfilled, (state,action) => {
            state.isLoading = false
            state.isSuccess = true
            state.message = action.payload.message
            state.isError = false
            state.errors = null
        })
    }
})

export const {reset} = meetingRequestSlice.actions
export default meetingRequestSlice.reducer