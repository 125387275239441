import React, {useState, useEffect} from 'react';
import { NavLink, Link } from "react-router-dom";
import {RiMenu3Line, RiCloseLine} from 'react-icons/ri';
import logo from '../../assets/logo.png';
import './navbar.css';

const Menu = () => (
  <>
    <p><NavLink to="/become-caregiver">Become a caregiver</NavLink></p>
    <p><NavLink to="/about-us">About Us</NavLink></p>
    <p><NavLink to="/pricing">Pricing</NavLink></p>
    <p><NavLink to="/faqs">FAQs</NavLink></p>
    <p><NavLink to="/contact-us">Contact Us</NavLink></p>
  </>
)

const Navbar = () => {
  const [toggleMenu, setTooggleMenu] = useState(false);
  const [nav, setNav] = useState(false);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll)
  });

  const handleScroll = () => {
    window.pageYOffset > 400 ? !nav && setNav(true) : nav && setNav(false)
  }

  return (
    <div className={`gmc__navbar ${nav && 'sticky-bar'}`}>
      <div className='gmc__navbar-links'>
        <div className='gmc__navbar-links_logo'>
            <Link to="/" alt="home">
              <img src={logo} alt="Greymate Care Logo" />
            </Link>
        </div>
        <div className='gmc__navbar-links_container'>
         <Menu />
        </div>
        <div className='gmc__navbar-sign'>
          <p><Link to="/get-started" className='button__primary'>Get Started</Link></p>
        </div>
        <div className='gmc__navbar-menu'>
          {toggleMenu 
          ? <RiCloseLine color="#000" size={27} onClick={() => setTooggleMenu(false)} />
          : <RiMenu3Line color="#000" size={27} onClick={() => setTooggleMenu(true)} />
          }
          {toggleMenu && (
            <div className='gmc__navbar-menu_container scale-up-center'>
              <div className='gmc__navbar-menu_container-links'>
              <Menu />
              </div>
              <div className='gmc__navbar-menu_container-links-sign'>
                <p><Link to="/get-started" className='button__primary'>Get Started</Link></p>
              </div>
            </div>
            )
          }
        </div>
      </div>
    </div>
  )
}

export default Navbar