import React from 'react'
import { getMeetingRequestsBadge } from '../../misc/helpers';

import { DetailsBody, DetailsHeader, DetailsHeaderDesc, DetailsLabel, DetailsList, DetailsListWrapper, DetailsPanelStyled, DetailsValue, Flex } from '../../styles/admin/DetailsPanel';
import Badge from '../badge/Badge';

function RequestPanel(props) {
    const badge = getMeetingRequestsBadge(props.data.status);

  return (
    <DetailsPanelStyled>
        <DetailsHeader>
            <Flex>
                <DetailsHeaderDesc>
                    <h3>{props.data.name}</h3>
                    <p>{props.data.email}</p>
                </DetailsHeaderDesc>
            </Flex>
            <div>
                <Badge {...badge.badgeType}>{badge.badgeText}</Badge>
            </div>
        </DetailsHeader>
        <DetailsBody padding="2rem">
            <DetailsListWrapper>
                {Object.keys(props.data.details).map((key) => 
                    <DetailsList key={props.data[key]}>
                        <DetailsLabel>
                            <span>{key}</span>
                        </DetailsLabel>
                        <DetailsValue>
                            <span>{props.data.details[key]}</span>
                        </DetailsValue>
                    </DetailsList>
                )}
            </DetailsListWrapper>
        </DetailsBody>
        
    </DetailsPanelStyled>
  )
}

export default RequestPanel