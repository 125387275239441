import authApi, { api } from "../../core/api/axios/baseAxios"
import { Routes } from "../../core/routes/apiRoutes";

const getPublicSubscriptionPlans = async (url) => {
    const response = await api.get(url);
    const resData = response.data;
    return resData;
}

const getSubscriptionPlans = async (url) => {
    const response = await authApi.get(url);
    const resData = response.data;
    return resData;
}

const showSubscriptionPlan = async (slug) => {
    const response = await authApi.get(Routes.showSubscriptionPlanUrl+`/${slug}`);
    const resData = response.data;
    return resData;
}

const deleteSubscriptionPlan = async (slug) => {
    const response = await authApi.delete(Routes.deleteSubscriptionPlanUrl+`/?slug=${slug}`);
    const resData = response.data;
    return resData;
}

const createSubscriptionPlan = async (data) => {
    const response = await authApi.post(Routes.createSubscriptionPlanUrl, data);
    const resData = response.data;
    return resData;
}

const updateSubscriptionPlan = async (data) => {
    const response = await authApi.put(Routes.updateSubscriptionPlanUrl, data);
    const resData = response.data;
    return resData;
}

const subscriptionPlanService = {
    getSubscriptionPlans,
    getPublicSubscriptionPlans,
    showSubscriptionPlan,
    createSubscriptionPlan,
    deleteSubscriptionPlan,
    updateSubscriptionPlan,
}

export default subscriptionPlanService