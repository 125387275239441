import React, {useEffect} from 'react'
import { ActivityCard, CardBox} from '../../../components';
import { DashboardRow } from '../../../styles/admin/CardBox.styled';
import usePublicResource from '../../../core/hooks/usePublicResource';
import { LoadingBar } from '../../../components/loading_bar/LoadingBar';
import { getActivityLogs, reset } from '../../../features/activity_log/activityLogSlice';
import { Routes } from '../../../core/routes/apiRoutes';
import { RiLoginBoxFill } from 'react-icons/ri';
import { useSelector } from 'react-redux';


function Activity() {
  const prepareTableData = (response) => {
		var rows = [];
		const tempData = response.data;
    
		for (let i = 0; i < tempData.length; i++) {
			var item = {
				key: i + 1,
				sn: i + 1,
				title: tempData[i].title,
				desc: tempData[i].subtitle,
				date: tempData[i].created,
        icon: <RiLoginBoxFill size={14}/>,
			};

			rows.push(item);
		}

		return rows;
	};

	const {data, ref} =
		usePublicResource({
			selector: useSelector((state) => state.activityLog),
			getReducer: getActivityLogs,
			resetReducer: reset,
			renderTableData: prepareTableData,
			routes: {
				getRoute: Routes.getActivityLogs,
			},
		});

	useEffect(() => {
		document.title = "Activity Logs - Greymate Care";
	}, []);
  
  const activityCards = data.map((activity) =>
    <ActivityCard {...activity} key={activity.key}/>
  );
  return (
    <>
     <LoadingBar ref={ref} shadow={true} />
      <h1>Activity log</h1>
      
      <DashboardRow>
        <CardBox width="50%">
         {activityCards}
        </CardBox>
      </DashboardRow>
    </>

  )
}

export default Activity