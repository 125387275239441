import React, { useEffect } from 'react'
import { Navbar } from '../../components'
import { Footer } from '../../containers'
import './error.css'

import BGHeader from '../../assets/bg-header.png';

function PageNotFound() {

  useEffect(() => {
    document.title = "Contact Us - Greymate Care";
  }, []);


  return (
    <>
    <div className='gmc__contact-us-background'>
      <Navbar/>
      <div className='gmc__contact-us__header section__padding section__bg__lines'  style={{ 
      backgroundImage: `url(${BGHeader})` 
    }}>
        <h1 className='section__heading'>Error 404</h1>
        <p className='section__desc'>The page you are looking for was not found or may have been moved</p>
      </div>
      
    </div>
    <Footer/>
    </>
  )
}

export default PageNotFound