import React, {useState} from 'react';
import {AdminSidebarStyled, AdminSidebarHeader, AdminSidebarHeaderBtn, AdminSidebarHeaderLogo, AdminSidebarMenu, AdminSidebarMenuFooter, AdminSidebarMenuList, MenuGroup, MenuListIcon, MenuListLink, MenuListName, DropdownMenuList } from "../../styles/admin/AdminSidebar.styled";
import {RiArrowRightSLine, RiArrowLeftSLine, RiShieldUserFill, RiNurseFill, RiBarChart2Fill, RiTeamFill, RiDashboardFill, RiSecurePaymentFill, RiMessage2Fill, RiLock2Fill } from "react-icons/ri";
import { FaUserFriends,FaCog } from "react-icons/fa";
import Logo from "../../assets/logo.png";
import {NavLink, useLocation} from 'react-router-dom';
import Can from '../auth_gate/Can';
import { VIEW_ACTIVITY_LOGS, VIEW_APP_SETTINGS, VIEW_CAREGIVERS, VIEW_CAREGIVERS_APPLICATION, VIEW_FORM_REQUESTS, VIEW_ROLES_AND_PERMISSIONS, VIEW_SERVICE_USERS, VIEW_SPONSORS, VIEW_SUBSCRIPTION_PLANS, VIEW_USERS } from '../../misc/constants';

function DropdownMenuButton(props) {

    
    const [isCollapseSidebar, setIsCollapseSidebar] = useState(false);
  
    const toggleIsCollapseNav = () => {
      setIsCollapseSidebar(!isCollapseSidebar);
    };
  
    return (
      <DropdownMenuList collapse={isCollapseSidebar ? "collapse" : ""} onClick={toggleIsCollapseNav}>
       {props.children}
      </DropdownMenuList>
    );
}



function AdminSidebar(props) {
    const location = useLocation();
    return (
        <>
        <AdminSidebarStyled collapse={props.isCollapseSidebar}>
        <AdminSidebarHeader>
            <AdminSidebarHeaderLogo>
                <a href="/">
                    <img src={Logo} alt="Greymate Care Logo" />{" "}
                    <h1>Greymate Care</h1>
                </a>
            </AdminSidebarHeaderLogo >
            <AdminSidebarHeaderBtn onClick={props.toggleIsCollapseNav}>
                <RiArrowLeftSLine size={20} />
            </AdminSidebarHeaderBtn>
        </AdminSidebarHeader>

        <AdminSidebarMenu>
            <MenuGroup>
                <AdminSidebarMenuList>
                    <li>
                        <MenuListLink as={NavLink} to="/admin/"><MenuListIcon><RiDashboardFill size={14} /></MenuListIcon> <MenuListName>Dashboard</MenuListName></MenuListLink>
                    </li>
                    {(Can(VIEW_CAREGIVERS) || Can(VIEW_CAREGIVERS_APPLICATION)) &&  <DropdownMenuButton as="li">
                        <MenuListLink as="a" className={location.pathname.includes('admin/caregivers')?'active':''}>
                            <MenuListIcon><RiShieldUserFill size={14} /></MenuListIcon>
                            <MenuListName>Caregivers</MenuListName>
                            <i><RiArrowRightSLine /></i>
                        </MenuListLink>
                        <ul>
                            {Can(VIEW_CAREGIVERS_APPLICATION) && <li><MenuListLink as={NavLink} to="/admin/caregivers/applications"><MenuListName>Applications</MenuListName></MenuListLink></li>}
                            { Can(VIEW_CAREGIVERS) && <li><MenuListLink as={NavLink} to="/admin/caregivers/assign"><MenuListName>Assign</MenuListName></MenuListLink></li>}
                        </ul>
                    </DropdownMenuButton>}
                    {Can(VIEW_SPONSORS) &&  <li>
                        <MenuListLink as={NavLink} to="/admin/sponsors"><MenuListIcon><RiNurseFill size={14} /></MenuListIcon> <MenuListName>Sponsors</MenuListName></MenuListLink>
                    </li>}
                    
                    {Can(VIEW_SERVICE_USERS) && <li>
                        <MenuListLink as={NavLink} to="/admin/service-users"><MenuListIcon><FaUserFriends size={14} /></MenuListIcon> <MenuListName>Service Users</MenuListName></MenuListLink>
                    </li>}
                    {/* <li>
                        <MenuListLink as={NavLink} to="/admin/discount" href="#menu"><MenuListIcon><RiPercentFill size={14} /></MenuListIcon> <MenuListName>Discount</MenuListName></MenuListLink>
                    </li> */}
                    {Can(VIEW_ACTIVITY_LOGS) && <li>
                        <MenuListLink as={NavLink} to="/admin/activity" href="#menu"><MenuListIcon><RiBarChart2Fill size={14} /></MenuListIcon> <MenuListName>Activity log</MenuListName></MenuListLink>
                    </li>}
                    {Can(VIEW_SUBSCRIPTION_PLANS) && <li>
                        <MenuListLink as={NavLink} to="/admin/subscription-plans" href="#menu"><MenuListIcon><RiSecurePaymentFill size={14} /></MenuListIcon> <MenuListName>Subscription Plans</MenuListName></MenuListLink>
                    </li>}
                    {Can(VIEW_USERS) && <li>
                        <MenuListLink as={NavLink} to="/admin/team-members" href="#menu"><MenuListIcon><RiTeamFill size={14} /></MenuListIcon> <MenuListName>Team members</MenuListName></MenuListLink>
                    </li>}
                    {Can(VIEW_ROLES_AND_PERMISSIONS) &&<li>
                        <MenuListLink as={NavLink} to="/admin/roles" href="#menu"><MenuListIcon><RiLock2Fill size={14} /></MenuListIcon> <MenuListName>Roles and Permissions</MenuListName></MenuListLink>
                    </li>}
                    {Can(VIEW_FORM_REQUESTS) && <DropdownMenuButton as="li">
                        <MenuListLink as="a" className={location.pathname.includes('admin/requests')?'active':''}>
                            <MenuListIcon><RiMessage2Fill size={14} /></MenuListIcon>
                            <MenuListName>Form Requests</MenuListName>
                            <i><RiArrowRightSLine /></i>
                        </MenuListLink>
                        <ul>
                            <li><MenuListLink as={NavLink} to="/admin/requests/demo-requests"><MenuListName>Demo Requests</MenuListName></MenuListLink></li>
                            <li><MenuListLink as={NavLink} to="/admin/requests/meeting-requests"><MenuListName>Meeting Requests</MenuListName></MenuListLink></li>
                        </ul>
                    </DropdownMenuButton>}
                </AdminSidebarMenuList>
            </MenuGroup>

            {Can(VIEW_APP_SETTINGS) && <AdminSidebarMenuFooter>
                <AdminSidebarMenuList>
                    <li>
                        <MenuListLink as={NavLink} to="/admin/settings" href="#menu"><MenuListIcon><FaCog size={14} /></MenuListIcon> <MenuListName>Settings</MenuListName></MenuListLink>
                    </li>
                </AdminSidebarMenuList>
            </AdminSidebarMenuFooter>}
        </AdminSidebarMenu>

    </AdminSidebarStyled>
        </>
    )
}

export default AdminSidebar
