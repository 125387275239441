import React from 'react'
import { CardBoxStyled, CardHeader, CardTitle, CardEx, CardBody} from '../../styles/admin/CardBox.styled'

function CardBox(props) {
  return (
    <>
        <CardBoxStyled  width={props.width ?? '50%'}>
            <CardHeader show={props.title ?true:false}>
                <CardTitle>{props.title}</CardTitle>
                {props.href && <CardEx><a href={props.href}>{props.linkText}</a></CardEx>}
            </CardHeader>
            <CardBody noPadding>
                {props.children}
            </CardBody>
        </CardBoxStyled>
    </>
  )
}

export default CardBox