import React, {Component} from 'react'
import { Navbar } from '../../components'
import { CaregiverReviews, Footer, Header2, HowTo, WhatsNew } from '../../containers'
import './become_caregiver.css'

export default class BecomeCaregiver extends Component {
    componentDidMount(){
        document.title = "Become a caregiver - Greymate Care";
    }
    
    render(){
        return (
            <>
            <div className='gmc__become-caregiver'>
                <Navbar />
                <Header2 /> 
                <HowTo />
            </div>
            <CaregiverReviews/>
            <WhatsNew/>
            <Footer/>
            </>
          )
    }
 
}