export const reqInterceptor = (req) => {
  const token = localStorage.getItem("token");

  if (!req.url.startsWith("https://api.cloudinary.com/v1_1/"))
    req.headers.Authorization = `Bearer ${token}`;
  return req;
};

export const sponsorReqInterceptor = (req) => {
  const token = localStorage.getItem("sponsor-token");

  if (!req.url.startsWith("https://api.cloudinary.com/v1_1/"))
    req.headers.Authorization = `Bearer ${token}`;
  return req;
};