import React from 'react';
import CircularProgress, {circularProgressClasses} from '@mui/material/CircularProgress';
import styled from 'styled-components';


const Wrapper = styled.div`
  position: relative;
  display: inline-flex;
`;

const Box = styled.div`
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ProgressLabel = styled.div`
  font-weight: 600;
  font-size: 16px;
  line-height: 32px;
`;


function CircularProgressStatic(props) {

  return (
    <Wrapper>
      <CircularProgress
        variant="determinate"
        sx={{
          color: (theme) =>
            theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
        }}
        size={100}
        thickness={5}
        value={100}
      />
      <CircularProgress
        variant="determinate"
        sx={{
          color: props.color,
          position: 'absolute',
          left: 0,
          [`& .${circularProgressClasses.circle}`]: {
            strokeLinecap: 'round',
          },
        }}
        size={100}
        thickness={5}
        value={props.value}
      />
      <Box>
        <ProgressLabel>
          {`${props.labelPrefix + Math.round(props.value)}%`}
        </ProgressLabel>
    </Box>
    </Wrapper>
  );
}

export default CircularProgressStatic;