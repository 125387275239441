import React from "react";
import moment from "moment";
import {
	FormPanelBodyHeader,
	FormPanelBodySection,
	FormPanelButton,
	FormPanelControl,
	FormPanelFooter,
} from "../../../styles/FormPanel.Styled";
import { Flex } from "../../../styles/admin/DetailsPanel";
import { useSponsorFormState } from "../../../core/context/SponsorFormContext";
import { useForm } from "react-hook-form";
import {
	FormDateField,
	FormDropdownField,
	FormRadioGroupField,
    FormMultiSelectChipField,
	FormChipSelect,
} from "../../../components/custom_form_component/CustomFormComponent";
import { ReactComponent as Cloud } from "../../../assets/cloud.svg";
import { ReactComponent as Moon } from "../../../assets/moon.svg";

function Preferences(props) {
	const {
		state: {
			preference: {
				living_arrangements,
				start_date,
				start_time,
				gender,
				language,
			},
		},
		dispatch,
	} = useSponsorFormState();

	const {
		handleSubmit,
		control,
		formState: { errors },
	} = useForm({
		defaultValues: {
			living_arrangements: living_arrangements,
			start_date:  start_date?moment(start_date,['MM/DD/YYYY']):start_date,
			start_time: start_time,
			gender: gender,
			language: language,
		},
	});

	const livingArrangements = [
		{
			label: "I want the caregiver to live with the care recipient",
			value: "livein",
		},
		{
			label: "I want the caregiver to come from their home",
			value: "independent",
		},
	];
	const genderOptions = [
		{ label: "Male", value: "male" },
		{ label: "Female", value: "female" },
		{ label: "Others", value: "others" },
		{ label: "Any", value: "any" },
	];

	const languageOptions = [
		{ label: "English", value: "english" },
		{ label: "Yoruba", value: "yoruba" },
		{ label: "Igbo", value: "igbo" },
		{ label: "Hausa", value: "hausa" },
	];

	const startTimeOptions = [
		{ label: "8am to 6pm", value: "8am to 6pm", icon: <Cloud/> },
		{ label: "8pm to 6am", value: "8pm to 6am", icon: <Moon/>},
	];

	const onSubmit = (data) => {
        data.start_date = data.start_date.format("MM/DD/YYYY");
	    dispatch({ type: "UPDATE_PREFERENCE_DATA", payload: data });
		props.onNextPage();
	};

	return (
		<form onSubmit={handleSubmit(onSubmit)}>
			<FormPanelBodyHeader>
				<h1>Preferences</h1>
				<p>
					Let us know what you would like, and we will do our best go make
					arrangements. Please note that these are based on availability.
				</p>
			</FormPanelBodyHeader>

			<FormPanelBodySection>
				<FormPanelControl>
					<FormDateField
						name="start_date"
						label="Preferred start date"
						placeholder="Preferred Caregiver Start Date"
						control={control}
						errors={errors}
					/>
				</FormPanelControl>
				<FormPanelControl>
					<FormChipSelect
						name="start_time"
						options={startTimeOptions}
						rules={{
							required: {
								value: "required",
								message:
									"Please the your preferred start time",
							},
						}}
						label="Preferred Time"
						placeholder="eg. 10am - 3pm Thursdays"
						control={control}
						errors={errors}
					/>
				</FormPanelControl>
				<FormPanelControl>
					<FormDropdownField
						name="gender"
						label="Gender"
						options={genderOptions}
						control={control}
						placeholder="Choose your gender"
						rules={{
							required: {
								value: "required",
								message: "Please select your gender",
							},
						}}
						errors={errors}
					/>
				</FormPanelControl>
				<FormPanelControl>
					<FormMultiSelectChipField
						name="language"
						label="Preferred language"
                        control={control}
						options={languageOptions}
						rules={{
							required: {
								value: "required",
								message: "Please select your preferred language",
							},
						}}
						errors={errors}
					/>
				</FormPanelControl>
			</FormPanelBodySection>

			<FormPanelBodySection>
				<FormPanelControl>
					<FormRadioGroupField
						name="living_arrangements"
						label="Preferred Living Arrangements"
						options={livingArrangements}
                        control={control}
						rules={{
							required: {
								value: "required",
								message: "Please select your preferred living arrangement",
							},
						}}
						errors={errors}
					/>
				</FormPanelControl>
			</FormPanelBodySection>
			<FormPanelFooter>
				<Flex justifyContent="space-between">
					<FormPanelButton secondary onClick={props.onPrevPage}>
						Back
					</FormPanelButton>
					<FormPanelButton type="submit">Save and Continue</FormPanelButton>
				</Flex>
			</FormPanelFooter>
		</form>
	);
}

export default Preferences;
