import styled, {css} from "styled-components";


export const Plan = styled.div`
    padding: 40px;
    background: #F2F2F6;
    transition: .5s background ease-in;
    border-radius: 16px;

    &:hover, &.active {
        background: var(--color-text-primary);
    }

    @media screen and (max-width: 490px) {
        & {
            padding: 20px;
        }
    }
`;

export const PlanWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 1rem;
    background: #fff;
    border-radius: 16px;
    padding: 40px;

    @media screen and (max-width: 490px) {
        & {
            padding: 20px;
        }
    }
`;

export const PlanSubtitle = styled.p`
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    text-transform: uppercase;
    color: var(--color-text-secondary);

    @media screen and (max-width: 490px) {
        & {
            font-weight: 700;
            font-size: 14px;
            line-height: 17px;
        }
    }
`;

export const PlanTitle = styled.p`
    font-weight: 800;
    font-size: 32px;
    line-height: 32px;
    color: #000624;

    & span{
        font-weight: 500;
        font-size: 16px;
        line-height: 19px;
        color: var(--color-text-secondary);
    }

    @media screen and (max-width: 490px) {
        & {
            font-size: 24px;
            line-height: 32px;
        }
    }
`;

export const PlanDesc = styled.p`
    font-size: 16px;
    line-height: 24px;
    color: var(--color-text-secondary);

    @media screen and (max-width: 490px) {
        & {
            font-size: 14px;
            line-height: 24px;
        }
    }
`;

export const PlanLink = styled.p`
    display: block;
    text-align: center;
    padding: 1.4rem 1rem;
    font-weight: 600;
    font-size: 14px;
    line-height: 140%;
    background: linear-gradient(0deg, #68C9F4 0%, #50ABED 100%);
    box-shadow: 0px 1px 1px rgba(22, 29, 37, 0.1), inset 0px 2px 0px rgba(255, 255, 255, 0.06);
    border-radius: 10px;
    color: #fff;
    text-transform: capitalize;
`;

export const PlanSwitchLabel = styled.label`
    font-family: var(--font-family);
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 28px;
    color:  ${props => props.color || "#3C5366"};
    
    ${props => props.$active && css`
        color: var(--color-primary);
    `}
    text-transform: capitalize;
`;