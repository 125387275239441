import authApi from "../../core/api/axios/baseAxios"
import { Routes } from "../../core/routes/apiRoutes";

const getRoles = async (url) => {
    const response = await authApi.get(url);
    const resData = response.data;
    return resData;
}

const deleteRole = async (id) => {
    const response = await authApi.post(Routes.getRolesUrl+`/delete`, {'role': id, "_method": "DELETE"});
    const resData = response.data;
    return resData;
}

const createRole = async (data) => {
    const response = await authApi.post(Routes.createRoleUrl, data);
    const resData = response.data;
    return resData;
}

const updateRole = async (data) => {
    const response = await authApi.post(Routes.updateRoleUrl, {...data, "_method": "put"});
    const resData = response.data;
    return resData;
}

const roleService = {
    getRoles,
    createRole,
    updateRole,
    deleteRole,
}

export default roleService