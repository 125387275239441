import styled, { css } from "styled-components";

export const TableWrapper = styled.div`
  width: 100%;
  overflow-x: auto;
  overflow-y: hidden;
  scroll-behavior: smooth;
`;

export const ModalTable  = styled.table`
  width: 100%;
  border-collapse: collapse;

  & thead tr td:first-child {
    border-radius: 8px 8px 0px 0px;
  }

  & thead tr td:last-child {
    border-radius: 8px 8px 0px 0px;
  }

  & th {
    padding: 1rem;
    font-weight: 600;
    font-size: 12px;
    line-height: 22px;
    background: none;
    text-transform: uppercase;
    white-space: nowrap;
    text-align: left;
    color: var(--color-text-primary);
  }

  & td {
    padding: 1rem;
    font-weight: 400;
    font-size: 12px;
    line-height: 22px;
    color: var(--color-text-primary);
    text-align: left;
    border-bottom: 1px solid #EAEDF3;


    a {
      color: #3885B5;
    }
  }
`;

export const Table  = styled.table`
  width: 100%;
  border-collapse: collapse;

  & thead tr td:first-child {
    border-radius: 8px 8px 0px 0px;
  }

  & thead tr td:last-child {
    border-radius: 8px 8px 0px 0px;
  }

  & th {
    padding: 1rem;
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    text-transform: uppercase;
    background: #F6F7F9;
    white-space: nowrap;
    text-align: left;
    color: var(--color-text-primary);
  }

  & td {
    padding: 1rem;
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;
    color: #737A91;
    text-align: left;
    border-bottom: 1px solid #EAEDF3;
  }
`;

export const BadgeStyled = styled.span`
  position: relative;
  display: block;
  background: rgba(83, 178, 119, 0.1);
  border-radius: 16px;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  text-align: center;
  color: #ababab;
  padding: .6rem;
  padding-left: 2rem;
  padding-right: 1.25rem;

  &::before {
    content: "";
    width: 4px;
    height: 4px;
    background-color: #5CC685;
    position: absolute;
    top: 50%;
    left: 1rem;
    transform: translateY(-50%);

  }

  ${(props) =>props.success && css`
      color: #5CC685;

      &::before {
        background-color: #5CC685;
      }
  `};

  ${(props) =>props.danger && css`
      background: #FEF3F2;
      color: #EB5757;

      &::before {
        background: #F04438;
      }
  `};

  ${(props) =>props.warning && css`
      background: rgba(247, 144, 9, 0.1);
      color: #F79009;

      &::before {
        background: #F79009;
      }
  `};
`;