import React, {Component} from 'react';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import './partners_carousel.css';

export default class PartnersCarousel extends Component {
    render() {
        const settings = {
            dots: false,
            infinite: true,
            slidesToShow: 5,
            slidesToScroll: 1,
            autoplay: true,
            autoplaySpeed: 4000,
            speed: 2000,
            cssEase: "linear",
            
            responsive: [
                {
                  breakpoint: 1024,
                  settings: {
                   slidesToShow: 3,
                  }
                },
                {
                  breakpoint: 600,
                  settings: {
                   slidesToShow: 2,
                  }
                 }
              ]
          };

        return (
            <Slider {...settings} className="gmc__partners-slider">
                {this.props.partners.map((card, index) => (
                    <div className='gmc__partners-slider_image' key={index.toString()}>
                        <a target="__blank" href={card.url}>
                            <img src={card.imgSrc} alt={card.name}/>
                        </a>
                    </div>
                ))}
            </Slider>
        );
    }
}