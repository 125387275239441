import authApi from "../../core/api/axios/baseAxios"

const getActivityLogs = async (url) => {
    const response = await authApi.get(url);
    const resData = response.data;
    return resData;
}

const activityLogService = {
    getActivityLogs,
}

export default activityLogService