import React, {Component} from 'react';
import SlickSlider from "react-slick";
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import './screenshot_carousel.css';

export default React.forwardRef((props, ref) => { 
    class ScreenshotCarousel extends Component {
   
        render() {
            const settings = {
                dots: false,
                infinite: true,
                slidesToShow: 3,
                slidesToScroll: 1,
                centerMode: true,
                speed: 500,
                arrows: false,
                cssEase: "linear",
                
                responsive: [
                    {
                    breakpoint: 1050,
                    settings: {
                    slidesToShow: 2,
                    centerMode: false,
                    }
                    },
                    {
                    breakpoint: 600,
                    settings: {
                    slidesToShow: 2,
                    centerMode: false,
                    }
                    }
                ]
            }

            return (
                <SlickSlider ref={ref} {...settings} className="gmc__screenshot-slider">
                    {props.list.map((card, index) => (
                        <div className='gmc__screenshot-slider_image' key={card.name}>
                            <img src={card.imgSrc} alt={card.name}/>
                        </div>
                    ))}
                </SlickSlider>
            );

            
        }
    }

    return <ScreenshotCarousel/>;

})