import authApi, {api, sponsorAuthApi} from "../../core/api/axios/baseAxios"
import { Routes } from "../../core/routes/apiRoutes";

const getSponsors = async (url) => {
    const response = await authApi.get(url);
    const resData = response.data;
    return resData;
}

const deleteSponsor = async (email) => {
    const response = await authApi.delete(Routes.getSponsorsUrl+`/${email}`);
    const resData = response.data;
    return resData;
}

const register = async (data) => {
    const response = await api.post(Routes.sponsorRegisterUrl, data);
    const resData = response.data;
    if(response.status === 201 && resData.status === 'success'){
        localStorage.setItem('sponsor', JSON.stringify(resData.sponsor));
        localStorage.setItem('sponsor-token', resData.token);
    }

    return resData.sponsor;
}



const logout = async () => {
    const response = await sponsorAuthApi.post(Routes.sponsorLogoutUrl);
    const resData = response.data;

    if(response.status === 200 && resData.status === 'success'){
        logoutLocal()
    }

    return resData;
    
}

const logoutLocal = async () => {
    localStorage.removeItem('sponsor');
    localStorage.removeItem('sponsor-token');  
}

const sponsorService = {
    getSponsors,
    deleteSponsor,
    register,
    logout,
    logoutLocal,
}

export default sponsorService